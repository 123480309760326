import React from "react";
import Header from "../../components/Layout/Header";
import Footer from "../../components/Layout/Footer";
import SearchModal from "../../components/Layout/Header/SearchModal";
import SiteBreadcrumb from "../../components/Common/Breadcumb";
import ServiceDetailsContent from "./ServiceDetailsContent";
import ServiceDetailContentRight from "./ServiceDetailContentRight";
// Breadcrumbs Image
import breadcrumbsImg from "../../assets/img/bais/bais-konteyner.jpg";

import aboutImg from "../../assets/img/bais/service-4.jpg";

import aboutImgTwo from "../../assets/img/bais/josco.png";

import { useTranslation } from "react-i18next";
import SectionTitle from "../../components/Common/SectionTitle";
import SectionHtmlTitle from "../../components/Common/SectionHtmlTitle/SectionHtmlTitle";

const MultimodalTransportation = () => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Header
        parentMenu="service"
        secondParentMenu="services"
        activeMenu="/multimodal-transport"
      />
      {/* breadcrumb-area-start */}
      <SiteBreadcrumb
        breadcrumbsImg={breadcrumbsImg}
        pageTitle={t("menu.service5")}
        pageCategory="Services"
        pageName={t("menu.service5")}
      />
      {/* breadcrumb-area-end */}
      <ServiceDetailsContent
        componentTitle={t("service.multimodalTitle")}
        componentImg={aboutImg}
        componentDesc={t("service.multimodalContent")}
        componentDescTwo={t("service.multimodalContent1")}
        htmlContent={true}
      />
      <SectionHtmlTitle
        sectionClass="sec-title2 text-center mb-44"
        subtitleClass="sub-text"
        subtitle=""
        titleClass="title-2 why-we-title"
        title={t("service.whyWeTitle")}
        descClass="desc"
        description={
          t("service.whyWeText")
        }
        effectClass="heading-line"
      />
      <ServiceDetailContentRight
        componentTitle={t("JOSCO TAICANG – ÇİN-HAYDARPASA-AVRUPA;")}
        componentImg={aboutImgTwo}
        componentDesc={t("service.multimodalContent2")}
        htmlContent={true}
      />
      <ServiceDetailsContent
        componentImg={aboutImg}
        componentDesc={t("service.multimodalContent3")}
        htmlContent={true}
      />
      <Footer footerClass="rs-footer" />
      <SearchModal />
    </React.Fragment>
  );
};

export default MultimodalTransportation;
