import React from "react";
import Header from "../../components/Layout/Header";
import { useTranslation } from "react-i18next";
import Footer from "../../components/Layout/Footer";
import SearchModal from "../../components/Layout/Header/SearchModal";
import ScrollToTop from "../../components/Common/ScrollTop";
import SiteBreadcrumb from "../../components/Common/Breadcumb";
import AboutMain from "../about/AboutMain";

// Breadcrumbs Background Image
import bannerbg from "../../assets/img/bais/burs_banner.jpg";
import SectionHtmlTitle from "../../components/Common/SectionHtmlTitle/SectionHtmlTitle";

const ScholarShip = () => {
    const { t } = useTranslation();
  return (
    <React.Fragment>
      <Header parentMenu="ScholarShip" activeMenu="/ScholarShip" />
      <main>
        {/* breadcrumb-area-start */}
        <SiteBreadcrumb
          pageTitle={t("scholarShip.title")}
          titleClass="page-title"
          pageName="Burs"
          breadcrumbsImg={bannerbg}
        />
        {/* breadcrumb-area-start */}
        <div
          id="rs-about"
          className="rs-about gray-bg pt-120 pb-120 md-pt-80 md-pb-80"
        >
          <div className="container">
            <div className="row align-items-center">
              {/* <iframe src="https://docs.google.com/forms/d/e/1FAIpQLScTaIv-OvM3EOZxzUxX6KaqaSVjDlPxbEAMqcDd8BhyJ5pFYQ/viewform?embedded=true" width={840} height={2000} frameBorder={0} marginHeight={0} marginWidth={0}></iframe> */}
              <SectionHtmlTitle
                sectionClass="sec-title2 text-center mb-44 why-we-area"
                subtitleClass="sub-text"
                subtitle=""
                titleClass="title-2 why-we-title text-center"
                title={t("scholarShip.contentText")}
              />
            </div>
          </div>
        </div>
        {/* scrolltop-start */}
        <ScrollToTop />
        {/* scrolltop-end */}
      </main>
      <Footer />
      <SearchModal />
    </React.Fragment>
  );
};

export default ScholarShip;
