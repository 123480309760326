import React from 'react';
import BannerHomeFive from '../../components/Banner/BannerHomeFive';
import Service from './ServiceSection';
import About from './AboutSection';
import WhyChooseUs from './WhyChooseUsSection';
import Brand from '../../components/Common/Brand'
import ScrollToTop from '../../components/Common/ScrollTop';
import Project from './ProjectSection';

const HomeFiveMain = () => {
	return (
		<React.Fragment>
			{/* banner-start */}
			<BannerHomeFive />
			{/* banner-start */}

			{/* About-area-start */}
			<About />
			{/* About-area-end */}
			{/* Service-area-start */}
			<Service />
			{/* Service-area-end */}

			{/* WhyChooseUs-area-start */}
			<WhyChooseUs />
			{/* WhyChooseUs-area-end */}

			<Project/>

			{/* brand-area-start */}
			<Brand />
			{/* brand-area-end */}

			{/* scrolltop-start */}
			<ScrollToTop />
			{/* scrolltop-end */}
		</React.Fragment>
	);
}

export default HomeFiveMain;