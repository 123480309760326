import React from "react";
import Header from "../../components/Layout/Header";
import Footer from "../../components/Layout/Footer";
import SearchModal from "../../components/Layout/Header/SearchModal";
import SiteBreadcrumb from "../../components/Common/Breadcumb";
import ServiceDetailsContent from "./ServiceDetailsContent";
import ServiceDetailsContentRight from "./ServiceDetailContentRight";
// Breadcrumbs Image
import breadcrumbsImg from "../../assets/img/bais/breadcrumb-truck.png";
import aboutImg from "../../assets/img/bais/service-3.jpg";
import aboutImgTwo from "../../assets/img/bais/service-5.jpg";
import aboutImgThree from "../../assets/img/bais/service-6.jpg";
import { useTranslation } from "react-i18next";
import SectionTitle from "../../components/Common/SectionTitle";
import ServiceDetailsContentLeft from "./ServiceDetailsContentLeft";
import SectionHtmlTitle from "../../components/Common/SectionHtmlTitle/SectionHtmlTitle";

const HighwayTransports = () => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <Header
        parentMenu="service"
        secondParentMenu="services"
        activeMenu="/road-transport"
      />
      {/* breadcrumb-area-start */}
      <SiteBreadcrumb
        breadcrumbsImg={breadcrumbsImg}
        pageTitle={t("menu.service3")}
        pageCategory="Services"
        pageName={t("menu.service3")}
      />
      <SectionTitle
        sectionClass="sec-title2 text-center mb-44"
        subtitleClass="sub-text"
        subtitle=""
        titleClass="title testi-title"
        title={t("service.threeModuleHeader")}
        descClass="desc"
        description={t("service.threeModuleHeaderContent")}
      />
      {/* breadcrumb-area-end */}
      <ServiceDetailsContentLeft
        componentTitle={t("menu.service8")}
        componentImg={aboutImg}
        componentDesc={t("service.roadContent")}
        htmlContent={true}
      />
      <ServiceDetailsContentRight
        componentTitle={t("menu.service7")}
        componentImg={aboutImgTwo}
        componentDesc={t("service.railWayContent")}
        htmlContent={true}
      />
      <ServiceDetailsContentLeft
        componentTitle={t("menu.service1")}
        componentImg={aboutImgThree}
        componentDesc={t("service.airlineContent")}
        htmlContent={true}
      />
      <SectionHtmlTitle
        sectionClass="sec-title2 text-center mb-44 why-we-area"
        subtitleClass="sub-text"
        subtitle=""
        titleClass="title-2 why-we-title"
        title={t("service.whyWeTitle")}
        descClass="desc"
        description={t("service.whyWeText")}
        effectClass="heading-line"
      />
      <Footer footerClass="rs-footer" />
      <SearchModal />
    </React.Fragment>
  );
};

export default HighwayTransports;
