import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
const MenuItems = (props) => {
    const { parentMenu, secondParentMenu, activeMenu } = props;
    const { t } = useTranslation();

    return (
        <React.Fragment>
            <li className={parentMenu === 'home' ? 'current-menu-item' : ''}><Link to="" as="/">{t("menu.home")}</Link>
            </li>
            <li className={parentMenu === 'about' ? 'current-menu-item' : ''}>
                <Link to="/about" className={activeMenu === "/about" ? "active-menu" : ""}>{t("menu.about")}</Link>
            </li>
            <li className={parentMenu === 'ScholarShip' ? 'current-menu-item' : ''}>
                <Link to="/scholarship" className={activeMenu === "/scholarship" ? "active-menu" : ""}>{t("menu.scholarShip")}</Link>
            </li>

            <li className={parentMenu === 'service' ? 'menu-item-has-children current-menu-item' : 'menu-item-has-children'}>
                <Link to="#">{t("menu.service")}</Link>
                <ul className="sub-menu">
                    <li>
                        <Link to="/service/railway-transport" className={activeMenu === "/railway-transport" ? "active-menu" : ""}>{t("menu.service2")}</Link>
                    </li>
                    <li>
                        <Link to="/service/road-transport" className={activeMenu === "/road-transport" ? "active-menu" : ""}>{t("menu.service3")}</Link>
                    </li>
                    <li>
                        <Link to="/service/sea-transport" className={activeMenu === "/sea-transport" ? "active-menu" : ""}>{t("menu.service4")}</Link>
                    </li>
                    <li>
                        <Link to="/service/multimodal-transport" className={activeMenu === "/multimodal-transport" ? "active-menu" : ""}>{t("menu.service5")}</Link>
                    </li>
                    <li>
                        <Link to="/service/blacksea-transport" lassName={activeMenu === "/blacksea-transport" ? "active-menu" : ""}>{t("menu.service6")}</Link>
                    </li>
                </ul>
            </li>
          
                    <li className={''}>
                <a href="https://www.baisgloballogistics.com" className={activeMenu === "" ? "active-menu" : ""}>{t("menu.eBais")}</a>
            </li>
            <li className={parentMenu === 'contact' ? 'current-menu-item' : ''}>
                <Link to="/contact" className={activeMenu === "/contact" ? "active-menu" : ""}>{t("menu.contact")}</Link>
            </li>
        </React.Fragment>
    );
}

export default MenuItems;