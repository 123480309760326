import React from 'react';
import { Link } from 'react-router-dom';

const SiteBreadcrumb = (props) => {
	const { pageTitle, titleClass, parentCategory, pageCategory, pageName, breadcrumbsImg } = props;

	const breadcrumbsImgStyle = {
		backgroundImage: `url(${breadcrumbsImg})`
	}

	return (
		<div className="rs-breadcrumbs" >
			<div className='rs-breadcrumbs--img'>
				<img src={breadcrumbsImg}>
				</img>
			</div>
			<div className='opacity-background-area'>
			</div>
				<div className="breadcrumbs-inner text-center">
					<h1 className={titleClass ? titleClass : 'page-title'}>{pageTitle ? pageTitle : 'Breadcrumbs'}</h1>
				</div>
		</div>
	);
}

export default SiteBreadcrumb;




