import React from 'react';
import { Link } from 'react-router-dom';

import Logo from '../../../assets/img/logo/baislogo-dark.png';
import { useTranslation } from 'react-i18next';

const TopHeader = () => {
    const { t } = useTranslation();
    return (
        <div className="topbar-area hidden-md">
            <div className="container">
                <div className="row">
                    <div className="col-lg-2">
                        <div className="logo-part">
                            <Link to="/"><img src={Logo} alt="Logo" /></Link>
                        </div>
                    </div>
                    <div className="col-lg-10 text-right">
                        <ul className="rs-contact-info">
                            <li className="contact-part">
                                <i className="flaticon-location"></i>
                                <span className="contact-info">
                                    <span>{t("contact.adress")}</span>
                                    Şenlikköy Mah. Otlukbeli Sok. No:26/5
                                    Florya Istanbul, Bakırköy 34153 TR
                                </span>
                            </li>
                            <li className="contact-part">
                                <i className="flaticon-email"></i>
                                <span className="contact-info">
                                    <span>E-mail</span>
                                    <a href="mailto:info@baisglobal.com.tr"> info@baisglobal.com.tr</a>
                                </span>
                            </li>
                            <li className="contact-part">
                                <i className="flaticon-call"></i>
                                <span className="contact-info">
                                    <span>{t("contact.telephone")}</span>
                                    +90 212 451 21 88
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TopHeader;