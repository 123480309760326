import React from "react";
import { Link } from "react-router-dom";

import SectionTitle from "../../components/Common/SectionTitle";
import aboutImg from "../../assets/img/bais/dokme-yuk.jpeg";
import aboutImg2 from "../../assets/img/bais/parsiyel.jpeg";
import aboutImg3 from "../../assets/img/choose/2.png";
import danceEffectImg from "../../assets/img/about/effect-1.png";
import effectImg1 from "../../assets/img/about/dotted-3.png";
import effectImg2 from "../../assets/img/about/shape3.png";
import { useTranslation } from "react-i18next";
import SectionHtmlTitle from "../../components/Common/SectionHtmlTitle/SectionHtmlTitle";
const AboutMain = () => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      {/* about-area-start */}
      <SectionTitle
        sectionClass="sec-title2 text-center mb-44"
        subtitleClass="sub-text"
        subtitle=""
        titleClass="title testi-title"
        title="BAIS GLOBAL,"
        descClass="desc"
        description={t("home.aboutContent")}
      />
      <SectionTitle
        sectionClass="sec-title2 text-center mb-44"
        subtitleClass="sub-text"
        subtitle=""
        titleClass="title-2 testi-title"
        title={t("about.experienceTitle")}
        descClass="desc"
        description={t("about.experienceContent")}
        effectClass="heading-line"
      />
      <div
        id="rs-about"
        className="rs-about gray-bg pt-120 pb-120 md-pt-80 md-pb-80"
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 md-mb-30">
              <div className="rs-animation-shape">
                <div className="images">
                  <img src={aboutImg} alt="" />
                </div>
                <div className="middle-image2">
                  <img className="dance" src={danceEffectImg} alt="" />
                </div>
              </div>
            </div>
            <div className="col-lg-6 pl-60 md-pl-14">
              <div className="contact-wrap">
                {/* Section Title Start */}
                <SectionHtmlTitle
                  sectionClass="sec-title mb-30"
                  subtitleClass="sub-text style-bg"
                  subtitle="BAİS"
                  titleClass="title pb-36"
                  title={t("about.contentTitle1")}
                  descClass="desc pb-34"
                  description={t("about.content1")}
                  secondDescClass="margin-0 pb-16"
                  secondDescription=""
                />
              </div>
            </div>
          </div>
          <div className="shape-image">
            <img className="top dance" src={effectImg1} alt="" />
            <img className="bottom dance" src={effectImg2} alt="" />
          </div>
        </div>
      </div>
      <div
        id="rs-about"
        className="rs-about gray-bg pt-120 pb-120 md-pt-80 md-pb-80"
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 pl-60 md-pl-14">
              <div className="contact-wrap">
                {/* Section Title Start */}
                <SectionHtmlTitle
                  sectionClass="sec-title mb-30"
                  subtitleClass="sub-text style-bg"
                  subtitle="BAİS"
                  titleClass="title pb-36"
                  title={t("about.contentTitle2")}
                  descClass="desc pb-34"
                  description={t("about.content2")}
                  secondDescClass="margin-0 pb-16"
                  secondDescription=""
                  htmlContent={true}
                />
              </div>
            </div>
            <div className="col-lg-6 md-mb-30">
              <div className="rs-animation-shape">
                <div className="images">
                  <img src={aboutImg2} alt="" />
                </div>
                <div className="middle-image2">
                  <img className="dance" src={danceEffectImg} alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="shape-image">
            <img className="top dance" src={effectImg1} alt="" />
            <img className="bottom dance" src={effectImg2} alt="" />
          </div>
        </div>
      </div>
      <div
        id="rs-about"
        className="rs-about gray-bg pt-120 pb-120 md-pt-80 md-pb-80"
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 md-mb-30">
              <div className="rs-animation-shape">
                <div className="images">
                  <img src={aboutImg3} alt="" />
                </div>
                <div className="middle-image2">
                  <img className="dance" src={danceEffectImg} alt="" />
                </div>
              </div>
            </div>
            <div className="col-lg-6 pl-60 md-pl-14">
              <div className="contact-wrap">
                {/* Section Title Start */}
                <SectionHtmlTitle
                  sectionClass="sec-title mb-30"
                  subtitleClass="sub-text style-bg"
                  subtitle="BAİS"
                  titleClass="title pb-36"
                  title={t("about.contentTitle3")}
                  descClass="desc pb-34"
                  description={t("about.content3")}
                  secondDescClass="margin-0 pb-16"
                  secondDescription=""
                />
              </div>
            </div>
          </div>
          <div className="shape-image">
            <img className="top dance" src={effectImg1} alt="" />
            <img className="bottom dance" src={effectImg2} alt="" />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AboutMain;
