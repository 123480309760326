export const AnchorIcon = ({clasName}) => {
    return (
  
        <svg className={clasName} style={{margin: "0px 3px 4px  1px"}} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" height="12px" width="12px" version="1.1" id="Capa_1" viewBox="0 0 266.955 266.955" xmlSpace="preserve">
          <g>
            <path style={{fill: '#010002'}} d="M255.953,190.297l-18.683-37.132l-37.127,18.7c-5.722,2.883-8.028,9.856-5.145,15.577   c2.883,5.727,9.866,8.017,15.583,5.145l4.351-2.187c-9.562,27.772-36.616,48.696-69.593,52.612V122.004h18.716   c6.407,0,11.602-5.194,11.602-11.602s-5.194-11.607-11.602-11.607h-18.716V86.443c18.656-5.113,32.417-22.164,32.417-42.419   C177.755,19.744,158.011,0,133.737,0S89.719,19.744,89.719,44.024c0,20.255,13.761,37.301,32.417,42.419v12.352h-17.769   c-6.407,0-11.602,5.2-11.602,11.607s5.194,11.602,11.602,11.602h17.769v120.883c-32.183-4.161-58.557-24.525-68.298-51.568   l2.54,1.273c5.738,2.877,12.7,0.577,15.583-5.145c2.883-5.722,0.577-12.695-5.145-15.577l-37.127-18.7l-18.683,37.132   c-2.883,5.722-0.571,12.7,5.145,15.577c1.675,0.843,3.454,1.24,5.211,1.24c4.248,0,8.338-2.339,10.372-6.391l0.528-1.044   c14.37,39.042,54.597,67.27,101.955,67.27c46.738,0,86.497-27.505,101.346-65.742c2.121,3.726,5.983,5.907,10.041,5.907   c1.751,0,3.535-0.392,5.211-1.24C256.524,202.997,258.83,196.018,255.953,190.297z M112.922,44.024   c0-11.482,9.333-20.815,20.815-20.815c11.482,0,20.815,9.333,20.815,20.815c0,11.471-9.339,20.81-20.815,20.81   C122.26,64.834,112.922,55.495,112.922,44.024z" />
          </g>
        </svg>
      )

    };