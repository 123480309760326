import React, { useState } from 'react';
import ModalVideo from 'react-modal-video';
import { Link } from 'react-router-dom';

import bannerImg from '../../assets/img/banner/banner-4.png';
import { useTranslation } from 'react-i18next';
const BannerHomeFive = () => {

    const [isOpen, setIsOpen] = useState(false);
    const openModal = () => setIsOpen(!isOpen);
    const { t } = useTranslation();

    return (
        <React.Fragment>

            {/* <!-- banner section start --> */}
            <div className="rs-banner style3 rs-rain-animate modify1">
            
            <div class="opacity-background-area"></div>
                {/* <ModalVideo  isOpen={isOpen} onClose={() => { openModal(); }} /> */}
                <div className="container">
                    <div className="row">
                        <div className="banner-content">
                            {/* <div className="rs-videos">
                                <div className="animate-border white-color style3">
                                    <a className="popup-border popup-videos" onClick={() => { openModal(); }}>
                                        <i className="fa fa-play"></i>
                                    </a>
                                </div>
                            </div> */}
                            <div className='banner-content-text-area'>
                            <h2 className="title" data-animation="fadeInLeft" data-delay=".3s">{t("home.bannerContent")} </h2>
                            <h2 className="title text-right" data-animation="fadeInLeft" data-delay=".3s">{t("home.bannerContent1")} </h2>
                            </div>
                            <ul className="banner-btn">
                                <li><Link className="readon started" to="/about">{t("menu.about")}</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="images-part hidden-md">
                    </div>
                    <div className="line-inner style2">
                        <div className="line"></div>
                        <div className="line"></div>
                        <div className="line"></div>
                    </div>
                </div>
            </div>
            {/* <!-- banner section end --> */}

        </React.Fragment>
    );
}

export default BannerHomeFive;