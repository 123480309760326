import React from 'react';
import SectionTitle from '../Common/SectionTitle';
import { useTranslation } from 'react-i18next';

const ContactInfo = (props) => {
    const { t } = useTranslation();
    const { contactBoxClass } = props;
    return (
        <div className={contactBoxClass ? contactBoxClass : 'contact-box'}>
            <SectionTitle
                sectionClass="sec-title mb-44"
                subtitleClass="sub-text new-text white-color"
                subtitle=""
                titleClass="title white-color"
                title=""
            />
            <div className="address-box mb-24">
                <div className="address-icon">
                    <i className="fa fa-phone"></i>
                </div>
                <div className="address-text">
                    <span className="label">{t("contact.telephone")}</span>
                    <a href="tel:+90 212 451 21 88">+90 212 451 21 88</a>
                </div>
            </div>
            <div className="address-box mb-24">
                <div className="address-icon">
                    <i className="fa fa-home"></i>
                </div>
                <div className="address-text">
                    <span className="label">{t("contact.email")}:</span>
                    <a href="mailto:info@baisglobal.com.tr">info@baisglobal.com.tr</a>
                </div>
            </div>
            <div className="address-box">
                <div className="address-icon">
                    <i className="fa fa-map-marker"></i>
                </div>
                <div className="address-text">
                    <span className="label">{t("contact.adress")}:</span>
                    <div className="desc">Şenlikköy Mah. Otlukbeli Sok. No:26/5 Florya Istanbul, Bakırköy 34153 TR</div>
                </div>
            </div>
        </div>
    );

}

export default ContactInfo;