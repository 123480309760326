import React from 'react';
import SiteBreadcrumb from '../../components/Common/Breadcumb';
import SectionTitle from '../../components/Common/SectionTitle';
import ContactForm from '../../components/Contact/ContactForm';
import ContactInfo from '../../components/Contact/ContactInfo';
import ScrollToTop from '../../components/Common/ScrollTop';

import bannerbg from '../../assets/img/breadcrumbs/3.jpg';

import { useTranslation } from 'react-i18next';
const ContactMain = () => {
    const { t } = useTranslation();
        return (
            <React.Fragment>

                {/* SiteBreadcrumb */}
                <SiteBreadcrumb
                    pageTitle={t("menu.contact")}
                    pageName={t("menu.contact")}
                    breadcrumbsImg={bannerbg}
                />
                {/* SiteBreadcrumb */}

                {/* Contact Section Start */}
                <div className="rs-contact pt-120 md-pt-80">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 md-mb-60">
                                <ContactInfo />
                            </div>
                            <div className="col-lg-8 pl-70 md-pl-14">
                                <div className="contact-widget">
                                    <SectionTitle
                                        sectionClass="sec-title2 mb-40"
                                        subtitleClass="sub-text contact mb-14"
                                        subtitle={t("menu.contact")}
                                        titleClass="title testi-title"
                                        title=""
                                    />

                                    {/* Contact Form */}
                                    <ContactForm />
                                    {/* Contact Form */}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="map-canvas pt-120 md-pt-80">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d24100.47412138784!2d28.798186999999995!3d40.969233!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14caa307e594f323%3A0x6793a525344cab27!2sBais%20Global%20Logistics!5e0!3m2!1str!2str!4v1704747147488!5m2!1str!2str" width={600} height={450} style={{border: 0}} allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade" />                    </div>

                    {/* scrolltop-start */}
                    <ScrollToTop />
                    {/* scrolltop-end */}
                </div>
                {/* Contact Section End */}
            </React.Fragment>

        );
}


export default ContactMain;