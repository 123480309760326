import React from "react";
import Header from "../../components/Layout/Header";
import Footer from "../../components/Layout/Footer";
import SearchModal from "../../components/Layout/Header/SearchModal";
import SiteBreadcrumb from "../../components/Common/Breadcumb";
import ServiceDetailsContent from "./ServiceDetailsContent";

// Breadcrumbs Image
import aboutImg from "../../assets/img/bais/service-5.jpg";
import breadcrumbsImg from "../../assets/img/bais/breadcrumb-train.png";

import { useTranslation } from "react-i18next";
import SectionHtmlTitle from "../../components/Common/SectionHtmlTitle/SectionHtmlTitle";
const RailWayTransportation = () => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <Header
        parentMenu="service"
        secondParentMenu="services"
        activeMenu="/railway-transport"
      />
      {/* breadcrumb-area-start */}
      <SiteBreadcrumb
        breadcrumbsImg={breadcrumbsImg}
        pageTitle={t("menu.service2")}
        pageCategory="Services"
        pageName={t("menu.service2")}
      />
      {/* breadcrumb-area-end */}
      <ServiceDetailsContent
        componentTitle={t("service.TransitTitle")}
        componentImg={aboutImg}
        componentDesc={t("service.TransitContent")}
        componentDescTwo={t("service.TransitContent1")}
        htmlContent={true}
      />
      <SectionHtmlTitle
        sectionClass="sec-title2 text-center mb-44 why-we-area"
        subtitleClass="sub-text"
        subtitle=""
        titleClass="title-2 why-we-title"
        title={t("service.whyWeTitle")}
        descClass="desc"
        description={t("service.whyWeText")}
        effectClass="heading-line"
      />
      <Footer footerClass="rs-footer" />
      <SearchModal />
    </React.Fragment>
  );
};

export default RailWayTransportation;
