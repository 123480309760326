import React from "react";
import Header from "../../components/Layout/Header";
import Footer from "../../components/Layout/Footer";
import SearchModal from "../../components/Layout/Header/SearchModal";
import SiteBreadcrumb from "../../components/Common/Breadcumb";
import ServiceDetailsContent from "./ServiceDetailsContent";

// Breadcrumbs Image
import breadcrumbsImg from "../../assets/img/bais/breadcrumb-ship.png";

import aboutImg from "../../assets/img/bais/service-2.jpg";

import { useTranslation } from "react-i18next";
import SectionHtmlTitle from "../../components/Common/SectionHtmlTitle/SectionHtmlTitle";

const MaritimeTransport = () => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Header
        parentMenu="service"
        secondParentMenu="services"
        activeMenu="/sea-transport"
      />
      {/* breadcrumb-area-start */}
      <SiteBreadcrumb
        breadcrumbsImg={breadcrumbsImg}
        pageTitle={t("menu.service4")}
        pageCategory="Services"
        pageName={t("menu.service4")}
      />
      {/* breadcrumb-area-end */}
      <ServiceDetailsContent
        componentTitle={t("menu.service4")}
        componentImg={aboutImg}
        componentDesc={t("service.seaTransportContent")}
        htmlContent={true}
      />
      <SectionHtmlTitle
        sectionClass="sec-title2 text-center mb-44 why-we-area"
        subtitleClass="sub-text"
        subtitle=""
        titleClass="title-2 why-we-title"
        title={t("service.whyWeTitle")}
        descClass="desc"
        description={t("service.whyWeText")}
        effectClass="heading-line"
      />
      <Footer footerClass="rs-footer" />
      <SearchModal />
    </React.Fragment>
  );
};

export default MaritimeTransport;
