import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

function LanguageSwitcher() {
  const { i18n } = useTranslation();
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedValue, setselectedValue] = useState("");
  const languageHelper = [
    {
      id: 0,
      value: "TR",
      img: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAALCAIAAAD5gJpuAAAABGdBTUEAAK/INwWK6QAAABl0RVh0U29mdHdhcmUAQWRvYmUgSW1hZ2VSZWFkeXHJZTwAAAF+SURBVHjaYvzPgAD/UNlYEUAAmuTYBgAYhIEgJJmboZgtHbaJKNK8TvozM8LIllwagEY4sIFV1UD/3swngMAaGBn/P3kCVApS7ebG8O/f/x07/n/6BFL36/f/37/+//oFZDPKygJtAAggkIb/YINBqqOjGUxNQeqUlf93dIDV/QLpAWtg+P0bqAEggJhA7gaqBtqoqMjg5PR/+vT/SUn/N2z4//Xr/+XL//Pwgu2BWgJUCxBATCAn/fgJEnVx/Q+05NgxkNzp0/9XrPgvJPR/zZr/ZmZQDX/+AE0HCCCQhv9//4D89OQxMMT+a2uDnKGm9v/SJZCrHj36v28fRAPESQABxALEjGBLGRYv/s/H97+oCOQYIIiM/P/ly/9Fi6CO+QMy9A8DA0AAgTQwg4MMaMD/rq7/vr7/WVlBrv/8GeROiAf+ADWAQgXoHIAAAmlg+v+fQVISbMxfhpMngToZhYUZ+PkZwAaDEDgMgQioGCCAGL+iRiSeOIYAgAADAO/XO1xGA79vAAAAAElFTkSuQmCC"
    },
    {
      id: 1,
      value: "EN",
      img: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAALCAIAAAD5gJpuAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAflJREFUeNpinDRzn5qN3uFDt16+YWBg+Pv339+KGN0rbVP+//2rW5tf0Hfy/2+mr99+yKpyOl3Ydt8njEWIn8f9zj639NC7j78eP//8739GVUUhNUNuhl8//ysKeZrJ/v7z10Zb2PTQTIY1XZO2Xmfad+f7XgkXxuUrVB6cjPVXef78JyMjA8PFuwyX7gAZj97+T2e9o3d4BWNp84K1NzubTjAB3fH0+fv6N3qP/ir9bW6ozNQCijB8/8zw/TuQ7r4/ndvN5mZgkpPXiis3Pv34+ZPh5t23//79Rwehof/9/NDEgMrOXHvJcrllgpoRN8PFOwy/fzP8+gUlgZI/f/5xcPj/69e/37//AUX+/mXRkN555gsOG2xt/5hZQMwF4r9///75++f3nz8nr75gSms82jfvQnT6zqvXPjC8e/srJQHo9P9fvwNtAHmG4f8zZ6dDc3bIyM2LTNlsbtfM9OPHH3FhtqUz3eXX9H+cOy9ZMB2o6t/Pn0DHMPz/b+2wXGTvPlPGFxdcD+mZyjP8+8MUE6sa7a/xo6Pykn1s4zdzIZ6///8zMGpKM2pKAB0jqy4UE7/msKat6Jw5mafrsxNtWZ6/fjvNLW29qv25pQd///n+5+/fxDDVbcc//P/zx/36m5Ub9zL8+7t66yEROcHK7q5bldMBAgwADcRBCuVLfoEAAAAASUVORK5CYII=",
    }
  ];
  useEffect(() => {
    const savedLanguage = localStorage.getItem('language') ? localStorage.getItem('language') : "TR";
    
    if (savedLanguage) {
      const selectedLanguage = languageHelper.find((item) => item.value === savedLanguage);
      setSelectedImage(selectedLanguage?.img);
      setselectedValue(selectedLanguage?.value);
      i18n.changeLanguage(savedLanguage);
    }
  }, []);

  const setLang = (item) => {
    
    localStorage.setItem('language', item?.value);
    i18n.changeLanguage(item.value);
    setSelectedImage(item?.img);
    setselectedValue(item.value);
  };

  // Filter out the selected language from the list
  const filteredLanguageHelper = languageHelper.filter((item) => item?.img !== selectedImage);

  return (
    <div className="dropdown-ls">
      <a className="choice-element">
        <img className="choice-img" src={selectedImage} alt="Selected Image" />
        <span className='choice-element__item__text'>{selectedValue}</span>
      </a>
      <div className="dropdown-content">
        {filteredLanguageHelper.map((item, idx) => {
          return (
            <a className="dropdown-content__item" onClick={() => setLang(item)} key={idx}>
              <img className='choice-img' src={item?.img} alt={item.value} />
              <span className='dropdown-content__item__text'>{item.value}</span>
            </a>
          );
        })}
      </div>
    </div>
  );
}

export default LanguageSwitcher;