import React from 'react';
import Header from '../../components/Layout/Header';
import Footer from '../../components/Layout/Footer';
import SearchModal from '../../components/Layout/Header/SearchModal';
import SiteBreadcrumb from '../../components/Common/Breadcumb';
import ServiceDetailsContent from './ServiceDetailsContent';
import aboutImg from '../../assets/img/bais/service-6.jpg';
// Breadcrumbs Image
import breadcrumbsImg from '../../assets/img/bais/breadcrumb-air.png';
import { useTranslation } from 'react-i18next';

const AirWaysTransporting = () => {
    const { t } = useTranslation();
    return (
        <React.Fragment>
            <Header
                parentMenu='hizmetler'
                secondParentMenu='hizmetler'
                activeMenu='/airline-transport'
            />
            {/* breadcrumb-area-start */}
            <SiteBreadcrumb
                breadcrumbsImg={breadcrumbsImg}
                pageTitle={t("menu.service1")}
                pageCategory='Services'
                pageName="airline-transport"
            />
            {/* breadcrumb-area-end */}
            <ServiceDetailsContent
                componentTitle={t("service.airlineTitle")}
                componentImg={aboutImg}
                componentDesc ={t("service.airlineContent")}
                htmlContent={true}
            />
            <Footer footerClass="rs-footer" />
            <SearchModal />
        </React.Fragment>
    );
}

export default AirWaysTransporting;