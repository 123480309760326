const SectionHtmlTitle = (props) => {
    const { titleClass, subtitleClass, descClass, effectClass, secondDescClass, secondDescription  } = props;

    const htmlContentOne = { __html: props.description };
    const htmlContentTwo = { __html: props.secondDescription };
    return (
        <div className={props.sectionClass}>
            { subtitleClass ? <div className={props.subtitleClass}>{props.subtitle}</div> : ''}
            { titleClass ? <div className={props.titleClass}>{props.title}</div> : ''}
            { descClass ? <div dangerouslySetInnerHTML={htmlContentOne} className={props.descClass}></div> : ''}
            { secondDescClass ? <div dangerouslySetInnerHTML={htmlContentTwo} className={props.descClass}></div> : ''}
            { effectClass ? <div className={props.effectClass}></div> : ''}
        </div>
    );
}

export default SectionHtmlTitle