import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import enTranslations from './resources/en.json';
import trTranslations from './resources/tr.json';
i18n.use(initReactI18next).init({
  lng: 'TR', // Set the default language
  resources: {
    EN: {
      translation: enTranslations,
    },
    TR: {
      translation: trTranslations,
    }
  },
  fallbackLng: 'EN', // Fallback language if a translation is missing
  interpolation: {
    escapeValue: false, // React handles escaping
  },
});

export default i18n;