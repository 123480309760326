import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import SectionTitle from "../../components/Common/SectionTitle";
import ProgressBar from "../../components/Elements/Progress/ProgressBar";

import aboutPart1 from "../../assets/img/vesselShedules/vesselShedulesThree.png";
import aboutPart2 from "../../assets/img/vesselShedules/VesselShedulesOne.png";
import aboutPart3 from "../../assets/img/vesselShedules/vesselShedulesTwo.png";
import Footer from "../../components/Layout/Footer";
import "./style.scss";
import Header from "../../components/Layout/Header";
import { AnchorIcon } from "../../assets/iconSvg/iconSvg";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { vesselShedulesData } from "./vesselShedulesData";

const VesselShedules = (props) => {
  const { t } = useTranslation();
  const { param } = useParams();
  const [detail, setDetail] = useState([]);
  useEffect(() => {
    // Update the state when the language changes
    setDetail(vesselShedulesData.find((obj) => obj.id === Number(param)));
  }, [param]); // Add i18n.language as a dependency

  return (
    <React.Fragment>
      {/* Header */}
      <Header parentMenu="vessel-shedules" activeMenu="/vessel-shedules" />
      {/* Header */}

      <div
        id="rs-about"
        className="rs-about pt-130 pb-190 md-pt-80 md-pb-80 sm-pt-0"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-12">
              <div className="img-wrap" style={{position : "relative"}}>
                <img className="main img-area" src={aboutPart1} alt="" />
                <div className="ly1">
                  <img className="dance" src={aboutPart2} alt="" />
                </div>
                <div className="ly2">
                  <img className="dance2" src={aboutPart3} alt="" />
                </div>
              </div>
            </div>
            <div className="col-lg-9 col-md-12 pl-40 md-pl-14 md-pt-240 sm-pt-66">
              <div className="appointment-style white-bg rs-rain-animate">
                <div className="form-title">
                  <h3 className="title">{t("vesselShedules.title")}</h3>

                  <div className="vc_row wpb_row vc_row-fluid">
                  {detail ? 
                  <>
                    <h5 style={{textAlign : "left"}}> {detail.itemText}</h5>
                  {detail.itemValue} 
                  </>:  <table className="vessel-table">
                      <tbody>
                        <tr>
                          <td style={{ width: "1px" }}>
                            <AnchorIcon />
                          </td>
                          <td>
                            <Link className="vessel-shedules-link" to="/vessel-shedules/1">
                            
                              AMBARLI - CHORNOMORSK
                            </Link>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: "1px" }}>
                            <AnchorIcon />
                          </td>
                          <td>
                          <Link className="vessel-shedules-link" to="/vessel-shedules/2">
                              
                              AMBARLI - Novorossiysk
                            </Link>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: "1px" }}>
                            <AnchorIcon />
                          </td>
                          <td>
                          <Link className="vessel-shedules-link" to="/vessel-shedules/3">
                              
                              AMBARLI - ODESSA
                            </Link>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: "1px" }}>
                            <AnchorIcon />
                          </td>
                          <td>
                          <Link className="vessel-shedules-link" to="/vessel-shedules/4">
                              
                              AMBARLI - PIVDENNYI
                            </Link>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: "1px" }}>
                            <AnchorIcon />
                          </td>
                          <td>
                          <Link className="vessel-shedules-link" to="/vessel-shedules/5">
                              
                              EVYAP - Novorossiysk
                            </Link>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: "1px" }}>
                            <AnchorIcon />
                          </td>
                          <td>
                          <Link className="vessel-shedules-link" to="/vessel-shedules/6">
                              
                              EVYAP - ODESSA
                            </Link>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: "1px" }}>
                            <AnchorIcon />
                          </td>
                          <td>
                          <Link className="vessel-shedules-link" to="/vessel-shedules/7">
                              
                              IZMIR - CHORNOMORSK
                            </Link>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: "1px" }}>
                            <AnchorIcon />
                          </td>
                          <td>
                          <Link className="vessel-shedules-link" to="/vessel-shedules/8">
                      
                              
                              IZMIR - Novorossiysk
                            </Link>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: "1px" }}>
                            <AnchorIcon />
                          </td>
                          <td>
                          <Link className="vessel-shedules-link" to="/vessel-shedules/9">
                              
                              IZMIR - ODESSA
                            </Link>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: "1px" }}>
                            <AnchorIcon />
                          </td>
                          <td>
                          <Link className="vessel-shedules-link" to="/vessel-shedules/10">
                              
                              IZMIR - PIVDENNYI
                            </Link>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: "1px" }}>
                            <AnchorIcon />
                          </td>
                          <td>
                          <Link className="vessel-shedules-link" to="/vessel-shedules/11">
                              
                              IZMIT KORFEZI - PIVDENNYI
                            </Link>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: "1px" }}>
                            <AnchorIcon />
                          </td>
                          <td>
                          <Link className="vessel-shedules-link" to="/vessel-shedules/12">
                              
                              IZMIT KORFEZI - CHORNOMORSK
                            </Link>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: "1px" }}>
                            <AnchorIcon />
                          </td>
                          <td>
                          <Link className="vessel-shedules-link" to="/vessel-shedules/13">
                              
                              IZMIT KORFEZI - Novorossiysk
                            </Link>
                          </td>
                        </tr>
                      </tbody>
                    </table>}
                  </div>
                </div>
                <div className="line-inner style2">
                        <div className="line"></div>
                        <div className="line" style={{display: "none"}}></div>
                        <div className="line"></div>
        </div>
              </div>
            </div>
          </div>
        </div>
      
      </div>

      {/* Footer */}
      <Footer />
      {/* Footer */}

      {/* SearchModal */}
    </React.Fragment>
  );
};

export default VesselShedules;
