import React from 'react';
import { Link } from 'react-router-dom';
import footerLogo1 from '../../../assets/img/logo/baislogo-dark.png';
import { useTranslation } from 'react-i18next';

const Footer = (props) => {
    const { t } = useTranslation();
    const { footerLogo, footerClass } = props;
    return (
        <footer className={footerClass ? footerClass : 'rs-footer'}>
            <div className="footer-top">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-4 col-lg-4 col-md-4">
                            <div className="footer-logo">
                                <Link to="/" ><img src={footerLogo ? footerLogo : footerLogo1} alt="Logo" /></Link>
                                <h5>BAİS GLOBAL LOJİSTİK</h5>
                            </div>
                            <div className="textwidget pb-30">
                                <p>{t("home.aboutContent")}</p>
                            </div>
                            <ul className="footer-social md-mb-30">
                                <li>
                                    <a href="https://www.facebook.com/baisglobal/"><i className="fa fa-facebook"></i></a>
                                </li>
                                <li>
                                    <a href="https://www.instagram.com/baisglobal/?hl=en"><i className="fa fa-instagram"></i></a>
                                </li>
                            </ul>
                        </div>
                        <div className="col-lg-4 col-md-12 col-sm-12 pl-44 md-pl-14 md-mb-30">
                            <h3 className="widget-title">{t("menu.service")}</h3>
                            <ul className="site-map">
                                <li><Link  to="/service/railway-transport">{t("menu.service2")}</Link></li>
                                <li><Link to="/service/road-transport">{t("menu.service3")}</Link></li>
                                <li><Link to="/service/sea-transport">{t("menu.service4")}</Link></li>
                                <li><Link to="/service/multimodal-transport">{t("menu.service5")}</Link></li>
                                <li><Link to="/service/blacksea-transport">{t("menu.service6")}</Link></li>
                            </ul>
                        </div>
                        <div className="col-lg-4 col-md-12 col-sm-12 md-mb-30">
                            <h3 className="widget-title">{t("menu.contact")}</h3>
                            <ul className="address-widget">
                                <li>
                                    <i className="flaticon-location"></i>
                                    <div className="desc">Şenlikköy Mah. Otlukbeli Sok. No:26/5 Florya Istanbul, Bakırköy 34153 TR</div>
                                </li>
                                <li>
                                    <i className="flaticon-call"></i>
                                    <div className="desc">
                                        <Link to="#">+90 212 451 21 88</Link>
                                    </div>
                                </li>
                                <li>
                                    <i className="flaticon-email"></i>
                                    <div className="desc">
                                        <Link to="#">info@baisglobal.com.tr</Link>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
           { /*<FooterBottom /> */}
        </footer>
    );
}

export default Footer;