import { Link } from "react-router-dom";

import bgImg from "../../assets/img/bais/containership_stock-1.jpg";
import { useTranslation } from "react-i18next";

const WhyChooseUs = () => {
  const { t } = useTranslation();
  const bgStyle = {
    backgroundImage: ` url(${bgImg})`,
  };

  return (
    <div
      className="rs-why-choose style3 pt-120 pb-120 md-pt-74 md-pb-80"
      style={bgStyle}
    >
      <div className="rs-why-choose--container">
      </div>
        <div className="container relative">
          <div className="sec-left">
            <h2 className="title white-color">{t("home.canWeDo")}</h2>
          </div>
          <div className="sec-right">
            <h2 className="title white-color">Any Emergency</h2>
          </div>
          <div className="row align-items-center">
            <div className="col-lg-6 md-pb-30">
              <div className="features-content">
                <ul className="features-list">
                  <li>
                    <i className="fa fa-check"></i>
                    <span>{t("portfolio.content")}</span>
                  </li>
                  <li>
                    <i className="fa fa-check"></i>
                    <span>{t("portfolio.content1")}</span>
                  </li>
                  <li>
                    <i className="fa fa-check"></i>
                    <span>{t("portfolio.content2")}</span>
                  </li>
                  <li>
                    <i className="fa fa-check"></i>
                    <span>{t("portfolio.content3")}</span>
                  </li>
                  <li>
                    <i className="fa fa-check"></i>
                    <span>{t("portfolio.content4")}​</span>
                  </li>
                  <li>
                    <i className="fa fa-check"></i>
                    <span>{t("portfolio.content5")}​</span>
                  </li>
                  <li>
                    <i className="fa fa-check"></i>
                    <span>{t("portfolio.content6")}​</span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6 pl-60 md-pl-14">
              <div className="features-wrap">
                <h2 className="sub-title">{t("contact.content")}</h2>
                <h1 className="title">{t("contact.number")}</h1>

                <Link className="readon learn-more learn-btn" to="/contact">
                  {t("contact.content1")}
                </Link>
              </div>
            </div>
          </div>
        </div>
    </div>
  );
};

export default WhyChooseUs;
