import SingleService from '../../components/Service/SingleService';

// Service Image Icons
import serviceIcon1 from '../../assets/img/bais/service-1.jpg';
import serviceIcon2 from '../../assets/img/bais/service-2.jpg';
import serviceIcon3 from '../../assets/img/bais/service-3.jpg';
import serviceIcon4 from '../../assets/img/bais/service-4.jpg';
import serviceIcon5 from '../../assets/img/bais/service-5.jpg';
import serviceIcon6 from '../../assets/img/bais/service-6.jpg';

import bgImg from '../../assets/img/bg/services-bg.jpg';

import { useTranslation } from 'react-i18next';
const bgStyle = {
    backgroundImage: `url(${bgImg})`
}

const Service = () => {
    const {t} = useTranslation(); 
    return (
        <div id="rs-service" className="rs-services main-home style3 pt-120 pb-120 md-pt-80 md-pb-80 rs-rain-animate" style={bgStyle}>
            <div className="container relative">
                <div className="sec-left">
                    <h2 className="title">{t("home.servicesContent")}</h2>
                </div>
                <div className="row">
                    <div className="col-lg-4 col-md-6 mb-24">
                        <SingleService
                            itemClass="services-item"
                            serviceImage={serviceIcon1}
                            serviceURL="service/blacksea-transport"
                            Title={t("menu.service6")}
                            Text={t("home.services6Content")}
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-24">
                        <SingleService
                            itemClass="services-item"
                            serviceImage={serviceIcon2}
                            serviceURL="service/sea-transport"
                            Title={t("menu.service4")}
                            Text={t("home.services4Content")}
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-24">
                        <SingleService
                            itemClass="services-item"
                            serviceImage={serviceIcon3}
                            serviceURL="service/road-transport"
                            Title={t("menu.service3")}
                            Text={t("home.services3Content")}                        
                            />
                    </div>
                    <div className="col-lg-4 col-md-6 md-mb-24">
                        <SingleService
                            itemClass="services-item"
                            serviceImage={serviceIcon4}
                            serviceURL="service/multimodal-transport"
                            Title={t("menu.service5")}
                            Text={t("home.services5Content")}
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 sm-mb-24">
                        <SingleService
                            itemClass="services-item"
                            serviceImage={serviceIcon5}
                            serviceURL="service/railway-transport"
                            Title={t("menu.service2")}
                            Text={t("home.services2Content")}
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 ">
                        <SingleService
                            itemClass="services-item"
                            serviceImage={serviceIcon6}
                            serviceURL="service/road-transport"
                            Title={t("menu.service1")}
                            Text={t("home.services1Content")}
                        />
                    </div>
                </div>
                <div className="line-inner">
                    <div className="line"></div>
                    <div className="line" style={{display: "none"}}></div>
                    <div className="line"></div>
                </div>
            </div>
        </div>
    );
}

export default Service;