import React from "react";
import { Link } from "react-router-dom";
import Brand from "../../components/Common/Brand";
import SectionTitle from "../../components/Common/SectionTitle";
import ScrollToTop from "../../components/Common/ScrollTop";
import aboutImg from "../../assets/img/bais/service-6.jpg";
import danceEffectImg from "../../assets/img/about/effect-1.png";
import SectionHtmlTitle from "../../components/Common/SectionHtmlTitle/SectionHtmlTitle";

const ServiceDetailsContentLeft = ({
  componentTitle,
  componentImg,
  componentDesc,
  componentDescTwo,
  htmlContent = false,
}) => {
  return (
    <React.Fragment>
      {/* About Section Start */}
      <div
        id="rs-about"
        className="rs-about pt-60 pb-60 md-pt-80 md-pb-80"
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 md-mb-30">
              <div className="rs-animation-shape">
                <div className="images">
                  <img src={componentImg} alt="" />
                </div>
                <div className="middle-image2">
                  <img className="dance" src={danceEffectImg} alt="" />
                </div>
              </div>
            </div>
            <div className="col-lg-6 pl-60 md-pl-14">
              <div className="contact-wrap">
                {/* Section Title Start */}
                {htmlContent == true ? (
                  <SectionHtmlTitle
                    sectionClass="sec-title mb-30"
                    subtitleClass="sub-text style-bg"
                    subtitle="Bais"
                    titleClass="title pb-36"
                    title={componentTitle}
                    descClass="desc pb-34"
                    description={componentDesc}
                    secondDescClass="margin-0 pb-16"
                    secondDescription={componentDescTwo}
                  />
                ) : (
                  <SectionTitle
                    sectionClass="sec-title mb-30"
                    subtitleClass="sub-text style-bg"
                    subtitle="Bais"
                    titleClass="title pb-36"
                    title={componentTitle}
                    descClass="desc pb-34"
                    description={componentDesc}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Why Choose US Section Start */}
      {/* Why Choose US Section End */}

      {/* Counter Section Start */}

      {/* price table end */}

      {/* brand-area-start */}  
      {/* brand-area-end */}

      {/* scrolltop-start */}
      <ScrollToTop />
      {/* scrolltop-end */}
    </React.Fragment>
  );
};

export default ServiceDetailsContentLeft;
