import React, {useEffect} from 'react';
import { Route, BrowserRouter as Router, Switch, withRouter } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import i18n from '../i18n';


//Custom Components

import HomeFive from '../pages/home-5';
import About from '../pages/about';
import Services from '../pages/service';
import Contact from '../pages/contact';
import Error from '../pages/404';
import LoadTop from '../components/Common/ScrollTop/LoadTop'
import AirWaysTransporting from '../pages/service/AirWaysTransporting';
import RailWayTransportation from '../pages/service/RailWayTransportation';
import HighwayTransports from '../pages/service/HighwayTransports';
import MaritimeTransport from '../pages/service/MaritimeTransport';
import MultimodalTransportation from '../pages/service/MultimodalTransportation';
import BlackSeaTransportation from '../pages/service/BlackSeaTransportation';
import ScholarShip from '../pages/scholarship/ScholarShip';
import LanguageSwitcher from '../components/LanguageSwitcher/LanguageSwitcher';
import VesselShedules from '../pages/vesselShedules/vesselShedules';


const App = () => {

    useEffect(() => {
        const savedLanguage = localStorage.getItem('language') ? localStorage.getItem('language') : "tr";
        
        if (savedLanguage) {
          i18n.changeLanguage(savedLanguage);
        }
      }, []);

    return (
        <div className='App'>
            <I18nextProvider i18n={i18n}>
            <Router>  
                <LoadTop />          
                <Switch>
                    <Route path="/" exact component={HomeFive} />
                    <Route path="/home" component={HomeFive} />
                    <Route path="/about" component={About} />
                    <Route path="/service" exact component={Services} />
                    <Route path="/service/airline-transport" component={AirWaysTransporting} />
                    <Route path="/service/railway-transport" component={RailWayTransportation} />
                    <Route path="/service/road-transport" component={HighwayTransports} />
                    <Route path="/service/sea-transport" component={MaritimeTransport} />
                    <Route path="/service/multimodal-transport" component={MultimodalTransportation} />
                    <Route path="/service/blacksea-transport" component={BlackSeaTransportation} />
                    <Route path="/scholarship" component={ScholarShip} />
                    <Route path="/contact" component={Contact} />
                    <Route path="/vessel-shedules/:param?" component={VesselShedules}></Route>
                    <Route component={Error} />
                </Switch>
            </Router>
            </I18nextProvider>
        </div>
    );
}

export default App;
