import { Link } from 'react-router-dom';
import SectionTitle from '../../components/Common/SectionTitle';
// About Image Icons
import aboutImg from '../../assets/img/bais/dokme-yuk.jpeg';

import { useTranslation } from 'react-i18next';
const About = () => {
    const {t} = useTranslation(); 
    return (
        <div id="rs-about" className="rs-about relative style3 pt-120 pb-120 md-pt-74 md-pb-80">
            <div className="container relative">
                <div className="row align-items-center">
                    <div className="col-lg-6 pr-56 md-pl-14 md-mb-30">
                        <SectionTitle
                            sectionClass="sec-title mb-30 zIndex"
                            subtitleClass="sub-text"
                            subtitle={t("menu.about")}
                            titleClass="title title3 pb-30"
                            title={t("home.aboutContent2")}
                            descClass="desc pb-30"
                            description={t("home.aboutContent3")}
                                          />
                        <div className="btn-part mt-40">
                            <Link className="readon learn-more learn-btn" to="/about">{t("main.more")}</Link>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="home8-about zIndex">
                            <img 
                                src={aboutImg}
                                alt="images" 
                            />
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    );
}

export default About;