export const vesselShedulesData = [
  {
    id: 1,
    itemText: "AMBARLI - CHORNOMORSK",
    itemValue: (
      <table class="vessel-table">
        <thead>
          <tr>
            <th>Week</th>
            <th>Ship</th>
            <th>ETD</th>
            <th>ETA</th>
            <th>Line</th>
            <th>Voyage</th>
            <th>VesselCategories</th>
            <th>CutOff</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>33</td>
            <td>MAERSK UTAH</td>
            <td>2021-08-30</td>
            <td>2021-09-06</td>
            <td>Sealand</td>
            <td>132W</td>
            <td>AMBARLI - CHORNOMORSK </td>
            <td>2021-08-26</td>
          </tr>
          <tr>
            <td>33</td>
            <td>MAERSK KIMI</td>
            <td>2020-08-17</td>
            <td>2020-09-07</td>
            <td>Sealand</td>
            <td>030W</td>
            <td>AMBARLI - CHORNOMORSK </td>
            <td>2020-08-13</td>
          </tr>
          <tr>
            <td>32</td>
            <td>ORCA I </td>
            <td>2021-08-23</td>
            <td>2021-08-30</td>
            <td>Sealand</td>
            <td> 131W</td>
            <td>AMBARLI - CHORNOMORSK </td>
            <td>2021-08-19</td>
          </tr>
          <tr>
            <td>32</td>
            <td>MAERSK KIMI</td>
            <td>2020-08-11</td>
            <td>2020-09-04</td>
            <td>Sealand</td>
            <td>030W</td>
            <td>AMBARLI - CHORNOMORSK </td>
            <td>2020-08-06</td>
          </tr>
          <tr>
            <td>31</td>
            <td>MAERSK KYRENIA </td>
            <td>2021-08-16</td>
            <td>2021-08-23</td>
            <td>Sealand</td>
            <td>129W</td>
            <td>AMBARLI - CHORNOMORSK </td>
            <td>2021-08-12</td>
          </tr>
          <tr>
            <td>31</td>
            <td>MAERSK KYRENIA</td>
            <td>2020-08-03</td>
            <td>2020-07-18</td>
            <td>Sealand</td>
            <td>029W</td>
            <td>AMBARLI - CHORNOMORSK </td>
            <td>2020-07-28</td>
          </tr>
          <tr>
            <td>30</td>
            <td>MAERSK KYRENIA </td>
            <td>2021-08-10</td>
            <td>2021-08-17</td>
            <td>Sealand</td>
            <td>128W</td>
            <td>AMBARLI - CHORNOMORSK </td>
            <td>2021-08-05</td>
          </tr>
          <tr>
            <td>30</td>
            <td>MAERSK KALMAR</td>
            <td>2020-07-27</td>
            <td>2020-08-13</td>
            <td>Sealand</td>
            <td>028W</td>
            <td>AMBARLI - CHORNOMORSK </td>
            <td>2020-07-23</td>
          </tr>
          <tr>
            <td>29</td>
            <td>MAERSK KIEL</td>
            <td>2021-08-02</td>
            <td>2021-08-09</td>
            <td>Sealand</td>
            <td>127W</td>
            <td>AMBARLI - CHORNOMORSK </td>
            <td>2021-07-29</td>
          </tr>
          <tr>
            <td>29</td>
            <td>MAERSK KLAIPEDA</td>
            <td>2020-07-21</td>
            <td>2020-08-07</td>
            <td>Sealand</td>
            <td>027W</td>
            <td>AMBARLI - CHORNOMORSK </td>
            <td>2020-07-16</td>
          </tr>
          <tr>
            <td>28</td>
            <td>MAERSK KAMPALA</td>
            <td>2021-07-26</td>
            <td>2021-08-03</td>
            <td>Sealand</td>
            <td>126W</td>
            <td>AMBARLI - CHORNOMORSK </td>
            <td>2021-07-13</td>
          </tr>
          <tr>
            <td>28</td>
            <td> MAERSK KITHIRA </td>
            <td>2020-07-14</td>
            <td>2020-07-28</td>
            <td>Sealand</td>
            <td>026W</td>
            <td>AMBARLI - CHORNOMORSK </td>
            <td>2020-07-09</td>
          </tr>
          <tr>
            <td>27</td>
            <td>SANTA CRUZ </td>
            <td>2021-07-19</td>
            <td>2021-07-24</td>
            <td>Sealand</td>
            <td>125W</td>
            <td>AMBARLI - CHORNOMORSK </td>
            <td>2021-07-12</td>
          </tr>
          <tr>
            <td>27</td>
            <td>MAERSK KIEL</td>
            <td>2020-07-07</td>
            <td>2020-07-19</td>
            <td>Sealand</td>
            <td>025W</td>
            <td>AMBARLI - CHORNOMORSK </td>
            <td>2020-07-02</td>
          </tr>
          <tr>
            <td>26</td>
            <td>MAERSK KIMI</td>
            <td>2021-07-12</td>
            <td>2021-07-19</td>
            <td>Sealand</td>
            <td>124W</td>
            <td>AMBARLI - CHORNOMORSK </td>
            <td>2021-07-08</td>
          </tr>
          <tr>
            <td>26</td>
            <td>MAERSK KAMPALA</td>
            <td>2020-06-30</td>
            <td>2020-07-03</td>
            <td>Sealand</td>
            <td>0224W</td>
            <td>AMBARLI - CHORNOMORSK </td>
            <td>2020-06-25</td>
          </tr>
          <tr>
            <td>25</td>
            <td>MAERSK KLAIPEDA</td>
            <td>2021-07-05</td>
            <td>2021-07-12</td>
            <td>Sealand</td>
            <td>123W</td>
            <td>AMBARLI - CHORNOMORSK </td>
            <td>2021-07-01</td>
          </tr>
          <tr>
            <td>24</td>
            <td>MAERSK KALMAR</td>
            <td>2021-06-28</td>
            <td>2021-07-05</td>
            <td>Sealand</td>
            <td>122W</td>
            <td>AMBARLI - CHORNOMORSK </td>
            <td>2021-06-24</td>
          </tr>
          <tr>
            <td>24</td>
            <td>MAERSK KIMI</td>
            <td>2020-06-15</td>
            <td>2020-06-19</td>
            <td>Sealand</td>
            <td>022W</td>
            <td>AMBARLI - CHORNOMORSK </td>
            <td>2020-06-11</td>
          </tr>
          <tr>
            <td>23</td>
            <td>MAERSK KLAIPEDA</td>
            <td>2021-06-21</td>
            <td>2021-06-28</td>
            <td>Sealand</td>
            <td>121W</td>
            <td>AMBARLI - CHORNOMORSK </td>
            <td>2021-06-17</td>
          </tr>
          <tr>
            <td>23</td>
            <td>MAERSK KYRENIA</td>
            <td>2020-06-08</td>
            <td>2020-06-12</td>
            <td>Sealand</td>
            <td>022W</td>
            <td>AMBARLI - CHORNOMORSK </td>
            <td>2020-06-04</td>
          </tr>
          <tr>
            <td>22</td>
            <td> MAERSK KITHIRA </td>
            <td>2021-06-13</td>
            <td>2021-06-20</td>
            <td>Sealand</td>
            <td>120W</td>
            <td>AMBARLI - CHORNOMORSK </td>
            <td>2021-06-10</td>
          </tr>
          <tr>
            <td>22</td>
            <td>SAFMARINE NOMAZWE</td>
            <td>2020-06-04</td>
            <td>2020-06-06</td>
            <td>Sealand</td>
            <td>019E</td>
            <td>AMBARLI - CHORNOMORSK </td>
            <td>2020-06-03</td>
          </tr>
          <tr>
            <td>21</td>
            <td>MAERSK KIEL</td>
            <td>2021-06-07</td>
            <td>2021-06-14</td>
            <td>Sealand</td>
            <td>119W</td>
            <td>AMBARLI - CHORNOMORSK </td>
            <td>2021-05-03</td>
          </tr>
          <tr>
            <td>20</td>
            <td>MAERSK KAMPALA</td>
            <td>2021-05-31</td>
            <td>2021-06-07</td>
            <td>Sealand</td>
            <td>118W</td>
            <td>AMBARLI - CHORNOMORSK </td>
            <td>2021-05-27</td>
          </tr>
          <tr>
            <td>20</td>
            <td> MAERSK KITHIRA </td>
            <td>2020-05-18</td>
            <td>2020-05-22</td>
            <td>Sealand</td>
            <td>018W</td>
            <td>AMBARLI - CHORNOMORSK </td>
            <td>2020-05-14</td>
          </tr>
          <tr>
            <td>19</td>
            <td>SANTA CRUZ </td>
            <td>2021-05-24</td>
            <td>2021-05-31</td>
            <td>Sealand</td>
            <td>117E</td>
            <td>AMBARLI - CHORNOMORSK </td>
            <td>2021-05-20</td>
          </tr>
          <tr>
            <td>19</td>
            <td>MAERSK KIEL</td>
            <td>2020-05-11</td>
            <td>2020-05-15</td>
            <td>Sealand</td>
            <td>017W</td>
            <td>AMBARLI - CHORNOMORSK </td>
            <td>2020-05-07</td>
          </tr>
          <tr>
            <td>18</td>
            <td>MAERSK KIMI</td>
            <td>2021-05-17</td>
            <td>2021-05-24</td>
            <td>Sealand</td>
            <td>116W</td>
            <td>AMBARLI - CHORNOMORSK </td>
            <td>2021-05-10</td>
          </tr>
          <tr>
            <td>18</td>
            <td>MAERSK KAMPALA</td>
            <td>2020-05-04</td>
            <td>2020-05-09</td>
            <td>Sealand</td>
            <td>016W</td>
            <td>AMBARLI - CHORNOMORSK </td>
            <td>2020-04-30</td>
          </tr>
          <tr>
            <td>17</td>
            <td>MAERSK KALMAR</td>
            <td>2021-05-03</td>
            <td>2021-05-10</td>
            <td>Sealand</td>
            <td>114W</td>
            <td>AMBARLI - CHORNOMORSK </td>
            <td>2021-04-29</td>
          </tr>
          <tr>
            <td>17</td>
            <td>SANTA CRUZ </td>
            <td>2020-04-27</td>
            <td>2020-05-01</td>
            <td>Sealand</td>
            <td>015W</td>
            <td>AMBARLI - CHORNOMORSK </td>
            <td>2020-04-23</td>
          </tr>
          <tr>
            <td>16</td>
            <td>MAERSK KALMAR</td>
            <td>2021-05-03</td>
            <td>2021-05-10</td>
            <td>Sealand</td>
            <td>114W</td>
            <td>AMBARLI - CHORNOMORSK </td>
            <td>2021-04-29</td>
          </tr>
          <tr>
            <td>16</td>
            <td>MAERSK KIMI</td>
            <td>2020-04-20</td>
            <td>2020-04-24</td>
            <td>Sealand</td>
            <td>014W</td>
            <td>AMBARLI - CHORNOMORSK </td>
            <td>2020-04-16</td>
          </tr>
          <tr>
            <td>15</td>
            <td>MAERSK KLAIPEDA</td>
            <td>2021-04-26</td>
            <td>2021-05-03</td>
            <td>Sealand</td>
            <td>113W</td>
            <td>AMBARLI - CHORNOMORSK </td>
            <td>2021-04-21</td>
          </tr>
          <tr>
            <td>15</td>
            <td>MAERSK KYRENIA</td>
            <td>2020-04-13</td>
            <td>2020-04-17</td>
            <td>Sealand</td>
            <td>013W</td>
            <td>AMBARLI - CHORNOMORSK </td>
            <td>2020-04-09</td>
          </tr>
          <tr>
            <td>14</td>
            <td> MAERSK KITHIRA </td>
            <td>2021-04-21</td>
            <td>2021-04-29</td>
            <td>Sealand</td>
            <td>112W</td>
            <td>AMBARLI - CHORNOMORSK </td>
            <td>2021-04-15</td>
          </tr>
          <tr>
            <td>14</td>
            <td>MAERSK KALMAR</td>
            <td>2020-04-06</td>
            <td>2020-04-10</td>
            <td>Sealand</td>
            <td>012W</td>
            <td>AMBARLI - CHORNOMORSK </td>
            <td>2020-04-02</td>
          </tr>
          <tr>
            <td>13</td>
            <td>MAERSK KAMPALA</td>
            <td>2021-04-05</td>
            <td>2021-04-12</td>
            <td>Sealand</td>
            <td>110W</td>
            <td>AMBARLI - CHORNOMORSK </td>
            <td>2021-04-01</td>
          </tr>
          <tr>
            <td>13</td>
            <td>MAERSK KLAIPEDA</td>
            <td>2020-03-30</td>
            <td>2020-03-05</td>
            <td>Sealand</td>
            <td></td>
            <td>AMBARLI - CHORNOMORSK </td>
            <td>2020-03-26</td>
          </tr>
          <tr>
            <td>12</td>
            <td>MAERSK KAMPALA</td>
            <td>2021-04-05</td>
            <td>2021-04-12</td>
            <td>Sealand</td>
            <td>110W</td>
            <td>AMBARLI - CHORNOMORSK </td>
            <td>2021-04-01</td>
          </tr>
          <tr>
            <td>12</td>
            <td> MAERSK KITHIRA </td>
            <td>2020-03-23</td>
            <td>2020-03-27</td>
            <td>Sealand</td>
            <td>010W</td>
            <td>AMBARLI - CHORNOMORSK </td>
            <td>2020-03-19</td>
          </tr>
          <tr>
            <td>11</td>
            <td>SANTA CRUZ </td>
            <td>2021-03-29</td>
            <td>2021-04-05</td>
            <td>Sealand</td>
            <td>109E</td>
            <td>AMBARLI - CHORNOMORSK </td>
            <td>2021-03-25</td>
          </tr>
          <tr>
            <td>11</td>
            <td>MAERSK KIEL</td>
            <td>2020-03-16</td>
            <td>2020-03-20</td>
            <td>Sealand</td>
            <td>009W</td>
            <td>AMBARLI - CHORNOMORSK </td>
            <td>2020-03-12</td>
          </tr>
          <tr>
            <td>10</td>
            <td>MAERSK KIMI</td>
            <td>2021-03-22</td>
            <td>2021-03-29</td>
            <td>Sealand</td>
            <td>108W</td>
            <td>AMBARLI - CHORNOMORSK </td>
            <td>2021-03-18</td>
          </tr>
          <tr>
            <td>10</td>
            <td>LICA MAERSK</td>
            <td>2020-03-06</td>
            <td>2020-03-10</td>
            <td>Sealand</td>
            <td>006E</td>
            <td>AMBARLI - CHORNOMORSK </td>
            <td>2020-03-04</td>
          </tr>
          <tr>
            <td>9</td>
            <td>MAERSK KYRENIA</td>
            <td>2021-03-15</td>
            <td>2021-03-22</td>
            <td>Sealand</td>
            <td>107W</td>
            <td>AMBARLI - CHORNOMORSK </td>
            <td>2021-03-11</td>
          </tr>
          <tr>
            <td>9</td>
            <td>LAURA MAERSK</td>
            <td>2020-02-28</td>
            <td>2020-03-03</td>
            <td>Sealand</td>
            <td>005E</td>
            <td>AMBARLI - CHORNOMORSK </td>
            <td>2020-02-26</td>
          </tr>
          <tr>
            <td>8</td>
            <td>MAERSK KALMAR</td>
            <td>2021-03-08</td>
            <td>2021-03-15</td>
            <td>Sealand</td>
            <td>106W</td>
            <td>AMBARLI - CHORNOMORSK </td>
            <td>2021-03-04</td>
          </tr>
          <tr>
            <td>8</td>
            <td> LARS MAERSK</td>
            <td>2020-02-20</td>
            <td>2020-02-23</td>
            <td>Sealand</td>
            <td>004E</td>
            <td>AMBARLI - CHORNOMORSK </td>
            <td>2020-02-19</td>
          </tr>
          <tr>
            <td>7</td>
            <td>MAERSK KLAIPEDA</td>
            <td>2021-03-01</td>
            <td>2021-03-10</td>
            <td>Sealand</td>
            <td>105W</td>
            <td>AMBARLI - CHORNOMORSK </td>
            <td>2021-02-25</td>
          </tr>
          <tr>
            <td>6</td>
            <td> MAERSK KITHIRA </td>
            <td>2021-02-22</td>
            <td>2021-03-01</td>
            <td>Sealand</td>
            <td>104W</td>
            <td>AMBARLI - CHORNOMORSK </td>
            <td>2021-02-18</td>
          </tr>
          <tr>
            <td>5</td>
            <td>MAERSK KIEL</td>
            <td>2021-02-15</td>
            <td>2021-02-22</td>
            <td>Sealand</td>
            <td>103W</td>
            <td>AMBARLI - CHORNOMORSK </td>
            <td>2021-02-11</td>
          </tr>
          <tr>
            <td>5</td>
            <td>MAERSK BINTAN </td>
            <td>2019-12-31</td>
            <td>2020-02-03</td>
            <td>Sealand</td>
            <td>001E</td>
            <td>AMBARLI - CHORNOMORSK </td>
            <td>2020-01-29</td>
          </tr>
          <tr>
            <td>4</td>
            <td>MAERSK KAMPALA</td>
            <td>2021-02-08</td>
            <td>2021-02-20</td>
            <td>Sealand</td>
            <td>102W</td>
            <td>AMBARLI - CHORNOMORSK </td>
            <td>2021-02-04</td>
          </tr>
          <tr>
            <td>3</td>
            <td>SANTA CRUZ </td>
            <td>2021-02-01</td>
            <td>2021-01-14</td>
            <td>Sealand</td>
            <td>101W</td>
            <td>AMBARLI - CHORNOMORSK </td>
            <td>2021-01-28</td>
          </tr>
          <tr>
            <td>2</td>
            <td>MAERSK KIMI</td>
            <td>2021-01-25</td>
            <td>2021-01-31</td>
            <td>Sealand</td>
            <td>053E</td>
            <td>AMBARLI - CHORNOMORSK </td>
            <td>2021-01-21</td>
          </tr>
          <tr>
            <td>1</td>
            <td>MAERSK KYRENIA</td>
            <td>2021-01-18</td>
            <td>2021-01-25</td>
            <td>Sealand</td>
            <td>052W</td>
            <td>AMBARLI - CHORNOMORSK </td>
            <td>2021-01-14</td>
          </tr>
          <tr>
            <td>1</td>
            <td>MAERSK LAUNCESTON</td>
            <td>2021-01-07</td>
            <td>2021-01-10</td>
            <td>Sealand</td>
            <td>050E</td>
            <td>AMBARLI - CHORNOMORSK </td>
            <td>2021-01-06</td>
          </tr>
          <tr>
            <td>52</td>
            <td> MAERSK KITHIRA </td>
            <td>2020-12-28</td>
            <td>2021-01-04</td>
            <td>Sealand</td>
            <td>049W</td>
            <td>AMBARLI - CHORNOMORSK </td>
            <td>2020-12-24</td>
          </tr>
          <tr>
            <td>39</td>
            <td>SAFMARINE NAKURU</td>
            <td>2020-09-26</td>
            <td>2020-10-10</td>
            <td>Sealand</td>
            <td>039S</td>
            <td>AMBARLI - CHORNOMORSK </td>
            <td>2020-09-24</td>
          </tr>
          <tr>
            <td>38</td>
            <td>SAFMARINE NUBA</td>
            <td>2020-09-19</td>
            <td>2020-09-26</td>
            <td>Sealand</td>
            <td>038S</td>
            <td>AMBARLI - CHORNOMORSK </td>
            <td>2020-09-17</td>
          </tr>
          <tr>
            <td>37</td>
            <td>MAERSK KLAIPEDA</td>
            <td>2020-09-14</td>
            <td>2020-09-28</td>
            <td>Sealand</td>
            <td>035W</td>
            <td>AMBARLI - CHORNOMORSK </td>
            <td>2020-09-10</td>
          </tr>
          <tr>
            <td>36</td>
            <td> MAERSK KITHIRA </td>
            <td>2020-09-07</td>
            <td>2020-09-28</td>
            <td>Sealand</td>
            <td>035W</td>
            <td>AMBARLI - CHORNOMORSK </td>
            <td>2020-09-03</td>
          </tr>
          <tr>
            <td>35</td>
            <td>MAERSK KIEL</td>
            <td>2020-09-01</td>
            <td>2020-09-20</td>
            <td>Sealand</td>
            <td>033W</td>
            <td>AMBARLI - CHORNOMORSK </td>
            <td>2020-08-27</td>
          </tr>
          <tr>
            <td>34</td>
            <td>MAERSK KAMPALA</td>
            <td>2020-08-25</td>
            <td>2020-09-16</td>
            <td>Sealand</td>
            <td>032W</td>
            <td>AMBARLI - CHORNOMORSK </td>
            <td>2020-08-20</td>
          </tr>
        </tbody>
      </table>
    ),
  },
  {
    id: 2,
    itemText: "AMBARLI - Novorossiysk",
    itemValue: (
      <table class="vessel-table">
        <thead>
          <tr>
            <th>Week</th>
            <th>Ship</th>
            <th>ETD</th>
            <th>ETA</th>
            <th>Line</th>
            <th>Voyage</th>
            <th>VesselCategories</th>
            <th>CutOff</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>13</td>
            <td>DANIEL A</td>
            <td>2022-04-13</td>
            <td>2022-04-16</td>
            <td>ARKAS</td>
            <td>MRS12N22</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2022-04-11</td>
          </tr>
          <tr>
            <td>13</td>
            <td>ELBE</td>
            <td>2022-04-16</td>
            <td>2022-04-18</td>
            <td>AKKON</td>
            <td>ELB1322</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2022-04-15</td>
          </tr>
          <tr>
            <td>13</td>
            <td>GRETA</td>
            <td>2022-04-16</td>
            <td>2022-04-19</td>
            <td>MARTI</td>
            <td>22-444</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2022-04-14</td>
          </tr>
          <tr>
            <td>13</td>
            <td>SEAGO ISTANBUL</td>
            <td>2021-04-02</td>
            <td>2021-04-07</td>
            <td>Sealand</td>
            <td>112E</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2021-03-31</td>
          </tr>
          <tr>
            <td>13</td>
            <td>ADMIRAL SUN </td>
            <td>2021-04-04</td>
            <td>2021-04-10</td>
            <td>MARTI</td>
            <td>21-101</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2021-04-02</td>
          </tr>
          <tr>
            <td>13</td>
            <td>LUCIEN G A</td>
            <td>2021-04-05</td>
            <td>2021-04-08</td>
            <td>ARKAS</td>
            <td>MRS11N21</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2021-04-02</td>
          </tr>
          <tr>
            <td>13</td>
            <td>SAFMARINE NOMAZWE</td>
            <td>2020-03-27</td>
            <td>2020-04-01</td>
            <td>Sealand</td>
            <td>009E</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2020-03-25</td>
          </tr>
          <tr>
            <td>13</td>
            <td>PACOBA</td>
            <td>2020-03-28</td>
            <td>2020-04-01</td>
            <td>MARTI</td>
            <td>20-304</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2020-03-26</td>
          </tr>
          <tr>
            <td>13</td>
            <td>KARLA A</td>
            <td>2020-03-29</td>
            <td>2020-04-02</td>
            <td>ARKAS</td>
            <td>MRS12N20</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2020-03-27</td>
          </tr>
          <tr>
            <td>12</td>
            <td>ELBE</td>
            <td>2022-04-03</td>
            <td>2022-04-06</td>
            <td>AKKON</td>
            <td>ELB1122</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2022-04-01</td>
          </tr>
          <tr>
            <td>12</td>
            <td>DANIEL A</td>
            <td>2022-04-12</td>
            <td>2022-04-16</td>
            <td>ARKAS</td>
            <td>MRS12N22</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2022-04-18</td>
          </tr>
          <tr>
            <td>12</td>
            <td>SEAGO ISTANBUL</td>
            <td>2021-04-02</td>
            <td>2021-04-07</td>
            <td>Sealand</td>
            <td>109E</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2021-03-31</td>
          </tr>
          <tr>
            <td>12</td>
            <td>GRETA</td>
            <td>2021-03-26</td>
            <td>2021-04-01</td>
            <td>MARTI</td>
            <td>21-417</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2021-03-25</td>
          </tr>
          <tr>
            <td>12</td>
            <td>LUCIEN G A</td>
            <td>2021-04-01</td>
            <td>2021-04-05</td>
            <td>ARKAS</td>
            <td>MRS10N21</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2021-03-26</td>
          </tr>
          <tr>
            <td>12</td>
            <td>SAFMARINE NOKWANDA</td>
            <td>2020-03-20</td>
            <td>2020-03-24</td>
            <td>SEAGO</td>
            <td>008E</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2020-03-18</td>
          </tr>
          <tr>
            <td>12</td>
            <td>PAAVA </td>
            <td>2020-03-21</td>
            <td>2020-03-23</td>
            <td>MARTI</td>
            <td>20-123</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2020-03-19</td>
          </tr>
          <tr>
            <td>11</td>
            <td>ELBE</td>
            <td>2022-03-24</td>
            <td>2022-03-27</td>
            <td>AKKON</td>
            <td>ELB1022</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2022-03-23</td>
          </tr>
          <tr>
            <td>11</td>
            <td>DANIEL A</td>
            <td>2022-03-27</td>
            <td>2022-03-30</td>
            <td>ARKAS</td>
            <td>MRS10N22</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2022-03-25</td>
          </tr>
          <tr>
            <td>11</td>
            <td>LEDA MAERSK</td>
            <td>2021-03-26</td>
            <td>2021-03-30</td>
            <td>Sealand</td>
            <td>108E</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2021-03-24</td>
          </tr>
          <tr>
            <td>11</td>
            <td>LUCIEN G A</td>
            <td>2021-03-28</td>
            <td>2021-04-01</td>
            <td>ARKAS</td>
            <td>MRS11N21</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2021-03-26</td>
          </tr>
          <tr>
            <td>11</td>
            <td>GRETA</td>
            <td>2021-03-20</td>
            <td>2021-03-25</td>
            <td>MARTI</td>
            <td>21-416</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2021-03-18</td>
          </tr>
          <tr>
            <td>11</td>
            <td>LEXA MAERSK</td>
            <td>2020-03-12</td>
            <td>2020-03-16</td>
            <td>Sealand</td>
            <td>011N</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2020-03-11</td>
          </tr>
          <tr>
            <td>11</td>
            <td>PAOLA</td>
            <td>2020-03-14</td>
            <td>2020-03-16</td>
            <td>MARTI</td>
            <td>20-222</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2020-03-13</td>
          </tr>
          <tr>
            <td>11</td>
            <td>KARLA A</td>
            <td>2020-03-23</td>
            <td>2020-03-26</td>
            <td>ARKAS</td>
            <td>MRS08N20</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2020-03-21</td>
          </tr>
          <tr>
            <td>10</td>
            <td>ELBE</td>
            <td>2022-03-18</td>
            <td>2022-03-21</td>
            <td>AKKON</td>
            <td>ELB0922</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2022-03-17</td>
          </tr>
          <tr>
            <td>10</td>
            <td>DANIEL A</td>
            <td>2022-03-24</td>
            <td>2022-03-27</td>
            <td>ARKAS</td>
            <td>MRS10N22</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2022-03-23</td>
          </tr>
          <tr>
            <td>10</td>
            <td>MAERSK LAUNCESTON</td>
            <td>2021-03-19</td>
            <td>2021-03-23</td>
            <td>Sealand</td>
            <td>107E</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2021-03-17</td>
          </tr>
          <tr>
            <td>10</td>
            <td>LUCIEN G A</td>
            <td>2021-03-22</td>
            <td>2021-03-25</td>
            <td>ARKAS</td>
            <td>MRS10N21</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2021-03-19</td>
          </tr>
          <tr>
            <td>10</td>
            <td>LICA MAERSK</td>
            <td>2020-03-06</td>
            <td>2020-03-10</td>
            <td>Sealand</td>
            <td>006E</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2020-03-04</td>
          </tr>
          <tr>
            <td>10</td>
            <td>KARLA A</td>
            <td>2020-03-08</td>
            <td>2020-03-11</td>
            <td>ARKAS</td>
            <td>MRS10N20</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2020-03-06</td>
          </tr>
          <tr>
            <td>9</td>
            <td>ELBE</td>
            <td>2022-03-11</td>
            <td>2022-03-14</td>
            <td>AKKON</td>
            <td>ELB0822</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2022-03-09</td>
          </tr>
          <tr>
            <td>9</td>
            <td>DANIEL A</td>
            <td>2022-03-16</td>
            <td>2022-03-19</td>
            <td>ARKAS</td>
            <td>MRS09N22</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2022-03-11</td>
          </tr>
          <tr>
            <td>9</td>
            <td>SAFMARINE NOMAZWE</td>
            <td>2021-03-12</td>
            <td>2021-03-16</td>
            <td>Sealand</td>
            <td>106E</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2021-03-10</td>
          </tr>
          <tr>
            <td>9</td>
            <td>LUCIEN G A</td>
            <td>2021-03-08</td>
            <td>2021-03-11</td>
            <td>ARKAS</td>
            <td>MRS08N21</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2021-03-05</td>
          </tr>
          <tr>
            <td>9</td>
            <td>LAURA MAERSK</td>
            <td>2020-02-28</td>
            <td>2020-03-03</td>
            <td>Sealand</td>
            <td>005E</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2020-02-26</td>
          </tr>
          <tr>
            <td>9</td>
            <td>KARLA A</td>
            <td>2020-03-01</td>
            <td>2020-03-05</td>
            <td>ARKAS</td>
            <td>MRS09N20</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2020-02-28</td>
          </tr>
          <tr>
            <td>9</td>
            <td>PAAVA </td>
            <td>2020-02-28</td>
            <td>2020-03-03</td>
            <td>MARTI</td>
            <td>20-122</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2020-02-28</td>
          </tr>
          <tr>
            <td>8</td>
            <td> POLAR BRASIL</td>
            <td>2022-03-04</td>
            <td>2022-03-08</td>
            <td>Sealand</td>
            <td>205E</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2022-03-01</td>
          </tr>
          <tr>
            <td>8</td>
            <td>ADMIRAL SUN </td>
            <td>2022-03-05</td>
            <td>2022-03-09</td>
            <td>MARTI</td>
            <td>22-122</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2022-03-03</td>
          </tr>
          <tr>
            <td>8</td>
            <td>ELBE</td>
            <td>2022-03-07</td>
            <td>2022-03-10</td>
            <td>AKKON</td>
            <td>ELB0822</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2022-03-04</td>
          </tr>
          <tr>
            <td>8</td>
            <td>SAFMARINE NOKWANDA</td>
            <td>2021-03-05</td>
            <td>2021-03-09</td>
            <td>Sealand</td>
            <td>105E</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2021-03-03</td>
          </tr>
          <tr>
            <td>8</td>
            <td>ALEXANDRA A</td>
            <td>2021-03-01</td>
            <td>2021-03-05</td>
            <td>ARKAS</td>
            <td>MRS07N21</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2021-03-26</td>
          </tr>
          <tr>
            <td>8</td>
            <td> LARS MAERSK</td>
            <td>2020-02-20</td>
            <td>2020-02-24</td>
            <td>Sealand</td>
            <td>004E</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2020-02-19</td>
          </tr>
          <tr>
            <td>8</td>
            <td>KARLA A</td>
            <td>2020-02-23</td>
            <td>2020-02-27</td>
            <td>ARKAS</td>
            <td>MRS08N20</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2020-02-21</td>
          </tr>
          <tr>
            <td>8</td>
            <td>PAOLA</td>
            <td>2020-02-21</td>
            <td>2020-02-25</td>
            <td>MARTI</td>
            <td> 20-221</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2020-02-20</td>
          </tr>
          <tr>
            <td>7</td>
            <td>SAFMARINE NOMAZWE</td>
            <td>2022-02-25</td>
            <td>2022-03-02</td>
            <td>Sealand</td>
            <td>204E</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2022-02-22</td>
          </tr>
          <tr>
            <td>7</td>
            <td>DANIEL A</td>
            <td>2022-02-21</td>
            <td>2022-02-24</td>
            <td>ARKAS</td>
            <td>MRS07N22</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2022-02-18</td>
          </tr>
          <tr>
            <td>7</td>
            <td>ELBE</td>
            <td>2022-02-22</td>
            <td>2022-02-25</td>
            <td>AKKON</td>
            <td>ELB0622</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2022-02-21</td>
          </tr>
          <tr>
            <td>7</td>
            <td>LEXA MAERSK</td>
            <td>2021-02-26</td>
            <td>2021-03-01</td>
            <td>Sealand</td>
            <td>104E</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2021-02-24</td>
          </tr>
          <tr>
            <td>7</td>
            <td>ALEXANDRA A</td>
            <td>2021-03-02</td>
            <td>2021-03-06</td>
            <td>Sealand</td>
            <td>MRS07N21</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2021-02-26</td>
          </tr>
          <tr>
            <td>7</td>
            <td>LUNA MAERSK</td>
            <td>2020-02-13</td>
            <td>2020-02-17</td>
            <td>Sealand</td>
            <td>003E</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2020-02-12</td>
          </tr>
          <tr>
            <td>7</td>
            <td>PACOBA</td>
            <td>2020-02-15</td>
            <td>2020-02-18</td>
            <td>MARTI</td>
            <td>20-302</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2020-02-13</td>
          </tr>
          <tr>
            <td>6</td>
            <td>DANIEL A</td>
            <td>2022-02-13</td>
            <td>2022-02-16</td>
            <td>ARKAS</td>
            <td>MRS06N22</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2022-02-10</td>
          </tr>
          <tr>
            <td>6</td>
            <td>ADMIRAL SUN </td>
            <td>2022-02-19</td>
            <td>2022-02-21</td>
            <td>MARTI</td>
            <td>SUN 22-121</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2022-02-17</td>
          </tr>
          <tr>
            <td>6</td>
            <td>SAFMARINE NOKWANDA</td>
            <td>2022-02-18</td>
            <td>2022-02-22</td>
            <td>Sealand</td>
            <td>203E</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2022-02-15</td>
          </tr>
          <tr>
            <td>6</td>
            <td>ELBE</td>
            <td>2022-02-13</td>
            <td>2022-02-17</td>
            <td>AKKON</td>
            <td>ELB0522</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2022-02-11</td>
          </tr>
          <tr>
            <td>6</td>
            <td>LEXA MAERSK</td>
            <td>2021-02-26</td>
            <td>2021-03-02</td>
            <td>Sealand</td>
            <td>104E</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2021-02-24</td>
          </tr>
          <tr>
            <td>6</td>
            <td>LAUST MAERSK</td>
            <td>2020-02-06</td>
            <td>2020-02-10</td>
            <td>Sealand</td>
            <td>002E</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2020-02-05</td>
          </tr>
          <tr>
            <td>6</td>
            <td>PAAVA </td>
            <td>2020-02-07</td>
            <td>2020-02-11</td>
            <td>MARTI</td>
            <td>20-121</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2020-02-06</td>
          </tr>
          <tr>
            <td>5</td>
            <td>LEDA MAERSK</td>
            <td>2022-02-11</td>
            <td>2022-02-16</td>
            <td>Sealand</td>
            <td>202E</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2022-02-08</td>
          </tr>
          <tr>
            <td>5</td>
            <td>DANIEL A</td>
            <td>2022-01-31</td>
            <td>2022-02-03</td>
            <td>ARKAS</td>
            <td>MRS05N22</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2022-01-28</td>
          </tr>
          <tr>
            <td>5</td>
            <td>ADMIRAL MOON</td>
            <td>2022-02-12</td>
            <td>2022-02-15</td>
            <td>MARTI</td>
            <td>22-219</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2022-02-10</td>
          </tr>
          <tr>
            <td>5</td>
            <td>LAURA MAERSK</td>
            <td>2021-02-12</td>
            <td>2021-02-16</td>
            <td>Sealand</td>
            <td>102E</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2021-02-10</td>
          </tr>
          <tr>
            <td>5</td>
            <td>GRETA</td>
            <td>2021-02-12</td>
            <td>2021-02-16</td>
            <td>MARTI</td>
            <td>21-411</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2021-02-11</td>
          </tr>
          <tr>
            <td>5</td>
            <td>LUCIEN G A</td>
            <td>2021-02-13</td>
            <td>2021-02-26</td>
            <td>ARKAS</td>
            <td>MRS MRS05N21 05N2</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2021-02-11</td>
          </tr>
          <tr>
            <td>5</td>
            <td>MAERSK BINTAN </td>
            <td>2020-01-31</td>
            <td>2020-02-04</td>
            <td>Sealand</td>
            <td>001E</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2020-01-29</td>
          </tr>
          <tr>
            <td>5</td>
            <td>PAOLA</td>
            <td>2020-01-31</td>
            <td>2020-01-04</td>
            <td>MARTI</td>
            <td>20-220</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2020-01-30</td>
          </tr>
          <tr>
            <td>4</td>
            <td>LAURA MAERSK</td>
            <td>2022-02-04</td>
            <td>2022-02-09</td>
            <td>Sealand</td>
            <td>201E</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2022-02-01</td>
          </tr>
          <tr>
            <td>4</td>
            <td>DANIEL A</td>
            <td>2022-02-07</td>
            <td>2022-02-10</td>
            <td>ARKAS</td>
            <td>MRS05N22</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2022-02-04</td>
          </tr>
          <tr>
            <td>4</td>
            <td>ADMIRAL SUN </td>
            <td>2022-02-06</td>
            <td>2022-02-09</td>
            <td>MARTI</td>
            <td>22-120</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2022-02-03</td>
          </tr>
          <tr>
            <td>4</td>
            <td>ELBE</td>
            <td>2022-02-08</td>
            <td>2022-02-10</td>
            <td>AKKON</td>
            <td>ELB0422</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2022-02-04</td>
          </tr>
          <tr>
            <td>4</td>
            <td> LARS MAERSK</td>
            <td>2021-02-05</td>
            <td>2021-02-09</td>
            <td>Sealand</td>
            <td>101E</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2021-02-03</td>
          </tr>
          <tr>
            <td>4</td>
            <td>GRETA</td>
            <td>2021-02-06</td>
            <td>2021-02-09</td>
            <td>MARTI</td>
            <td>21-411</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2021-02-04</td>
          </tr>
          <tr>
            <td>4</td>
            <td>LUCIEN G A</td>
            <td>2021-02-08</td>
            <td>2021-02-12</td>
            <td>ARKAS</td>
            <td>MRS05N21</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2021-02-08</td>
          </tr>
          <tr>
            <td>51</td>
            <td>ADILIA I</td>
            <td>2021-12-26</td>
            <td>2021-12-30</td>
            <td>AKKON</td>
            <td>0721</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2021-12-24</td>
          </tr>
          <tr>
            <td>51</td>
            <td>DANIEL A</td>
            <td>2021-12-28</td>
            <td>2021-12-29</td>
            <td>ARKAS</td>
            <td>MRS44N21</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2021-12-24</td>
          </tr>
          <tr>
            <td>51</td>
            <td>GRETA</td>
            <td>2021-12-26</td>
            <td>2021-12-28</td>
            <td>MARTI</td>
            <td>21-438</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2021-12-23</td>
          </tr>
          <tr>
            <td>50</td>
            <td>ADILIA I</td>
            <td>2021-12-23</td>
            <td>2021-12-26</td>
            <td>AKKON</td>
            <td>0721</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2021-12-22</td>
          </tr>
          <tr>
            <td>50</td>
            <td>DANIEL A</td>
            <td>2021-12-20</td>
            <td>2021-12-22</td>
            <td>ARKAS</td>
            <td>MRS43N21</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2021-12-17</td>
          </tr>
          <tr>
            <td>50</td>
            <td>MAERSK LAUNCESTON</td>
            <td>2021-12-23</td>
            <td>2021-12-25</td>
            <td>Sealand</td>
            <td>147E</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2021-12-21</td>
          </tr>
          <tr>
            <td>50</td>
            <td>GRETA</td>
            <td>2021-12-19</td>
            <td>2021-12-22</td>
            <td>MARTI</td>
            <td>21-438</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2021-12-16</td>
          </tr>
          <tr>
            <td>49</td>
            <td>ADILIA I</td>
            <td>2021-12-12</td>
            <td>2021-12-14</td>
            <td>AKKON</td>
            <td>0621</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2021-12-10</td>
          </tr>
          <tr>
            <td>49</td>
            <td>SAFMARINE NOMAZWE</td>
            <td>2021-12-16</td>
            <td>2021-12-18</td>
            <td>Sealand</td>
            <td>146E</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2021-12-14</td>
          </tr>
          <tr>
            <td>49</td>
            <td>ADILIA I</td>
            <td>2021-12-15</td>
            <td>2021-12-16</td>
            <td>AKKON</td>
            <td>0721</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2021-12-14</td>
          </tr>
          <tr>
            <td>49</td>
            <td>DANIEL A</td>
            <td>2021-12-13</td>
            <td>2021-12-14</td>
            <td>ARKAS</td>
            <td>MRS43N21</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2021-12-10</td>
          </tr>
          <tr>
            <td>49</td>
            <td>GRETA</td>
            <td>2021-12-11</td>
            <td>2021-12-15</td>
            <td>MARTI</td>
            <td>21-437</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2021-12-08</td>
          </tr>
          <tr>
            <td>48</td>
            <td>ADILIA I</td>
            <td>2021-12-06</td>
            <td>2021-12-07</td>
            <td>AKKON</td>
            <td>0621</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2021-12-03</td>
          </tr>
          <tr>
            <td>48</td>
            <td>DANIEL A</td>
            <td>2021-11-06</td>
            <td>2021-12-07</td>
            <td>ARKAS</td>
            <td>MRS42N21</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2021-12-03</td>
          </tr>
          <tr>
            <td>48</td>
            <td>GRETA</td>
            <td>2021-11-27</td>
            <td>2021-11-30</td>
            <td>MARTI</td>
            <td>21-436</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2021-11-24</td>
          </tr>
          <tr>
            <td>48</td>
            <td>SAFMARINE NOKWANDA</td>
            <td>2021-12-09</td>
            <td>2021-12-11</td>
            <td>Sealand</td>
            <td>145E</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2021-12-07</td>
          </tr>
          <tr>
            <td>47</td>
            <td>DANIEL A</td>
            <td>2021-11-29</td>
            <td>2021-12-02</td>
            <td>ARKAS</td>
            <td>MRS41N21</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2021-11-26</td>
          </tr>
          <tr>
            <td>47</td>
            <td>ADILIA I</td>
            <td>2021-12-04</td>
            <td>2021-12-06</td>
            <td>AKKON</td>
            <td>0621</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2021-12-03</td>
          </tr>
          <tr>
            <td>47</td>
            <td>CSAV TRAIGUEN</td>
            <td>2021-12-02</td>
            <td>2021-12-04</td>
            <td>Sealand</td>
            <td>144E</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2021-11-30</td>
          </tr>
          <tr>
            <td>46</td>
            <td>DANIEL A</td>
            <td>2021-11-22</td>
            <td>2021-11-25</td>
            <td>ARKAS</td>
            <td>MRS40N21</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2021-11-19</td>
          </tr>
          <tr>
            <td>46</td>
            <td>LAURA MAERSK</td>
            <td>2021-11-25</td>
            <td>2021-11-27</td>
            <td>Sealand</td>
            <td>143E</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2021-11-23</td>
          </tr>
          <tr>
            <td>46</td>
            <td>ADILIA I</td>
            <td>2021-11-20</td>
            <td>2021-11-21</td>
            <td>AKKON</td>
            <td>0521</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2021-11-19</td>
          </tr>
          <tr>
            <td>46</td>
            <td>DANIEL A</td>
            <td>2021-11-29</td>
            <td>2021-11-30</td>
            <td>ARKAS</td>
            <td>MRS41N21</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2021-11-26</td>
          </tr>
          <tr>
            <td>45</td>
            <td>ADMIRAL MOON</td>
            <td>2021-11-12</td>
            <td>2021-11-15</td>
            <td>MARTI</td>
            <td>21-215</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2021-11-11</td>
          </tr>
          <tr>
            <td>45</td>
            <td>DANIEL A</td>
            <td>2021-11-15</td>
            <td>2021-11-18</td>
            <td>ARKAS</td>
            <td>MRS39N21</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2021-11-12</td>
          </tr>
          <tr>
            <td>45</td>
            <td>LICA MAERSK</td>
            <td>2021-11-18</td>
            <td>2021-11-20</td>
            <td>Sealand</td>
            <td>142E</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2021-11-16</td>
          </tr>
          <tr>
            <td>45</td>
            <td>ADILIA I</td>
            <td>2021-11-20</td>
            <td>2021-11-21</td>
            <td>AKKON</td>
            <td>L0521</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2021-11-19</td>
          </tr>
          <tr>
            <td>44</td>
            <td> LARS MAERSK</td>
            <td>2021-11-09</td>
            <td>2021-11-11</td>
            <td>Sealand</td>
            <td>141E</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2021-11-09</td>
          </tr>
          <tr>
            <td>44</td>
            <td>ADMIRAL SUN </td>
            <td>2021-11-06</td>
            <td>2021-11-09</td>
            <td>MARTI</td>
            <td>21-115</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2021-11-04</td>
          </tr>
          <tr>
            <td>43</td>
            <td>ADMIRAL MOON</td>
            <td>2021-10-29</td>
            <td>2021-11-01</td>
            <td>MARTI</td>
            <td>21-214</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2021-10-28</td>
          </tr>
          <tr>
            <td>43</td>
            <td>ADILIA I</td>
            <td>2021-10-30</td>
            <td>2021-10-31</td>
            <td>AKKON</td>
            <td>ADL0321</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2021-10-28</td>
          </tr>
          <tr>
            <td>43</td>
            <td>LUNA MAERSK</td>
            <td>2021-11-04</td>
            <td>2021-11-08</td>
            <td>Sealand</td>
            <td>140E</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2021-11-02</td>
          </tr>
          <tr>
            <td>43</td>
            <td>DANIEL A</td>
            <td>2021-11-08</td>
            <td>2021-11-11</td>
            <td>ARKAS</td>
            <td>MRS38N21</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2021-11-05</td>
          </tr>
          <tr>
            <td>43</td>
            <td>ADILIA I</td>
            <td>2021-11-10</td>
            <td>2021-11-12</td>
            <td>AKKON</td>
            <td>0421</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2021-11-09</td>
          </tr>
          <tr>
            <td>42</td>
            <td>DANIEL A</td>
            <td>2021-11-01</td>
            <td>2021-10-04</td>
            <td>ARKAS</td>
            <td>MRS37N21</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2021-10-27</td>
          </tr>
          <tr>
            <td>42</td>
            <td>LAUST MAERSK</td>
            <td>2021-10-28</td>
            <td>2021-10-29</td>
            <td>Sealand</td>
            <td>139E</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2021-10-26</td>
          </tr>
          <tr>
            <td>42</td>
            <td>ADILIA I</td>
            <td>2021-10-23</td>
            <td>2021-10-25</td>
            <td>AKKON</td>
            <td>ADL0221</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2021-10-22</td>
          </tr>
          <tr>
            <td>42</td>
            <td>DANIEL A</td>
            <td>2021-11-01</td>
            <td>2021-10-04</td>
            <td>ARKAS</td>
            <td>MRS37N21</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2021-10-27</td>
          </tr>
          <tr>
            <td>42</td>
            <td>LAUST MAERSK</td>
            <td>2021-10-28</td>
            <td>2021-10-31</td>
            <td>Sealand</td>
            <td>139E</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2021-10-25</td>
          </tr>
          <tr>
            <td>41</td>
            <td>MAX</td>
            <td>2021-10-16</td>
            <td>2021-10-19</td>
            <td>AKKON</td>
            <td>0621</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2021-10-15</td>
          </tr>
          <tr>
            <td>41</td>
            <td>LEDA MAERSK</td>
            <td>2021-10-21</td>
            <td>2021-10-22</td>
            <td>Sealand</td>
            <td>138E</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2021-10-19</td>
          </tr>
          <tr>
            <td>41</td>
            <td>ADMIRAL SUN </td>
            <td>2021-10-15</td>
            <td>2021-10-18</td>
            <td>MARTI</td>
            <td>21-114</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2021-10-14</td>
          </tr>
          <tr>
            <td>41</td>
            <td>DANIEL A</td>
            <td>2021-10-17</td>
            <td>2021-10-21</td>
            <td>ARKAS</td>
            <td>MRS35N21</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2021-10-15</td>
          </tr>
          <tr>
            <td>40</td>
            <td>ADMIRAL SUN </td>
            <td>2021-10-08</td>
            <td>2021-10-11</td>
            <td>MARTI</td>
            <td>21-114</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2021-10-07</td>
          </tr>
          <tr>
            <td>40</td>
            <td>DANIEL A</td>
            <td>2021-10-11</td>
            <td>2021-10-13</td>
            <td>ARKAS</td>
            <td>MRS35N21</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2021-10-08</td>
          </tr>
          <tr>
            <td>40</td>
            <td>MAX</td>
            <td>2021-10-09</td>
            <td>2021-10-11</td>
            <td>AKKON</td>
            <td>0521</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2021-10-08</td>
          </tr>
          <tr>
            <td>40</td>
            <td>MAERSK LAUNCESTON</td>
            <td>2021-10-14</td>
            <td>2021-10-15</td>
            <td>Sealand</td>
            <td>137E</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2021-10-11</td>
          </tr>
          <tr>
            <td>39</td>
            <td>ADMIRAL MOON</td>
            <td>2021-10-03</td>
            <td>2021-10-05</td>
            <td>MARTI</td>
            <td>21-213</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2021-09-30</td>
          </tr>
          <tr>
            <td>39</td>
            <td>ALK</td>
            <td>2021-10-01</td>
            <td>2021-10-05</td>
            <td>AKKON</td>
            <td>1621</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2021-09-30</td>
          </tr>
          <tr>
            <td>39</td>
            <td>DANIEL A</td>
            <td>2021-10-03</td>
            <td>2021-10-10</td>
            <td>ARKAS</td>
            <td>MRS34N21</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2021-10-01</td>
          </tr>
          <tr>
            <td>39</td>
            <td>SAFMARINE NOMAZWE</td>
            <td>2021-10-07</td>
            <td>2021-10-10</td>
            <td>Sealand</td>
            <td>136E</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2021-10-05</td>
          </tr>
          <tr>
            <td>39</td>
            <td>LAURA MAERSK</td>
            <td>2020-10-27</td>
            <td>2020-10-28</td>
            <td>Sealand</td>
            <td>035E</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2020-10-23</td>
          </tr>
          <tr>
            <td>39</td>
            <td>PAAVA </td>
            <td>2020-09-19</td>
            <td>2020-09-21</td>
            <td>MARTI</td>
            <td>20-135</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2020-09-15</td>
          </tr>
          <tr>
            <td>38</td>
            <td>DANIEL A</td>
            <td>2021-09-27</td>
            <td>2021-09-30</td>
            <td>ARKAS</td>
            <td>MRS33N21</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2021-09-24</td>
          </tr>
          <tr>
            <td>38</td>
            <td>MAX</td>
            <td>2021-10-01</td>
            <td>2021-09-02</td>
            <td>AKKON</td>
            <td>MAX0621</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2021-09-27</td>
          </tr>
          <tr>
            <td>38</td>
            <td>ADMIRAL SUN </td>
            <td>2021-09-26</td>
            <td>2021-09-27</td>
            <td>MARTI</td>
            <td>21-113</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2021-09-23</td>
          </tr>
          <tr>
            <td>38</td>
            <td>SAFMARINE NOKWANDA</td>
            <td>2021-09-30</td>
            <td>2021-10-01</td>
            <td>Sealand</td>
            <td>135E</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2021-09-28</td>
          </tr>
          <tr>
            <td>38</td>
            <td> LARS MAERSK</td>
            <td>2020-09-19</td>
            <td>2020-09-23</td>
            <td>Sealand</td>
            <td>034E</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2020-09-16</td>
          </tr>
          <tr>
            <td>38</td>
            <td>PAAVA </td>
            <td>2020-09-18</td>
            <td>2020-09-22</td>
            <td>MARTI</td>
            <td>20-135</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2020-09-17</td>
          </tr>
          <tr>
            <td>38</td>
            <td>LUCIEN G A</td>
            <td>2020-09-20</td>
            <td>2020-09-24</td>
            <td>ARKAS</td>
            <td>MRS33N20</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2020-09-18</td>
          </tr>
          <tr>
            <td>37</td>
            <td>LEXA MAERSK</td>
            <td>2021-09-24</td>
            <td>2021-09-29</td>
            <td>Sealand</td>
            <td>134E</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2021-09-21</td>
          </tr>
          <tr>
            <td>37</td>
            <td>ADMIRAL MOON</td>
            <td>2021-09-17</td>
            <td>2021-09-20</td>
            <td>Sealand</td>
            <td>21-212</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2021-09-16</td>
          </tr>
          <tr>
            <td>37</td>
            <td>DANIEL A</td>
            <td>2021-09-19</td>
            <td>2021-09-21</td>
            <td>ARKAS</td>
            <td>MRS32N21</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2021-09-17</td>
          </tr>
          <tr>
            <td>37</td>
            <td>PAAVA </td>
            <td>2020-09-19</td>
            <td>2020-09-21</td>
            <td>MARTI</td>
            <td>20-135</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2020-09-17</td>
          </tr>
          <tr>
            <td>37</td>
            <td>LUCIEN G A</td>
            <td>2020-09-14</td>
            <td>2020-09-17</td>
            <td>ARKAS</td>
            <td>MRS32N20</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2020-09-09</td>
          </tr>
          <tr>
            <td>37</td>
            <td>LUCIEN G A</td>
            <td>2020-09-14</td>
            <td>2020-09-17</td>
            <td>ARKAS</td>
            <td>MRS32N20</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2020-09-09</td>
          </tr>
          <tr>
            <td>37</td>
            <td>LUCIEN G A</td>
            <td>2020-09-14</td>
            <td>2020-09-17</td>
            <td>ARKAS</td>
            <td>MRS32N20</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2020-09-09</td>
          </tr>
          <tr>
            <td>37</td>
            <td>LUNA MAERSK</td>
            <td>2020-09-10</td>
            <td>2020-09-14</td>
            <td>Sealand</td>
            <td>033E</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2020-09-09</td>
          </tr>
          <tr>
            <td>36</td>
            <td>LAURA MAERSK</td>
            <td>2021-09-17</td>
            <td>2021-09-22</td>
            <td>Sealand</td>
            <td>133E</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2021-09-14</td>
          </tr>
          <tr>
            <td>36</td>
            <td>ADMIRAL SUN </td>
            <td>2021-09-10</td>
            <td>2021-09-13</td>
            <td>MARTI</td>
            <td>21-112</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2021-09-09</td>
          </tr>
          <tr>
            <td>36</td>
            <td>DANIEL A</td>
            <td>2021-09-17</td>
            <td>2021-09-21</td>
            <td>ARKAS</td>
            <td>MRS32N21</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2021-09-17</td>
          </tr>
          <tr>
            <td>36</td>
            <td>LAUST MAERSK</td>
            <td>2020-09-03</td>
            <td>2020-09-07</td>
            <td>Sealand</td>
            <td>032E</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2020-09-02</td>
          </tr>
          <tr>
            <td>36</td>
            <td>LUCIEN G A</td>
            <td>2020-09-06</td>
            <td>2020-09-10</td>
            <td>ARKAS</td>
            <td>MRS31N20</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2020-09-04</td>
          </tr>
          <tr>
            <td>35</td>
            <td>LAURA MAERSK</td>
            <td>2021-09-17</td>
            <td>2021-09-22</td>
            <td>Sealand</td>
            <td>133E</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2021-09-14</td>
          </tr>
          <tr>
            <td>35</td>
            <td>LEDA MAERSK</td>
            <td>2020-08-27</td>
            <td>2020-08-31</td>
            <td>Sealand</td>
            <td>031E</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2020-08-26</td>
          </tr>
          <tr>
            <td>35</td>
            <td>PAAVA </td>
            <td>2020-08-22</td>
            <td>2020-09-24</td>
            <td>MARTI</td>
            <td>20-133</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2020-08-20</td>
          </tr>
          <tr>
            <td>34</td>
            <td>LICA MAERSK</td>
            <td>2021-09-10</td>
            <td>2021-09-15</td>
            <td>Sealand</td>
            <td>132E</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2021-09-07</td>
          </tr>
          <tr>
            <td>34</td>
            <td>ADMIRAL MOON</td>
            <td>2021-09-11</td>
            <td>2021-09-15</td>
            <td>MARTI</td>
            <td>21-211</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2021-09-09</td>
          </tr>
          <tr>
            <td>34</td>
            <td>DANIEL A</td>
            <td>2021-09-14</td>
            <td>2021-09-18</td>
            <td>ARKAS</td>
            <td>MRS32N21</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2021-09-10</td>
          </tr>
          <tr>
            <td>34</td>
            <td>MAERSK LAUNCESTON</td>
            <td>2020-08-20</td>
            <td>2020-08-24</td>
            <td>Sealand</td>
            <td>030E</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2020-08-19</td>
          </tr>
          <tr>
            <td>34</td>
            <td>PAAVA </td>
            <td>2020-08-22</td>
            <td>2020-08-24</td>
            <td>MARTI</td>
            <td>2O-133</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2020-08-20</td>
          </tr>
          <tr>
            <td>34</td>
            <td>LUCIEN G A</td>
            <td>2020-08-30</td>
            <td>2020-09-03</td>
            <td>ARKAS</td>
            <td>MRS30N20</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2020-08-28</td>
          </tr>
          <tr>
            <td>33</td>
            <td>LUNA MAERSK</td>
            <td>2021-08-27</td>
            <td>2021-08-31</td>
            <td>Sealand</td>
            <td>130E</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2021-08-24</td>
          </tr>
          <tr>
            <td>33</td>
            <td>ADMIRAL MOON</td>
            <td>2021-08-20</td>
            <td>2021-08-23</td>
            <td>MARTI</td>
            <td>21-210</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2021-08-19</td>
          </tr>
          <tr>
            <td>33</td>
            <td>DANIEL A</td>
            <td>2021-08-27</td>
            <td>2021-09-02</td>
            <td>MARTI</td>
            <td>MRS30N21</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2021-08-25</td>
          </tr>
          <tr>
            <td>33</td>
            <td>SAFMARINE NOMAZWE</td>
            <td>2020-08-14</td>
            <td>2020-08-18</td>
            <td>Sealand</td>
            <td>029E</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2020-08-12</td>
          </tr>
          <tr>
            <td>33</td>
            <td>PAOLA</td>
            <td>2020-08-15</td>
            <td>2020-08-17</td>
            <td>MARTI</td>
            <td>20-232</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2020-08-13</td>
          </tr>
          <tr>
            <td>32</td>
            <td>DANIEL A</td>
            <td>2021-08-23</td>
            <td>2021-08-26</td>
            <td>ARKAS</td>
            <td>MRS29N21</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2021-08-20</td>
          </tr>
          <tr>
            <td>32</td>
            <td>LAUST MAERSK</td>
            <td>2021-08-20</td>
            <td>2021-08-24</td>
            <td>Sealand</td>
            <td>129E</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2021-08-17</td>
          </tr>
          <tr>
            <td>32</td>
            <td>ADMIRAL SUN </td>
            <td>2021-08-13</td>
            <td>2021-08-19</td>
            <td>MARTI</td>
            <td> 21-110</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2021-08-12</td>
          </tr>
          <tr>
            <td>32</td>
            <td>PAAVA </td>
            <td>2020-08-07</td>
            <td>2020-08-10</td>
            <td>MARTI</td>
            <td>20-132</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2020-08-06</td>
          </tr>
          <tr>
            <td>31</td>
            <td>LEDA MAERSK</td>
            <td>2021-08-12</td>
            <td>2021-08-17</td>
            <td>Sealand</td>
            <td>128E</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2021-08-10</td>
          </tr>
          <tr>
            <td>31</td>
            <td>DANIEL A</td>
            <td>2021-08-08</td>
            <td>2021-08-12</td>
            <td>ARKAS</td>
            <td>MRS27N21</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2021-08-06</td>
          </tr>
          <tr>
            <td>31</td>
            <td>SAFMARINE NOKWANDA</td>
            <td>2020-08-11</td>
            <td>2020-07-15</td>
            <td>Sealand</td>
            <td>028E</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2020-08-05</td>
          </tr>
          <tr>
            <td>31</td>
            <td>PAOLA</td>
            <td>2020-07-31</td>
            <td>2020-08-03</td>
            <td>MARTI</td>
            <td>20-231</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2020-07-29</td>
          </tr>
          <tr>
            <td>30</td>
            <td>MAERSK LAUNCESTON</td>
            <td>2021-08-06</td>
            <td>2021-08-10</td>
            <td>Sealand</td>
            <td>127E</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2021-08-03</td>
          </tr>
          <tr>
            <td>30</td>
            <td>DANIEL A</td>
            <td>2021-08-02</td>
            <td>2021-08-05</td>
            <td>ARKAS</td>
            <td>MRS26N21</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2021-07-30</td>
          </tr>
          <tr>
            <td>30</td>
            <td>LICA MAERSK</td>
            <td>2020-07-24</td>
            <td>2020-07-27</td>
            <td>Sealand</td>
            <td>026E</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2020-07-22</td>
          </tr>
          <tr>
            <td>30</td>
            <td>PAAVA </td>
            <td>2020-07-24</td>
            <td>2020-07-27</td>
            <td>MARTI</td>
            <td> PAAVA 20-131</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2020-07-23</td>
          </tr>
          <tr>
            <td>30</td>
            <td>LUCIEN G A</td>
            <td>2020-07-27</td>
            <td>2020-07-29</td>
            <td>ARKAS</td>
            <td>MRS26N20</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2020-07-24</td>
          </tr>
          <tr>
            <td>29</td>
            <td>SAFMARINE NOMAZWE</td>
            <td>2021-07-29</td>
            <td>2021-08-03</td>
            <td>Sealand</td>
            <td>126E</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2021-07-27</td>
          </tr>
          <tr>
            <td>29</td>
            <td>ADMIRAL SUN </td>
            <td>2021-07-29</td>
            <td>2021-08-01</td>
            <td>MARTI</td>
            <td> 21-108</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2021-07-27</td>
          </tr>
          <tr>
            <td>29</td>
            <td>TURKON EGYPT</td>
            <td>2021-07-29</td>
            <td>2021-08-02</td>
            <td>ARKAS</td>
            <td>TLS21S21</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2021-07-26</td>
          </tr>
          <tr>
            <td>29</td>
            <td>MONTE ACONCAGUA </td>
            <td>2020-07-17</td>
            <td>2020-07-21</td>
            <td>Sealand</td>
            <td>026E</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2020-07-14</td>
          </tr>
          <tr>
            <td>29</td>
            <td>PAOLA</td>
            <td>2020-07-17</td>
            <td>2020-07-20</td>
            <td>MARTI</td>
            <td>20-230</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2020-07-16</td>
          </tr>
          <tr>
            <td>29</td>
            <td>LUCIEN G A</td>
            <td>2020-07-19</td>
            <td>2020-07-23</td>
            <td>ARKAS</td>
            <td>MRS25N20</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2020-07-17</td>
          </tr>
          <tr>
            <td>28</td>
            <td>SEAGO ISTANBUL</td>
            <td>2021-07-23</td>
            <td>2021-07-28</td>
            <td>Sealand</td>
            <td>125E</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2021-07-13</td>
          </tr>
          <tr>
            <td>28</td>
            <td>ADMIRAL SUN </td>
            <td>2021-07-16</td>
            <td>2021-07-19</td>
            <td>MARTI</td>
            <td> 21-108</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2021-07-14</td>
          </tr>
          <tr>
            <td>28</td>
            <td>MONTE ACONCAGUA </td>
            <td>2020-07-17</td>
            <td>2020-07-22</td>
            <td>Sealand</td>
            <td>026E</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2020-07-14</td>
          </tr>
          <tr>
            <td>28</td>
            <td>PAAVA </td>
            <td>2020-07-11</td>
            <td>2020-07-14</td>
            <td>MARTI</td>
            <td>M/V PAAVA 20-130</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2020-07-09</td>
          </tr>
          <tr>
            <td>28</td>
            <td>LUCIEN G A</td>
            <td>2020-07-13</td>
            <td>2020-07-16</td>
            <td>ARKAS</td>
            <td>MRS24N20</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2020-07-10</td>
          </tr>
          <tr>
            <td>27</td>
            <td>LEXA MAERSK</td>
            <td>2021-07-15</td>
            <td>2021-07-20</td>
            <td>Sealand</td>
            <td>124E</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2021-07-12</td>
          </tr>
          <tr>
            <td>27</td>
            <td>ADMIRAL MOON</td>
            <td>2021-07-02</td>
            <td>2021-07-05</td>
            <td>MARTI</td>
            <td>21-207</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2021-07-01</td>
          </tr>
          <tr>
            <td>27</td>
            <td>LUNA MAERSK</td>
            <td>2020-07-03</td>
            <td>2020-07-07</td>
            <td>Sealand</td>
            <td>023E</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2020-07-01</td>
          </tr>
          <tr>
            <td>27</td>
            <td>PAOLA</td>
            <td>2020-07-04</td>
            <td>2020-07-07</td>
            <td>MARTI</td>
            <td>20-229</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2020-07-02</td>
          </tr>
          <tr>
            <td>27</td>
            <td>LUCIEN G A</td>
            <td>2020-07-06</td>
            <td>2020-07-09</td>
            <td>ARKAS</td>
            <td>MRS23N20</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2020-07-03</td>
          </tr>
          <tr>
            <td>26</td>
            <td>SEAGO ISTANBUL</td>
            <td>2021-07-09</td>
            <td>2021-07-14</td>
            <td>Sealand</td>
            <td>123E</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2021-07-06</td>
          </tr>
          <tr>
            <td>26</td>
            <td>ADMIRAL SUN </td>
            <td>2021-07-01</td>
            <td>2021-07-05</td>
            <td>MARTI</td>
            <td>21-107</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2021-06-30</td>
          </tr>
          <tr>
            <td>26</td>
            <td>LAUST MAERSK</td>
            <td>2020-06-25</td>
            <td>2020-06-29</td>
            <td>Sealand</td>
            <td>022E</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2020-06-24</td>
          </tr>
          <tr>
            <td>26</td>
            <td>PAAVA </td>
            <td>2020-06-27</td>
            <td>2020-06-29</td>
            <td>MARTI</td>
            <td> 20-129</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2020-06-25</td>
          </tr>
          <tr>
            <td>26</td>
            <td>LUCIEN G A</td>
            <td>2020-06-29</td>
            <td>2020-07-01</td>
            <td>ARKAS</td>
            <td>MRS22N20</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2020-06-26</td>
          </tr>
          <tr>
            <td>26</td>
            <td>BERNARD A</td>
            <td>2020-06-27</td>
            <td>2020-07-02</td>
            <td>MLH</td>
            <td>026</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2020-06-26</td>
          </tr>
          <tr>
            <td>25</td>
            <td>LAURA MAERSK</td>
            <td>2021-07-02</td>
            <td>2021-07-07</td>
            <td>Sealand</td>
            <td>122E</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2021-06-29</td>
          </tr>
          <tr>
            <td>25</td>
            <td>ADMIRAL MOON</td>
            <td>2021-06-25</td>
            <td>2021-07-30</td>
            <td>MARTI</td>
            <td>21-206</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2021-06-23</td>
          </tr>
          <tr>
            <td>25</td>
            <td>KARLA A</td>
            <td>2021-07-04</td>
            <td>2021-07-08</td>
            <td>ARKAS</td>
            <td>MRS23N21</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2021-07-02</td>
          </tr>
          <tr>
            <td>25</td>
            <td>LEDA MAERSK</td>
            <td>2020-06-18</td>
            <td>2020-06-23</td>
            <td>Sealand</td>
            <td>021E</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2020-06-17</td>
          </tr>
          <tr>
            <td>25</td>
            <td>PAOLA</td>
            <td>2020-06-20</td>
            <td>2020-06-22</td>
            <td>MARTI</td>
            <td>20-228</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2020-06-18</td>
          </tr>
          <tr>
            <td>25</td>
            <td>LUCIEN G A</td>
            <td>2020-06-22</td>
            <td>2020-06-25</td>
            <td>ARKAS</td>
            <td>MRS21N20</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2020-06-19</td>
          </tr>
          <tr>
            <td>24</td>
            <td>LICA MAERSK</td>
            <td>2021-09-10</td>
            <td>2021-09-15</td>
            <td>Sealand</td>
            <td>132E</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2021-09-07</td>
          </tr>
          <tr>
            <td>24</td>
            <td> LARS MAERSK</td>
            <td>2021-06-25</td>
            <td>2021-06-30</td>
            <td>Sealand</td>
            <td>122E</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2021-06-22</td>
          </tr>
          <tr>
            <td>24</td>
            <td>ADMIRAL SUN </td>
            <td>2021-06-19</td>
            <td>2021-06-23</td>
            <td>MARTI</td>
            <td>21-106</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2021-06-17</td>
          </tr>
          <tr>
            <td>24</td>
            <td>KARLA A</td>
            <td>2021-06-28</td>
            <td>2021-07-01</td>
            <td>ARKAS</td>
            <td>MRS22N21</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2021-06-25</td>
          </tr>
          <tr>
            <td>24</td>
            <td>MAERSK LAUNCESTON</td>
            <td>2020-06-11</td>
            <td>2020-06-15</td>
            <td>Sealand</td>
            <td>020E</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2020-06-10</td>
          </tr>
          <tr>
            <td>24</td>
            <td>PAAVA </td>
            <td>2020-06-13</td>
            <td>2020-06-15</td>
            <td>MARTI</td>
            <td>20-128</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2020-06-11</td>
          </tr>
          <tr>
            <td>24</td>
            <td>LUCIEN G A</td>
            <td>2020-06-15</td>
            <td>2020-06-18</td>
            <td>ARKAS</td>
            <td>MRS20N20</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2020-06-12</td>
          </tr>
          <tr>
            <td>23</td>
            <td>LUNA MAERSK</td>
            <td>2021-06-18</td>
            <td>2021-06-22</td>
            <td>Sealand</td>
            <td>120E</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2021-06-15</td>
          </tr>
          <tr>
            <td>23</td>
            <td>ADMIRAL MOON</td>
            <td>2021-06-12</td>
            <td>2021-06-16</td>
            <td>MARTI</td>
            <td>21-205</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2021-06-10</td>
          </tr>
          <tr>
            <td>23</td>
            <td>KARLA A</td>
            <td>2021-06-21</td>
            <td>2021-06-24</td>
            <td>ARKAS</td>
            <td>MRS21N21</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2021-06-18</td>
          </tr>
          <tr>
            <td>23</td>
            <td>SAFMARINE NOMAZWE</td>
            <td>2020-06-04</td>
            <td>2020-06-08</td>
            <td>Sealand</td>
            <td>019E</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2020-06-03</td>
          </tr>
          <tr>
            <td>23</td>
            <td>PAOLA</td>
            <td>2020-06-05</td>
            <td>2020-06-08</td>
            <td>MARTI</td>
            <td> M/V PAOLA 20-227</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2020-06-04</td>
          </tr>
          <tr>
            <td>23</td>
            <td>LUCIEN G A</td>
            <td>2020-06-08</td>
            <td>2020-06-11</td>
            <td>ARKAS</td>
            <td>MRS19N20</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2020-06-05</td>
          </tr>
          <tr>
            <td>22</td>
            <td>SEAGO ISTANBUL</td>
            <td>2021-06-11</td>
            <td>2021-06-16</td>
            <td>Sealand</td>
            <td>119E</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2021-06-08</td>
          </tr>
          <tr>
            <td>22</td>
            <td>ADMIRAL MOON</td>
            <td>2021-06-12</td>
            <td>2021-06-16</td>
            <td>MARTI</td>
            <td> 21-205</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2021-06-09</td>
          </tr>
          <tr>
            <td>22</td>
            <td>KARLA A</td>
            <td>2021-06-07</td>
            <td>2021-06-11</td>
            <td>ARKAS</td>
            <td>MRS19N21</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2021-06-04</td>
          </tr>
          <tr>
            <td>22</td>
            <td>LUCIEN G A</td>
            <td>2020-05-02</td>
            <td>2020-06-05</td>
            <td>ARKAS</td>
            <td>MRS19N20</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2020-06-01</td>
          </tr>
          <tr>
            <td>22</td>
            <td>SAFMARINE NOMAZWE</td>
            <td>2020-06-04</td>
            <td>2020-06-08</td>
            <td>Sealand</td>
            <td>019E</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2020-06-03</td>
          </tr>
          <tr>
            <td>21</td>
            <td>LEDA MAERSK</td>
            <td>2021-06-04</td>
            <td>2021-06-06</td>
            <td>Sealand</td>
            <td>118E</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2021-06-01</td>
          </tr>
          <tr>
            <td>21</td>
            <td>GRETA</td>
            <td>2021-05-30</td>
            <td>2021-06-04</td>
            <td>MARTI</td>
            <td>21-426</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2021-05-27</td>
          </tr>
          <tr>
            <td>21</td>
            <td>KARLA A</td>
            <td>2021-06-02</td>
            <td>2021-06-07</td>
            <td>ARKAS</td>
            <td>MRS15N21</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2021-05-30</td>
          </tr>
          <tr>
            <td>21</td>
            <td>LEXA MAERSK</td>
            <td>2020-05-29</td>
            <td>2020-06-02</td>
            <td>Sealand</td>
            <td>017E</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2020-05-27</td>
          </tr>
          <tr>
            <td>21</td>
            <td>PAOLA</td>
            <td>2020-05-22</td>
            <td>2020-05-25</td>
            <td>Sealand</td>
            <td>20-226</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2020-05-21</td>
          </tr>
          <tr>
            <td>21</td>
            <td>LUCIEN G A</td>
            <td>2020-05-26</td>
            <td>2020-05-29</td>
            <td>ARKAS</td>
            <td>MRS18N20</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2020-05-22</td>
          </tr>
          <tr>
            <td>20</td>
            <td>MAERSK LAUNCESTON</td>
            <td>2021-05-27</td>
            <td>2021-06-01</td>
            <td>Sealand</td>
            <td>117E</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2021-05-25</td>
          </tr>
          <tr>
            <td>20</td>
            <td>GRETA</td>
            <td>2021-05-22</td>
            <td>2021-06-27</td>
            <td>MARTI</td>
            <td>21-425</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2021-05-20</td>
          </tr>
          <tr>
            <td>20</td>
            <td>KARLA A</td>
            <td>2021-05-27</td>
            <td>2021-06-01</td>
            <td>ARKAS</td>
            <td>MRS14N21</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2021-05-26</td>
          </tr>
          <tr>
            <td>20</td>
            <td>LICA MAERSK</td>
            <td>2020-05-15</td>
            <td>2020-05-19</td>
            <td>Sealand</td>
            <td>016E</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2020-05-13</td>
          </tr>
          <tr>
            <td>20</td>
            <td>LUCIEN G A</td>
            <td>2020-05-18</td>
            <td>2020-05-22</td>
            <td>ARKAS</td>
            <td>MRS17N20</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2020-05-15</td>
          </tr>
          <tr>
            <td>19</td>
            <td>SAFMARINE NOMAZWE</td>
            <td>2021-05-21</td>
            <td>2021-05-26</td>
            <td>Sealand</td>
            <td>116E</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2021-05-17</td>
          </tr>
          <tr>
            <td>19</td>
            <td>GRETA</td>
            <td>2021-05-14</td>
            <td>2021-05-19</td>
            <td>MARTI</td>
            <td>21-424</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2021-05-11</td>
          </tr>
          <tr>
            <td>19</td>
            <td>PAOLA</td>
            <td>2020-05-09</td>
            <td>2020-05-11</td>
            <td>MARTI</td>
            <td>20-225</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2020-05-07</td>
          </tr>
          <tr>
            <td>19</td>
            <td>LAURA MAERSK</td>
            <td>2020-05-08</td>
            <td>2020-05-12</td>
            <td>Sealand</td>
            <td>015E</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2020-05-06</td>
          </tr>
          <tr>
            <td>18</td>
            <td>SAFMARINE NOKWANDA</td>
            <td>2021-05-14</td>
            <td>2021-05-19</td>
            <td>Sealand</td>
            <td>115E</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2021-05-10</td>
          </tr>
          <tr>
            <td>18</td>
            <td>GRETA</td>
            <td>2021-05-08</td>
            <td>2021-05-13</td>
            <td>MARTI</td>
            <td>21-423</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2021-05-06</td>
          </tr>
          <tr>
            <td>18</td>
            <td> LARS MAERSK</td>
            <td>2020-05-01</td>
            <td>2020-05-05</td>
            <td>Sealand</td>
            <td>014E</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2020-05-29</td>
          </tr>
          <tr>
            <td>18</td>
            <td>PAAVA </td>
            <td>2020-05-01</td>
            <td>2020-05-04</td>
            <td>MARTI</td>
            <td>20-125</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2020-04-30</td>
          </tr>
          <tr>
            <td>17</td>
            <td>LEXA MAERSK</td>
            <td>2021-05-07</td>
            <td>2021-05-12</td>
            <td>Sealand</td>
            <td>114E</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2021-05-05</td>
          </tr>
          <tr>
            <td>17</td>
            <td>GRETA</td>
            <td>2021-05-01</td>
            <td>2021-05-07</td>
            <td>MARTI</td>
            <td> 21-422</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2021-03-29</td>
          </tr>
          <tr>
            <td>17</td>
            <td>LUNA MAERSK</td>
            <td>2020-04-24</td>
            <td>2020-04-28</td>
            <td>Sealand</td>
            <td>013E</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2020-04-20</td>
          </tr>
          <tr>
            <td>17</td>
            <td>PAOLA</td>
            <td>2020-04-24</td>
            <td>2020-04-27</td>
            <td>MARTI</td>
            <td>20-124</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2020-04-23</td>
          </tr>
          <tr>
            <td>16</td>
            <td>SEAGO ISTANBUL</td>
            <td>2021-04-30</td>
            <td>2021-05-05</td>
            <td>Sealand</td>
            <td>113E</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2021-04-28</td>
          </tr>
          <tr>
            <td>16</td>
            <td>GRETA</td>
            <td>2021-04-16</td>
            <td>2021-04-20</td>
            <td>MARTI</td>
            <td>21-420</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2021-04-14</td>
          </tr>
          <tr>
            <td>16</td>
            <td>LAUST MAERSK</td>
            <td>2020-04-17</td>
            <td>2020-04-21</td>
            <td>Sealand</td>
            <td>012E</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2020-04-13</td>
          </tr>
          <tr>
            <td>16</td>
            <td>PAAVA </td>
            <td>2020-04-17</td>
            <td>2020-04-19</td>
            <td>MARTI</td>
            <td>20-125</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2020-04-16</td>
          </tr>
          <tr>
            <td>16</td>
            <td>KARLA A</td>
            <td>2020-04-15</td>
            <td>2020-04-19</td>
            <td>ARKAS</td>
            <td>MRS14N20</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2020-04-13</td>
          </tr>
          <tr>
            <td>15</td>
            <td>LAURA MAERSK</td>
            <td>2021-04-23</td>
            <td>2021-04-27</td>
            <td>Sealand</td>
            <td>112E</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2021-04-21</td>
          </tr>
          <tr>
            <td>15</td>
            <td>GRETA</td>
            <td>2021-04-09</td>
            <td>2021-04-13</td>
            <td>MARTI</td>
            <td>21-419</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2021-04-08</td>
          </tr>
          <tr>
            <td>15</td>
            <td>LEDA MAERSK</td>
            <td>2020-04-10</td>
            <td>2020-04-14</td>
            <td>Sealand</td>
            <td>011E</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2020-04-08</td>
          </tr>
          <tr>
            <td>15</td>
            <td>PAAVA </td>
            <td>2020-04-10</td>
            <td>2020-04-13</td>
            <td>MARTI</td>
            <td> 20-124</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2020-04-09</td>
          </tr>
          <tr>
            <td>15</td>
            <td>KARLA A</td>
            <td>2020-04-08</td>
            <td>2020-04-12</td>
            <td>ARKAS</td>
            <td>MRS13N20</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2020-04-06</td>
          </tr>
          <tr>
            <td>14</td>
            <td>KARLA A</td>
            <td>2021-04-18</td>
            <td>2021-04-22</td>
            <td>ARKAS</td>
            <td>MRS13N21</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2021-04-16</td>
          </tr>
          <tr>
            <td>14</td>
            <td>KARLA A</td>
            <td>2021-04-18</td>
            <td>2021-04-22</td>
            <td>ARKAS</td>
            <td>MRS13N21</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2021-04-16</td>
          </tr>
          <tr>
            <td>14</td>
            <td> LARS MAERSK</td>
            <td>2021-04-16</td>
            <td>2021-04-21</td>
            <td>Sealand</td>
            <td>111E</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2021-04-14</td>
          </tr>
          <tr>
            <td>14</td>
            <td>GRETA</td>
            <td>2021-04-10</td>
            <td>2021-04-15</td>
            <td>MARTI</td>
            <td>21-419</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2021-04-08</td>
          </tr>
          <tr>
            <td>14</td>
            <td>MAERSK LAUNCESTON</td>
            <td>2020-04-03</td>
            <td>2020-04-07</td>
            <td>Sealand</td>
            <td>010E</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2020-04-01</td>
          </tr>
          <tr>
            <td>14</td>
            <td>PAOLA</td>
            <td>2020-04-04</td>
            <td>2020-04-07</td>
            <td>MARTI</td>
            <td>20-223</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2020-04-03</td>
          </tr>
          <tr>
            <td>14</td>
            <td>KARLA A</td>
            <td>2020-04-08</td>
            <td>2020-04-11</td>
            <td>ARKAS</td>
            <td>MRS13N20</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2020-04-06</td>
          </tr>
          <tr>
            <td>3</td>
            <td>LUNA MAERSK</td>
            <td>2021-01-29</td>
            <td>2021-02-01</td>
            <td>Sealand</td>
            <td>053E</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2021-01-27</td>
          </tr>
          <tr>
            <td>2</td>
            <td>LAUST MAERSK</td>
            <td>2021-01-21</td>
            <td>2021-01-24</td>
            <td>Sealand</td>
            <td>052E</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2021-01-20</td>
          </tr>
          <tr>
            <td>2</td>
            <td>GRETA</td>
            <td>2021-01-23</td>
            <td>2021-01-25</td>
            <td>MARTI</td>
            <td> 20-409</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2021-01-22</td>
          </tr>
          <tr>
            <td>2</td>
            <td>LUCIEN G A</td>
            <td>2021-01-18</td>
            <td>2021-01-21</td>
            <td>ARKAS</td>
            <td>MRS02N21</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2021-01-15</td>
          </tr>
          <tr>
            <td>1</td>
            <td>LEDA MAERSK</td>
            <td>2021-01-15</td>
            <td>2021-01-19</td>
            <td>Sealand</td>
            <td>051E</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2021-01-13</td>
          </tr>
          <tr>
            <td>1</td>
            <td>GRETA</td>
            <td>2021-01-11</td>
            <td>2021-01-15</td>
            <td>MARTI</td>
            <td>20-408</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2021-01-08</td>
          </tr>
          <tr>
            <td>1</td>
            <td>LUCIEN G A</td>
            <td>2021-01-11</td>
            <td>2021-01-14</td>
            <td>ARKAS</td>
            <td>MRS01N21</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2021-01-08</td>
          </tr>
          <tr>
            <td>1</td>
            <td>MAERSK LAUNCESTON</td>
            <td>2020-12-10</td>
            <td>2020-12-14</td>
            <td>Sealand</td>
            <td>050E</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2020-12-08</td>
          </tr>
          <tr>
            <td>1</td>
            <td>GRETA</td>
            <td>2021-01-02</td>
            <td>2021-01-04</td>
            <td>MARTI</td>
            <td>20-408</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2020-12-30</td>
          </tr>
          <tr>
            <td>52</td>
            <td>SAFMARINE NOKWANDA</td>
            <td>2020-12-25</td>
            <td>2020-12-28</td>
            <td>Sealand</td>
            <td>048E</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2020-12-23</td>
          </tr>
          <tr>
            <td>52</td>
            <td>GRETA</td>
            <td>2020-12-26</td>
            <td>2020-12-30</td>
            <td>MARTI</td>
            <td>20-407</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2020-12-25</td>
          </tr>
          <tr>
            <td>52</td>
            <td>LUCIEN G A</td>
            <td>2020-12-29</td>
            <td>2021-01-04</td>
            <td>ARKAS</td>
            <td>MRS45N20</td>
            <td>AMBARLI - Novorossiysk</td>
            <td>2020-12-28</td>
          </tr>
        </tbody>
      </table>
    ),
  },
  {
    id: 3,
    itemText: "	AMBARLI - ODESSA",
    itemValue: (
      <table class="vessel-table">
        <thead>
          <tr>
            <th>Week</th>
            <th>Ship</th>
            <th>ETD</th>
            <th>ETA</th>
            <th>Line</th>
            <th>Voyage</th>
            <th>VesselCategories</th>
            <th>CutOff</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>8</td>
            <td>ADMIRAL SUN </td>
            <td>2022-03-05</td>
            <td>2022-03-09</td>
            <td>MARTI</td>
            <td>22-122</td>
            <td>AMBARLI - ODESSA</td>
            <td>2022-03-03</td>
          </tr>
          <tr>
            <td>8</td>
            <td>SERENADA</td>
            <td>2022-03-05</td>
            <td>2022-03-09</td>
            <td>AKKON</td>
            <td>SER0822</td>
            <td>AMBARLI - ODESSA</td>
            <td>2022-03-04</td>
          </tr>
          <tr>
            <td>8</td>
            <td>COSCO SHIPPING RHINE</td>
            <td>2021-03-07</td>
            <td>2021-03-11</td>
            <td>OOCL</td>
            <td>020E</td>
            <td>AMBARLI - ODESSA</td>
            <td>2021-03-04</td>
          </tr>
          <tr>
            <td>8</td>
            <td>YM INVENTIVE</td>
            <td>2020-02-25</td>
            <td>2020-02-28</td>
            <td>ARKAS</td>
            <td>008N</td>
            <td>AMBARLI - ODESSA</td>
            <td>2020-02-21</td>
          </tr>
          <tr>
            <td>8</td>
            <td>CMA CGM CONGO</td>
            <td>2020-02-18</td>
            <td>2020-02-20</td>
            <td>OOCL</td>
            <td>0BX5ME1MA</td>
            <td>AMBARLI - ODESSA</td>
            <td>2020-02-13</td>
          </tr>
          <tr>
            <td>8</td>
            <td>PAOLA</td>
            <td>2020-02-21</td>
            <td>2020-02-26</td>
            <td>MARTI</td>
            <td> 20-221</td>
            <td>AMBARLI - ODESSA</td>
            <td>2020-02-20</td>
          </tr>
          <tr>
            <td>7</td>
            <td>YM INVENTIVE</td>
            <td>2022-02-22</td>
            <td>2022-02-26</td>
            <td>ARKAS</td>
            <td>IBX379N20 (379N)</td>
            <td>AMBARLI - ODESSA</td>
            <td>2022-02-16</td>
          </tr>
          <tr>
            <td>7</td>
            <td>YM INVENTIVE</td>
            <td>2021-03-03</td>
            <td>2021-03-06</td>
            <td>ARKAS</td>
            <td>IBX330N20 (330N)</td>
            <td>AMBARLI - ODESSA</td>
            <td>2021-02-26</td>
          </tr>
          <tr>
            <td>7</td>
            <td>HANSA CLOPPENBURG</td>
            <td>2020-02-12</td>
            <td>2020-02-15</td>
            <td>ARKAS</td>
            <td>006N</td>
            <td>AMBARLI - ODESSA</td>
            <td>2020-02-10</td>
          </tr>
          <tr>
            <td>7</td>
            <td>CMA CGM URUGUAY </td>
            <td>2020-02-07</td>
            <td>2020-02-08</td>
            <td>OOCL</td>
            <td>0BX5KE1MA</td>
            <td>AMBARLI - ODESSA</td>
            <td>2020-02-05</td>
          </tr>
          <tr>
            <td>7</td>
            <td>PACOBA</td>
            <td>2020-02-15</td>
            <td>2020-02-19</td>
            <td>MARTI</td>
            <td>20-302</td>
            <td>AMBARLI - ODESSA</td>
            <td>2020-02-13</td>
          </tr>
          <tr>
            <td>6</td>
            <td>YM INVENTIVE</td>
            <td>2022-02-18</td>
            <td>2022-02-21</td>
            <td>ARKAS</td>
            <td>IBX379N20 (379N)</td>
            <td>AMBARLI - ODESSA</td>
            <td>2022-02-10</td>
          </tr>
          <tr>
            <td>6</td>
            <td>ADMIRAL SUN </td>
            <td>2022-02-19</td>
            <td>2022-02-22</td>
            <td>MARTI</td>
            <td>SUN 22-121</td>
            <td>AMBARLI - ODESSA</td>
            <td>2022-02-17</td>
          </tr>
          <tr>
            <td>6</td>
            <td>PAAVA </td>
            <td>2020-02-07</td>
            <td>2020-02-12</td>
            <td>MARTI</td>
            <td>20-121</td>
            <td>AMBARLI - ODESSA</td>
            <td>2020-02-06</td>
          </tr>
          <tr>
            <td>5</td>
            <td>YM INVENTIVE</td>
            <td>2022-02-07</td>
            <td>2022-02-10</td>
            <td>ARKAS</td>
            <td>IBX378N20 (378N)</td>
            <td>AMBARLI - ODESSA</td>
            <td>2022-02-04</td>
          </tr>
          <tr>
            <td>5</td>
            <td>ADMIRAL MOON</td>
            <td>2022-02-12</td>
            <td>2022-02-16</td>
            <td>MARTI</td>
            <td>22-219</td>
            <td>AMBARLI - ODESSA</td>
            <td>2022-02-10</td>
          </tr>
          <tr>
            <td>5</td>
            <td>GRETA</td>
            <td>2021-02-12</td>
            <td>2021-02-16</td>
            <td>MARTI</td>
            <td>21-411</td>
            <td>AMBARLI - ODESSA</td>
            <td>2021-02-11</td>
          </tr>
          <tr>
            <td>5</td>
            <td>PAOLA</td>
            <td>2020-01-31</td>
            <td>2020-02-05</td>
            <td>MARTI</td>
            <td>20-220</td>
            <td>AMBARLI - ODESSA</td>
            <td>2020-01-30</td>
          </tr>
          <tr>
            <td>4</td>
            <td>YM INVENTIVE</td>
            <td>2022-02-01</td>
            <td>2022-02-04</td>
            <td>ARKAS</td>
            <td>IBX376N20 (377N)</td>
            <td>AMBARLI - ODESSA</td>
            <td>2022-01-27</td>
          </tr>
          <tr>
            <td>4</td>
            <td>ADMIRAL SUN </td>
            <td>2022-02-06</td>
            <td>2022-02-11</td>
            <td>MARTI</td>
            <td>22-120</td>
            <td>AMBARLI - ODESSA</td>
            <td>2022-02-03</td>
          </tr>
          <tr>
            <td>4</td>
            <td>GRETA</td>
            <td>2021-02-06</td>
            <td>2021-02-08</td>
            <td>MARTI</td>
            <td>21-411</td>
            <td>AMBARLI - ODESSA</td>
            <td>2021-02-04</td>
          </tr>
          <tr>
            <td>51</td>
            <td>YM INVENTIVE</td>
            <td>2021-12-24</td>
            <td>2021-12-26</td>
            <td>ARKAS</td>
            <td>IBX371N20 (371N)</td>
            <td>AMBARLI - ODESSA</td>
            <td>2021-12-17</td>
          </tr>
          <tr>
            <td>51</td>
            <td>YM INVENTIVE</td>
            <td>2021-12-24</td>
            <td>2021-12-25</td>
            <td>ARKAS</td>
            <td>IBX371N20 (371N)</td>
            <td>AMBARLI - ODESSA</td>
            <td>2021-12-21</td>
          </tr>
          <tr>
            <td>50</td>
            <td>YM INVENTIVE</td>
            <td>2021-12-13</td>
            <td>2021-12-14</td>
            <td>ARKAS</td>
            <td>IBX370N20 (370N)</td>
            <td>AMBARLI - ODESSA</td>
            <td>2021-12-10</td>
          </tr>
          <tr>
            <td>50</td>
            <td>GRETA</td>
            <td>2021-12-19</td>
            <td>2021-12-20</td>
            <td>MARTI</td>
            <td>21-438</td>
            <td>AMBARLI - ODESSA</td>
            <td>2021-12-16</td>
          </tr>
          <tr>
            <td>49</td>
            <td>GRETA</td>
            <td>2021-12-11</td>
            <td>2021-12-13</td>
            <td>MARTI</td>
            <td>21-437</td>
            <td>AMBARLI - ODESSA</td>
            <td>2021-12-08</td>
          </tr>
          <tr>
            <td>48</td>
            <td>GRETA</td>
            <td>2021-12-03</td>
            <td>2021-12-05</td>
            <td>MARTI</td>
            <td>21-437</td>
            <td>AMBARLI - ODESSA</td>
            <td>2021-12-02</td>
          </tr>
          <tr>
            <td>48</td>
            <td>YM INVENTIVE</td>
            <td>2021-12-04</td>
            <td>2021-12-05</td>
            <td>ARKAS</td>
            <td>IBX368N20 (368N)</td>
            <td>AMBARLI - ODESSA</td>
            <td>2021-12-01</td>
          </tr>
          <tr>
            <td>47</td>
            <td>GRETA</td>
            <td>2021-11-27</td>
            <td>2021-11-28</td>
            <td>MARTI</td>
            <td>21-436</td>
            <td>AMBARLI - ODESSA</td>
            <td>2021-11-24</td>
          </tr>
          <tr>
            <td>46</td>
            <td>YM INVENTIVE</td>
            <td>2021-11-28</td>
            <td>2021-11-30</td>
            <td>ARKAS</td>
            <td>IBX367N20 (367N)</td>
            <td>AMBARLI - ODESSA</td>
            <td>2021-11-19</td>
          </tr>
          <tr>
            <td>45</td>
            <td>ADMIRAL MOON</td>
            <td>2021-11-12</td>
            <td>2021-11-17</td>
            <td>MARTI</td>
            <td>21-215</td>
            <td>AMBARLI - ODESSA</td>
            <td>2021-11-11</td>
          </tr>
          <tr>
            <td>45</td>
            <td>YM INVENTIVE</td>
            <td>2021-11-15</td>
            <td>2021-11-18</td>
            <td>ARKAS</td>
            <td>IBX365N20 (365N)</td>
            <td>AMBARLI - ODESSA</td>
            <td>2021-11-09</td>
          </tr>
          <tr>
            <td>44</td>
            <td>ADMIRAL SUN </td>
            <td>2021-11-06</td>
            <td>2021-11-11</td>
            <td>MARTI</td>
            <td>21-115</td>
            <td>AMBARLI - ODESSA</td>
            <td>2021-11-04</td>
          </tr>
          <tr>
            <td>43</td>
            <td>ADMIRAL MOON</td>
            <td>2021-10-29</td>
            <td>2021-11-03</td>
            <td>MARTI</td>
            <td>21-214</td>
            <td>AMBARLI - ODESSA</td>
            <td>2021-10-28</td>
          </tr>
          <tr>
            <td>43</td>
            <td>YM INVENTIVE</td>
            <td>2021-11-10</td>
            <td>2021-11-13</td>
            <td>ARKAS</td>
            <td>IBX365N20 (365N)</td>
            <td>AMBARLI - ODESSA</td>
            <td>2021-11-05</td>
          </tr>
          <tr>
            <td>42</td>
            <td>YM INVENTIVE</td>
            <td>2021-11-03</td>
            <td>2021-11-06</td>
            <td>ARKAS</td>
            <td>IBX364N20 (364N)</td>
            <td>AMBARLI - ODESSA</td>
            <td>2021-10-27</td>
          </tr>
          <tr>
            <td>42</td>
            <td>YM INVENTIVE</td>
            <td>2021-11-03</td>
            <td>2021-10-06</td>
            <td>ARKAS</td>
            <td>IBX364N20 (364N)</td>
            <td>AMBARLI - ODESSA</td>
            <td>2021-10-27</td>
          </tr>
          <tr>
            <td>41</td>
            <td>ADMIRAL SUN </td>
            <td>2021-10-15</td>
            <td>2021-10-20</td>
            <td>MARTI</td>
            <td>21-114</td>
            <td>AMBARLI - ODESSA</td>
            <td>2021-10-14</td>
          </tr>
          <tr>
            <td>41</td>
            <td>YM INVENTIVE</td>
            <td>2021-10-27</td>
            <td>2021-10-30</td>
            <td>ARKAS</td>
            <td>IBX363N20 (363N)</td>
            <td>AMBARLI - ODESSA</td>
            <td>2021-10-22</td>
          </tr>
          <tr>
            <td>40</td>
            <td>ADMIRAL SUN </td>
            <td>2021-10-08</td>
            <td>2021-10-13</td>
            <td>MARTI</td>
            <td>21-114</td>
            <td>AMBARLI - ODESSA</td>
            <td>2021-10-07</td>
          </tr>
          <tr>
            <td>40</td>
            <td>YM INVENTIVE</td>
            <td>2021-10-13</td>
            <td>2021-10-16</td>
            <td>ARKAS</td>
            <td>IBX361N20 (361N)</td>
            <td>AMBARLI - ODESSA</td>
            <td>2021-10-07</td>
          </tr>
          <tr>
            <td>39</td>
            <td>ADMIRAL MOON</td>
            <td>2021-10-06</td>
            <td>2021-10-10</td>
            <td>MARTI</td>
            <td>21-213</td>
            <td>AMBARLI - ODESSA</td>
            <td>2021-09-30</td>
          </tr>
          <tr>
            <td>39</td>
            <td>YM INVENTIVE</td>
            <td>2021-10-01</td>
            <td>2021-10-05</td>
            <td>ARKAS</td>
            <td>IBX360N20</td>
            <td>AMBARLI - ODESSA</td>
            <td>2021-09-30</td>
          </tr>
          <tr>
            <td>39</td>
            <td>PAAVA </td>
            <td>2020-09-19</td>
            <td>2020-09-22</td>
            <td>Sealand</td>
            <td>20-135</td>
            <td>AMBARLI - ODESSA</td>
            <td>2020-09-17</td>
          </tr>
          <tr>
            <td>38</td>
            <td>YM INVENTIVE</td>
            <td>2021-09-29</td>
            <td>2021-10-02</td>
            <td>ARKAS</td>
            <td>IBX359N20 (359N)</td>
            <td>AMBARLI - ODESSA</td>
            <td>2021-09-24</td>
          </tr>
          <tr>
            <td>38</td>
            <td>ADMIRAL SUN </td>
            <td>2021-09-28</td>
            <td>2021-09-29</td>
            <td>MARTI</td>
            <td>21-113</td>
            <td>AMBARLI - ODESSA</td>
            <td>2021-09-23</td>
          </tr>
          <tr>
            <td>38</td>
            <td>PAAVA </td>
            <td>2020-09-18</td>
            <td>2020-09-22</td>
            <td>MARTI</td>
            <td>20-135</td>
            <td>AMBARLI - ODESSA</td>
            <td>2020-09-17</td>
          </tr>
          <tr>
            <td>38</td>
            <td>YM INVENTIVE</td>
            <td>2020-09-20</td>
            <td>2020-09-24</td>
            <td>ARKAS</td>
            <td>IBX307N20 (307N)</td>
            <td>AMBARLI - ODESSA</td>
            <td>2020-09-18</td>
          </tr>
          <tr>
            <td>37</td>
            <td>GRETA</td>
            <td>2021-09-18</td>
            <td>2021-09-20</td>
            <td>MARTI</td>
            <td>21-432</td>
            <td>AMBARLI - ODESSA</td>
            <td>2021-09-16</td>
          </tr>
          <tr>
            <td>37</td>
            <td>YM INVENTIVE</td>
            <td>2021-09-21</td>
            <td>2021-09-25</td>
            <td>ARKAS</td>
            <td>IBX358N20 (358N)</td>
            <td>AMBARLI - ODESSA</td>
            <td>2021-09-17</td>
          </tr>
          <tr>
            <td>37</td>
            <td>PAAVA </td>
            <td>2020-09-19</td>
            <td>2020-09-22</td>
            <td>MARTI</td>
            <td>20-135</td>
            <td>AMBARLI - ODESSA</td>
            <td>2020-09-17</td>
          </tr>
          <tr>
            <td>37</td>
            <td>YM INVENTIVE</td>
            <td>2020-09-16</td>
            <td>2020-09-19</td>
            <td>ARKAS</td>
            <td>IBX306N20 (306N)</td>
            <td>AMBARLI - ODESSA</td>
            <td>2020-09-11</td>
          </tr>
          <tr>
            <td>37</td>
            <td>YM INVENTIVE</td>
            <td>2020-09-16</td>
            <td>2020-09-19</td>
            <td>ARKAS</td>
            <td>IBX306N20 (306N)</td>
            <td>AMBARLI - ODESSA</td>
            <td>2020-09-11</td>
          </tr>
          <tr>
            <td>36</td>
            <td>ADMIRAL SUN </td>
            <td>2021-09-10</td>
            <td>2021-09-15</td>
            <td>MARTI</td>
            <td>21-112</td>
            <td>AMBARLI - ODESSA</td>
            <td>2021-09-09</td>
          </tr>
          <tr>
            <td>36</td>
            <td>YM INVENTIVE</td>
            <td>2021-09-21</td>
            <td>2021-09-23</td>
            <td>ARKAS</td>
            <td>IBX358N20 (358N)</td>
            <td>AMBARLI - ODESSA</td>
            <td>2021-09-16</td>
          </tr>
          <tr>
            <td>36</td>
            <td>YM INVENTIVE</td>
            <td>2020-09-01</td>
            <td>2020-09-05</td>
            <td>ARKAS</td>
            <td>IBX304N20 (304N)</td>
            <td>AMBARLI - ODESSA</td>
            <td>2020-08-31</td>
          </tr>
          <tr>
            <td>34</td>
            <td>ADMIRAL MOON</td>
            <td>2021-09-11</td>
            <td>2021-09-15</td>
            <td>MARTI</td>
            <td>21-211</td>
            <td>AMBARLI - ODESSA</td>
            <td>2021-09-09</td>
          </tr>
          <tr>
            <td>34</td>
            <td>PAAVA </td>
            <td>2020-08-24</td>
            <td>2020-08-25</td>
            <td>MARTI</td>
            <td>20-133</td>
            <td>AMBARLI - ODESSA</td>
            <td>2020-08-22</td>
          </tr>
          <tr>
            <td>33</td>
            <td>ADMIRAL MOON</td>
            <td>2021-08-20</td>
            <td>2021-08-23</td>
            <td>MARTI</td>
            <td>21-210</td>
            <td>AMBARLI - ODESSA</td>
            <td>2021-08-19</td>
          </tr>
          <tr>
            <td>33</td>
            <td>YM INVENTIVE</td>
            <td>2021-08-30</td>
            <td>2021-09-03</td>
            <td>ARKAS</td>
            <td>IBX355N20 (355N)</td>
            <td>AMBARLI - ODESSA</td>
            <td>2021-08-25</td>
          </tr>
          <tr>
            <td>33</td>
            <td>PAOLA</td>
            <td>2020-08-15</td>
            <td>2020-08-17</td>
            <td>MARTI</td>
            <td>20-232</td>
            <td>AMBARLI - ODESSA</td>
            <td>2020-08-13</td>
          </tr>
          <tr>
            <td>32</td>
            <td>YM INVENTIVE</td>
            <td>2021-08-20</td>
            <td>2021-08-24</td>
            <td>ARKAS</td>
            <td>IBX355N20 (355N)</td>
            <td>AMBARLI - ODESSA</td>
            <td>2021-08-18</td>
          </tr>
          <tr>
            <td>32</td>
            <td>ADMIRAL SUN </td>
            <td>2021-08-13</td>
            <td>2021-08-16</td>
            <td>MARTI</td>
            <td> 21-110</td>
            <td>AMBARLI - ODESSA</td>
            <td>2021-08-12</td>
          </tr>
          <tr>
            <td>32</td>
            <td>PAAVA </td>
            <td>2020-08-07</td>
            <td>2020-08-11</td>
            <td>MARTI</td>
            <td>20-132</td>
            <td>AMBARLI - ODESSA</td>
            <td>2020-08-06</td>
          </tr>
          <tr>
            <td>31</td>
            <td>YM INVENTIVE</td>
            <td>2021-08-09</td>
            <td>2021-08-13</td>
            <td>ARKAS</td>
            <td>BX352N20 (352N)</td>
            <td>AMBARLI - ODESSA</td>
            <td>2021-08-07</td>
          </tr>
          <tr>
            <td>31</td>
            <td>PAOLA</td>
            <td>2020-07-31</td>
            <td>2020-08-04</td>
            <td>MARTI</td>
            <td>20-231</td>
            <td>AMBARLI - ODESSA</td>
            <td>2020-07-29</td>
          </tr>
          <tr>
            <td>31</td>
            <td>CMA CGM VOLGA</td>
            <td>2020-08-01</td>
            <td>2020-08-03</td>
            <td>OOCL</td>
            <td>0BX6YE1MA</td>
            <td>AMBARLI - ODESSA</td>
            <td>2020-07-28</td>
          </tr>
          <tr>
            <td>31</td>
            <td>YM INVENTIVE</td>
            <td>2020-08-05</td>
            <td>2020-08-08</td>
            <td>ARKAS</td>
            <td>IBX300N20 (300N)</td>
            <td>AMBARLI - ODESSA</td>
            <td>2020-07-29</td>
          </tr>
          <tr>
            <td>30</td>
            <td>YM INVENTIVE</td>
            <td>2021-08-04</td>
            <td>2021-08-07</td>
            <td>ARKAS</td>
            <td>IBX350N20 (350N)</td>
            <td>AMBARLI - ODESSA</td>
            <td>2021-07-30</td>
          </tr>
          <tr>
            <td>30</td>
            <td>PAAVA </td>
            <td>2020-07-24</td>
            <td>2020-07-28</td>
            <td>MARTI</td>
            <td> PAAVA 20-131</td>
            <td>AMBARLI - ODESSA</td>
            <td>2020-07-23</td>
          </tr>
          <tr>
            <td>30</td>
            <td>YM INVENTIVE</td>
            <td>2020-07-28</td>
            <td>2020-07-31</td>
            <td>ARKAS</td>
            <td>IBX298N20 (298N)</td>
            <td>AMBARLI - ODESSA</td>
            <td>2020-07-27</td>
          </tr>
          <tr>
            <td>30</td>
            <td>COSCO SHIPPING SEINE </td>
            <td>2020-07-25</td>
            <td>2020-07-28</td>
            <td>OOCL</td>
            <td>016E</td>
            <td>AMBARLI - ODESSA</td>
            <td>2020-07-22</td>
          </tr>
          <tr>
            <td>29</td>
            <td>ADMIRAL SUN </td>
            <td>2021-07-29</td>
            <td>2021-08-01</td>
            <td>MARTI</td>
            <td> 21-108</td>
            <td>AMBARLI - ODESSA</td>
            <td>2021-07-27</td>
          </tr>
          <tr>
            <td>29</td>
            <td>YM INVENTIVE</td>
            <td>2021-08-01</td>
            <td>2021-08-04</td>
            <td>ARKAS</td>
            <td>IBX350N20 (350N)</td>
            <td>AMBARLI - ODESSA</td>
            <td>2021-07-30</td>
          </tr>
          <tr>
            <td>29</td>
            <td>PAOLA</td>
            <td>2020-07-17</td>
            <td>2020-07-21</td>
            <td>MARTI</td>
            <td>20-230</td>
            <td>AMBARLI - ODESSA</td>
            <td>2020-07-16</td>
          </tr>
          <tr>
            <td>29</td>
            <td>YM INVENTIVE</td>
            <td>2020-07-19</td>
            <td>2020-07-23</td>
            <td>ARKAS</td>
            <td>IBX297N20 (297N)</td>
            <td>AMBARLI - ODESSA</td>
            <td>2020-07-17</td>
          </tr>
          <tr>
            <td>28</td>
            <td>ADMIRAL SUN </td>
            <td>2021-07-16</td>
            <td>2021-07-20</td>
            <td>MARTI</td>
            <td> 21-108</td>
            <td>AMBARLI - ODESSA</td>
            <td>2021-07-13</td>
          </tr>
          <tr>
            <td>28</td>
            <td>PAAVA </td>
            <td>2020-07-11</td>
            <td>2020-07-15</td>
            <td>MARTI</td>
            <td>M/V PAAVA 20-130</td>
            <td>AMBARLI - ODESSA</td>
            <td>2020-07-09</td>
          </tr>
          <tr>
            <td>28</td>
            <td>YM INVENTIVE</td>
            <td>2020-07-19</td>
            <td>2020-07-23</td>
            <td>ARKAS</td>
            <td>IBX297N20 (297N)</td>
            <td>AMBARLI - ODESSA</td>
            <td>2020-07-16</td>
          </tr>
          <tr>
            <td>28</td>
            <td>CMA CGM CONGO</td>
            <td>2020-07-11</td>
            <td>2020-07-13</td>
            <td>OOCL</td>
            <td>0BX6SE1MA</td>
            <td>AMBARLI - ODESSA</td>
            <td>2020-07-08</td>
          </tr>
          <tr>
            <td>27</td>
            <td>ADMIRAL MOON</td>
            <td>2021-07-02</td>
            <td>2021-07-05</td>
            <td>MARTI</td>
            <td>21-207</td>
            <td>AMBARLI - ODESSA</td>
            <td>2021-06-01</td>
          </tr>
          <tr>
            <td>27</td>
            <td>PAOLA</td>
            <td>2020-07-04</td>
            <td>2020-07-07</td>
            <td>MARTI</td>
            <td>20-229</td>
            <td>AMBARLI - ODESSA</td>
            <td>2020-07-02</td>
          </tr>
          <tr>
            <td>27</td>
            <td>YM INVENTIVE</td>
            <td>2020-06-30</td>
            <td>2020-07-04</td>
            <td>ARKAS</td>
            <td>IBX295N20 (295N)</td>
            <td>AMBARLI - ODESSA</td>
            <td>2020-06-26</td>
          </tr>
          <tr>
            <td>26</td>
            <td>ADMIRAL SUN </td>
            <td>2021-07-01</td>
            <td>2021-07-05</td>
            <td>MARTI</td>
            <td>21-107</td>
            <td>AMBARLI - ODESSA</td>
            <td>2021-06-30</td>
          </tr>
          <tr>
            <td>26</td>
            <td>PAAVA </td>
            <td>2020-06-27</td>
            <td>2020-06-30</td>
            <td>MARTI</td>
            <td> 20-129</td>
            <td>AMBARLI - ODESSA</td>
            <td>2020-06-25</td>
          </tr>
          <tr>
            <td>26</td>
            <td>YM INVENTIVE</td>
            <td>2020-07-01</td>
            <td>2020-07-04</td>
            <td>ARKAS</td>
            <td>IBX295N20 (295N)</td>
            <td>AMBARLI - ODESSA</td>
            <td>2020-06-29</td>
          </tr>
          <tr>
            <td>26</td>
            <td>CMA CGM URUGUAY </td>
            <td>2020-06-27</td>
            <td>2020-06-30</td>
            <td>OOCL</td>
            <td>0BX6OE1MA</td>
            <td>AMBARLI - ODESSA</td>
            <td>2020-06-24</td>
          </tr>
          <tr>
            <td>25</td>
            <td>ADMIRAL MOON</td>
            <td>2021-06-26</td>
            <td>2021-06-30</td>
            <td>MARTI</td>
            <td>21-206</td>
            <td>AMBARLI - ODESSA</td>
            <td>2021-06-23</td>
          </tr>
          <tr>
            <td>25</td>
            <td>YM INVENTIVE</td>
            <td>2021-07-10</td>
            <td>2021-07-13</td>
            <td>ARKAS</td>
            <td></td>
            <td>AMBARLI - ODESSA</td>
            <td>2021-07-06</td>
          </tr>
          <tr>
            <td>25</td>
            <td>PAOLA</td>
            <td>2020-06-20</td>
            <td>2020-06-23</td>
            <td>MARTI</td>
            <td>20-228</td>
            <td>AMBARLI - ODESSA</td>
            <td>2020-06-18</td>
          </tr>
          <tr>
            <td>25</td>
            <td>YM INVENTIVE</td>
            <td>2020-06-23</td>
            <td>2020-06-27</td>
            <td>ARKAS</td>
            <td>IBX294N20 (294N</td>
            <td>AMBARLI - ODESSA</td>
            <td>2020-06-19</td>
          </tr>
          <tr>
            <td>25</td>
            <td>CMA CGM ARKANSAS</td>
            <td>2020-06-18</td>
            <td>2020-06-21</td>
            <td>OOCL</td>
            <td>0BX6ME1MA</td>
            <td>AMBARLI - ODESSA</td>
            <td>2020-06-16</td>
          </tr>
          <tr>
            <td>24</td>
            <td>ADMIRAL SUN </td>
            <td>2021-06-18</td>
            <td>2021-06-23</td>
            <td>MARTI</td>
            <td>21-106</td>
            <td>AMBARLI - ODESSA</td>
            <td>2021-06-17</td>
          </tr>
          <tr>
            <td>24</td>
            <td>YM INVENTIVE</td>
            <td>2021-07-07</td>
            <td>2021-07-10</td>
            <td>ARKAS</td>
            <td>IBX346N20 (345N)</td>
            <td>AMBARLI - ODESSA</td>
            <td>2021-07-02</td>
          </tr>
          <tr>
            <td>24</td>
            <td>PAAVA </td>
            <td>2020-06-13</td>
            <td>2020-06-16</td>
            <td>MARTI</td>
            <td>20-128</td>
            <td>AMBARLI - ODESSA</td>
            <td>2020-06-11</td>
          </tr>
          <tr>
            <td>24</td>
            <td>MAIRA XL</td>
            <td>2020-06-13</td>
            <td>2020-06-16</td>
            <td>OOCL</td>
            <td>025E</td>
            <td>AMBARLI - ODESSA</td>
            <td>2020-06-10</td>
          </tr>
          <tr>
            <td>24</td>
            <td>YM INVENTIVE</td>
            <td>2020-06-15</td>
            <td>2020-06-17</td>
            <td>ARKAS</td>
            <td>IBX293N20 (293N)</td>
            <td>AMBARLI - ODESSA</td>
            <td>2020-06-12</td>
          </tr>
          <tr>
            <td>23</td>
            <td>ADMIRAL MOON</td>
            <td>2021-06-12</td>
            <td>2021-06-16</td>
            <td>MARTI</td>
            <td>21-205</td>
            <td>AMBARLI - ODESSA</td>
            <td>2021-06-10</td>
          </tr>
          <tr>
            <td>23</td>
            <td>YM INVENTIVE</td>
            <td>2021-06-16</td>
            <td>2021-06-20</td>
            <td>ARKAS</td>
            <td>IBX344N20 (344N)</td>
            <td>AMBARLI - ODESSA</td>
            <td>2021-06-11</td>
          </tr>
          <tr>
            <td>23</td>
            <td>PAOLA</td>
            <td>2020-06-05</td>
            <td>2020-06-09</td>
            <td>MARTI</td>
            <td> M/V PAOLA 20-227</td>
            <td>AMBARLI - ODESSA</td>
            <td>2020-06-04</td>
          </tr>
          <tr>
            <td>23</td>
            <td>YM INVENTIVE</td>
            <td>2020-06-05</td>
            <td>2020-06-07</td>
            <td>ARKAS</td>
            <td>IBX291N20 (291N)</td>
            <td>AMBARLI - ODESSA</td>
            <td>2020-06-04</td>
          </tr>
          <tr>
            <td>22</td>
            <td>ADMIRAL MOON</td>
            <td>2021-06-12</td>
            <td>2021-06-16</td>
            <td>MARTI</td>
            <td> 21-205</td>
            <td>AMBARLI - ODESSA</td>
            <td>2021-06-09</td>
          </tr>
          <tr>
            <td>22</td>
            <td>KARLA A</td>
            <td>2021-06-07</td>
            <td>2021-06-11</td>
            <td>ARKAS</td>
            <td>MRS19N21</td>
            <td>AMBARLI - ODESSA</td>
            <td>2021-06-04</td>
          </tr>
          <tr>
            <td>22</td>
            <td>YM INVENTIVE</td>
            <td>2020-06-03</td>
            <td>2020-06-06</td>
            <td>ARKAS</td>
            <td>IBX291N20 (291N)</td>
            <td>AMBARLI - ODESSA</td>
            <td>2020-06-01</td>
          </tr>
          <tr>
            <td>22</td>
            <td>COSCO SHIPPING RHINE</td>
            <td>2020-05-31</td>
            <td>2020-06-04</td>
            <td>OOCL</td>
            <td>016E</td>
            <td>AMBARLI - ODESSA</td>
            <td>2020-05-27</td>
          </tr>
          <tr>
            <td>21</td>
            <td>GRETA</td>
            <td>2021-05-30</td>
            <td>2021-06-02</td>
            <td>MARTI</td>
            <td>21-426</td>
            <td>AMBARLI - ODESSA</td>
            <td>2021-05-27</td>
          </tr>
          <tr>
            <td>21</td>
            <td>KARLA A</td>
            <td>2021-06-02</td>
            <td>2021-06-07</td>
            <td>ARKAS</td>
            <td>MRS15N21</td>
            <td>AMBARLI - ODESSA</td>
            <td>2021-05-30</td>
          </tr>
          <tr>
            <td>21</td>
            <td>PAOLA</td>
            <td>2020-05-22</td>
            <td>2020-05-26</td>
            <td>MARTI</td>
            <td>20-226</td>
            <td>AMBARLI - ODESSA</td>
            <td>2020-05-21</td>
          </tr>
          <tr>
            <td>21</td>
            <td>YM INVENTIVE</td>
            <td>2020-05-19</td>
            <td>2020-05-23</td>
            <td>ARKAS</td>
            <td>IBX289N20 (289N)</td>
            <td>AMBARLI - ODESSA</td>
            <td>2020-05-18</td>
          </tr>
          <tr>
            <td>21</td>
            <td>CMA CGM VOLGA</td>
            <td>2020-05-22</td>
            <td>2020-05-24</td>
            <td>OOCL</td>
            <td>0BX6EE1MA</td>
            <td>AMBARLI - ODESSA</td>
            <td>2020-05-18</td>
          </tr>
          <tr>
            <td>20</td>
            <td>GRETA</td>
            <td>2021-05-22</td>
            <td>2021-05-25</td>
            <td>MARTI</td>
            <td>21-425</td>
            <td>AMBARLI - ODESSA</td>
            <td>2021-05-20</td>
          </tr>
          <tr>
            <td>20</td>
            <td>KARLA A</td>
            <td>2021-05-27</td>
            <td>2021-06-01</td>
            <td>ARKAS</td>
            <td>MRS14N21</td>
            <td>AMBARLI - ODESSA</td>
            <td>2021-05-26</td>
          </tr>
          <tr>
            <td>20</td>
            <td>YM INVENTIVE</td>
            <td>2020-05-11</td>
            <td>2020-05-15</td>
            <td>ARKAS</td>
            <td>IBX288N20 (288N)</td>
            <td>AMBARLI - ODESSA</td>
            <td>2020-05-07</td>
          </tr>
          <tr>
            <td>19</td>
            <td>GRETA</td>
            <td>2021-05-14</td>
            <td>2021-05-19</td>
            <td>MARTI</td>
            <td>21-424</td>
            <td>AMBARLI - ODESSA</td>
            <td>2021-05-11</td>
          </tr>
          <tr>
            <td>19</td>
            <td>PAOLA</td>
            <td>2020-05-09</td>
            <td>2020-05-12</td>
            <td>MARTI</td>
            <td>20-225</td>
            <td>AMBARLI - ODESSA</td>
            <td>2020-05-07</td>
          </tr>
          <tr>
            <td>19</td>
            <td>CMA CGM MISSOURI</td>
            <td>2020-05-09</td>
            <td>2020-05-11</td>
            <td>OOCL</td>
            <td>0BX6AE1MA</td>
            <td>AMBARLI - ODESSA</td>
            <td>2020-05-06</td>
          </tr>
          <tr>
            <td>18</td>
            <td>GRETA</td>
            <td>2021-05-08</td>
            <td>2021-05-13</td>
            <td>MARTI</td>
            <td>21-423</td>
            <td>AMBARLI - ODESSA</td>
            <td>2021-05-06</td>
          </tr>
          <tr>
            <td>18</td>
            <td>PAAVA </td>
            <td>2020-05-01</td>
            <td>2020-05-05</td>
            <td>MARTI</td>
            <td>20-125</td>
            <td>AMBARLI - ODESSA</td>
            <td>2020-04-30</td>
          </tr>
          <tr>
            <td>17</td>
            <td>GRETA</td>
            <td>2021-05-01</td>
            <td>2021-05-05</td>
            <td>MARTI</td>
            <td> 21-422</td>
            <td>AMBARLI - ODESSA</td>
            <td>2021-04-29</td>
          </tr>
          <tr>
            <td>17</td>
            <td>PAOLA</td>
            <td>2020-04-24</td>
            <td>2020-04-28</td>
            <td>MARTI</td>
            <td>20-124</td>
            <td>AMBARLI - ODESSA</td>
            <td>2020-04-23</td>
          </tr>
          <tr>
            <td>16</td>
            <td>GRETA</td>
            <td>2021-04-16</td>
            <td>2021-04-20</td>
            <td>MARTI</td>
            <td>21-420</td>
            <td>AMBARLI - ODESSA</td>
            <td>2021-04-14</td>
          </tr>
          <tr>
            <td>16</td>
            <td>PAAVA </td>
            <td>2020-04-17</td>
            <td>2020-04-21</td>
            <td>MARTI</td>
            <td>20-125</td>
            <td>AMBARLI - ODESSA</td>
            <td>2020-04-16</td>
          </tr>
          <tr>
            <td>16</td>
            <td>YM INVENTIVE</td>
            <td>2020-04-14</td>
            <td>2020-04-18</td>
            <td>ARKAS</td>
            <td>IBX284N20 (284N)</td>
            <td>AMBARLI - ODESSA</td>
            <td>2020-04-13</td>
          </tr>
          <tr>
            <td>15</td>
            <td>GRETA</td>
            <td>2021-04-09</td>
            <td>2021-04-13</td>
            <td>MARTI</td>
            <td>21-419</td>
            <td>AMBARLI - ODESSA</td>
            <td>2021-04-08</td>
          </tr>
          <tr>
            <td>15</td>
            <td>PAAVA </td>
            <td>2020-04-10</td>
            <td>2020-04-14</td>
            <td>MARTI</td>
            <td> 20-124</td>
            <td>AMBARLI - ODESSA</td>
            <td>2020-04-09</td>
          </tr>
          <tr>
            <td>15</td>
            <td>YM INVENTIVE</td>
            <td>2020-04-14</td>
            <td>2020-04-18</td>
            <td>ARKAS</td>
            <td>IBX284N20 (284N)</td>
            <td>AMBARLI - ODESSA</td>
            <td>2020-04-10</td>
          </tr>
          <tr>
            <td>15</td>
            <td>COSCO SHIPPING SEINE </td>
            <td>2020-04-11</td>
            <td>2020-04-14</td>
            <td>OOCL</td>
            <td>EM1SHI015East</td>
            <td>AMBARLI - ODESSA</td>
            <td>2020-04-06</td>
          </tr>
          <tr>
            <td>14</td>
            <td>GRETA</td>
            <td>2021-04-10</td>
            <td>2021-04-13</td>
            <td>MARTI</td>
            <td>21-419</td>
            <td>AMBARLI - ODESSA</td>
            <td>2021-04-08</td>
          </tr>
          <tr>
            <td>14</td>
            <td>PAOLA</td>
            <td>2020-04-04</td>
            <td>2020-04-07</td>
            <td>MARTI</td>
            <td>20-223</td>
            <td>AMBARLI - ODESSA</td>
            <td>2020-04-03</td>
          </tr>
          <tr>
            <td>14</td>
            <td>YM INVENTIVE</td>
            <td>2020-03-31</td>
            <td>2020-04-04</td>
            <td>ARKAS</td>
            <td>IBX282N20 (282N)</td>
            <td>AMBARLI - ODESSA</td>
            <td>2020-03-30</td>
          </tr>
          <tr>
            <td>13</td>
            <td>ADMIRAL SUN </td>
            <td>2021-04-04</td>
            <td>2021-04-08</td>
            <td>MARTI</td>
            <td>21-101</td>
            <td>AMBARLI - ODESSA</td>
            <td>2021-04-02</td>
          </tr>
          <tr>
            <td>13</td>
            <td>PACOBA</td>
            <td>2020-03-28</td>
            <td>2020-04-02</td>
            <td>MARTI</td>
            <td>20-304</td>
            <td>AMBARLI - ODESSA</td>
            <td>2020-03-26</td>
          </tr>
          <tr>
            <td>13</td>
            <td>YM INVENTIVE</td>
            <td>2020-03-24</td>
            <td>2020-03-28</td>
            <td>ARKAS</td>
            <td>(281N)</td>
            <td>AMBARLI - ODESSA</td>
            <td>2020-03-20</td>
          </tr>
          <tr>
            <td>12</td>
            <td>GRETA</td>
            <td>2021-03-26</td>
            <td>2021-03-30</td>
            <td>MARTI</td>
            <td>21-417</td>
            <td>AMBARLI - ODESSA</td>
            <td>2021-03-25</td>
          </tr>
          <tr>
            <td>12</td>
            <td>PAAVA </td>
            <td>2020-03-21</td>
            <td>2020-03-24</td>
            <td>MARTI</td>
            <td>20-123</td>
            <td>AMBARLI - ODESSA</td>
            <td>2020-03-19</td>
          </tr>
          <tr>
            <td>12</td>
            <td>MAIRA XL</td>
            <td>2020-03-21</td>
            <td>2020-03-24</td>
            <td>OOCL</td>
            <td>024E</td>
            <td>AMBARLI - ODESSA</td>
            <td>2020-03-18</td>
          </tr>
          <tr>
            <td>11</td>
            <td>GRETA</td>
            <td>2021-03-20</td>
            <td>2021-03-23</td>
            <td>MARTI</td>
            <td>21-416</td>
            <td>AMBARLI - ODESSA</td>
            <td>2021-03-18</td>
          </tr>
          <tr>
            <td>11</td>
            <td>PAOLA</td>
            <td>2020-03-13</td>
            <td>2020-03-15</td>
            <td>MARTI</td>
            <td>20-222</td>
            <td>AMBARLI - ODESSA</td>
            <td>2020-03-12</td>
          </tr>
          <tr>
            <td>11</td>
            <td>YM INVENTIVE</td>
            <td>2020-03-25</td>
            <td>2020-03-29</td>
            <td>ARKAS</td>
            <td>IBX277N20 (277N)</td>
            <td>AMBARLI - ODESSA</td>
            <td>2020-03-24</td>
          </tr>
          <tr>
            <td>3</td>
            <td>GRETA</td>
            <td>2021-01-23</td>
            <td>2021-01-25</td>
            <td>MARTI</td>
            <td> 21-409</td>
            <td>AMBARLI - ODESSA</td>
            <td>2021-01-21</td>
          </tr>
          <tr>
            <td>2</td>
            <td>GRETA</td>
            <td>2021-01-23</td>
            <td>2021-01-25</td>
            <td>MARTI</td>
            <td> 20-409</td>
            <td>AMBARLI - ODESSA</td>
            <td>2021-01-22</td>
          </tr>
          <tr>
            <td>2</td>
            <td>YM INVENTIVE</td>
            <td>2021-01-20</td>
            <td>2021-01-23</td>
            <td>ARKAS</td>
            <td>IBX324N20 (324N)</td>
            <td>AMBARLI - ODESSA</td>
            <td>2021-01-18</td>
          </tr>
          <tr>
            <td>2</td>
            <td></td>
            <td>2021-01-18</td>
            <td>2021-01-20</td>
            <td>OOCL</td>
            <td>002E</td>
            <td>AMBARLI - ODESSA</td>
            <td>2021-01-14</td>
          </tr>
          <tr>
            <td>1</td>
            <td>GRETA</td>
            <td>2021-01-11</td>
            <td>2021-01-13</td>
            <td>MARTI</td>
            <td>20-408</td>
            <td>AMBARLI - ODESSA</td>
            <td>2021-01-08</td>
          </tr>
          <tr>
            <td>1</td>
            <td>YM INVENTIVE</td>
            <td>2021-01-13</td>
            <td>2021-01-16</td>
            <td>ARKAS</td>
            <td>IBX323N20 (323N)</td>
            <td>AMBARLI - ODESSA</td>
            <td>2021-01-11</td>
          </tr>
          <tr>
            <td>1</td>
            <td>GRETA</td>
            <td>2021-01-02</td>
            <td>2020-12-04</td>
            <td>MARTI</td>
            <td>20-408</td>
            <td>AMBARLI - ODESSA</td>
            <td>2020-12-30</td>
          </tr>
          <tr>
            <td>52</td>
            <td>GRETA</td>
            <td>2020-12-26</td>
            <td>2020-12-28</td>
            <td>MARTI</td>
            <td>20-407</td>
            <td>AMBARLI - ODESSA</td>
            <td>2020-12-25</td>
          </tr>
          <tr>
            <td>52</td>
            <td>YM INVENTIVE</td>
            <td>2021-01-01</td>
            <td>2020-12-05</td>
            <td>ARKAS</td>
            <td>IBX321N20 (321N)</td>
            <td>AMBARLI - ODESSA</td>
            <td>2020-12-31</td>
          </tr>
          <tr>
            <td>35</td>
            <td>PAAVA </td>
            <td>2020-09-22</td>
            <td>2020-09-24</td>
            <td>MARTI</td>
            <td>20-133</td>
            <td>AMBARLI - ODESSA</td>
            <td>2020-08-20</td>
          </tr>
          <tr>
            <td>35</td>
            <td>YM INVENTIVE</td>
            <td>2020-09-01</td>
            <td>2020-09-05</td>
            <td>ARKAS</td>
            <td>IBX304N20 (304N)</td>
            <td>AMBARLI - ODESSA</td>
            <td>2020-08-28</td>
          </tr>
          <tr>
            <td>10</td>
            <td>ROZA A</td>
            <td>2020-03-08</td>
            <td>2020-03-11</td>
            <td>ARKAS</td>
            <td>BME001</td>
            <td>AMBARLI - ODESSA</td>
            <td>2020-03-06</td>
          </tr>
          <tr>
            <td>10</td>
            <td>COSCO SHIPPING RHINE</td>
            <td>2020-03-07</td>
            <td>2020-03-10</td>
            <td>OOCL</td>
            <td>015E</td>
            <td>AMBARLI - ODESSA</td>
            <td>2020-03-04</td>
          </tr>
          <tr>
            <td>9</td>
            <td>YM INVENTIVE</td>
            <td>2020-03-03</td>
            <td>2020-03-07</td>
            <td>ARKAS</td>
            <td>278N</td>
            <td>AMBARLI - ODESSA</td>
            <td>2020-02-28</td>
          </tr>
          <tr>
            <td>9</td>
            <td>CMA CGM VOLGA</td>
            <td>2020-03-01</td>
            <td>2020-03-03</td>
            <td>OOCL</td>
            <td>0BX5QE1MA</td>
            <td>AMBARLI - ODESSA</td>
            <td>2020-02-26</td>
          </tr>
        </tbody>
      </table>
    ),
  },
  {
    id: 4,
    itemText: "AMBARLI - PIVDENNYI",
    itemValue: (
      <table class="vessel-table">
        <thead>
          <tr>
            <th>Week</th>
            <th>Ship</th>
            <th>ETD</th>
            <th>ETA</th>
            <th>Line</th>
            <th>Voyage</th>
            <th>VesselCategories</th>
            <th>CutOff</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>8</td>
            <td> POLAR BRASIL</td>
            <td>2022-03-04</td>
            <td>2022-03-08</td>
            <td>Sealand</td>
            <td>205E</td>
            <td>AMBARLI - PIVDENNYI</td>
            <td>2022-03-01</td>
          </tr>
          <tr>
            <td>8</td>
            <td>SAFMARINE NOKWANDA</td>
            <td>2021-03-05</td>
            <td>2021-03-09</td>
            <td>Sealand</td>
            <td>105E</td>
            <td>AMBARLI - PIVDENNYI</td>
            <td>2021-03-03</td>
          </tr>
          <tr>
            <td>8</td>
            <td> LARS MAERSK</td>
            <td>2020-02-20</td>
            <td>2020-02-22</td>
            <td>Sealand</td>
            <td>004E</td>
            <td>AMBARLI - PIVDENNYI</td>
            <td>2020-02-19</td>
          </tr>
          <tr>
            <td>7</td>
            <td>SAFMARINE NOMAZWE</td>
            <td>2022-02-25</td>
            <td>2022-03-02</td>
            <td>Sealand</td>
            <td>204E</td>
            <td>AMBARLI - PIVDENNYI</td>
            <td>2022-02-22</td>
          </tr>
          <tr>
            <td>7</td>
            <td>SERENADA</td>
            <td>2022-02-25</td>
            <td>2022-02-28</td>
            <td>AKKON</td>
            <td>SER0722</td>
            <td>AMBARLI - PIVDENNYI</td>
            <td>2022-02-24</td>
          </tr>
          <tr>
            <td>7</td>
            <td>LEXA MAERSK</td>
            <td>2021-02-26</td>
            <td>2021-03-01</td>
            <td>Sealand</td>
            <td>104E</td>
            <td>AMBARLI - PIVDENNYI</td>
            <td>2021-02-24</td>
          </tr>
          <tr>
            <td>7</td>
            <td>LUNA MAERSK</td>
            <td>2020-02-13</td>
            <td>2020-02-15</td>
            <td>Sealand</td>
            <td>003E</td>
            <td>AMBARLI - PIVDENNYI</td>
            <td>2020-02-12</td>
          </tr>
          <tr>
            <td>6</td>
            <td>SAFMARINE NOKWANDA</td>
            <td>2022-02-18</td>
            <td>2022-02-22</td>
            <td>Sealand</td>
            <td>203E</td>
            <td>AMBARLI - PIVDENNYI</td>
            <td>2022-02-15</td>
          </tr>
          <tr>
            <td>6</td>
            <td>SERENADA</td>
            <td>2022-02-08</td>
            <td>2022-02-14</td>
            <td>AKKON</td>
            <td>SER0522</td>
            <td>AMBARLI - PIVDENNYI</td>
            <td>2022-02-07</td>
          </tr>
          <tr>
            <td>6</td>
            <td>LEXA MAERSK</td>
            <td>2021-02-26</td>
            <td>2021-03-01</td>
            <td>Sealand</td>
            <td>104E</td>
            <td>AMBARLI - PIVDENNYI</td>
            <td>2021-02-24</td>
          </tr>
          <tr>
            <td>6</td>
            <td>LAUST MAERSK</td>
            <td>2020-02-06</td>
            <td>2020-02-10</td>
            <td>Sealand</td>
            <td>002E</td>
            <td>AMBARLI - PIVDENNYI</td>
            <td>2020-02-05</td>
          </tr>
          <tr>
            <td>5</td>
            <td>LEDA MAERSK</td>
            <td>2022-02-11</td>
            <td>2022-02-16</td>
            <td>Sealand</td>
            <td>202E</td>
            <td>AMBARLI - PIVDENNYI</td>
            <td>2022-02-08</td>
          </tr>
          <tr>
            <td>5</td>
            <td>LAURA MAERSK</td>
            <td>2021-02-12</td>
            <td>2021-02-16</td>
            <td>Sealand</td>
            <td>102E</td>
            <td>AMBARLI - PIVDENNYI</td>
            <td>2021-02-10</td>
          </tr>
          <tr>
            <td>5</td>
            <td>MAERSK BINTAN </td>
            <td>2020-01-31</td>
            <td>2020-02-04</td>
            <td>Sealand</td>
            <td>001E</td>
            <td>AMBARLI - PIVDENNYI</td>
            <td>2020-01-29</td>
          </tr>
          <tr>
            <td>4</td>
            <td>LAURA MAERSK</td>
            <td>2022-02-04</td>
            <td>2022-02-09</td>
            <td>Sealand</td>
            <td>201E</td>
            <td>AMBARLI - PIVDENNYI</td>
            <td>2022-02-01</td>
          </tr>
          <tr>
            <td>4</td>
            <td>SERENADA</td>
            <td>2022-02-07</td>
            <td>2022-02-10</td>
            <td>AKKON</td>
            <td>SER0522</td>
            <td>AMBARLI - PIVDENNYI</td>
            <td>2022-02-04</td>
          </tr>
          <tr>
            <td>4</td>
            <td> LARS MAERSK</td>
            <td>2021-02-05</td>
            <td>2021-02-09</td>
            <td>Sealand</td>
            <td>101E</td>
            <td>AMBARLI - PIVDENNYI</td>
            <td>2021-02-03</td>
          </tr>
          <tr>
            <td>51</td>
            <td>ADILIA I</td>
            <td>2021-12-26</td>
            <td>2022-01-01</td>
            <td>AKKON</td>
            <td>0721</td>
            <td>AMBARLI - PIVDENNYI</td>
            <td>2021-12-24</td>
          </tr>
          <tr>
            <td>51</td>
            <td>MAERSK LAUNCESTON</td>
            <td>2021-12-30</td>
            <td>2022-01-03</td>
            <td>Sealand</td>
            <td>152W</td>
            <td>AMBARLI - PIVDENNYI</td>
            <td>2021-12-30</td>
          </tr>
          <tr>
            <td>50</td>
            <td>ADILIA I</td>
            <td>2021-12-23</td>
            <td>2021-12-28</td>
            <td>AKKON</td>
            <td>0721</td>
            <td>AMBARLI - PIVDENNYI</td>
            <td>2021-12-22</td>
          </tr>
          <tr>
            <td>50</td>
            <td>MAERSK LAUNCESTON</td>
            <td>2021-12-23</td>
            <td>2021-12-24</td>
            <td>Sealand</td>
            <td>147E</td>
            <td>AMBARLI - PIVDENNYI</td>
            <td>2021-12-21</td>
          </tr>
          <tr>
            <td>49</td>
            <td>ADILIA I</td>
            <td>2021-12-12</td>
            <td>2021-12-17</td>
            <td>AKKON</td>
            <td>0621</td>
            <td>AMBARLI - PIVDENNYI</td>
            <td>2021-12-10</td>
          </tr>
          <tr>
            <td>49</td>
            <td>SAFMARINE NOMAZWE</td>
            <td>2021-12-16</td>
            <td>2021-12-17</td>
            <td>Sealand</td>
            <td>146E</td>
            <td>AMBARLI - PIVDENNYI</td>
            <td>2021-12-14</td>
          </tr>
          <tr>
            <td>49</td>
            <td>ADILIA I</td>
            <td>2021-12-15</td>
            <td>2021-12-18</td>
            <td>AKKON</td>
            <td>0721</td>
            <td>AMBARLI - PIVDENNYI</td>
            <td>2021-12-14</td>
          </tr>
          <tr>
            <td>48</td>
            <td>ADILIA I</td>
            <td>2021-12-06</td>
            <td>2021-12-09</td>
            <td>AKKON</td>
            <td>0621</td>
            <td>AMBARLI - PIVDENNYI</td>
            <td>2021-12-03</td>
          </tr>
          <tr>
            <td>47</td>
            <td>ADILIA I</td>
            <td>2021-12-04</td>
            <td>2021-12-08</td>
            <td>AKKON</td>
            <td>0621</td>
            <td>AMBARLI - PIVDENNYI</td>
            <td>2021-12-03</td>
          </tr>
          <tr>
            <td>47</td>
            <td>CSAV TRAIGUEN</td>
            <td>2021-12-02</td>
            <td>2021-12-03</td>
            <td>Sealand</td>
            <td>144E</td>
            <td>AMBARLI - PIVDENNYI</td>
            <td>2021-11-30</td>
          </tr>
          <tr>
            <td>46</td>
            <td>LAURA MAERSK</td>
            <td>2021-11-25</td>
            <td>2021-11-26</td>
            <td>Sealand</td>
            <td>143E</td>
            <td>AMBARLI - PIVDENNYI</td>
            <td>2021-11-23</td>
          </tr>
          <tr>
            <td>46</td>
            <td>ADILIA I</td>
            <td>2021-11-20</td>
            <td>2021-11-22</td>
            <td>AKKON</td>
            <td>0521</td>
            <td>AMBARLI - PIVDENNYI</td>
            <td>2021-11-19</td>
          </tr>
          <tr>
            <td>45</td>
            <td>LICA MAERSK</td>
            <td>2021-11-18</td>
            <td>2021-11-19</td>
            <td>Sealand</td>
            <td>142E</td>
            <td>AMBARLI - PIVDENNYI</td>
            <td>2021-11-16</td>
          </tr>
          <tr>
            <td>45</td>
            <td>ADILIA I</td>
            <td>2021-11-20</td>
            <td>2021-11-22</td>
            <td>AKKON</td>
            <td>L0521</td>
            <td>AMBARLI - PIVDENNYI</td>
            <td>2021-11-19</td>
          </tr>
          <tr>
            <td>44</td>
            <td> LARS MAERSK</td>
            <td>2021-11-09</td>
            <td>2021-11-12</td>
            <td>Sealand</td>
            <td>141E</td>
            <td>AMBARLI - PIVDENNYI</td>
            <td>2021-11-09</td>
          </tr>
          <tr>
            <td>43</td>
            <td>ADILIA I</td>
            <td>2021-10-30</td>
            <td>2021-11-04</td>
            <td>AKKON</td>
            <td>ADL0321</td>
            <td>AMBARLI - PIVDENNYI</td>
            <td>2021-10-28</td>
          </tr>
          <tr>
            <td>43</td>
            <td>LUNA MAERSK</td>
            <td>2021-11-05</td>
            <td>2021-11-07</td>
            <td>Sealand</td>
            <td>140E</td>
            <td>AMBARLI - PIVDENNYI</td>
            <td>2021-11-02</td>
          </tr>
          <tr>
            <td>43</td>
            <td>ADILIA I</td>
            <td>2021-12-10</td>
            <td>2021-11-12</td>
            <td>AKKON</td>
            <td>0421</td>
            <td>AMBARLI - PIVDENNYI</td>
            <td>2021-12-09</td>
          </tr>
          <tr>
            <td>42</td>
            <td>LAUST MAERSK</td>
            <td>2021-10-28</td>
            <td>2021-10-30</td>
            <td>Sealand</td>
            <td>139E</td>
            <td>AMBARLI - PIVDENNYI</td>
            <td>2021-10-26</td>
          </tr>
          <tr>
            <td>42</td>
            <td>ADILIA I</td>
            <td>2021-10-23</td>
            <td>2021-10-30</td>
            <td>AKKON</td>
            <td>ADL0221</td>
            <td>AMBARLI - PIVDENNYI</td>
            <td>2021-10-22</td>
          </tr>
          <tr>
            <td>41</td>
            <td>MAX</td>
            <td>2021-10-16</td>
            <td>2021-10-21</td>
            <td>AKKON</td>
            <td>0621</td>
            <td>AMBARLI - PIVDENNYI</td>
            <td>2021-10-15</td>
          </tr>
          <tr>
            <td>41</td>
            <td>LEDA MAERSK</td>
            <td>2021-10-21</td>
            <td>2021-10-23</td>
            <td>Sealand</td>
            <td>138E</td>
            <td>AMBARLI - PIVDENNYI</td>
            <td>2021-10-19</td>
          </tr>
          <tr>
            <td>40</td>
            <td>MAX</td>
            <td>2021-10-09</td>
            <td>2021-10-12</td>
            <td>AKKON</td>
            <td>0521</td>
            <td>AMBARLI - PIVDENNYI</td>
            <td>2021-10-08</td>
          </tr>
          <tr>
            <td>40</td>
            <td>MAERSK LAUNCESTON</td>
            <td>2021-10-14</td>
            <td>2021-10-16</td>
            <td>Sealand</td>
            <td>137E</td>
            <td>AMBARLI - PIVDENNYI</td>
            <td>2021-10-11</td>
          </tr>
          <tr>
            <td>39</td>
            <td>ALK</td>
            <td>2021-10-01</td>
            <td>2021-10-04</td>
            <td>AKKON</td>
            <td>1621</td>
            <td>AMBARLI - PIVDENNYI</td>
            <td>2021-09-30</td>
          </tr>
          <tr>
            <td>39</td>
            <td>SAFMARINE NOMAZWE</td>
            <td>2021-10-07</td>
            <td>2021-10-10</td>
            <td>Sealand</td>
            <td>136E</td>
            <td>AMBARLI - PIVDENNYI</td>
            <td>2021-10-05</td>
          </tr>
          <tr>
            <td>39</td>
            <td>LAURA MAERSK</td>
            <td>2020-10-24</td>
            <td>2020-10-28</td>
            <td>Sealand</td>
            <td>0345</td>
            <td>AMBARLI - PIVDENNYI</td>
            <td>2020-09-23</td>
          </tr>
          <tr>
            <td>38</td>
            <td>MAX</td>
            <td>2021-10-01</td>
            <td>2021-09-02</td>
            <td>AKKON</td>
            <td>MAX0621</td>
            <td>AMBARLI - PIVDENNYI</td>
            <td>2021-09-27</td>
          </tr>
          <tr>
            <td>38</td>
            <td>SAFMARINE NOKWANDA</td>
            <td>2021-09-30</td>
            <td>2021-10-02</td>
            <td>Sealand</td>
            <td>135E</td>
            <td>AMBARLI - PIVDENNYI</td>
            <td>2021-09-28</td>
          </tr>
          <tr>
            <td>38</td>
            <td> LARS MAERSK</td>
            <td>2020-09-19</td>
            <td>2020-09-22</td>
            <td>Sealand</td>
            <td>034E</td>
            <td>AMBARLI - PIVDENNYI</td>
            <td>2020-09-16</td>
          </tr>
          <tr>
            <td>37</td>
            <td>LEXA MAERSK</td>
            <td>2021-09-24</td>
            <td>2021-09-29</td>
            <td>Sealand</td>
            <td>134E</td>
            <td>AMBARLI - PIVDENNYI</td>
            <td>2021-09-21</td>
          </tr>
          <tr>
            <td>37</td>
            <td>LUNA MAERSK</td>
            <td>2020-09-10</td>
            <td>2020-09-14</td>
            <td>Sealand</td>
            <td>033E</td>
            <td>AMBARLI - PIVDENNYI</td>
            <td>2020-09-09</td>
          </tr>
          <tr>
            <td>36</td>
            <td>LAURA MAERSK</td>
            <td>2021-09-17</td>
            <td>2021-09-22</td>
            <td>Sealand</td>
            <td>133E</td>
            <td>AMBARLI - PIVDENNYI</td>
            <td>2021-09-14</td>
          </tr>
          <tr>
            <td>36</td>
            <td>LAUST MAERSK</td>
            <td>2020-09-03</td>
            <td>2020-09-07</td>
            <td>Sealand</td>
            <td>034E</td>
            <td>AMBARLI - PIVDENNYI</td>
            <td>2020-09-02</td>
          </tr>
          <tr>
            <td>35</td>
            <td>LAURA MAERSK</td>
            <td>2021-09-17</td>
            <td>2021-09-22</td>
            <td>Sealand</td>
            <td>133E</td>
            <td>AMBARLI - PIVDENNYI</td>
            <td>2021-09-14</td>
          </tr>
          <tr>
            <td>35</td>
            <td>LEDA MAERSK</td>
            <td>2020-08-27</td>
            <td>2020-07-27</td>
            <td>Sealand</td>
            <td>031E</td>
            <td>AMBARLI - PIVDENNYI</td>
            <td>2020-08-26</td>
          </tr>
          <tr>
            <td>34</td>
            <td>LICA MAERSK</td>
            <td>2021-09-10</td>
            <td>2021-09-15</td>
            <td>Sealand</td>
            <td>132E</td>
            <td>AMBARLI - PIVDENNYI</td>
            <td>2021-09-07</td>
          </tr>
          <tr>
            <td>34</td>
            <td>MAERSK LAUNCESTON</td>
            <td>2020-08-20</td>
            <td>2020-08-24</td>
            <td>Sealand</td>
            <td>030E</td>
            <td>AMBARLI - PIVDENNYI</td>
            <td>2020-08-19</td>
          </tr>
          <tr>
            <td>33</td>
            <td>LUNA MAERSK</td>
            <td>2021-08-27</td>
            <td>2021-08-31</td>
            <td>Sealand</td>
            <td>130E</td>
            <td>AMBARLI - PIVDENNYI</td>
            <td>2021-08-24</td>
          </tr>
          <tr>
            <td>33</td>
            <td>SAFMARINE NOMAZWE</td>
            <td>2020-08-14</td>
            <td>2020-08-18</td>
            <td>Sealand</td>
            <td>029E</td>
            <td>AMBARLI - PIVDENNYI</td>
            <td>2020-08-12</td>
          </tr>
          <tr>
            <td>32</td>
            <td>LAUST MAERSK</td>
            <td>2021-08-20</td>
            <td>2021-08-24</td>
            <td>Sealand</td>
            <td>129E</td>
            <td>AMBARLI - PIVDENNYI</td>
            <td>2021-08-17</td>
          </tr>
          <tr>
            <td>31</td>
            <td>LEDA MAERSK</td>
            <td>2021-08-13</td>
            <td>2021-08-17</td>
            <td>Sealand</td>
            <td>128E</td>
            <td>AMBARLI - PIVDENNYI</td>
            <td>2021-08-10</td>
          </tr>
          <tr>
            <td>31</td>
            <td>SAFMARINE NOKWANDA</td>
            <td>2020-08-11</td>
            <td>2020-08-15</td>
            <td>Sealand</td>
            <td>028E</td>
            <td>AMBARLI - PIVDENNYI</td>
            <td>2020-08-05</td>
          </tr>
          <tr>
            <td>30</td>
            <td>MAERSK LAUNCESTON</td>
            <td>2021-08-06</td>
            <td>2021-08-10</td>
            <td>Sealand</td>
            <td>131E</td>
            <td>AMBARLI - PIVDENNYI</td>
            <td>2021-08-03</td>
          </tr>
          <tr>
            <td>30</td>
            <td>LICA MAERSK</td>
            <td>2020-07-24</td>
            <td>2020-07-27</td>
            <td>Sealand</td>
            <td>026E</td>
            <td>AMBARLI - PIVDENNYI</td>
            <td>2020-07-22</td>
          </tr>
          <tr>
            <td>29</td>
            <td>SAFMARINE NOMAZWE</td>
            <td>2021-07-29</td>
            <td>2021-08-03</td>
            <td>Sealand</td>
            <td>126E</td>
            <td>AMBARLI - PIVDENNYI</td>
            <td>2021-07-27</td>
          </tr>
          <tr>
            <td>29</td>
            <td>MONTE ACONCAGUA </td>
            <td>2020-07-17</td>
            <td>2020-07-21</td>
            <td>Sealand</td>
            <td>026E</td>
            <td>AMBARLI - PIVDENNYI</td>
            <td>2020-07-14</td>
          </tr>
          <tr>
            <td>28</td>
            <td>SEAGO ISTANBUL</td>
            <td>2021-07-23</td>
            <td>2021-07-28</td>
            <td>Sealand</td>
            <td>125E</td>
            <td>AMBARLI - PIVDENNYI</td>
            <td>2021-07-13</td>
          </tr>
          <tr>
            <td>28</td>
            <td>MONTE ACONCAGUA </td>
            <td>2020-07-17</td>
            <td>2020-07-21</td>
            <td>Sealand</td>
            <td>026E</td>
            <td>AMBARLI - PIVDENNYI</td>
            <td>2020-07-14</td>
          </tr>
          <tr>
            <td>27</td>
            <td>LEXA MAERSK</td>
            <td>2021-07-16</td>
            <td>2021-07-20</td>
            <td>Sealand</td>
            <td>124E</td>
            <td>AMBARLI - PIVDENNYI</td>
            <td>2021-07-12</td>
          </tr>
          <tr>
            <td>27</td>
            <td>LUNA MAERSK</td>
            <td>2020-07-03</td>
            <td>2020-07-07</td>
            <td>Sealand</td>
            <td>023E</td>
            <td>AMBARLI - PIVDENNYI</td>
            <td>2020-07-01</td>
          </tr>
          <tr>
            <td>26</td>
            <td>SEAGO ISTANBUL</td>
            <td>2021-07-09</td>
            <td>2021-07-14</td>
            <td>Sealand</td>
            <td>123E</td>
            <td>AMBARLI - PIVDENNYI</td>
            <td>2021-07-06</td>
          </tr>
          <tr>
            <td>26</td>
            <td>ADILIA I</td>
            <td>2021-11-20</td>
            <td>2021-11-22</td>
            <td>AKKON</td>
            <td>0521</td>
            <td>AMBARLI - PIVDENNYI</td>
            <td>2021-11-19</td>
          </tr>
          <tr>
            <td>26</td>
            <td>LAUST MAERSK</td>
            <td>2020-06-25</td>
            <td>2020-06-29</td>
            <td>Sealand</td>
            <td>022E</td>
            <td>AMBARLI - PIVDENNYI</td>
            <td>2020-06-24</td>
          </tr>
          <tr>
            <td>25</td>
            <td>LAURA MAERSK</td>
            <td>2021-07-02</td>
            <td>2021-07-07</td>
            <td>Sealand</td>
            <td>122E</td>
            <td>AMBARLI - PIVDENNYI</td>
            <td>2021-06-29</td>
          </tr>
          <tr>
            <td>25</td>
            <td>LEDA MAERSK</td>
            <td>2020-06-18</td>
            <td>2020-06-23</td>
            <td>Sealand</td>
            <td>021E</td>
            <td>AMBARLI - PIVDENNYI</td>
            <td>2020-06-17</td>
          </tr>
          <tr>
            <td>24</td>
            <td> LARS MAERSK</td>
            <td>2021-06-25</td>
            <td>2021-06-30</td>
            <td>Sealand</td>
            <td>121E</td>
            <td>AMBARLI - PIVDENNYI</td>
            <td>2021-06-22</td>
          </tr>
          <tr>
            <td>24</td>
            <td>MAERSK LAUNCESTON</td>
            <td>2020-06-11</td>
            <td>2020-06-14</td>
            <td>Sealand</td>
            <td>020E</td>
            <td>AMBARLI - PIVDENNYI</td>
            <td>2020-06-10</td>
          </tr>
          <tr>
            <td>23</td>
            <td>LUNA MAERSK</td>
            <td>2021-06-18</td>
            <td>2021-06-23</td>
            <td>Sealand</td>
            <td>120E</td>
            <td>AMBARLI - PIVDENNYI</td>
            <td>2021-06-15</td>
          </tr>
          <tr>
            <td>23</td>
            <td>SAFMARINE NOMAZWE</td>
            <td>2020-06-04</td>
            <td>2020-06-08</td>
            <td>Sealand</td>
            <td>019E</td>
            <td>AMBARLI - PIVDENNYI</td>
            <td>2020-06-03</td>
          </tr>
          <tr>
            <td>22</td>
            <td>SEAGO ISTANBUL</td>
            <td>2021-06-11</td>
            <td>2021-06-16</td>
            <td>Sealand</td>
            <td>119E</td>
            <td>AMBARLI - PIVDENNYI</td>
            <td>2021-06-08</td>
          </tr>
          <tr>
            <td>22</td>
            <td>SAFMARINE NOMAZWE</td>
            <td>2020-06-04</td>
            <td>2020-06-08</td>
            <td>Sealand</td>
            <td>019E</td>
            <td>AMBARLI - PIVDENNYI</td>
            <td>2020-06-03</td>
          </tr>
          <tr>
            <td>21</td>
            <td>LEDA MAERSK</td>
            <td>2021-06-01</td>
            <td>2021-06-04</td>
            <td>Sealand</td>
            <td>118E</td>
            <td>AMBARLI - PIVDENNYI</td>
            <td>2021-06-01</td>
          </tr>
          <tr>
            <td>21</td>
            <td>LEXA MAERSK</td>
            <td>2020-05-29</td>
            <td>2020-06-02</td>
            <td>Sealand</td>
            <td>017E</td>
            <td>AMBARLI - PIVDENNYI</td>
            <td>2020-05-27</td>
          </tr>
          <tr>
            <td>20</td>
            <td>MAERSK LAUNCESTON</td>
            <td>2021-05-27</td>
            <td>2021-06-01</td>
            <td>Sealand</td>
            <td>117E</td>
            <td>AMBARLI - PIVDENNYI</td>
            <td>2021-05-25</td>
          </tr>
          <tr>
            <td>20</td>
            <td>LICA MAERSK</td>
            <td>2020-05-15</td>
            <td>2020-05-19</td>
            <td>Sealand</td>
            <td>016E</td>
            <td>AMBARLI - PIVDENNYI</td>
            <td>2020-05-13</td>
          </tr>
          <tr>
            <td>19</td>
            <td>SAFMARINE NOMAZWE</td>
            <td>2021-05-21</td>
            <td>2021-05-26</td>
            <td>Sealand</td>
            <td>116E</td>
            <td>AMBARLI - PIVDENNYI</td>
            <td>2021-05-17</td>
          </tr>
          <tr>
            <td>19</td>
            <td>LAURA MAERSK</td>
            <td>2020-05-08</td>
            <td>2020-05-12</td>
            <td>Sealand</td>
            <td>015E</td>
            <td>AMBARLI - PIVDENNYI</td>
            <td>2020-05-06</td>
          </tr>
          <tr>
            <td>18</td>
            <td>SAFMARINE NOKWANDA</td>
            <td>2021-05-13</td>
            <td>2021-05-17</td>
            <td>Sealand</td>
            <td>115E</td>
            <td>AMBARLI - PIVDENNYI</td>
            <td>2021-05-10</td>
          </tr>
          <tr>
            <td>18</td>
            <td> LARS MAERSK</td>
            <td>2020-05-01</td>
            <td>2020-05-05</td>
            <td>Sealand</td>
            <td>014E</td>
            <td>AMBARLI - PIVDENNYI</td>
            <td>2020-04-29</td>
          </tr>
          <tr>
            <td>17</td>
            <td>LEXA MAERSK</td>
            <td>2021-05-07</td>
            <td>2021-05-12</td>
            <td>Sealand</td>
            <td>114E</td>
            <td>AMBARLI - PIVDENNYI</td>
            <td>2021-05-05</td>
          </tr>
          <tr>
            <td>17</td>
            <td>LUNA MAERSK</td>
            <td>2020-04-24</td>
            <td>2020-04-28</td>
            <td>Sealand</td>
            <td>013E</td>
            <td>AMBARLI - PIVDENNYI</td>
            <td>2020-04-22</td>
          </tr>
          <tr>
            <td>16</td>
            <td>SEAGO ISTANBUL</td>
            <td>2021-04-30</td>
            <td>2021-05-05</td>
            <td>Sealand</td>
            <td>113E</td>
            <td>AMBARLI - PIVDENNYI</td>
            <td>2021-04-28</td>
          </tr>
          <tr>
            <td>16</td>
            <td>LAUST MAERSK</td>
            <td>2020-04-17</td>
            <td>2020-04-21</td>
            <td>Sealand</td>
            <td>012E</td>
            <td>AMBARLI - PIVDENNYI</td>
            <td>2020-04-15</td>
          </tr>
          <tr>
            <td>15</td>
            <td>LAURA MAERSK</td>
            <td>2021-04-23</td>
            <td>2021-04-27</td>
            <td>Sealand</td>
            <td>112E</td>
            <td>AMBARLI - PIVDENNYI</td>
            <td>2021-04-21</td>
          </tr>
          <tr>
            <td>15</td>
            <td>LEDA MAERSK</td>
            <td>2020-04-10</td>
            <td>2020-04-14</td>
            <td>Sealand</td>
            <td>011E</td>
            <td>AMBARLI - PIVDENNYI</td>
            <td>2020-04-08</td>
          </tr>
          <tr>
            <td>14</td>
            <td> LARS MAERSK</td>
            <td>2021-04-16</td>
            <td>2021-04-21</td>
            <td>Sealand</td>
            <td>111E</td>
            <td>AMBARLI - PIVDENNYI</td>
            <td>2021-04-14</td>
          </tr>
          <tr>
            <td>14</td>
            <td>MAERSK LAUNCESTON</td>
            <td>2020-04-03</td>
            <td>2020-04-07</td>
            <td>Sealand</td>
            <td>010E</td>
            <td>AMBARLI - PIVDENNYI</td>
            <td>2020-04-01</td>
          </tr>
          <tr>
            <td>13</td>
            <td>SEAGO ISTANBUL</td>
            <td>2021-04-02</td>
            <td>2021-04-07</td>
            <td>Sealand</td>
            <td>112E</td>
            <td>AMBARLI - PIVDENNYI</td>
            <td>2021-03-31</td>
          </tr>
          <tr>
            <td>13</td>
            <td>SAFMARINE NOMAZWE</td>
            <td>2020-03-27</td>
            <td>2020-05-01</td>
            <td>Sealand</td>
            <td>009E</td>
            <td>AMBARLI - PIVDENNYI</td>
            <td>2020-03-25</td>
          </tr>
          <tr>
            <td>12</td>
            <td>SEAGO ISTANBUL</td>
            <td>2021-04-02</td>
            <td>2021-04-07</td>
            <td>Sealand</td>
            <td>109E</td>
            <td>AMBARLI - PIVDENNYI</td>
            <td>2021-03-31</td>
          </tr>
          <tr>
            <td>12</td>
            <td>SAFMARINE NOKWANDA</td>
            <td>2020-03-20</td>
            <td>2020-03-24</td>
            <td>Sealand</td>
            <td>008E</td>
            <td>AMBARLI - PIVDENNYI</td>
            <td>2020-03-18</td>
          </tr>
          <tr>
            <td>11</td>
            <td>LEDA MAERSK</td>
            <td>2021-03-26</td>
            <td>2021-03-30</td>
            <td>Sealand</td>
            <td>108E</td>
            <td>AMBARLI - PIVDENNYI</td>
            <td>2021-03-24</td>
          </tr>
          <tr>
            <td>11</td>
            <td>LEXA MAERSK</td>
            <td>2020-03-12</td>
            <td>2020-03-16</td>
            <td>Sealand</td>
            <td>011N</td>
            <td>AMBARLI - PIVDENNYI</td>
            <td>2020-03-11</td>
          </tr>
          <tr>
            <td>10</td>
            <td>MAERSK LAUNCESTON</td>
            <td>2021-03-19</td>
            <td>2021-03-24</td>
            <td>Sealand</td>
            <td>107E</td>
            <td>AMBARLI - PIVDENNYI</td>
            <td>2021-03-17</td>
          </tr>
          <tr>
            <td>10</td>
            <td>LICA MAERSK</td>
            <td>2020-03-06</td>
            <td>2020-03-03</td>
            <td>SEAGO</td>
            <td>006E</td>
            <td>AMBARLI - PIVDENNYI</td>
            <td>2020-03-04</td>
          </tr>
          <tr>
            <td>9</td>
            <td>SAFMARINE NOMAZWE</td>
            <td>2021-03-12</td>
            <td>2021-03-16</td>
            <td>Sealand</td>
            <td>106E</td>
            <td>AMBARLI - PIVDENNYI</td>
            <td>2021-03-10</td>
          </tr>
          <tr>
            <td>9</td>
            <td>LAURA MAERSK</td>
            <td>2020-02-28</td>
            <td>2020-03-02</td>
            <td>Sealines</td>
            <td>005E</td>
            <td>AMBARLI - PIVDENNYI</td>
            <td>2020-02-26</td>
          </tr>
          <tr>
            <td>3</td>
            <td>LUNA MAERSK</td>
            <td>2021-01-28</td>
            <td>2021-01-31</td>
            <td>Sealand</td>
            <td>053E</td>
            <td>AMBARLI - PIVDENNYI</td>
            <td>2021-01-27</td>
          </tr>
          <tr>
            <td>2</td>
            <td>LAUST MAERSK</td>
            <td>2022-01-07</td>
            <td>2021-12-13</td>
            <td>Sealand</td>
            <td>149E</td>
            <td>AMBARLI - PIVDENNYI</td>
            <td>2022-01-04</td>
          </tr>
          <tr>
            <td>2</td>
            <td>LAUST MAERSK</td>
            <td>2021-01-21</td>
            <td>2021-01-24</td>
            <td>Sealand</td>
            <td>052E</td>
            <td>AMBARLI - PIVDENNYI</td>
            <td>2021-01-20</td>
          </tr>
          <tr>
            <td>1</td>
            <td>MAERSK LAUNCESTON</td>
            <td>2021-01-15</td>
            <td>2021-01-19</td>
            <td>Sealand</td>
            <td>050E</td>
            <td>AMBARLI - PIVDENNYI</td>
            <td>2021-01-13</td>
          </tr>
          <tr>
            <td>1</td>
            <td>MAERSK LAUNCESTON</td>
            <td>2020-12-10</td>
            <td>2020-12-14</td>
            <td>Sealand</td>
            <td>050E</td>
            <td>AMBARLI - PIVDENNYI</td>
            <td>2020-12-08</td>
          </tr>
          <tr>
            <td>1</td>
            <td>MAERSK LAUNCESTON</td>
            <td>2020-12-10</td>
            <td>2020-12-14</td>
            <td>Sealand</td>
            <td>050E</td>
            <td>AMBARLI - PIVDENNYI</td>
            <td>2020-12-08</td>
          </tr>
          <tr>
            <td>1</td>
            <td>MAERSK LAUNCESTON</td>
            <td>2020-12-10</td>
            <td>2020-12-14</td>
            <td>Sealand</td>
            <td>050E</td>
            <td>AMBARLI - PIVDENNYI</td>
            <td>2020-12-08</td>
          </tr>
          <tr>
            <td>52</td>
            <td>SAFMARINE NOKWANDA</td>
            <td>2020-12-25</td>
            <td>2020-12-28</td>
            <td>Sealand</td>
            <td>048E</td>
            <td>AMBARLI - PIVDENNYI</td>
            <td>2020-12-23</td>
          </tr>
        </tbody>
      </table>
    ),
  },
  {
    id: 5,
    itemText: "EVYAP - Novorossiysk",
    itemValue: (
      <table class="vessel-table">
        <thead>
          <tr>
            <th>Week</th>
            <th>Ship</th>
            <th>ETD</th>
            <th>ETA</th>
            <th>Line</th>
            <th>Voyage</th>
            <th>VesselCategories</th>
            <th>CutOff</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>13</td>
            <td>SASKIA A</td>
            <td>2022-04-15</td>
            <td>2022-04-18</td>
            <td>ARKAS</td>
            <td>TLS09S22</td>
            <td>EVYAP - Novorossiysk</td>
            <td>2022-04-11</td>
          </tr>
          <tr>
            <td>13</td>
            <td>ELBE</td>
            <td>2022-04-17</td>
            <td>2022-04-19</td>
            <td>AKKON</td>
            <td>ELB1322</td>
            <td>EVYAP - Novorossiysk</td>
            <td>2022-04-15</td>
          </tr>
          <tr>
            <td>13</td>
            <td>GRETA</td>
            <td>2022-04-17</td>
            <td>2022-04-20</td>
            <td>MARTI</td>
            <td>22-444</td>
            <td>EVYAP - Novorossiysk</td>
            <td>2022-04-14</td>
          </tr>
          <tr>
            <td>13</td>
            <td>ADMIRAL SUN </td>
            <td>2021-04-03</td>
            <td>2021-04-06</td>
            <td>MARTI</td>
            <td>21-101</td>
            <td>EVYAP - Novorossiysk</td>
            <td>2021-04-01</td>
          </tr>
          <tr>
            <td>13</td>
            <td>ADMIRAL SUN </td>
            <td>2021-04-03</td>
            <td>2021-04-06</td>
            <td>MARTI</td>
            <td>21-101</td>
            <td>EVYAP - Novorossiysk</td>
            <td>2021-04-01</td>
          </tr>
          <tr>
            <td>13</td>
            <td>SASKIA A</td>
            <td>2021-04-10</td>
            <td>2021-04-15</td>
            <td>ARKAS</td>
            <td>TLS01021</td>
            <td>EVYAP - Novorossiysk</td>
            <td>2021-04-07</td>
          </tr>
          <tr>
            <td>13</td>
            <td>PACOBA</td>
            <td>2020-03-28</td>
            <td>2020-04-01</td>
            <td>MARTI</td>
            <td>20-304</td>
            <td>EVYAP - Novorossiysk</td>
            <td>2020-03-26</td>
          </tr>
          <tr>
            <td>12</td>
            <td>ELBE</td>
            <td>2022-04-04</td>
            <td>2022-04-06</td>
            <td>AKKON</td>
            <td>ELB1122</td>
            <td>EVYAP - Novorossiysk</td>
            <td>2022-04-01</td>
          </tr>
          <tr>
            <td>12</td>
            <td>TURKON EGYPT</td>
            <td>2022-04-03</td>
            <td>2022-04-06</td>
            <td>ARKAS</td>
            <td>TLS08S23</td>
            <td>EVYAP - Novorossiysk</td>
            <td>2022-04-01</td>
          </tr>
          <tr>
            <td>12</td>
            <td>PAAVA </td>
            <td>2021-03-27</td>
            <td>2021-03-30</td>
            <td>MARTI</td>
            <td> 21-148</td>
            <td>EVYAP - Novorossiysk</td>
            <td>2021-03-25</td>
          </tr>
          <tr>
            <td>12</td>
            <td>PAAVA </td>
            <td>2020-03-21</td>
            <td>2020-03-23</td>
            <td>MARTI</td>
            <td>20-123</td>
            <td>EVYAP - Novorossiysk</td>
            <td>2020-03-20</td>
          </tr>
          <tr>
            <td>11</td>
            <td>ELBE</td>
            <td>2022-03-25</td>
            <td>2022-03-28</td>
            <td>AKKON</td>
            <td>ELB0922</td>
            <td>EVYAP - Novorossiysk</td>
            <td>2022-03-24</td>
          </tr>
          <tr>
            <td>11</td>
            <td>SASKIA A</td>
            <td>2022-03-26</td>
            <td>2022-03-29</td>
            <td>AKKON</td>
            <td>TLS07S22</td>
            <td>EVYAP - Novorossiysk</td>
            <td>2022-03-23</td>
          </tr>
          <tr>
            <td>11</td>
            <td>PAOLA</td>
            <td>2021-03-20</td>
            <td>2021-03-23</td>
            <td>MARTI</td>
            <td> 21-247</td>
            <td>EVYAP - Novorossiysk</td>
            <td>2021-03-18</td>
          </tr>
          <tr>
            <td>11</td>
            <td>PAOLA</td>
            <td>2020-03-14</td>
            <td>2020-03-16</td>
            <td>MARTI</td>
            <td>20-222</td>
            <td>EVYAP - Novorossiysk</td>
            <td>2020-03-13</td>
          </tr>
          <tr>
            <td>10</td>
            <td>ELBE</td>
            <td>2022-03-17</td>
            <td>2022-03-20</td>
            <td>AKKON</td>
            <td>ELB0922</td>
            <td>EVYAP - Novorossiysk</td>
            <td>2022-03-16</td>
          </tr>
          <tr>
            <td>10</td>
            <td>SASKIA A</td>
            <td>2022-03-21</td>
            <td>2022-03-25</td>
            <td>ARKAS</td>
            <td>TLS07S22</td>
            <td>EVYAP - Novorossiysk</td>
            <td>2022-03-18</td>
          </tr>
          <tr>
            <td>10</td>
            <td>PACOBA</td>
            <td>2020-03-07</td>
            <td>2020-03-10</td>
            <td>MARTI</td>
            <td> 20-303</td>
            <td>EVYAP - Novorossiysk</td>
            <td>2020-03-06</td>
          </tr>
          <tr>
            <td>9</td>
            <td>ELBE</td>
            <td>2022-03-10</td>
            <td>2022-03-14</td>
            <td>AKKON</td>
            <td>ELB0822</td>
            <td>EVYAP - Novorossiysk</td>
            <td>2022-03-08</td>
          </tr>
          <tr>
            <td>9</td>
            <td>TURKON EGYPT</td>
            <td>2022-03-10</td>
            <td>2022-03-14</td>
            <td>ARKAS</td>
            <td>TLS06S22</td>
            <td>EVYAP - Novorossiysk</td>
            <td>2022-03-07</td>
          </tr>
          <tr>
            <td>9</td>
            <td>PAAVA </td>
            <td>2020-02-29</td>
            <td>2020-03-02</td>
            <td>MARTI</td>
            <td>20-122</td>
            <td>EVYAP - Novorossiysk</td>
            <td>2020-02-28</td>
          </tr>
          <tr>
            <td>8</td>
            <td>ADMIRAL SUN </td>
            <td>2022-03-06</td>
            <td>2022-03-07</td>
            <td>MARTI</td>
            <td> 22-122</td>
            <td>EVYAP - Novorossiysk</td>
            <td>2022-03-03</td>
          </tr>
          <tr>
            <td>8</td>
            <td>ELBE</td>
            <td>2022-03-08</td>
            <td>2022-03-10</td>
            <td>AKKON</td>
            <td>ELB0822</td>
            <td>EVYAP - Novorossiysk</td>
            <td>2022-03-04</td>
          </tr>
          <tr>
            <td>8</td>
            <td>PAOLA</td>
            <td>2020-02-22</td>
            <td>2020-02-25</td>
            <td>MARTI</td>
            <td>20/221</td>
            <td>EVYAP - Novorossiysk</td>
            <td>2020-02-21</td>
          </tr>
          <tr>
            <td>7</td>
            <td>ADMIRAL MOON</td>
            <td>2022-02-26</td>
            <td>2022-02-28</td>
            <td>Sealand</td>
            <td> 22-220</td>
            <td>EVYAP - Novorossiysk</td>
            <td>2022-02-24</td>
          </tr>
          <tr>
            <td>7</td>
            <td>SASKIA A</td>
            <td>2022-02-27</td>
            <td>2022-03-03</td>
            <td>ARKAS</td>
            <td>TLS05N22</td>
            <td>EVYAP - Novorossiysk</td>
            <td>2022-02-24</td>
          </tr>
          <tr>
            <td>7</td>
            <td>ELBE</td>
            <td>2022-02-23</td>
            <td>2022-02-25</td>
            <td>AKKON</td>
            <td>ELB0622</td>
            <td>EVYAP - Novorossiysk</td>
            <td>2022-02-22</td>
          </tr>
          <tr>
            <td>7</td>
            <td>PACOBA</td>
            <td>2020-02-15</td>
            <td>2020-02-17</td>
            <td>Sealand</td>
            <td>20/302</td>
            <td>EVYAP - Novorossiysk</td>
            <td>2020-02-14</td>
          </tr>
          <tr>
            <td>6</td>
            <td>TURKON EGYPT</td>
            <td>2022-02-16</td>
            <td>2022-02-19</td>
            <td>ARKAS</td>
            <td>TLS04S22</td>
            <td>EVYAP - Novorossiysk</td>
            <td>2022-02-10</td>
          </tr>
          <tr>
            <td>6</td>
            <td>ADMIRAL SUN </td>
            <td>2022-02-19</td>
            <td>2022-02-21</td>
            <td>MARTI</td>
            <td> 22-121</td>
            <td>EVYAP - Novorossiysk</td>
            <td>2022-02-17</td>
          </tr>
          <tr>
            <td>6</td>
            <td>ELBE</td>
            <td>2022-02-14</td>
            <td>2022-02-17</td>
            <td>AKKON</td>
            <td>ELB0522</td>
            <td>EVYAP - Novorossiysk</td>
            <td>2022-02-11</td>
          </tr>
          <tr>
            <td>6</td>
            <td>PAAVA </td>
            <td>2020-02-07</td>
            <td>2020-02-10</td>
            <td>MARTI</td>
            <td>20/121</td>
            <td>EVYAP - Novorossiysk</td>
            <td>2020-02-06</td>
          </tr>
          <tr>
            <td>5</td>
            <td>ADMIRAL MOON</td>
            <td>2022-02-12</td>
            <td>2022-02-15</td>
            <td>MARTI</td>
            <td>22-219</td>
            <td>EVYAP - Novorossiysk</td>
            <td>2022-02-10</td>
          </tr>
          <tr>
            <td>5</td>
            <td>TURKON EGYPT</td>
            <td>2022-02-13</td>
            <td>2022-02-17</td>
            <td>ARKAS</td>
            <td>TLS04S22</td>
            <td>EVYAP - Novorossiysk</td>
            <td>2022-02-10</td>
          </tr>
          <tr>
            <td>5</td>
            <td>GRETA</td>
            <td>2021-02-12</td>
            <td>2021-02-16</td>
            <td>MARTI</td>
            <td>21-411</td>
            <td>EVYAP - Novorossiysk</td>
            <td>2021-02-11</td>
          </tr>
          <tr>
            <td>5</td>
            <td>PAOLA</td>
            <td>2020-01-31</td>
            <td>2020-02-03</td>
            <td>Sealand</td>
            <td>20/2020</td>
            <td>EVYAP - Novorossiysk</td>
            <td>2020-01-30</td>
          </tr>
          <tr>
            <td>4</td>
            <td>SASKIA A</td>
            <td>2022-01-27</td>
            <td>2022-01-30</td>
            <td>ARKAS</td>
            <td>TLS03S22</td>
            <td>EVYAP - Novorossiysk</td>
            <td>2022-01-25</td>
          </tr>
          <tr>
            <td>4</td>
            <td>ADMIRAL SUN </td>
            <td>2022-02-06</td>
            <td>2022-02-09</td>
            <td>MARTI</td>
            <td>22-120</td>
            <td>EVYAP - Novorossiysk</td>
            <td>2022-02-03</td>
          </tr>
          <tr>
            <td>4</td>
            <td>ELBE</td>
            <td>2022-02-07</td>
            <td>2022-02-10</td>
            <td>AKKON</td>
            <td>ELB0422</td>
            <td>EVYAP - Novorossiysk</td>
            <td>2022-02-04</td>
          </tr>
          <tr>
            <td>4</td>
            <td>GRETA</td>
            <td>2021-02-05</td>
            <td>2021-02-08</td>
            <td>MARTI</td>
            <td>21-411</td>
            <td>EVYAP - Novorossiysk</td>
            <td>2021-02-04</td>
          </tr>
          <tr>
            <td>51</td>
            <td>TURKON EGYPT</td>
            <td>2021-12-27</td>
            <td>2021-12-29</td>
            <td>ARKAS</td>
            <td>TLS36S21</td>
            <td>EVYAP - Novorossiysk</td>
            <td>2021-12-22</td>
          </tr>
          <tr>
            <td>51</td>
            <td>ADMIRAL MOON</td>
            <td>2021-12-26</td>
            <td>2021-12-28</td>
            <td>MARTI</td>
            <td>21-216</td>
            <td>EVYAP - Novorossiysk</td>
            <td>2021-12-23</td>
          </tr>
          <tr>
            <td>50</td>
            <td>EMMA A</td>
            <td>2021-12-18</td>
            <td>2021-12-20</td>
            <td>ARKAS</td>
            <td>TLS35S21</td>
            <td>EVYAP - Novorossiysk</td>
            <td>2021-12-16</td>
          </tr>
          <tr>
            <td>50</td>
            <td>ADMIRAL SUN </td>
            <td>2021-12-18</td>
            <td>2021-12-20</td>
            <td>MARTI</td>
            <td>21-117</td>
            <td>EVYAP - Novorossiysk</td>
            <td>2021-12-16</td>
          </tr>
          <tr>
            <td>49</td>
            <td>ADMIRAL MOON</td>
            <td>2021-12-12</td>
            <td>2021-12-14</td>
            <td>MARTI</td>
            <td>21-216</td>
            <td>EVYAP - Novorossiysk</td>
            <td>2021-12-09</td>
          </tr>
          <tr>
            <td>48</td>
            <td>ADMIRAL MOON</td>
            <td>2021-12-05</td>
            <td>2021-12-07</td>
            <td>MARTI</td>
            <td>21-216</td>
            <td>EVYAP - Novorossiysk</td>
            <td>2021-12-02</td>
          </tr>
          <tr>
            <td>48</td>
            <td>SASKIA A</td>
            <td>2021-12-09</td>
            <td>2021-12-11</td>
            <td>ARKAS</td>
            <td>TLS34S21</td>
            <td>EVYAP - Novorossiysk</td>
            <td>2021-12-06</td>
          </tr>
          <tr>
            <td>47</td>
            <td>ADILIA I</td>
            <td>2021-12-04</td>
            <td>2021-12-06</td>
            <td>AKKON</td>
            <td>0621</td>
            <td>EVYAP - Novorossiysk</td>
            <td>2021-12-03</td>
          </tr>
          <tr>
            <td>47</td>
            <td>ADMIRAL SUN </td>
            <td>2021-11-26</td>
            <td>2021-11-29</td>
            <td>MARTI</td>
            <td>21-116</td>
            <td>EVYAP - Novorossiysk</td>
            <td>2021-11-25</td>
          </tr>
          <tr>
            <td>46</td>
            <td>ADMIRAL MOON</td>
            <td>2021-11-20</td>
            <td>2021-11-22</td>
            <td>MARTI</td>
            <td>21-215</td>
            <td>EVYAP - Novorossiysk</td>
            <td>2021-11-18</td>
          </tr>
          <tr>
            <td>46</td>
            <td>TURKON EGYPT</td>
            <td>2021-11-30</td>
            <td>2021-12-04</td>
            <td>ARKAS</td>
            <td>TLS33S21</td>
            <td>EVYAP - Novorossiysk</td>
            <td>2021-11-23</td>
          </tr>
          <tr>
            <td>46</td>
            <td>ADILIA I</td>
            <td>2021-11-26</td>
            <td>2021-11-28</td>
            <td>AKKON</td>
            <td>0521</td>
            <td>EVYAP - Novorossiysk</td>
            <td>2021-11-26</td>
          </tr>
          <tr>
            <td>45</td>
            <td>ADMIRAL MOON</td>
            <td>2021-11-13</td>
            <td>2021-11-15</td>
            <td>MARTI</td>
            <td> 21-215</td>
            <td>EVYAP - Novorossiysk</td>
            <td>2021-11-11</td>
          </tr>
          <tr>
            <td>45</td>
            <td>EMMA A</td>
            <td>2021-11-19</td>
            <td>2021-11-21</td>
            <td>ARKAS</td>
            <td>TLS32S21</td>
            <td>EVYAP - Novorossiysk</td>
            <td>2021-11-16</td>
          </tr>
          <tr>
            <td>44</td>
            <td>ADMIRAL SUN </td>
            <td>2021-11-07</td>
            <td>2021-11-09</td>
            <td>MARTI</td>
            <td>21-115</td>
            <td>EVYAP - Novorossiysk</td>
            <td>2021-11-04</td>
          </tr>
          <tr>
            <td>43</td>
            <td>ADMIRAL MOON</td>
            <td>2021-10-30</td>
            <td>2021-11-01</td>
            <td>MARTI</td>
            <td>21-214</td>
            <td>EVYAP - Novorossiysk</td>
            <td>2021-10-28</td>
          </tr>
          <tr>
            <td>43</td>
            <td>SASKIA A</td>
            <td>2021-11-08</td>
            <td>2021-11-11</td>
            <td>ARKAS</td>
            <td>TLS31S21</td>
            <td>EVYAP - Novorossiysk</td>
            <td>2021-11-05</td>
          </tr>
          <tr>
            <td>42</td>
            <td>TURKON EGYPT</td>
            <td>2021-10-29</td>
            <td>2021-10-31</td>
            <td>ARKAS</td>
            <td>TLS30S21</td>
            <td>EVYAP - Novorossiysk</td>
            <td>2021-10-25</td>
          </tr>
          <tr>
            <td>42</td>
            <td>TURKON EGYPT</td>
            <td>2021-10-29</td>
            <td>2021-11-02</td>
            <td>ARKAS</td>
            <td>TLS30S21</td>
            <td>EVYAP - Novorossiysk</td>
            <td>2021-10-25</td>
          </tr>
          <tr>
            <td>41</td>
            <td>ADMIRAL SUN </td>
            <td>2021-10-16</td>
            <td>2021-10-18</td>
            <td>MARTI</td>
            <td> 21-114</td>
            <td>EVYAP - Novorossiysk</td>
            <td>2021-10-14</td>
          </tr>
          <tr>
            <td>41</td>
            <td>EMMA A</td>
            <td>2021-10-17</td>
            <td>2021-10-18</td>
            <td>ARKAS</td>
            <td>TLS2921</td>
            <td>EVYAP - Novorossiysk</td>
            <td>2021-10-15</td>
          </tr>
          <tr>
            <td>40</td>
            <td>ADMIRAL SUN </td>
            <td>2021-10-09</td>
            <td>2021-10-11</td>
            <td>MARTI</td>
            <td>21-114</td>
            <td>EVYAP - Novorossiysk</td>
            <td>2021-10-07</td>
          </tr>
          <tr>
            <td>40</td>
            <td>SASKIA A</td>
            <td>2021-10-07</td>
            <td>2021-10-09</td>
            <td>ARKAS</td>
            <td>TLS28S21</td>
            <td>EVYAP - Novorossiysk</td>
            <td>2021-10-05</td>
          </tr>
          <tr>
            <td>39</td>
            <td>ADMIRAL MOON</td>
            <td>2021-10-03</td>
            <td>2021-10-05</td>
            <td>MARTI</td>
            <td>21-213</td>
            <td>EVYAP - Novorossiysk</td>
            <td>2021-09-30</td>
          </tr>
          <tr>
            <td>39</td>
            <td>SASKIA A</td>
            <td>2021-10-05</td>
            <td>2021-10-07</td>
            <td>ARKAS</td>
            <td>TLS28S21</td>
            <td>EVYAP - Novorossiysk</td>
            <td>2021-10-04</td>
          </tr>
          <tr>
            <td>39</td>
            <td>PAAVA </td>
            <td>2020-09-21</td>
            <td>2020-09-24</td>
            <td>MARTI</td>
            <td>20-135</td>
            <td>EVYAP - Novorossiysk</td>
            <td>2020-09-17</td>
          </tr>
          <tr>
            <td>38</td>
            <td>TURKON EGYPT</td>
            <td>2021-09-27</td>
            <td>2021-09-28</td>
            <td>ARKAS</td>
            <td>TLS27S21</td>
            <td>EVYAP - Novorossiysk</td>
            <td>2021-09-23</td>
          </tr>
          <tr>
            <td>38</td>
            <td>ADMIRAL SUN </td>
            <td>2021-09-26</td>
            <td>2021-09-27</td>
            <td>MARTI</td>
            <td> 21-113</td>
            <td>EVYAP - Novorossiysk</td>
            <td>2021-09-23</td>
          </tr>
          <tr>
            <td>38</td>
            <td>PAAVA </td>
            <td>2020-09-19</td>
            <td>2020-09-22</td>
            <td>MARTI</td>
            <td>20-135</td>
            <td>EVYAP - Novorossiysk</td>
            <td>2020-09-18</td>
          </tr>
          <tr>
            <td>37</td>
            <td>ADMIRAL MOON</td>
            <td>2021-09-17</td>
            <td>2021-09-20</td>
            <td>MARTI</td>
            <td>21-212</td>
            <td>EVYAP - Novorossiysk</td>
            <td>2021-09-16</td>
          </tr>
          <tr>
            <td>37</td>
            <td>TURKON EGYPT</td>
            <td>2021-09-29</td>
            <td>2021-10-06</td>
            <td>ARKAS</td>
            <td>TLS27S21</td>
            <td>EVYAP - Novorossiysk</td>
            <td>2021-09-23</td>
          </tr>
          <tr>
            <td>37</td>
            <td>PAAVA </td>
            <td>2020-09-19</td>
            <td>2020-09-21</td>
            <td>MARTI</td>
            <td>20-135</td>
            <td>EVYAP - Novorossiysk</td>
            <td>2020-09-17</td>
          </tr>
          <tr>
            <td>36</td>
            <td>ADMIRAL SUN </td>
            <td>2021-09-11</td>
            <td>2021-09-15</td>
            <td>MARTI</td>
            <td> 21-112</td>
            <td>EVYAP - Novorossiysk</td>
            <td>2021-09-09</td>
          </tr>
          <tr>
            <td>36</td>
            <td>EMMA A</td>
            <td>2021-09-14</td>
            <td>2021-09-18</td>
            <td>ARKAS</td>
            <td>TLS26S21</td>
            <td>EVYAP - Novorossiysk</td>
            <td>2021-09-13</td>
          </tr>
          <tr>
            <td>34</td>
            <td>ADMIRAL MOON</td>
            <td>2021-09-11</td>
            <td>2021-09-15</td>
            <td>MARTI</td>
            <td>21-211</td>
            <td>EVYAP - Novorossiysk</td>
            <td>2021-09-09</td>
          </tr>
          <tr>
            <td>34</td>
            <td>EMMA A</td>
            <td>2021-09-17</td>
            <td>2021-09-22</td>
            <td>ARKAS</td>
            <td>TLS26S21</td>
            <td>EVYAP - Novorossiysk</td>
            <td>2021-09-14</td>
          </tr>
          <tr>
            <td>34</td>
            <td>PAAVA </td>
            <td>2020-08-22</td>
            <td>2020-08-24</td>
            <td>MARTI</td>
            <td>20-133</td>
            <td>EVYAP - Novorossiysk</td>
            <td>2020-08-20</td>
          </tr>
          <tr>
            <td>32</td>
            <td>ADMIRAL MOON</td>
            <td>2021-08-20</td>
            <td>2021-08-23</td>
            <td>MARTI</td>
            <td>21-210</td>
            <td>EVYAP - Novorossiysk</td>
            <td>2021-08-19</td>
          </tr>
          <tr>
            <td>32</td>
            <td>ADMIRAL SUN </td>
            <td>2021-08-13</td>
            <td>2021-08-16</td>
            <td>MARTI</td>
            <td> 21-110</td>
            <td>EVYAP - Novorossiysk</td>
            <td>2021-08-12</td>
          </tr>
          <tr>
            <td>32</td>
            <td>PAAVA </td>
            <td>2020-08-07</td>
            <td>2020-08-11</td>
            <td>MARTI</td>
            <td>20-132</td>
            <td>EVYAP - Novorossiysk</td>
            <td>2020-08-06</td>
          </tr>
          <tr>
            <td>29</td>
            <td>ADMIRAL SUN </td>
            <td>2021-07-29</td>
            <td>2021-08-01</td>
            <td>MARTI</td>
            <td> 21-108</td>
            <td>EVYAP - Novorossiysk</td>
            <td>2021-07-27</td>
          </tr>
          <tr>
            <td>29</td>
            <td>KARLA A</td>
            <td>2021-08-01</td>
            <td>2021-08-05</td>
            <td>ARKAS</td>
            <td>MRS26N21</td>
            <td>EVYAP - Novorossiysk</td>
            <td>2021-07-30</td>
          </tr>
          <tr>
            <td>29</td>
            <td>PAOLA</td>
            <td>2020-07-17</td>
            <td>2020-07-20</td>
            <td>MARTI</td>
            <td>20-230</td>
            <td>EVYAP - Novorossiysk</td>
            <td>2020-07-16</td>
          </tr>
          <tr>
            <td>28</td>
            <td>ADMIRAL SUN </td>
            <td>2021-07-16</td>
            <td>2021-07-19</td>
            <td>MARTI</td>
            <td> 21-108</td>
            <td>EVYAP - Novorossiysk</td>
            <td>2021-07-14</td>
          </tr>
          <tr>
            <td>28</td>
            <td>PAAVA </td>
            <td>2020-07-11</td>
            <td>2020-07-14</td>
            <td>MARTI</td>
            <td>M/V PAAVA 20-130</td>
            <td>EVYAP - Novorossiysk</td>
            <td>2020-07-09</td>
          </tr>
          <tr>
            <td>28</td>
            <td>BERNARD A</td>
            <td>2020-07-13</td>
            <td>2020-07-18</td>
            <td>ARKAS</td>
            <td>BMX028N20</td>
            <td>EVYAP - Novorossiysk</td>
            <td>2020-07-10</td>
          </tr>
          <tr>
            <td>27</td>
            <td>ADMIRAL MOON</td>
            <td>2021-07-02</td>
            <td>2021-07-05</td>
            <td>MARTI</td>
            <td>21-207</td>
            <td>EVYAP - Novorossiysk</td>
            <td>2021-07-01</td>
          </tr>
          <tr>
            <td>27</td>
            <td>PAOLA</td>
            <td>2020-07-04</td>
            <td>2020-07-06</td>
            <td>MARTI</td>
            <td>20-229</td>
            <td>EVYAP - Novorossiysk</td>
            <td>2020-07-02</td>
          </tr>
          <tr>
            <td>26</td>
            <td>ADMIRAL SUN </td>
            <td>2021-07-03</td>
            <td>2021-07-06</td>
            <td>MARTI</td>
            <td>21-107</td>
            <td>EVYAP - Novorossiysk</td>
            <td>2021-07-01</td>
          </tr>
          <tr>
            <td>26</td>
            <td>SASKIA A</td>
            <td>2021-07-08</td>
            <td>2021-07-12</td>
            <td>ARKAS</td>
            <td>TLS19S21</td>
            <td>EVYAP - Novorossiysk</td>
            <td>2021-07-06</td>
          </tr>
          <tr>
            <td>26</td>
            <td>KARLA A</td>
            <td>2021-07-11</td>
            <td>2021-07-14</td>
            <td>ARKAS</td>
            <td>MRS24N21</td>
            <td>EVYAP - Novorossiysk</td>
            <td>2021-07-09</td>
          </tr>
          <tr>
            <td>26</td>
            <td>PAAVA </td>
            <td>2020-06-27</td>
            <td>2020-06-29</td>
            <td>MARTI</td>
            <td> 20-129</td>
            <td>EVYAP - Novorossiysk</td>
            <td>2020-06-25</td>
          </tr>
          <tr>
            <td>25</td>
            <td>ADMIRAL MOON</td>
            <td>2021-06-26</td>
            <td>2021-06-30</td>
            <td>MARTI</td>
            <td>21-206</td>
            <td>EVYAP - Novorossiysk</td>
            <td>2021-06-24</td>
          </tr>
          <tr>
            <td>25</td>
            <td>SASKIA A</td>
            <td>2021-07-08</td>
            <td>2021-07-12</td>
            <td>ARKAS</td>
            <td>TLS19S21</td>
            <td>EVYAP - Novorossiysk</td>
            <td>2021-07-05</td>
          </tr>
          <tr>
            <td>25</td>
            <td>PAOLA</td>
            <td>2020-06-20</td>
            <td>2020-06-22</td>
            <td>MARTI</td>
            <td>20-228</td>
            <td>EVYAP - Novorossiysk</td>
            <td>2020-06-18</td>
          </tr>
          <tr>
            <td>24</td>
            <td>ADMIRAL SUN </td>
            <td>2021-06-19</td>
            <td>2021-06-23</td>
            <td>MARTI</td>
            <td>21-106</td>
            <td>EVYAP - Novorossiysk</td>
            <td>2021-06-17</td>
          </tr>
          <tr>
            <td>24</td>
            <td>PAAVA </td>
            <td>2020-06-13</td>
            <td>2020-06-15</td>
            <td>MARTI</td>
            <td>20-128</td>
            <td>EVYAP - Novorossiysk</td>
            <td>2020-06-11</td>
          </tr>
          <tr>
            <td>23</td>
            <td>ADMIRAL MOON</td>
            <td>2021-06-12</td>
            <td>2021-06-16</td>
            <td>MARTI</td>
            <td>21-205</td>
            <td>EVYAP - Novorossiysk</td>
            <td>2021-06-10</td>
          </tr>
          <tr>
            <td>23</td>
            <td>PAOLA</td>
            <td>2020-06-05</td>
            <td>2020-06-08</td>
            <td>MARTI</td>
            <td> M/V PAOLA 20-227</td>
            <td>EVYAP - Novorossiysk</td>
            <td>2020-06-04</td>
          </tr>
          <tr>
            <td>22</td>
            <td>ADMIRAL SUN </td>
            <td>2021-06-05</td>
            <td>2021-06-08</td>
            <td>MARTI</td>
            <td> 21-105</td>
            <td>EVYAP - Novorossiysk</td>
            <td>2021-06-03</td>
          </tr>
          <tr>
            <td>22</td>
            <td>SASKIA A</td>
            <td>2021-06-10</td>
            <td>2021-06-15</td>
            <td>ARKAS</td>
            <td>TLS16S21</td>
            <td>EVYAP - Novorossiysk</td>
            <td>2021-06-08</td>
          </tr>
          <tr>
            <td>21</td>
            <td>GRETA</td>
            <td>2021-05-30</td>
            <td>2021-06-04</td>
            <td>MARTI</td>
            <td>21-426</td>
            <td>EVYAP - Novorossiysk</td>
            <td>2021-05-27</td>
          </tr>
          <tr>
            <td>21</td>
            <td>TURKON EGYPT</td>
            <td>2021-05-30</td>
            <td>2021-06-04</td>
            <td>ARKAS</td>
            <td>TLS01221</td>
            <td>EVYAP - Novorossiysk</td>
            <td>2021-05-27</td>
          </tr>
          <tr>
            <td>21</td>
            <td>PAOLA</td>
            <td>2020-05-22</td>
            <td>2020-05-26</td>
            <td>MARTI</td>
            <td>20-226</td>
            <td>EVYAP - Novorossiysk</td>
            <td>2020-05-22</td>
          </tr>
          <tr>
            <td>20</td>
            <td>GRETA</td>
            <td>2021-05-22</td>
            <td>2021-05-27</td>
            <td>MARTI</td>
            <td>21-425</td>
            <td>EVYAP - Novorossiysk</td>
            <td>2021-05-20</td>
          </tr>
          <tr>
            <td>19</td>
            <td>GRETA</td>
            <td>2021-05-14</td>
            <td>2021-05-19</td>
            <td>MARTI</td>
            <td>21-424</td>
            <td>EVYAP - Novorossiysk</td>
            <td>2021-05-11</td>
          </tr>
          <tr>
            <td>19</td>
            <td>PAOLA</td>
            <td>2020-05-09</td>
            <td>2020-05-11</td>
            <td>MARTI</td>
            <td>20-225</td>
            <td>EVYAP - Novorossiysk</td>
            <td>2020-05-07</td>
          </tr>
          <tr>
            <td>16</td>
            <td>GRETA</td>
            <td>2021-04-16</td>
            <td>2021-04-20</td>
            <td>MARTI</td>
            <td>21-420</td>
            <td>EVYAP - Novorossiysk</td>
            <td>2021-04-14</td>
          </tr>
          <tr>
            <td>16</td>
            <td>PAAVA </td>
            <td>2020-04-17</td>
            <td>2020-04-20</td>
            <td>MARTI</td>
            <td>20-125</td>
            <td>EVYAP - Novorossiysk</td>
            <td>2020-04-16</td>
          </tr>
          <tr>
            <td>16</td>
            <td>TURKON EGYPT</td>
            <td>2020-04-15</td>
            <td>2020-04-19</td>
            <td>ARKAS</td>
            <td>TLS13S20</td>
            <td>EVYAP - Novorossiysk</td>
            <td>2020-04-12</td>
          </tr>
          <tr>
            <td>15</td>
            <td>GRETA</td>
            <td>2021-04-09</td>
            <td>2021-04-15</td>
            <td>Sealand</td>
            <td>21-419</td>
            <td>EVYAP - Novorossiysk</td>
            <td>2021-04-08</td>
          </tr>
          <tr>
            <td>15</td>
            <td>PAAVA </td>
            <td>2020-04-10</td>
            <td>2020-04-13</td>
            <td>MARTI</td>
            <td> 20-124</td>
            <td>EVYAP - Novorossiysk</td>
            <td>2020-04-09</td>
          </tr>
          <tr>
            <td>15</td>
            <td>ELECTRA A </td>
            <td>2020-04-06</td>
            <td>2020-04-10</td>
            <td>ARKAS</td>
            <td>TLS12S20</td>
            <td>EVYAP - Novorossiysk</td>
            <td>2020-04-03</td>
          </tr>
          <tr>
            <td>14</td>
            <td>ADMIRAL SUN </td>
            <td>2021-04-10</td>
            <td>2021-04-13</td>
            <td>MARTI</td>
            <td>21-201</td>
            <td>EVYAP - Novorossiysk</td>
            <td>2021-04-08</td>
          </tr>
          <tr>
            <td>14</td>
            <td>EMMA A</td>
            <td>2021-04-16</td>
            <td>2021-04-20</td>
            <td>ARKAS</td>
            <td>TLS01121</td>
            <td>EVYAP - Novorossiysk</td>
            <td>2021-04-14</td>
          </tr>
          <tr>
            <td>14</td>
            <td>PAOLA</td>
            <td>2020-04-04</td>
            <td>2020-04-07</td>
            <td>MARTI</td>
            <td>20-223</td>
            <td>EVYAP - Novorossiysk</td>
            <td>2020-04-03</td>
          </tr>
          <tr>
            <td>3</td>
            <td>PAAVA </td>
            <td>2021-01-23</td>
            <td>2021-01-27</td>
            <td>MARTI</td>
            <td>21-144</td>
            <td>EVYAP - Novorossiysk</td>
            <td>2021-01-21</td>
          </tr>
          <tr>
            <td>2</td>
            <td>PAOLA</td>
            <td>2021-01-16</td>
            <td>2021-01-20</td>
            <td>MARTI</td>
            <td>20-243</td>
            <td>EVYAP - Novorossiysk</td>
            <td>2021-01-15</td>
          </tr>
          <tr>
            <td>1</td>
            <td>PAAVA </td>
            <td>2021-01-09</td>
            <td>2021-01-12</td>
            <td>MARTI</td>
            <td>20-143</td>
            <td>EVYAP - Novorossiysk</td>
            <td>2021-01-08</td>
          </tr>
          <tr>
            <td>1</td>
            <td>PAOLA</td>
            <td>2021-01-02</td>
            <td>2021-01-06</td>
            <td>MARTI</td>
            <td>20-242</td>
            <td>EVYAP - Novorossiysk</td>
            <td>2020-12-30</td>
          </tr>
          <tr>
            <td>52</td>
            <td>PAAVA </td>
            <td>2020-12-26</td>
            <td>2020-12-30</td>
            <td>MARTI</td>
            <td>20-142</td>
            <td>EVYAP - Novorossiysk</td>
            <td>2020-12-25</td>
          </tr>
          <tr>
            <td>35</td>
            <td>PAAVA </td>
            <td>2020-08-22</td>
            <td>2020-08-24</td>
            <td>MARTI</td>
            <td>20-133</td>
            <td>EVYAP - Novorossiysk</td>
            <td>2020-08-20</td>
          </tr>
          <tr>
            <td>33</td>
            <td>SAFMARINE NOMAZWE</td>
            <td>2020-08-13</td>
            <td>2020-08-17</td>
            <td>Sealand</td>
            <td>029E</td>
            <td>EVYAP - Novorossiysk</td>
            <td>2020-08-10</td>
          </tr>
          <tr>
            <td>33</td>
            <td>PAOLA</td>
            <td>2020-08-15</td>
            <td>2020-08-18</td>
            <td>MARTI</td>
            <td>20-232</td>
            <td>EVYAP - Novorossiysk</td>
            <td>2020-08-13</td>
          </tr>
          <tr>
            <td>31</td>
            <td>PAOLA</td>
            <td>2020-07-31</td>
            <td>2020-08-03</td>
            <td>MARTI</td>
            <td>20-231</td>
            <td>EVYAP - Novorossiysk</td>
            <td>2020-07-29</td>
          </tr>
          <tr>
            <td>30</td>
            <td>PAAVA </td>
            <td>2020-07-24</td>
            <td>2020-07-27</td>
            <td>MARTI</td>
            <td> PAAVA 20-131</td>
            <td>EVYAP - Novorossiysk</td>
            <td>2020-07-23</td>
          </tr>
          <tr>
            <td>18</td>
            <td>PAAVA </td>
            <td>2020-05-01</td>
            <td>2020-05-04</td>
            <td>MARTI</td>
            <td>20-125</td>
            <td>EVYAP - Novorossiysk</td>
            <td>2020-04-30</td>
          </tr>
          <tr>
            <td>17</td>
            <td>PAOLA</td>
            <td>2020-04-25</td>
            <td>2020-04-27</td>
            <td>MARTI</td>
            <td>20-124</td>
            <td>EVYAP - Novorossiysk</td>
            <td>2020-04-23</td>
          </tr>
        </tbody>
      </table>
    ),
  },
  {
    id: 6,
    itemText: "EVYAP - ODESSA",
    itemValue: (
      <table class="vessel-table">
        <thead>
          <tr>
            <th>Week</th>
            <th>Ship</th>
            <th>ETD</th>
            <th>ETA</th>
            <th>Line</th>
            <th>Voyage</th>
            <th>VesselCategories</th>
            <th>CutOff</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>8</td>
            <td>ADMIRAL SUN </td>
            <td>2022-03-06</td>
            <td>2022-03-09</td>
            <td>MARTI</td>
            <td> 22-122</td>
            <td>EVYAP - ODESSA</td>
            <td>2022-03-03</td>
          </tr>
          <tr>
            <td>8</td>
            <td>SASKIA A</td>
            <td>2021-03-12</td>
            <td>2021-03-16</td>
            <td>ARKAS</td>
            <td>TLS07S21</td>
            <td>EVYAP - ODESSA</td>
            <td>2021-03-09</td>
          </tr>
          <tr>
            <td>8</td>
            <td>CMA CGM CONGO</td>
            <td>2020-02-17</td>
            <td>2020-02-20</td>
            <td>OOCL</td>
            <td>0BX5ME1MA</td>
            <td>EVYAP - ODESSA</td>
            <td>2020-02-12</td>
          </tr>
          <tr>
            <td>8</td>
            <td>PAOLA</td>
            <td>2020-02-22</td>
            <td>2020-02-26</td>
            <td>MARTI</td>
            <td>20/221</td>
            <td>EVYAP - ODESSA</td>
            <td>2020-02-20</td>
          </tr>
          <tr>
            <td>7</td>
            <td>ADMIRAL MOON</td>
            <td>2022-02-26</td>
            <td>2022-03-02</td>
            <td>MARTI</td>
            <td> 22-220</td>
            <td>EVYAP - ODESSA</td>
            <td>2022-02-24</td>
          </tr>
          <tr>
            <td>7</td>
            <td>SASKIA A</td>
            <td>2022-02-27</td>
            <td>2022-03-03</td>
            <td>ARKAS</td>
            <td>TLS05N22</td>
            <td>EVYAP - ODESSA</td>
            <td>2022-02-24</td>
          </tr>
          <tr>
            <td>7</td>
            <td>TURKON EGYPT</td>
            <td>2021-03-02</td>
            <td>2021-03-10</td>
            <td>ARKAS</td>
            <td>TLS06S21</td>
            <td>EVYAP - ODESSA</td>
            <td>2021-02-26</td>
          </tr>
          <tr>
            <td>7</td>
            <td>PACOBA</td>
            <td>2020-02-15</td>
            <td>2020-02-18</td>
            <td>MARTI</td>
            <td>20/302</td>
            <td>EVYAP - ODESSA</td>
            <td>2020-02-14</td>
          </tr>
          <tr>
            <td>6</td>
            <td>TURKON EGYPT</td>
            <td>2022-02-16</td>
            <td>2022-02-19</td>
            <td>ARKAS</td>
            <td>TLS04S22</td>
            <td>EVYAP - ODESSA</td>
            <td>2022-02-10</td>
          </tr>
          <tr>
            <td>6</td>
            <td>ADMIRAL SUN </td>
            <td>2022-02-19</td>
            <td>2022-02-23</td>
            <td>MARTI</td>
            <td> 22-121</td>
            <td>EVYAP - ODESSA</td>
            <td>2022-02-17</td>
          </tr>
          <tr>
            <td>6</td>
            <td>SASKIA A</td>
            <td>2020-01-07</td>
            <td>2020-01-11</td>
            <td>ARKAS</td>
            <td>TLS05S20</td>
            <td>EVYAP - ODESSA</td>
            <td>2020-02-05</td>
          </tr>
          <tr>
            <td>6</td>
            <td>CMA CGM URAL</td>
            <td>2020-02-07</td>
            <td>2020-02-08</td>
            <td>OOCL</td>
            <td>0BX5KE1MA</td>
            <td>EVYAP - ODESSA</td>
            <td>2020-02-04</td>
          </tr>
          <tr>
            <td>5</td>
            <td>ADMIRAL MOON</td>
            <td>2022-02-12</td>
            <td>2022-02-16</td>
            <td>MARTI</td>
            <td>22-219</td>
            <td>EVYAP - ODESSA</td>
            <td>2022-02-10</td>
          </tr>
          <tr>
            <td>5</td>
            <td>TURKON EGYPT</td>
            <td>2022-02-13</td>
            <td>2022-02-16</td>
            <td>ARKAS</td>
            <td>TLS04S22</td>
            <td>EVYAP - ODESSA</td>
            <td>2022-02-10</td>
          </tr>
          <tr>
            <td>5</td>
            <td>GRETA</td>
            <td>2021-02-12</td>
            <td>2021-02-16</td>
            <td>MARTI</td>
            <td>21-411</td>
            <td>EVYAP - ODESSA</td>
            <td>2021-02-11</td>
          </tr>
          <tr>
            <td>5</td>
            <td>EMMA A</td>
            <td>2021-02-19</td>
            <td>2021-03-01</td>
            <td>ARKAS</td>
            <td>TLS05S21</td>
            <td>EVYAP - ODESSA</td>
            <td>2021-02-16</td>
          </tr>
          <tr>
            <td>5</td>
            <td>PAOLA</td>
            <td>2020-02-01</td>
            <td>2020-02-04</td>
            <td>MARTI</td>
            <td>20/2020</td>
            <td>EVYAP - ODESSA</td>
            <td>2020-01-30</td>
          </tr>
          <tr>
            <td>4</td>
            <td>SASKIA A</td>
            <td>2022-01-27</td>
            <td>2022-01-31</td>
            <td>ARKAS</td>
            <td>TLS03S22</td>
            <td>EVYAP - ODESSA</td>
            <td>2022-01-25</td>
          </tr>
          <tr>
            <td>4</td>
            <td>ADMIRAL SUN </td>
            <td>2022-02-06</td>
            <td>2022-02-11</td>
            <td>MARTI</td>
            <td>22-120</td>
            <td>EVYAP - ODESSA</td>
            <td>2022-02-03</td>
          </tr>
          <tr>
            <td>4</td>
            <td>GRETA</td>
            <td>2021-02-05</td>
            <td>2021-02-08</td>
            <td>MARTI</td>
            <td>21-411</td>
            <td>EVYAP - ODESSA</td>
            <td>2021-02-04</td>
          </tr>
          <tr>
            <td>4</td>
            <td>SASKIA A</td>
            <td>2021-02-09</td>
            <td>2021-02-20</td>
            <td>ARKAS</td>
            <td>TLS04S21</td>
            <td>EVYAP - ODESSA</td>
            <td>2021-02-05</td>
          </tr>
          <tr>
            <td>51</td>
            <td>TURKON EGYPT</td>
            <td>2021-12-27</td>
            <td>2021-12-28</td>
            <td>ARKAS</td>
            <td>TLS36S21</td>
            <td>EVYAP - ODESSA</td>
            <td>2021-12-22</td>
          </tr>
          <tr>
            <td>51</td>
            <td>GRETA</td>
            <td>2021-12-26</td>
            <td>2021-12-30</td>
            <td>MARTI</td>
            <td>21-438</td>
            <td>EVYAP - ODESSA</td>
            <td>2021-12-23</td>
          </tr>
          <tr>
            <td>51</td>
            <td>GRETA</td>
            <td>2021-12-25</td>
            <td>2021-12-30</td>
            <td>MARTI</td>
            <td>21-438</td>
            <td>EVYAP - ODESSA</td>
            <td>2021-12-24</td>
          </tr>
          <tr>
            <td>50</td>
            <td>EMMA A</td>
            <td>2021-12-18</td>
            <td>2021-12-19</td>
            <td>ARKAS</td>
            <td>TLS35S21</td>
            <td>EVYAP - ODESSA</td>
            <td>2021-12-16</td>
          </tr>
          <tr>
            <td>50</td>
            <td>GRETA</td>
            <td>2021-12-19</td>
            <td>2021-12-20</td>
            <td>MARTI</td>
            <td>21-438</td>
            <td>EVYAP - ODESSA</td>
            <td>2021-12-16</td>
          </tr>
          <tr>
            <td>49</td>
            <td>GRETA</td>
            <td>2021-12-12</td>
            <td>2021-12-13</td>
            <td>MARTI</td>
            <td>21-437</td>
            <td>EVYAP - ODESSA</td>
            <td>2021-12-08</td>
          </tr>
          <tr>
            <td>48</td>
            <td>GRETA</td>
            <td>2021-12-04</td>
            <td>2021-12-06</td>
            <td>MARTI</td>
            <td> 21-437</td>
            <td>EVYAP - ODESSA</td>
            <td>2021-12-02</td>
          </tr>
          <tr>
            <td>48</td>
            <td>SASKIA A</td>
            <td>2021-12-09</td>
            <td>2021-12-10</td>
            <td>ARKAS</td>
            <td>TLS34S21</td>
            <td>EVYAP - ODESSA</td>
            <td>2021-12-06</td>
          </tr>
          <tr>
            <td>47</td>
            <td>GRETA</td>
            <td>2021-11-26</td>
            <td>2021-11-28</td>
            <td>MARTI</td>
            <td>21-436</td>
            <td>EVYAP - ODESSA</td>
            <td>2021-11-25</td>
          </tr>
          <tr>
            <td>46</td>
            <td>GRETA</td>
            <td>2021-11-22</td>
            <td>2021-11-23</td>
            <td>MARTI</td>
            <td>21-436</td>
            <td>EVYAP - ODESSA</td>
            <td>2021-11-18</td>
          </tr>
          <tr>
            <td>46</td>
            <td>TURKON EGYPT</td>
            <td>2021-11-30</td>
            <td>2021-12-02</td>
            <td>ARKAS</td>
            <td>TLS33S21</td>
            <td>EVYAP - ODESSA</td>
            <td>2021-11-23</td>
          </tr>
          <tr>
            <td>45</td>
            <td>ADMIRAL MOON</td>
            <td>2021-11-13</td>
            <td>2021-11-17</td>
            <td>MARTI</td>
            <td> 21-215</td>
            <td>EVYAP - ODESSA</td>
            <td>2021-11-11</td>
          </tr>
          <tr>
            <td>45</td>
            <td>EMMA A</td>
            <td>2021-11-19</td>
            <td>2021-11-20</td>
            <td>ARKAS</td>
            <td>TLS32S21</td>
            <td>EVYAP - ODESSA</td>
            <td>2021-11-16</td>
          </tr>
          <tr>
            <td>44</td>
            <td>ADMIRAL MOON</td>
            <td>2021-10-30</td>
            <td>2021-11-03</td>
            <td>MARTI</td>
            <td>21-214</td>
            <td>EVYAP - ODESSA</td>
            <td>2021-10-28</td>
          </tr>
          <tr>
            <td>44</td>
            <td>ADMIRAL SUN </td>
            <td>2021-11-07</td>
            <td>2021-11-11</td>
            <td>MARTI</td>
            <td>21-115</td>
            <td>EVYAP - ODESSA</td>
            <td>2021-11-04</td>
          </tr>
          <tr>
            <td>43</td>
            <td>ADMIRAL MOON</td>
            <td>2021-10-30</td>
            <td>2021-11-03</td>
            <td>MARTI</td>
            <td>21-214</td>
            <td>EVYAP - ODESSA</td>
            <td>2021-10-28</td>
          </tr>
          <tr>
            <td>43</td>
            <td>SASKIA A</td>
            <td>2021-11-09</td>
            <td>2021-11-12</td>
            <td>ARKAS</td>
            <td>TLS31S21</td>
            <td>EVYAP - ODESSA</td>
            <td>2021-11-05</td>
          </tr>
          <tr>
            <td>42</td>
            <td>TURKON EGYPT</td>
            <td>2021-10-29</td>
            <td>2021-11-01</td>
            <td>ARKAS</td>
            <td>TLS30S21</td>
            <td>EVYAP - ODESSA</td>
            <td>2021-10-25</td>
          </tr>
          <tr>
            <td>42</td>
            <td>TURKON EGYPT</td>
            <td>2021-10-29</td>
            <td>2021-10-31</td>
            <td>ARKAS</td>
            <td>TLS30S21</td>
            <td>EVYAP - ODESSA</td>
            <td>2021-10-25</td>
          </tr>
          <tr>
            <td>41</td>
            <td>ADMIRAL SUN </td>
            <td>2021-10-16</td>
            <td>2021-10-20</td>
            <td>MARTI</td>
            <td> 21-114</td>
            <td>EVYAP - ODESSA</td>
            <td>2021-10-14</td>
          </tr>
          <tr>
            <td>41</td>
            <td>EMMA A</td>
            <td>2021-10-17</td>
            <td>2021-10-19</td>
            <td>ARKAS</td>
            <td>TLS2921</td>
            <td>EVYAP - ODESSA</td>
            <td>2021-10-15</td>
          </tr>
          <tr>
            <td>40</td>
            <td>ADMIRAL SUN </td>
            <td>2021-10-09</td>
            <td>2021-10-13</td>
            <td>MARTI</td>
            <td>21-114</td>
            <td>EVYAP - ODESSA</td>
            <td>2021-10-07</td>
          </tr>
          <tr>
            <td>40</td>
            <td>SASKIA A</td>
            <td>2021-10-07</td>
            <td>2021-10-10</td>
            <td>ARKAS</td>
            <td>TLS28S21</td>
            <td>EVYAP - ODESSA</td>
            <td>2021-10-05</td>
          </tr>
          <tr>
            <td>39</td>
            <td>ADMIRAL MOON</td>
            <td>2021-10-03</td>
            <td>2021-10-07</td>
            <td>MARTI</td>
            <td>21-213</td>
            <td>EVYAP - ODESSA</td>
            <td>2021-09-30</td>
          </tr>
          <tr>
            <td>39</td>
            <td>SASKIA A</td>
            <td>2021-10-05</td>
            <td>2021-10-07</td>
            <td>ARKAS</td>
            <td>TLS28S21</td>
            <td>EVYAP - ODESSA</td>
            <td>2021-10-04</td>
          </tr>
          <tr>
            <td>39</td>
            <td>PAAVA </td>
            <td>2020-09-21</td>
            <td>2020-09-23</td>
            <td>MARTI</td>
            <td>20-135</td>
            <td>EVYAP - ODESSA</td>
            <td>2020-09-17</td>
          </tr>
          <tr>
            <td>38</td>
            <td>TURKON EGYPT</td>
            <td>2021-09-27</td>
            <td>2021-09-28</td>
            <td>ARKAS</td>
            <td>TLS27S21</td>
            <td>EVYAP - ODESSA</td>
            <td>2021-09-23</td>
          </tr>
          <tr>
            <td>38</td>
            <td>ADMIRAL SUN </td>
            <td>2021-09-28</td>
            <td>2021-09-29</td>
            <td>MARTI</td>
            <td> 21-113</td>
            <td>EVYAP - ODESSA</td>
            <td>2021-09-23</td>
          </tr>
          <tr>
            <td>38</td>
            <td>PAAVA </td>
            <td>2020-09-18</td>
            <td>2020-09-22</td>
            <td>MARTI</td>
            <td>20-135</td>
            <td>EVYAP - ODESSA</td>
            <td>2020-09-18</td>
          </tr>
          <tr>
            <td>37</td>
            <td>GRETA</td>
            <td>2021-09-18</td>
            <td>2021-09-20</td>
            <td>MARTI</td>
            <td>21-432</td>
            <td>EVYAP - ODESSA</td>
            <td>2021-09-16</td>
          </tr>
          <tr>
            <td>37</td>
            <td>TURKON EGYPT</td>
            <td>2021-09-29</td>
            <td>2021-10-06</td>
            <td>ARKAS</td>
            <td>TLS27S21</td>
            <td>EVYAP - ODESSA</td>
            <td>2021-09-23</td>
          </tr>
          <tr>
            <td>37</td>
            <td>PAAVA </td>
            <td>2020-09-19</td>
            <td>2020-09-22</td>
            <td>MARTI</td>
            <td>20-135</td>
            <td>EVYAP - ODESSA</td>
            <td>2020-09-17</td>
          </tr>
          <tr>
            <td>36</td>
            <td>ADMIRAL SUN </td>
            <td>2021-09-11</td>
            <td>2021-09-15</td>
            <td>MARTI</td>
            <td> 21-112</td>
            <td>EVYAP - ODESSA</td>
            <td>2021-09-09</td>
          </tr>
          <tr>
            <td>36</td>
            <td>EMMA A</td>
            <td>2021-09-14</td>
            <td>2021-09-18</td>
            <td>ARKAS</td>
            <td>TLS26S21</td>
            <td>EVYAP - ODESSA</td>
            <td>2021-09-13</td>
          </tr>
          <tr>
            <td>34</td>
            <td>ADMIRAL MOON</td>
            <td>2021-09-11</td>
            <td>2021-09-15</td>
            <td>MARTI</td>
            <td>21-211</td>
            <td>EVYAP - ODESSA</td>
            <td>2021-09-09</td>
          </tr>
          <tr>
            <td>34</td>
            <td>EMMA A</td>
            <td>2021-09-17</td>
            <td>2021-09-22</td>
            <td>ARKAS</td>
            <td>TLS26S21</td>
            <td>EVYAP - ODESSA</td>
            <td>2021-09-14</td>
          </tr>
          <tr>
            <td>34</td>
            <td>PAAVA </td>
            <td>2020-08-22</td>
            <td>2020-08-25</td>
            <td>MARTI</td>
            <td>20-133</td>
            <td>EVYAP - ODESSA</td>
            <td>2020-08-20</td>
          </tr>
          <tr>
            <td>33</td>
            <td>ADMIRAL MOON</td>
            <td>2021-08-20</td>
            <td>2021-08-23</td>
            <td>MARTI</td>
            <td>21-210</td>
            <td>EVYAP - ODESSA</td>
            <td>2021-08-19</td>
          </tr>
          <tr>
            <td>33</td>
            <td>TURKON EGYPT</td>
            <td>2021-08-27</td>
            <td>2021-08-30</td>
            <td>ARKAS</td>
            <td>TLS24S21</td>
            <td>EVYAP - ODESSA</td>
            <td>2021-08-25</td>
          </tr>
          <tr>
            <td>33</td>
            <td>PAOLA</td>
            <td>2020-08-15</td>
            <td>2020-08-17</td>
            <td>MARTI</td>
            <td>20-232</td>
            <td>EVYAP - ODESSA</td>
            <td>2020-08-13</td>
          </tr>
          <tr>
            <td>32</td>
            <td>ADMIRAL SUN </td>
            <td>2021-08-13</td>
            <td>2021-08-16</td>
            <td>MARTI</td>
            <td>21-110</td>
            <td>EVYAP - ODESSA</td>
            <td>2021-08-12</td>
          </tr>
          <tr>
            <td>32</td>
            <td>TURKON EGYPT</td>
            <td>2021-08-27</td>
            <td>2021-09-02</td>
            <td>ARKAS</td>
            <td>TLS24S21</td>
            <td>EVYAP - ODESSA</td>
            <td>2021-08-25</td>
          </tr>
          <tr>
            <td>32</td>
            <td>PAAVA </td>
            <td>2020-08-07</td>
            <td>2020-08-11</td>
            <td>MARTI</td>
            <td>20-132</td>
            <td>EVYAP - ODESSA</td>
            <td>2020-08-06</td>
          </tr>
          <tr>
            <td>31</td>
            <td>SASKIA A</td>
            <td>2021-08-06</td>
            <td>2021-08-10</td>
            <td>ARKAS</td>
            <td>TLS22S21</td>
            <td>EVYAP - ODESSA</td>
            <td>2021-08-04</td>
          </tr>
          <tr>
            <td>31</td>
            <td>PAOLA</td>
            <td>2020-07-31</td>
            <td>2020-08-04</td>
            <td>MARTI</td>
            <td>20-231</td>
            <td>EVYAP - ODESSA</td>
            <td>2020-07-29</td>
          </tr>
          <tr>
            <td>31</td>
            <td>CMA CGM VOLGA</td>
            <td>2020-07-31</td>
            <td>2020-08-03</td>
            <td>OOCL</td>
            <td>0BX6YE1MA</td>
            <td>EVYAP - ODESSA</td>
            <td>2020-07-28</td>
          </tr>
          <tr>
            <td>30</td>
            <td>SASKIA A</td>
            <td>2021-08-07</td>
            <td>2021-08-11</td>
            <td>ARKAS</td>
            <td>TLS22S21</td>
            <td>EVYAP - ODESSA</td>
            <td>2021-08-04</td>
          </tr>
          <tr>
            <td>30</td>
            <td>PAAVA </td>
            <td>2020-07-24</td>
            <td>2020-07-28</td>
            <td>MARTI</td>
            <td> PAAVA 20-131</td>
            <td>EVYAP - ODESSA</td>
            <td>2020-07-23</td>
          </tr>
          <tr>
            <td>30</td>
            <td>COSCO SHIPPING SEINE </td>
            <td>2020-07-24</td>
            <td>2020-07-27</td>
            <td>OOCL</td>
            <td>016E</td>
            <td>EVYAP - ODESSA</td>
            <td>2020-07-21</td>
          </tr>
          <tr>
            <td>29</td>
            <td>ADMIRAL SUN </td>
            <td>2021-07-29</td>
            <td>2021-08-01</td>
            <td>MARTI</td>
            <td> 21-108</td>
            <td>EVYAP - ODESSA</td>
            <td>2021-07-27</td>
          </tr>
          <tr>
            <td>29</td>
            <td>PAOLA</td>
            <td>2020-07-17</td>
            <td>2020-07-21</td>
            <td>MARTI</td>
            <td>20-230</td>
            <td>EVYAP - ODESSA</td>
            <td>2020-07-16</td>
          </tr>
          <tr>
            <td>29</td>
            <td>CMA CGM MISSOURI</td>
            <td>2020-07-19</td>
            <td>2020-07-21</td>
            <td>OOCL</td>
            <td>0BX6UE1MA</td>
            <td>EVYAP - ODESSA</td>
            <td>2020-07-14</td>
          </tr>
          <tr>
            <td>29</td>
            <td>TURKON EGYPT</td>
            <td>2020-07-25</td>
            <td>2020-07-28</td>
            <td>ARKAS</td>
            <td>TLS25S20</td>
            <td>EVYAP - ODESSA</td>
            <td>2020-07-22</td>
          </tr>
          <tr>
            <td>28</td>
            <td>ADMIRAL SUN </td>
            <td>2021-07-16</td>
            <td>2021-07-20</td>
            <td>MARTI</td>
            <td> 21-108</td>
            <td>EVYAP - ODESSA</td>
            <td>2021-07-13</td>
          </tr>
          <tr>
            <td>28</td>
            <td>PAAVA </td>
            <td>2020-07-11</td>
            <td>2020-07-15</td>
            <td>MARTI</td>
            <td>M/V PAAVA 20-130</td>
            <td>EVYAP - ODESSA</td>
            <td>2020-07-09</td>
          </tr>
          <tr>
            <td>28</td>
            <td>BERNARD A</td>
            <td>2020-07-13</td>
            <td>2020-07-15</td>
            <td>ARKAS</td>
            <td>BMX028N20</td>
            <td>EVYAP - ODESSA</td>
            <td>2020-07-10</td>
          </tr>
          <tr>
            <td>28</td>
            <td>CMA CGM CONGO</td>
            <td>2020-07-11</td>
            <td>2020-07-13</td>
            <td>OOCL</td>
            <td>0BX6SE1MA</td>
            <td>EVYAP - ODESSA</td>
            <td>2020-07-07</td>
          </tr>
          <tr>
            <td>27</td>
            <td>ADMIRAL MOON</td>
            <td>2021-07-02</td>
            <td>2021-07-05</td>
            <td>MARTI</td>
            <td>21-207</td>
            <td>EVYAP - ODESSA</td>
            <td>2021-07-01</td>
          </tr>
          <tr>
            <td>27</td>
            <td>PAOLA</td>
            <td>2020-07-04</td>
            <td>2020-07-07</td>
            <td>MARTI</td>
            <td>20-229</td>
            <td>EVYAP - ODESSA</td>
            <td>2020-07-02</td>
          </tr>
          <tr>
            <td>26</td>
            <td>ADMIRAL SUN </td>
            <td>2021-07-02</td>
            <td>2021-07-05</td>
            <td>MARTI</td>
            <td>21-107</td>
            <td>EVYAP - ODESSA</td>
            <td>2021-07-01</td>
          </tr>
          <tr>
            <td>26</td>
            <td>SASKIA A</td>
            <td>2021-07-08</td>
            <td>2021-07-12</td>
            <td>ARKAS</td>
            <td>TLS19S21</td>
            <td>EVYAP - ODESSA</td>
            <td>2021-07-06</td>
          </tr>
          <tr>
            <td>26</td>
            <td>YM INVENTIVE</td>
            <td>2021-07-16</td>
            <td>2021-07-19</td>
            <td>ARKAS</td>
            <td>IBX348N20 (348N)</td>
            <td>EVYAP - ODESSA</td>
            <td>2021-07-12</td>
          </tr>
          <tr>
            <td>26</td>
            <td>PAAVA </td>
            <td>2020-06-27</td>
            <td>2020-06-30</td>
            <td>MARTI</td>
            <td> 20-129</td>
            <td>EVYAP - ODESSA</td>
            <td>2020-06-25</td>
          </tr>
          <tr>
            <td>26</td>
            <td>CMA CGM URUGUAY </td>
            <td>2020-06-27</td>
            <td>2020-06-30</td>
            <td>OOCL</td>
            <td>0BX6OE1MA</td>
            <td>EVYAP - ODESSA</td>
            <td>2020-06-23</td>
          </tr>
          <tr>
            <td>25</td>
            <td>ADMIRAL MOON</td>
            <td>2021-06-26</td>
            <td>2021-06-30</td>
            <td>MARTI</td>
            <td>21-206</td>
            <td>EVYAP - ODESSA</td>
            <td>2021-06-24</td>
          </tr>
          <tr>
            <td>25</td>
            <td>SASKIA A</td>
            <td>2021-07-08</td>
            <td>2021-07-12</td>
            <td>ARKAS</td>
            <td>TLS19S21</td>
            <td>EVYAP - ODESSA</td>
            <td>2021-07-05</td>
          </tr>
          <tr>
            <td>25</td>
            <td>PAOLA</td>
            <td>2020-06-20</td>
            <td>2020-06-23</td>
            <td>MARTI</td>
            <td>20-228</td>
            <td>EVYAP - ODESSA</td>
            <td>2020-06-18</td>
          </tr>
          <tr>
            <td>25</td>
            <td>CMA CGM ARKANSAS</td>
            <td>2020-06-19</td>
            <td>2020-06-21</td>
            <td>OOCL</td>
            <td>0BX6ME1MA</td>
            <td>EVYAP - ODESSA</td>
            <td>2020-06-17</td>
          </tr>
          <tr>
            <td>24</td>
            <td>ADMIRAL SUN </td>
            <td>2021-06-19</td>
            <td>2021-06-23</td>
            <td>MARTI</td>
            <td>21-106</td>
            <td>EVYAP - ODESSA</td>
            <td>2021-06-17</td>
          </tr>
          <tr>
            <td>24</td>
            <td>PAAVA </td>
            <td>2020-06-13</td>
            <td>2020-06-16</td>
            <td>MARTI</td>
            <td>20-128</td>
            <td>EVYAP - ODESSA</td>
            <td>2020-06-11</td>
          </tr>
          <tr>
            <td>24</td>
            <td>MAIRA XL</td>
            <td>2020-06-11</td>
            <td>2020-06-14</td>
            <td>OOCL</td>
            <td>025E</td>
            <td>EVYAP - ODESSA</td>
            <td>2020-06-10</td>
          </tr>
          <tr>
            <td>23</td>
            <td>ADMIRAL MOON</td>
            <td>2021-06-12</td>
            <td>2021-06-16</td>
            <td>MARTI</td>
            <td>21-205</td>
            <td>EVYAP - ODESSA</td>
            <td>2021-06-10</td>
          </tr>
          <tr>
            <td>23</td>
            <td>PAOLA</td>
            <td>2020-06-05</td>
            <td>2020-06-09</td>
            <td>MARTI</td>
            <td> M/V PAOLA 20-227</td>
            <td>EVYAP - ODESSA</td>
            <td>2020-06-04</td>
          </tr>
          <tr>
            <td>22</td>
            <td>GRETA</td>
            <td>2021-06-06</td>
            <td>2021-06-09</td>
            <td>MARTI</td>
            <td>21-426</td>
            <td>EVYAP - ODESSA</td>
            <td>2021-06-03</td>
          </tr>
          <tr>
            <td>22</td>
            <td>SASKIA A</td>
            <td>2021-06-10</td>
            <td>2021-06-15</td>
            <td>ARKAS</td>
            <td>TLS16S21</td>
            <td>EVYAP - ODESSA</td>
            <td>2021-06-08</td>
          </tr>
          <tr>
            <td>22</td>
            <td>COSCO SHIPPING RHINE</td>
            <td>2020-05-29</td>
            <td>2020-06-04</td>
            <td>OOCL</td>
            <td>016E</td>
            <td>EVYAP - ODESSA</td>
            <td>2020-05-22</td>
          </tr>
          <tr>
            <td>21</td>
            <td>GRETA</td>
            <td>2021-05-30</td>
            <td>2021-06-02</td>
            <td>MARTI</td>
            <td>21-426</td>
            <td>EVYAP - ODESSA</td>
            <td>2021-05-27</td>
          </tr>
          <tr>
            <td>21</td>
            <td>PAOLA</td>
            <td>2020-05-22</td>
            <td>2020-05-26</td>
            <td>MARTI</td>
            <td>20-226</td>
            <td>EVYAP - ODESSA</td>
            <td>2020-05-21</td>
          </tr>
          <tr>
            <td>21</td>
            <td>CMA CGM VOLGA</td>
            <td>2020-05-24</td>
            <td>2020-05-28</td>
            <td>OOCL</td>
            <td>0BX6EE1MA</td>
            <td>EVYAP - ODESSA</td>
            <td>2020-05-22</td>
          </tr>
          <tr>
            <td>20</td>
            <td>GRETA</td>
            <td>2021-05-22</td>
            <td>2021-05-25</td>
            <td>MARTI</td>
            <td>21-425</td>
            <td>EVYAP - ODESSA</td>
            <td>2021-05-20</td>
          </tr>
          <tr>
            <td>20</td>
            <td>TURKON EGYPT</td>
            <td>2021-05-29</td>
            <td>2021-06-03</td>
            <td>ARKAS</td>
            <td>TLS01221</td>
            <td>EVYAP - ODESSA</td>
            <td>2021-05-27</td>
          </tr>
          <tr>
            <td>19</td>
            <td>GRETA</td>
            <td>2021-05-14</td>
            <td>2021-05-19</td>
            <td>MARTI</td>
            <td>21-424</td>
            <td>EVYAP - ODESSA</td>
            <td>2021-05-11</td>
          </tr>
          <tr>
            <td>19</td>
            <td>PAOLA</td>
            <td>2020-05-09</td>
            <td>2020-05-12</td>
            <td>MARTI</td>
            <td>20-225</td>
            <td>EVYAP - ODESSA</td>
            <td>2020-05-07</td>
          </tr>
          <tr>
            <td>19</td>
            <td>CMA CGM MISSOURI</td>
            <td>2020-05-08</td>
            <td>2020-05-11</td>
            <td>OOCL</td>
            <td>0BX6AE1MA</td>
            <td>EVYAP - ODESSA</td>
            <td>2020-05-05</td>
          </tr>
          <tr>
            <td>18</td>
            <td>GRETA</td>
            <td>2021-05-08</td>
            <td>2021-05-13</td>
            <td>MARTI</td>
            <td>21-423</td>
            <td>EVYAP - ODESSA</td>
            <td>2021-05-06</td>
          </tr>
          <tr>
            <td>18</td>
            <td>PAAVA </td>
            <td>2020-05-01</td>
            <td>2020-05-04</td>
            <td>MARTI</td>
            <td>20-125</td>
            <td>EVYAP - ODESSA</td>
            <td>2020-04-30</td>
          </tr>
          <tr>
            <td>17</td>
            <td>GRETA</td>
            <td>2021-05-02</td>
            <td>2021-05-05</td>
            <td>MARTI</td>
            <td>21-422</td>
            <td>EVYAP - ODESSA</td>
            <td>2021-04-29</td>
          </tr>
          <tr>
            <td>17</td>
            <td>PAOLA</td>
            <td>2020-04-24</td>
            <td>2020-04-28</td>
            <td>Sealand</td>
            <td>20-124</td>
            <td>EVYAP - ODESSA</td>
            <td>2020-04-23</td>
          </tr>
          <tr>
            <td>16</td>
            <td>GRETA</td>
            <td>2021-04-16</td>
            <td>2021-04-20</td>
            <td>MARTI</td>
            <td>21-420</td>
            <td>EVYAP - ODESSA</td>
            <td>2021-04-14</td>
          </tr>
          <tr>
            <td>16</td>
            <td>PAAVA </td>
            <td>2020-04-17</td>
            <td>2020-04-21</td>
            <td>MARTI</td>
            <td>20-125</td>
            <td>EVYAP - ODESSA</td>
            <td>2020-04-16</td>
          </tr>
          <tr>
            <td>15</td>
            <td>GRETA</td>
            <td>2021-04-09</td>
            <td>2021-04-13</td>
            <td>Sealand</td>
            <td>21-419</td>
            <td>EVYAP - ODESSA</td>
            <td>2021-04-08</td>
          </tr>
          <tr>
            <td>15</td>
            <td>PAAVA </td>
            <td>2020-04-10</td>
            <td>2020-04-14</td>
            <td>MARTI</td>
            <td> 20-124</td>
            <td>EVYAP - ODESSA</td>
            <td>2020-04-09</td>
          </tr>
          <tr>
            <td>15</td>
            <td>COSCO SHIPPING SEINE </td>
            <td>2020-04-10</td>
            <td>2020-04-14</td>
            <td>OOCL</td>
            <td>EM1SHI015East</td>
            <td>EVYAP - ODESSA</td>
            <td>2020-04-04</td>
          </tr>
          <tr>
            <td>14</td>
            <td>GRETA</td>
            <td>2021-04-10</td>
            <td>2021-04-13</td>
            <td>MARTI</td>
            <td> 21-419</td>
            <td>EVYAP - ODESSA</td>
            <td>2021-04-08</td>
          </tr>
          <tr>
            <td>14</td>
            <td>EMMA A</td>
            <td>2021-04-16</td>
            <td>2021-04-20</td>
            <td>ARKAS</td>
            <td>TLS01121</td>
            <td>EVYAP - ODESSA</td>
            <td>2021-04-14</td>
          </tr>
          <tr>
            <td>14</td>
            <td>PAOLA</td>
            <td>2020-04-04</td>
            <td>2020-04-07</td>
            <td>MARTI</td>
            <td>20-223</td>
            <td>EVYAP - ODESSA</td>
            <td>2020-04-03</td>
          </tr>
          <tr>
            <td>14</td>
            <td>CMA CGM ARKANSAS</td>
            <td>2020-04-04</td>
            <td>2020-04-07</td>
            <td>OOCL</td>
            <td>0BX60E1MA</td>
            <td>EVYAP - ODESSA</td>
            <td>2020-03-31</td>
          </tr>
          <tr>
            <td>13</td>
            <td>GRETA</td>
            <td>2021-04-10</td>
            <td>2021-04-13</td>
            <td>MARTI</td>
            <td>21-419</td>
            <td>EVYAP - ODESSA</td>
            <td>2021-04-08</td>
          </tr>
          <tr>
            <td>13</td>
            <td>SASKIA A</td>
            <td>2021-04-10</td>
            <td>2021-04-14</td>
            <td>ARKAS</td>
            <td>TLS01021</td>
            <td>EVYAP - ODESSA</td>
            <td>2021-04-07</td>
          </tr>
          <tr>
            <td>13</td>
            <td>PACOBA</td>
            <td>2020-03-28</td>
            <td>2020-04-01</td>
            <td>MARTI</td>
            <td>20-304</td>
            <td>EVYAP - ODESSA</td>
            <td>2020-03-26</td>
          </tr>
          <tr>
            <td>12</td>
            <td>PAAVA </td>
            <td>2021-03-27</td>
            <td>2021-03-30</td>
            <td>MARTI</td>
            <td> 21-148</td>
            <td>EVYAP - ODESSA</td>
            <td>2021-03-25</td>
          </tr>
          <tr>
            <td>12</td>
            <td>TURKON EGYPT</td>
            <td>2021-04-01</td>
            <td>2021-04-05</td>
            <td></td>
            <td>TLS09S21</td>
            <td>EVYAP - ODESSA</td>
            <td>2021-03-30</td>
          </tr>
          <tr>
            <td>12</td>
            <td>PAAVA </td>
            <td>2020-03-21</td>
            <td>2020-03-24</td>
            <td>Sealand</td>
            <td> 20/123</td>
            <td>EVYAP - ODESSA</td>
            <td>2020-03-19</td>
          </tr>
          <tr>
            <td>12</td>
            <td>MAIRA XL</td>
            <td>2020-03-20</td>
            <td>2020-03-24</td>
            <td>OOCL</td>
            <td>024E</td>
            <td>EVYAP - ODESSA</td>
            <td>2020-03-17</td>
          </tr>
          <tr>
            <td>10</td>
            <td>EMMA A</td>
            <td>2021-03-23</td>
            <td>2021-03-27</td>
            <td>ARKAS</td>
            <td>TLS08S21</td>
            <td>EVYAP - ODESSA</td>
            <td>2021-03-19</td>
          </tr>
          <tr>
            <td>10</td>
            <td>GRETA</td>
            <td>2021-03-20</td>
            <td>2021-03-23</td>
            <td>MARTI</td>
            <td>21-416</td>
            <td>EVYAP - ODESSA</td>
            <td>2021-03-18</td>
          </tr>
          <tr>
            <td>10</td>
            <td>PACOBA</td>
            <td>2020-03-07</td>
            <td>2020-03-10</td>
            <td>MARTI</td>
            <td>20-303</td>
            <td>EVYAP - ODESSA</td>
            <td>2020-03-06</td>
          </tr>
          <tr>
            <td>10</td>
            <td>COSCO SHIPPING RHINE</td>
            <td>2020-03-07</td>
            <td>2020-03-10</td>
            <td>OOCL</td>
            <td>015E</td>
            <td>EVYAP - ODESSA</td>
            <td>2020-02-03</td>
          </tr>
          <tr>
            <td>9</td>
            <td>EMMA A</td>
            <td>2021-03-19</td>
            <td>2021-03-23</td>
            <td>ARKAS</td>
            <td>TLS08S21</td>
            <td>EVYAP - ODESSA</td>
            <td>2021-03-17</td>
          </tr>
          <tr>
            <td>9</td>
            <td>CMA CGM LITANI</td>
            <td>2021-03-09</td>
            <td>2021-03-12</td>
            <td>OOCL</td>
            <td>0BX8QE1MA</td>
            <td>EVYAP - ODESSA</td>
            <td>2021-03-07</td>
          </tr>
          <tr>
            <td>9</td>
            <td>PAAVA </td>
            <td>2020-02-29</td>
            <td>2020-03-03</td>
            <td>MARTI</td>
            <td>20-122</td>
            <td>EVYAP - ODESSA</td>
            <td>2020-02-28</td>
          </tr>
          <tr>
            <td>3</td>
            <td>TURKON EGYPT</td>
            <td>2021-01-29</td>
            <td>2021-02-10</td>
            <td>ARKAS</td>
            <td>TLS03S21</td>
            <td>EVYAP - ODESSA</td>
            <td>2021-01-28</td>
          </tr>
          <tr>
            <td>3</td>
            <td>GRETA</td>
            <td>2021-01-23</td>
            <td>2021-01-25</td>
            <td>MARTI</td>
            <td> 21-409</td>
            <td>EVYAP - ODESSA</td>
            <td>2021-01-21</td>
          </tr>
          <tr>
            <td>2</td>
            <td>PAOLA</td>
            <td>2021-01-16</td>
            <td>2021-01-22</td>
            <td>MARTI</td>
            <td>20-243</td>
            <td>EVYAP - ODESSA</td>
            <td>2021-01-15</td>
          </tr>
          <tr>
            <td>2</td>
            <td></td>
            <td>2021-01-18</td>
            <td>2021-01-20</td>
            <td>OOCL</td>
            <td>002E</td>
            <td>EVYAP - ODESSA</td>
            <td>2021-01-13</td>
          </tr>
          <tr>
            <td>1</td>
            <td>PAAVA </td>
            <td>2021-01-09</td>
            <td>2021-01-12</td>
            <td>MARTI</td>
            <td>20-143</td>
            <td>EVYAP - ODESSA</td>
            <td>2021-01-08</td>
          </tr>
          <tr>
            <td>1</td>
            <td>GRETA</td>
            <td>2021-01-02</td>
            <td>2021-01-04</td>
            <td>MARTI</td>
            <td>20/408</td>
            <td>EVYAP - ODESSA</td>
            <td>2020-12-30</td>
          </tr>
          <tr>
            <td>52</td>
            <td>GRETA</td>
            <td>2020-12-26</td>
            <td>2020-12-28</td>
            <td>MARTI</td>
            <td>20-407</td>
            <td>EVYAP - ODESSA</td>
            <td>2020-12-25</td>
          </tr>
          <tr>
            <td>35</td>
            <td>PAAVA </td>
            <td>2020-08-22</td>
            <td>2020-08-24</td>
            <td>MARTI</td>
            <td>20-133</td>
            <td>EVYAP - ODESSA</td>
            <td>2020-08-20</td>
          </tr>
          <tr>
            <td>11</td>
            <td>PAOLA</td>
            <td>2020-03-14</td>
            <td>2020-03-17</td>
            <td>MARTI</td>
            <td>20-222</td>
            <td>EVYAP - ODESSA</td>
            <td>2020-03-13</td>
          </tr>
        </tbody>
      </table>
    ),
  },
  {
    id: 7,
    itemText: "IZMIR - CHORNOMORSK",
    itemValue: (
      <table class="vessel-table">
        <thead>
          <tr>
            <th>Week</th>
            <th>Ship</th>
            <th>ETD</th>
            <th>ETA</th>
            <th>Line</th>
            <th>Voyage</th>
            <th>VesselCategories</th>
            <th>CutOff</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>31</td>
            <td>SAFMARINE NUBA</td>
            <td>2021-08-13</td>
            <td>2021-08-20</td>
            <td>Sealand</td>
            <td>132N</td>
            <td>IZMIR - CHORNOMORSK </td>
            <td>2021-08-11</td>
          </tr>
          <tr>
            <td>31</td>
            <td>MAERSK ARIZONA</td>
            <td>2020-07-30</td>
            <td>2020-08-14</td>
            <td>Sealand</td>
            <td>031S</td>
            <td>IZMIR - CHORNOMORSK </td>
            <td>2020-07-29</td>
          </tr>
          <tr>
            <td>29</td>
            <td>SAFMARINE NAKURU</td>
            <td>2021-07-30</td>
            <td>2021-08-07</td>
            <td>Sealand</td>
            <td>130N</td>
            <td>IZMIR - CHORNOMORSK </td>
            <td>2021-07-28</td>
          </tr>
          <tr>
            <td>29</td>
            <td>MAERSK ARIZONA</td>
            <td>2020-07-16</td>
            <td>2020-08-03</td>
            <td>Sealand</td>
            <td>029S</td>
            <td>IZMIR - CHORNOMORSK </td>
            <td>2020-07-13</td>
          </tr>
          <tr>
            <td>28</td>
            <td>SAFMARINE NUBA</td>
            <td>2021-07-23</td>
            <td>2021-07-30</td>
            <td>Sealand</td>
            <td>128N</td>
            <td>IZMIR - CHORNOMORSK </td>
            <td>2021-07-12</td>
          </tr>
          <tr>
            <td>28</td>
            <td>ANTWERP</td>
            <td>2020-07-09</td>
            <td>2020-07-29</td>
            <td>Sealand</td>
            <td>028S</td>
            <td>IZMIR - CHORNOMORSK </td>
            <td>2020-07-06</td>
          </tr>
          <tr>
            <td>27</td>
            <td>JULIUS-S</td>
            <td>2021-07-16</td>
            <td>2021-07-23</td>
            <td>Sealand</td>
            <td>128N</td>
            <td>IZMIR - CHORNOMORSK </td>
            <td>2021-07-12</td>
          </tr>
          <tr>
            <td>27</td>
            <td>MAERSK ARIZONA</td>
            <td>2020-07-07</td>
            <td>2020-07-25</td>
            <td>Sealand</td>
            <td>027S</td>
            <td>IZMIR - CHORNOMORSK </td>
            <td>2020-07-02</td>
          </tr>
          <tr>
            <td>26</td>
            <td>SAFMARINE NAKURU</td>
            <td>2021-07-09</td>
            <td>2021-07-16</td>
            <td>Sealand</td>
            <td>127N</td>
            <td>IZMIR - CHORNOMORSK </td>
            <td>2021-07-07</td>
          </tr>
          <tr>
            <td>26</td>
            <td>ANTWERP</td>
            <td>2020-06-25</td>
            <td>2020-07-12</td>
            <td>Sealand</td>
            <td>026S</td>
            <td>IZMIR - CHORNOMORSK </td>
            <td>2020-06-22</td>
          </tr>
          <tr>
            <td>25</td>
            <td>SAFMARINE NUBA</td>
            <td>2021-07-04</td>
            <td>2021-07-11</td>
            <td>Sealand</td>
            <td>126N</td>
            <td>IZMIR - CHORNOMORSK </td>
            <td>2021-06-30</td>
          </tr>
          <tr>
            <td>25</td>
            <td>MAERSK ARIZONA</td>
            <td>2020-06-18</td>
            <td>2020-07-07</td>
            <td>Sealand</td>
            <td>025S</td>
            <td>IZMIR - CHORNOMORSK </td>
            <td>2020-06-15</td>
          </tr>
          <tr>
            <td>24</td>
            <td>ALEXANDER MAERSK </td>
            <td>2021-06-29</td>
            <td>2021-07-17</td>
            <td>Sealand</td>
            <td>126S</td>
            <td>IZMIR - CHORNOMORSK </td>
            <td>2021-06-25</td>
          </tr>
          <tr>
            <td>23</td>
            <td>SAFMARINE NAKURU</td>
            <td>2021-06-18</td>
            <td>2021-06-25</td>
            <td>Sealand</td>
            <td>124N</td>
            <td>IZMIR - CHORNOMORSK </td>
            <td>2021-06-16</td>
          </tr>
          <tr>
            <td>23</td>
            <td>PACUL </td>
            <td>2020-06-09</td>
            <td>2020-06-13</td>
            <td>Sealand</td>
            <td>024W</td>
            <td>IZMIR - CHORNOMORSK </td>
            <td>2020-06-04</td>
          </tr>
          <tr>
            <td>22</td>
            <td>SAFMARINE NAKURU</td>
            <td>2021-06-11</td>
            <td>2021-06-18</td>
            <td>Sealand</td>
            <td>123N</td>
            <td>IZMIR - CHORNOMORSK </td>
            <td>2021-06-09</td>
          </tr>
          <tr>
            <td>22</td>
            <td>ASIATIC ORCHID </td>
            <td>2020-06-01</td>
            <td>2020-06-19</td>
            <td>Sealand</td>
            <td>023W</td>
            <td>IZMIR - CHORNOMORSK </td>
            <td>2020-05-28</td>
          </tr>
          <tr>
            <td>21</td>
            <td>SAFMARINE NAKURU</td>
            <td>2021-06-04</td>
            <td>2021-06-11</td>
            <td>Sealand</td>
            <td>122N</td>
            <td>IZMIR - CHORNOMORSK </td>
            <td>2021-06-02</td>
          </tr>
          <tr>
            <td>21</td>
            <td>PACUL </td>
            <td>2020-05-25</td>
            <td>2020-05-12</td>
            <td>Sealand</td>
            <td>022W</td>
            <td>IZMIR - CHORNOMORSK </td>
            <td>2020-05-21</td>
          </tr>
          <tr>
            <td>20</td>
            <td>SAFMARINE NAKURU</td>
            <td>2021-06-28</td>
            <td>2021-06-04</td>
            <td>Sealand</td>
            <td>120N</td>
            <td>IZMIR - CHORNOMORSK </td>
            <td>2021-05-25</td>
          </tr>
          <tr>
            <td>20</td>
            <td>ASIATIC ORCHID </td>
            <td>2020-05-18</td>
            <td>2020-06-05</td>
            <td>Sealand</td>
            <td>021W</td>
            <td>IZMIR - CHORNOMORSK </td>
            <td>2020-05-14</td>
          </tr>
          <tr>
            <td>19</td>
            <td>SAFMARINE NUBA</td>
            <td>2021-05-21</td>
            <td>2021-05-28</td>
            <td>Sealand</td>
            <td>120N</td>
            <td>IZMIR - CHORNOMORSK </td>
            <td>2021-05-18</td>
          </tr>
          <tr>
            <td>18</td>
            <td>JULIUS-S</td>
            <td>2021-05-14</td>
            <td>2021-05-21</td>
            <td>Sealand</td>
            <td>119N</td>
            <td>IZMIR - CHORNOMORSK </td>
            <td>2021-05-10</td>
          </tr>
          <tr>
            <td>17</td>
            <td>SAFMARINE NAKURU</td>
            <td>2021-04-07</td>
            <td>2021-04-14</td>
            <td>Sealand</td>
            <td>118N</td>
            <td>IZMIR - CHORNOMORSK </td>
            <td>2021-04-05</td>
          </tr>
          <tr>
            <td>16</td>
            <td>SAFMARINE NUBA</td>
            <td>2021-04-30</td>
            <td>2021-05-07</td>
            <td>Sealand</td>
            <td>117N</td>
            <td>IZMIR - CHORNOMORSK </td>
            <td>2021-04-27</td>
          </tr>
          <tr>
            <td>15</td>
            <td>JULIUS-S</td>
            <td>2021-04-23</td>
            <td>2021-04-30</td>
            <td>Sealand</td>
            <td>116S</td>
            <td>IZMIR - CHORNOMORSK </td>
            <td>2021-04-21</td>
          </tr>
          <tr>
            <td>14</td>
            <td>SAFMARINE NAKURU</td>
            <td>2021-04-16</td>
            <td>2021-04-23</td>
            <td>Sealand</td>
            <td>115N</td>
            <td>IZMIR - CHORNOMORSK </td>
            <td>2021-04-14</td>
          </tr>
          <tr>
            <td>13</td>
            <td>SAFMARINE NAKURU</td>
            <td>2021-04-06</td>
            <td>2021-04-12</td>
            <td>Sealand</td>
            <td>113S</td>
            <td>IZMIR - CHORNOMORSK </td>
            <td>2021-04-02</td>
          </tr>
          <tr>
            <td>12</td>
            <td>JULIUS-S</td>
            <td>2021-04-02</td>
            <td>2021-04-09</td>
            <td>Sealand</td>
            <td>113N</td>
            <td>IZMIR - CHORNOMORSK </td>
            <td>2021-03-31</td>
          </tr>
          <tr>
            <td>11</td>
            <td>SAFMARINE NAKURU</td>
            <td>2021-03-26</td>
            <td>2021-04-02</td>
            <td>Sealand</td>
            <td>112N</td>
            <td>IZMIR - CHORNOMORSK </td>
            <td>2021-03-24</td>
          </tr>
          <tr>
            <td>10</td>
            <td>SAFMARINE NUBA</td>
            <td>2021-03-19</td>
            <td>2021-03-26</td>
            <td>Sealand</td>
            <td>111N</td>
            <td>IZMIR - CHORNOMORSK </td>
            <td>2021-03-17</td>
          </tr>
          <tr>
            <td>9</td>
            <td>JULIUS-S</td>
            <td>2021-03-12</td>
            <td>2021-03-19</td>
            <td>Sealand</td>
            <td>110S</td>
            <td>IZMIR - CHORNOMORSK </td>
            <td>2021-03-10</td>
          </tr>
          <tr>
            <td>9</td>
            <td>PACUL </td>
            <td>2020-03-02</td>
            <td>2020-03-05</td>
            <td>Sealand</td>
            <td>010W</td>
            <td>IZMIR - CHORNOMORSK </td>
            <td>2020-02-27</td>
          </tr>
          <tr>
            <td>8</td>
            <td>SAFMARINE NAKURU</td>
            <td>2021-03-05</td>
            <td>2021-03-12</td>
            <td>Sealand</td>
            <td>109N</td>
            <td>IZMIR - CHORNOMORSK </td>
            <td>2021-03-03</td>
          </tr>
          <tr>
            <td>8</td>
            <td>ASIATIC ORCHID </td>
            <td>2020-02-24</td>
            <td>2020-02-28</td>
            <td>Sealand</td>
            <td>009W</td>
            <td>IZMIR - CHORNOMORSK </td>
            <td>2020-02-20</td>
          </tr>
          <tr>
            <td>7</td>
            <td>SAFMARINE NUBA</td>
            <td>2021-02-26</td>
            <td>2021-03-06</td>
            <td>Sealand</td>
            <td>108N</td>
            <td>IZMIR - CHORNOMORSK </td>
            <td>2021-02-24</td>
          </tr>
          <tr>
            <td>7</td>
            <td>PACUL </td>
            <td>2020-02-17</td>
            <td>2020-02-21</td>
            <td>Sealand</td>
            <td>008W</td>
            <td>IZMIR - CHORNOMORSK </td>
            <td>2020-02-13</td>
          </tr>
          <tr>
            <td>6</td>
            <td>SAFMARINE NUBA</td>
            <td>2021-02-26</td>
            <td>2021-03-05</td>
            <td>Sealand</td>
            <td>108N</td>
            <td>IZMIR - CHORNOMORSK </td>
            <td>2021-02-24</td>
          </tr>
          <tr>
            <td>6</td>
            <td>ASIATIC ORCHID </td>
            <td>2020-02-10</td>
            <td>2020-02-14</td>
            <td>Sealand</td>
            <td>007W</td>
            <td>IZMIR - CHORNOMORSK </td>
            <td>2020-02-06</td>
          </tr>
          <tr>
            <td>5</td>
            <td>SAFMARINE NAKURU</td>
            <td>2021-02-12</td>
            <td>2021-02-19</td>
            <td>Sealand</td>
            <td>106N</td>
            <td>IZMIR - CHORNOMORSK </td>
            <td>2021-02-10</td>
          </tr>
          <tr>
            <td>5</td>
            <td>PACUL </td>
            <td>2020-02-03</td>
            <td>2020-02-07</td>
            <td>Sealand</td>
            <td>006W</td>
            <td>IZMIR - CHORNOMORSK </td>
            <td>2020-01-30</td>
          </tr>
          <tr>
            <td>4</td>
            <td>SAFMARINE NUBA</td>
            <td>2021-02-05</td>
            <td>2021-02-12</td>
            <td>Sealand</td>
            <td>105N</td>
            <td>IZMIR - CHORNOMORSK </td>
            <td>2021-02-03</td>
          </tr>
          <tr>
            <td>3</td>
            <td>SAFMARINE NAKURU</td>
            <td>2021-01-22</td>
            <td>2021-01-29</td>
            <td>Sealand</td>
            <td>103N</td>
            <td>IZMIR - CHORNOMORSK </td>
            <td>2021-01-20</td>
          </tr>
          <tr>
            <td>2</td>
            <td>SAFMARINE NAKURU</td>
            <td>2021-01-22</td>
            <td>2021-01-29</td>
            <td>Sealand</td>
            <td>103N</td>
            <td>IZMIR - CHORNOMORSK </td>
            <td>2021-01-20</td>
          </tr>
          <tr>
            <td>1</td>
            <td>SAFMARINE NUBA</td>
            <td>2021-01-15</td>
            <td>2021-01-22</td>
            <td>Sealand</td>
            <td>102S</td>
            <td>IZMIR - CHORNOMORSK </td>
            <td>2021-01-13</td>
          </tr>
          <tr>
            <td>1</td>
            <td>SAFMARINE NAKURU</td>
            <td>2021-01-08</td>
            <td>2021-01-15</td>
            <td>Sealand</td>
            <td>053N</td>
            <td>IZMIR - CHORNOMORSK </td>
            <td>2021-01-06</td>
          </tr>
          <tr>
            <td>52</td>
            <td>SAFMARINE NUBA</td>
            <td>2020-12-25</td>
            <td>2021-01-01</td>
            <td>Sealand</td>
            <td>052N</td>
            <td>IZMIR - CHORNOMORSK </td>
            <td>2020-12-23</td>
          </tr>
          <tr>
            <td>38</td>
            <td>SAFMARINE NUBA</td>
            <td>2020-09-18</td>
            <td>2020-09-25</td>
            <td>Sealand</td>
            <td>048N</td>
            <td>IZMIR - CHORNOMORSK </td>
            <td>2020-09-16</td>
          </tr>
          <tr>
            <td>37</td>
            <td>MAERSK ARIZONA</td>
            <td>2020-09-09</td>
            <td>2020-09-28</td>
            <td>Sealand</td>
            <td>037S</td>
            <td>IZMIR - CHORNOMORSK </td>
            <td>2020-09-07</td>
          </tr>
          <tr>
            <td>36</td>
            <td>ANTWERP</td>
            <td>2020-09-02</td>
            <td>2020-09-24</td>
            <td>Sealand</td>
            <td>036S</td>
            <td>IZMIR - CHORNOMORSK </td>
            <td>2020-08-31</td>
          </tr>
          <tr>
            <td>35</td>
            <td>MAERSK ARIZONA</td>
            <td>2020-08-27</td>
            <td>2020-09-14</td>
            <td>Sealand</td>
            <td>035S</td>
            <td>IZMIR - CHORNOMORSK </td>
            <td>2020-08-24</td>
          </tr>
          <tr>
            <td>34</td>
            <td>ANTWERP</td>
            <td>2020-08-20</td>
            <td>2020-09-08</td>
            <td>Sealand</td>
            <td>034S</td>
            <td>IZMIR - CHORNOMORSK </td>
            <td>2020-08-17</td>
          </tr>
          <tr>
            <td>33</td>
            <td>MAERSK ARIZONA</td>
            <td>2020-08-13</td>
            <td>2020-09-09</td>
            <td>Sealand</td>
            <td>033S</td>
            <td>IZMIR - CHORNOMORSK </td>
            <td>2020-08-10</td>
          </tr>
          <tr>
            <td>30</td>
            <td> SEAGO ANTWERP</td>
            <td>2020-07-23</td>
            <td>2020-08-10</td>
            <td>Sealand</td>
            <td>030S</td>
            <td>IZMIR - CHORNOMORSK </td>
            <td>2020-07-20</td>
          </tr>
        </tbody>
      </table>
    ),
  },
  {
    id: 8,
    itemText: "IZMIR - Novorossiysk",
    itemValue: (
      <table class="vessel-table">
        <thead>
          <tr>
            <th>Week</th>
            <th>Ship</th>
            <th>ETD</th>
            <th>ETA</th>
            <th>Line</th>
            <th>Voyage</th>
            <th>VesselCategories</th>
            <th>CutOff</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>8</td>
            <td>NEXOE MAERSK</td>
            <td>2022-03-05</td>
            <td>2022-03-21</td>
            <td>Sealand</td>
            <td>209S</td>
            <td>IZMIR - Novorossiysk</td>
            <td>2022-03-02</td>
          </tr>
          <tr>
            <td>8</td>
            <td>ALEXANDER MAERSK </td>
            <td>2021-03-03</td>
            <td>2021-03-22</td>
            <td>Sealand</td>
            <td>109S</td>
            <td>IZMIR - Novorossiysk</td>
            <td>2021-03-01</td>
          </tr>
          <tr>
            <td>8</td>
            <td>ASIATIC ORCHID </td>
            <td>2020-02-24</td>
            <td>2020-02-28</td>
            <td>Sealand</td>
            <td>009W</td>
            <td>IZMIR - Novorossiysk</td>
            <td>2020-02-20</td>
          </tr>
          <tr>
            <td>7</td>
            <td>CAPE MALE</td>
            <td>2022-02-26</td>
            <td>2022-03-14</td>
            <td>Sealand</td>
            <td>208S</td>
            <td>IZMIR - Novorossiysk</td>
            <td>2022-02-23</td>
          </tr>
          <tr>
            <td>7</td>
            <td>ANTWERP</td>
            <td>2021-02-24</td>
            <td>2021-03-14</td>
            <td>Sealand</td>
            <td>108S</td>
            <td>IZMIR - Novorossiysk</td>
            <td>2021-02-22</td>
          </tr>
          <tr>
            <td>7</td>
            <td>PACUL </td>
            <td>2020-02-17</td>
            <td>2020-02-21</td>
            <td>Sealand</td>
            <td>008W</td>
            <td>IZMIR - Novorossiysk</td>
            <td>2020-02-13</td>
          </tr>
          <tr>
            <td>6</td>
            <td>CAPE MALE</td>
            <td>2022-02-19</td>
            <td>2022-03-07</td>
            <td>Sealand</td>
            <td>207S</td>
            <td>IZMIR - Novorossiysk</td>
            <td>2022-02-16</td>
          </tr>
          <tr>
            <td>6</td>
            <td>MAERSK ARIZONA</td>
            <td>2021-02-22</td>
            <td>2021-03-15</td>
            <td>Sealand</td>
            <td>108S</td>
            <td>IZMIR - Novorossiysk</td>
            <td>2021-02-17</td>
          </tr>
          <tr>
            <td>6</td>
            <td>ASIATIC ORCHID </td>
            <td>2020-02-10</td>
            <td>2020-02-14</td>
            <td>Sealand</td>
            <td>007W</td>
            <td>IZMIR - Novorossiysk</td>
            <td>2020-01-06</td>
          </tr>
          <tr>
            <td>5</td>
            <td>CAPE MALE</td>
            <td>2022-02-04</td>
            <td>2022-02-20</td>
            <td>Sealand</td>
            <td>205S</td>
            <td>IZMIR - Novorossiysk</td>
            <td>2022-02-02</td>
          </tr>
          <tr>
            <td>5</td>
            <td>MAERSK ARIZONA</td>
            <td>2021-02-10</td>
            <td>2021-02-23</td>
            <td>Sealand</td>
            <td>106S</td>
            <td>IZMIR - Novorossiysk</td>
            <td>2021-02-08</td>
          </tr>
          <tr>
            <td>5</td>
            <td>PACUL </td>
            <td>2020-02-03</td>
            <td>2020-02-07</td>
            <td>Sealand</td>
            <td>006W</td>
            <td>IZMIR - Novorossiysk</td>
            <td>2020-01-30</td>
          </tr>
          <tr>
            <td>4</td>
            <td>CAPE MALE</td>
            <td>2022-01-28</td>
            <td>2022-02-13</td>
            <td>Sealand</td>
            <td>204S</td>
            <td>IZMIR - Novorossiysk</td>
            <td>2022-01-26</td>
          </tr>
          <tr>
            <td>4</td>
            <td>ANTWERP</td>
            <td>2021-02-04</td>
            <td>2021-02-23</td>
            <td>Sealand</td>
            <td>102S</td>
            <td>IZMIR - Novorossiysk</td>
            <td>2021-02-01</td>
          </tr>
          <tr>
            <td>51</td>
            <td>CAPE MALE</td>
            <td>2021-12-31</td>
            <td>2022-01-17</td>
            <td>Sealand</td>
            <td>152S</td>
            <td>IZMIR - Novorossiysk</td>
            <td>2021-12-28</td>
          </tr>
          <tr>
            <td>50</td>
            <td>CAPE MALE</td>
            <td>2021-12-25</td>
            <td>2022-01-12</td>
            <td>Sealand</td>
            <td>151S</td>
            <td>IZMIR - Novorossiysk</td>
            <td>2021-12-22</td>
          </tr>
          <tr>
            <td>49</td>
            <td>AS CAMELLIA</td>
            <td>2021-12-18</td>
            <td>2022-01-05</td>
            <td>Sealand</td>
            <td>150N</td>
            <td>IZMIR - Novorossiysk</td>
            <td>2021-12-15</td>
          </tr>
          <tr>
            <td>48</td>
            <td>AS CAMELLIA</td>
            <td>2021-12-11</td>
            <td>2021-12-29</td>
            <td>Sealand</td>
            <td>149S</td>
            <td>IZMIR - Novorossiysk</td>
            <td>2021-12-08</td>
          </tr>
          <tr>
            <td>47</td>
            <td>AS CAMELLIA</td>
            <td>2021-12-04</td>
            <td>2021-12-22</td>
            <td>Sealand</td>
            <td>148S</td>
            <td>IZMIR - Novorossiysk</td>
            <td>2021-12-01</td>
          </tr>
          <tr>
            <td>46</td>
            <td>AS CAMELLIA</td>
            <td>2021-11-27</td>
            <td>2021-12-15</td>
            <td>Sealand</td>
            <td>147S</td>
            <td>IZMIR - Novorossiysk</td>
            <td>2021-11-24</td>
          </tr>
          <tr>
            <td>45</td>
            <td>AS CAMELLIA</td>
            <td>2021-11-19</td>
            <td>2021-12-07</td>
            <td>Sealand</td>
            <td>146S</td>
            <td>IZMIR - Novorossiysk</td>
            <td>2021-11-17</td>
          </tr>
          <tr>
            <td>44</td>
            <td>AS CAMELLIA</td>
            <td>2021-11-12</td>
            <td>2021-11-30</td>
            <td>Sealand</td>
            <td>145S</td>
            <td>IZMIR - Novorossiysk</td>
            <td>2021-11-10</td>
          </tr>
          <tr>
            <td>43</td>
            <td>AS CAMELLIA</td>
            <td>2021-11-06</td>
            <td>2021-11-23</td>
            <td>Sealand</td>
            <td>144S</td>
            <td>IZMIR - Novorossiysk</td>
            <td>2021-11-03</td>
          </tr>
          <tr>
            <td>42</td>
            <td>AS CAMELLIA</td>
            <td>2021-10-29</td>
            <td>2021-11-16</td>
            <td>Sealand</td>
            <td>143S</td>
            <td>IZMIR - Novorossiysk</td>
            <td>2021-10-26</td>
          </tr>
          <tr>
            <td>41</td>
            <td>AS CAMELLIA</td>
            <td>2021-10-22</td>
            <td>2021-12-05</td>
            <td>Sealand</td>
            <td>142S</td>
            <td>IZMIR - Novorossiysk</td>
            <td>2021-10-20</td>
          </tr>
          <tr>
            <td>40</td>
            <td>AS CAMELLIA</td>
            <td>2021-10-16</td>
            <td>2021-10-17</td>
            <td>Sealand</td>
            <td>141S</td>
            <td>IZMIR - Novorossiysk</td>
            <td>2021-10-13</td>
          </tr>
          <tr>
            <td>39</td>
            <td>AS CAMELLIA</td>
            <td>2021-10-08</td>
            <td>2021-10-24</td>
            <td>Sealand</td>
            <td>140S</td>
            <td>IZMIR - Novorossiysk</td>
            <td>2021-10-06</td>
          </tr>
          <tr>
            <td>39</td>
            <td>MAERSK ARIZONA</td>
            <td>2020-09-23</td>
            <td>2020-10-08</td>
            <td>Sealand</td>
            <td>039S</td>
            <td>IZMIR - Novorossiysk</td>
            <td>2020-09-21</td>
          </tr>
          <tr>
            <td>38</td>
            <td>AS CAMELLIA</td>
            <td>2021-10-01</td>
            <td>2021-09-18</td>
            <td>Sealand</td>
            <td>139S</td>
            <td>IZMIR - Novorossiysk</td>
            <td>2021-09-29</td>
          </tr>
          <tr>
            <td>38</td>
            <td>ANTWERP</td>
            <td>2020-09-16</td>
            <td>2020-10-03</td>
            <td>Sealand</td>
            <td>038S</td>
            <td>IZMIR - Novorossiysk</td>
            <td>2020-09-14</td>
          </tr>
          <tr>
            <td>37</td>
            <td>AS CAMELLIA</td>
            <td>2021-09-24</td>
            <td>2021-10-12</td>
            <td>Sealand</td>
            <td>138S</td>
            <td>IZMIR - Novorossiysk</td>
            <td>2021-09-22</td>
          </tr>
          <tr>
            <td>37</td>
            <td>MAERSK ARIZONA</td>
            <td>2020-09-09</td>
            <td>2020-09-28</td>
            <td>Sealand</td>
            <td>037S</td>
            <td>IZMIR - Novorossiysk</td>
            <td>2020-09-07</td>
          </tr>
          <tr>
            <td>36</td>
            <td>AS CAMELLIA</td>
            <td>2021-09-17</td>
            <td>2021-10-05</td>
            <td>Sealand</td>
            <td>137S</td>
            <td>IZMIR - Novorossiysk</td>
            <td>2021-09-15</td>
          </tr>
          <tr>
            <td>36</td>
            <td>ANTWERP</td>
            <td>2020-09-02</td>
            <td>2020-09-24</td>
            <td>Sealand</td>
            <td>036S</td>
            <td>IZMIR - Novorossiysk</td>
            <td>2020-08-31</td>
          </tr>
          <tr>
            <td>35</td>
            <td>SAFMARINE NAKURU</td>
            <td>2021-09-12</td>
            <td>2021-09-30</td>
            <td>Sealand</td>
            <td>137E</td>
            <td>IZMIR - Novorossiysk</td>
            <td>2021-09-09</td>
          </tr>
          <tr>
            <td>35</td>
            <td>MAERSK ARIZONA</td>
            <td>2020-08-27</td>
            <td>2020-09-14</td>
            <td>Sealand</td>
            <td>035S</td>
            <td>IZMIR - Novorossiysk</td>
            <td>2020-08-24</td>
          </tr>
          <tr>
            <td>33</td>
            <td>ALEXANDER MAERSK </td>
            <td>2021-08-26</td>
            <td>2021-09-13</td>
            <td>Sealand</td>
            <td>134S</td>
            <td>IZMIR - Novorossiysk</td>
            <td>2021-08-23</td>
          </tr>
          <tr>
            <td>33</td>
            <td>MAERSK ARIZONA</td>
            <td>2020-08-13</td>
            <td>2020-08-17</td>
            <td>Sealand</td>
            <td>033S</td>
            <td>IZMIR - Novorossiysk</td>
            <td>2020-08-10</td>
          </tr>
          <tr>
            <td>32</td>
            <td>ANTWERP</td>
            <td>2021-08-18</td>
            <td>2021-09-06</td>
            <td>Sealand</td>
            <td>133S</td>
            <td>IZMIR - Novorossiysk</td>
            <td>2021-08-16</td>
          </tr>
          <tr>
            <td>31</td>
            <td>ALEXANDER MAERSK </td>
            <td>2021-08-12</td>
            <td>2021-08-31</td>
            <td>Sealand</td>
            <td>132S</td>
            <td>IZMIR - Novorossiysk</td>
            <td>2021-08-09</td>
          </tr>
          <tr>
            <td>31</td>
            <td>MAERSK ARIZONA</td>
            <td>2020-07-30</td>
            <td>2020-08-14</td>
            <td>Sealand</td>
            <td>031S</td>
            <td>IZMIR - Novorossiysk</td>
            <td>2020-07-29</td>
          </tr>
          <tr>
            <td>30</td>
            <td>ANTWERP</td>
            <td>2021-08-04</td>
            <td>2021-08-22</td>
            <td>Sealand</td>
            <td>131S</td>
            <td>IZMIR - Novorossiysk</td>
            <td>2021-08-02</td>
          </tr>
          <tr>
            <td>30</td>
            <td>ANTWERP</td>
            <td>2020-07-23</td>
            <td>2020-08-17</td>
            <td>Sealand</td>
            <td>030S</td>
            <td>IZMIR - Novorossiysk</td>
            <td>2020-07-20</td>
          </tr>
          <tr>
            <td>28</td>
            <td>ALEXANDER MAERSK </td>
            <td>2021-07-28</td>
            <td>2021-08-15</td>
            <td>Sealand</td>
            <td>130S</td>
            <td>IZMIR - Novorossiysk</td>
            <td>2021-07-13</td>
          </tr>
          <tr>
            <td>28</td>
            <td>ANTWERP</td>
            <td>2020-07-09</td>
            <td>2020-07-30</td>
            <td>Sealand</td>
            <td>028S</td>
            <td>IZMIR - Novorossiysk</td>
            <td>2020-07-06</td>
          </tr>
          <tr>
            <td>27</td>
            <td>ANTWERP</td>
            <td>2021-07-21</td>
            <td>2021-08-08</td>
            <td>Sealand</td>
            <td>127S</td>
            <td>IZMIR - Novorossiysk</td>
            <td>2021-07-12</td>
          </tr>
          <tr>
            <td>27</td>
            <td>MAERSK ARIZONA</td>
            <td>2020-07-07</td>
            <td>2020-06-25</td>
            <td>Sealand</td>
            <td>027S</td>
            <td>IZMIR - Novorossiysk</td>
            <td>2020-07-02</td>
          </tr>
          <tr>
            <td>26</td>
            <td>ALEXANDER MAERSK </td>
            <td>2021-07-13</td>
            <td>2021-07-31</td>
            <td>Sealand</td>
            <td>128S</td>
            <td>IZMIR - Novorossiysk</td>
            <td>2021-07-09</td>
          </tr>
          <tr>
            <td>26</td>
            <td>MAERSK ARIZONA</td>
            <td>2020-06-25</td>
            <td>2020-07-12</td>
            <td>Sealand</td>
            <td>026S</td>
            <td>IZMIR - Novorossiysk</td>
            <td>2020-06-22</td>
          </tr>
          <tr>
            <td>25</td>
            <td>ALEXANDER MAERSK </td>
            <td>2021-07-06</td>
            <td>2021-07-24</td>
            <td>Sealand</td>
            <td>127S</td>
            <td>IZMIR - Novorossiysk</td>
            <td>2021-07-02</td>
          </tr>
          <tr>
            <td>25</td>
            <td>MAERSK ARIZONA</td>
            <td>2020-06-18</td>
            <td>2020-07-07</td>
            <td>Sealand</td>
            <td>025S</td>
            <td>IZMIR - Novorossiysk</td>
            <td>2020-06-15</td>
          </tr>
          <tr>
            <td>24</td>
            <td>ALEXANDER MAERSK </td>
            <td>2021-06-29</td>
            <td>2021-07-17</td>
            <td>Sealand</td>
            <td>126S</td>
            <td>IZMIR - Novorossiysk</td>
            <td>2021-06-25</td>
          </tr>
          <tr>
            <td>24</td>
            <td>ANTWERP</td>
            <td>2020-06-11</td>
            <td>2020-07-07</td>
            <td>Sealand</td>
            <td>024S</td>
            <td>IZMIR - Novorossiysk</td>
            <td>2020-06-08</td>
          </tr>
          <tr>
            <td>23</td>
            <td>ALEXANDER MAERSK </td>
            <td>2021-06-22</td>
            <td>2021-07-11</td>
            <td>Sealand</td>
            <td>125S</td>
            <td>IZMIR - Novorossiysk</td>
            <td>2021-06-18</td>
          </tr>
          <tr>
            <td>23</td>
            <td>PACUL </td>
            <td>2020-06-09</td>
            <td>2020-06-13</td>
            <td>Sealand</td>
            <td>024W</td>
            <td>IZMIR - Novorossiysk</td>
            <td>2020-06-04</td>
          </tr>
          <tr>
            <td>22</td>
            <td>ALEXANDER MAERSK </td>
            <td>2021-06-15</td>
            <td>2021-07-03</td>
            <td>Sealand</td>
            <td>124S</td>
            <td>IZMIR - Novorossiysk</td>
            <td>2021-06-11</td>
          </tr>
          <tr>
            <td>22</td>
            <td>ASIATIC ORCHID </td>
            <td>2020-06-01</td>
            <td>2020-06-19</td>
            <td>Sealand</td>
            <td>023W</td>
            <td>IZMIR - Novorossiysk</td>
            <td>2020-05-28</td>
          </tr>
          <tr>
            <td>21</td>
            <td>ALEXANDER MAERSK </td>
            <td>2021-06-08</td>
            <td>2021-06-25</td>
            <td>Sealand</td>
            <td>122S</td>
            <td>IZMIR - Novorossiysk</td>
            <td>2021-06-04</td>
          </tr>
          <tr>
            <td>21</td>
            <td>PACUL </td>
            <td>2020-05-25</td>
            <td>2020-06-12</td>
            <td>Sealand</td>
            <td>022W</td>
            <td>IZMIR - Novorossiysk</td>
            <td>2020-05-21</td>
          </tr>
          <tr>
            <td>20</td>
            <td>ALEXANDER MAERSK </td>
            <td>2021-06-01</td>
            <td>2021-06-20</td>
            <td>Sealand</td>
            <td>122S</td>
            <td>IZMIR - Novorossiysk</td>
            <td>2021-05-28</td>
          </tr>
          <tr>
            <td>20</td>
            <td>ASIATIC ORCHID </td>
            <td>2020-05-18</td>
            <td>2020-06-05</td>
            <td>Sealand</td>
            <td>021W</td>
            <td>IZMIR - Novorossiysk</td>
            <td>2020-05-14</td>
          </tr>
          <tr>
            <td>19</td>
            <td>ALEXANDER MAERSK </td>
            <td>2021-05-25</td>
            <td>2021-06-13</td>
            <td>Sealand</td>
            <td>121S</td>
            <td>IZMIR - Novorossiysk</td>
            <td>2021-05-21</td>
          </tr>
          <tr>
            <td>19</td>
            <td>PACUL </td>
            <td>2020-05-10</td>
            <td>2020-05-28</td>
            <td>Sealand</td>
            <td>020W</td>
            <td>IZMIR - Novorossiysk</td>
            <td>2020-05-07</td>
          </tr>
          <tr>
            <td>18</td>
            <td>ALEXANDER MAERSK </td>
            <td>2021-05-18</td>
            <td>2021-06-06</td>
            <td>Sealand</td>
            <td>120S</td>
            <td>IZMIR - Novorossiysk</td>
            <td>2021-05-10</td>
          </tr>
          <tr>
            <td>18</td>
            <td>ASIATIC ORCHID </td>
            <td>2020-05-01</td>
            <td>2020-05-19</td>
            <td>Sealand</td>
            <td>019W</td>
            <td>IZMIR - Novorossiysk</td>
            <td>2020-04-30</td>
          </tr>
          <tr>
            <td>18</td>
            <td>ASIATIC ORCHID </td>
            <td>2020-05-01</td>
            <td>2020-05-19</td>
            <td>Sealand</td>
            <td>019W</td>
            <td>IZMIR - Novorossiysk</td>
            <td>2020-04-30</td>
          </tr>
          <tr>
            <td>17</td>
            <td>ALEXANDRA A</td>
            <td>2021-04-11</td>
            <td>2021-04-26</td>
            <td>Sealand</td>
            <td>119S</td>
            <td>IZMIR - Novorossiysk</td>
            <td>2021-04-07</td>
          </tr>
          <tr>
            <td>17</td>
            <td>PACUL </td>
            <td>2020-04-28</td>
            <td>2020-05-15</td>
            <td>Sealand</td>
            <td>018W</td>
            <td>IZMIR - Novorossiysk</td>
            <td>2020-04-23</td>
          </tr>
          <tr>
            <td>16</td>
            <td>ALEXANDER MAERSK </td>
            <td>2021-05-04</td>
            <td>2021-05-23</td>
            <td>Sealand</td>
            <td>118E</td>
            <td>IZMIR - Novorossiysk</td>
            <td>2021-04-30</td>
          </tr>
          <tr>
            <td>16</td>
            <td>ASIATIC ORCHID </td>
            <td>2020-04-20</td>
            <td>2020-05-05</td>
            <td>Sealand</td>
            <td>017W</td>
            <td>IZMIR - Novorossiysk</td>
            <td>2020-04-16</td>
          </tr>
          <tr>
            <td>15</td>
            <td>ALEXANDER MAERSK </td>
            <td>2021-04-27</td>
            <td>2021-05-16</td>
            <td>Sealand</td>
            <td>117S</td>
            <td>IZMIR - Novorossiysk</td>
            <td>2021-04-22</td>
          </tr>
          <tr>
            <td>15</td>
            <td>PACUL </td>
            <td>2020-04-14</td>
            <td>2020-04-27</td>
            <td>Sealand</td>
            <td>016W</td>
            <td>IZMIR - Novorossiysk</td>
            <td>2020-04-09</td>
          </tr>
          <tr>
            <td>14</td>
            <td>ALEXANDER MAERSK </td>
            <td>2021-04-15</td>
            <td>2021-05-04</td>
            <td>Sealand</td>
            <td>115S</td>
            <td>IZMIR - Novorossiysk</td>
            <td>2021-04-12</td>
          </tr>
          <tr>
            <td>14</td>
            <td>ASIATIC ORCHID </td>
            <td>2020-04-07</td>
            <td>2020-04-27</td>
            <td>Sealand</td>
            <td>015W</td>
            <td>IZMIR - Novorossiysk</td>
            <td>2020-04-02</td>
          </tr>
          <tr>
            <td>13</td>
            <td>ALEXANDER MAERSK </td>
            <td>2021-03-31</td>
            <td>2021-04-19</td>
            <td>Sealand</td>
            <td>113S</td>
            <td>IZMIR - Novorossiysk</td>
            <td>2021-03-29</td>
          </tr>
          <tr>
            <td>13</td>
            <td>PACUL </td>
            <td>2020-04-01</td>
            <td>2020-03-12</td>
            <td>Sealand</td>
            <td>014E</td>
            <td>IZMIR - Novorossiysk</td>
            <td>2020-03-26</td>
          </tr>
          <tr>
            <td>12</td>
            <td>ANTWERP</td>
            <td>2021-03-24</td>
            <td>2021-04-11</td>
            <td>Sealand</td>
            <td>111S</td>
            <td>IZMIR - Novorossiysk</td>
            <td>2021-03-22</td>
          </tr>
          <tr>
            <td>12</td>
            <td>ASIATIC ORCHID </td>
            <td>2020-03-23</td>
            <td>2020-04-14</td>
            <td>Sealand</td>
            <td>013W</td>
            <td>IZMIR - Novorossiysk</td>
            <td>2020-03-19</td>
          </tr>
          <tr>
            <td>11</td>
            <td>ANTWERP</td>
            <td>2021-03-24</td>
            <td>2021-04-14</td>
            <td>SEAGO</td>
            <td>111S</td>
            <td>IZMIR - Novorossiysk</td>
            <td>2021-03-22</td>
          </tr>
          <tr>
            <td>11</td>
            <td>PACUL </td>
            <td>2020-03-17</td>
            <td>2020-03-21</td>
            <td>Sealand</td>
            <td>012W</td>
            <td>IZMIR - Novorossiysk</td>
            <td>2020-03-12</td>
          </tr>
          <tr>
            <td>10</td>
            <td>ALEXANDER MAERSK </td>
            <td>2021-03-17</td>
            <td>2021-04-05</td>
            <td>Sealand</td>
            <td>111N</td>
            <td>IZMIR - Novorossiysk</td>
            <td>2021-03-15</td>
          </tr>
          <tr>
            <td>10</td>
            <td>PACUL </td>
            <td>2020-03-04</td>
            <td>2020-03-25</td>
            <td>Sealand</td>
            <td>010W</td>
            <td>IZMIR - Novorossiysk</td>
            <td>2020-02-27</td>
          </tr>
          <tr>
            <td>9</td>
            <td>ANTWERP</td>
            <td>2021-03-11</td>
            <td>2021-03-30</td>
            <td>Sealand</td>
            <td>110S</td>
            <td>IZMIR - Novorossiysk</td>
            <td>2021-03-08</td>
          </tr>
          <tr>
            <td>9</td>
            <td>PACUL </td>
            <td>2020-03-02</td>
            <td>2020-03-06</td>
            <td>Sealand</td>
            <td>010W</td>
            <td>IZMIR - Novorossiysk</td>
            <td>2020-02-27</td>
          </tr>
          <tr>
            <td>3</td>
            <td>MAERSK ARIZONA</td>
            <td>2021-01-28</td>
            <td>2021-02-11</td>
            <td>Sealand</td>
            <td>104S</td>
            <td>IZMIR - Novorossiysk</td>
            <td>2021-01-25</td>
          </tr>
          <tr>
            <td>2</td>
            <td>ANTWERP</td>
            <td>2021-01-20</td>
            <td>2021-01-31</td>
            <td>Sealand</td>
            <td>103S</td>
            <td>IZMIR - Novorossiysk</td>
            <td>2021-01-18</td>
          </tr>
          <tr>
            <td>1</td>
            <td>MAERSK ARIZONA</td>
            <td>2021-01-13</td>
            <td>2021-01-31</td>
            <td>Sealand</td>
            <td>102S</td>
            <td>IZMIR - Novorossiysk</td>
            <td>2021-01-11</td>
          </tr>
          <tr>
            <td>1</td>
            <td>ANTWERP</td>
            <td>2021-01-08</td>
            <td>2021-01-23</td>
            <td>Sealand</td>
            <td>101S</td>
            <td>IZMIR - Novorossiysk</td>
            <td>2021-01-04</td>
          </tr>
          <tr>
            <td>52</td>
            <td>ANTWERP</td>
            <td>2020-12-24</td>
            <td>2021-01-10</td>
            <td>Sealand</td>
            <td>053S</td>
            <td>IZMIR - Novorossiysk</td>
            <td>2020-12-21</td>
          </tr>
          <tr>
            <td>34</td>
            <td>ANTWERP</td>
            <td>2020-08-20</td>
            <td>2020-09-08</td>
            <td>Sealand</td>
            <td>034S</td>
            <td>IZMIR - Novorossiysk</td>
            <td>2020-08-17</td>
          </tr>
          <tr>
            <td>29</td>
            <td>MAERSK ARIZONA</td>
            <td>2020-07-16</td>
            <td>2020-08-03</td>
            <td>Sealand</td>
            <td>029S</td>
            <td>IZMIR - Novorossiysk</td>
            <td>2020-07-13</td>
          </tr>
        </tbody>
      </table>
    ),
  },
  {
    id: 9,
    itemText: "IZMIR - ODESSA",
    itemValue: (
      <table class="vessel-table">
        <thead>
          <tr>
            <th>Week</th>
            <th>Ship</th>
            <th>ETD</th>
            <th>ETA</th>
            <th>Line</th>
            <th>Voyage</th>
            <th>VesselCategories</th>
            <th>CutOff</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>21</td>
            <td>PAOLA</td>
            <td>2020-05-21</td>
            <td>2020-05-26</td>
            <td>MARTI</td>
            <td>20-226</td>
            <td>IZMIR - ODESSA</td>
            <td>2020-05-20</td>
          </tr>
          <tr>
            <td>19</td>
            <td>PAOLA</td>
            <td>2020-05-08</td>
            <td>2020-05-12</td>
            <td>MARTI</td>
            <td>20-225</td>
            <td>IZMIR - ODESSA</td>
            <td>2020-05-06</td>
          </tr>
          <tr>
            <td>18</td>
            <td>PAAVA </td>
            <td>2020-04-30</td>
            <td>2020-05-04</td>
            <td>MARTI</td>
            <td>20-125</td>
            <td>IZMIR - ODESSA</td>
            <td>2020-04-29</td>
          </tr>
          <tr>
            <td>17</td>
            <td>PAOLA</td>
            <td>2020-04-25</td>
            <td>2020-04-29</td>
            <td>MARTI</td>
            <td>20-124</td>
            <td>IZMIR - ODESSA</td>
            <td>2020-04-23</td>
          </tr>
          <tr>
            <td>16</td>
            <td>PAAVA </td>
            <td>2020-04-16</td>
            <td>2020-04-21</td>
            <td>MARTI</td>
            <td>20-125</td>
            <td>IZMIR - ODESSA</td>
            <td>2020-04-17</td>
          </tr>
          <tr>
            <td>15</td>
            <td>PAAVA </td>
            <td>2020-04-08</td>
            <td>2020-04-13</td>
            <td>MARTI</td>
            <td> 20-124</td>
            <td>IZMIR - ODESSA</td>
            <td>2020-04-09</td>
          </tr>
          <tr>
            <td>14</td>
            <td>PAOLA</td>
            <td>2020-04-03</td>
            <td>2020-04-07</td>
            <td>MARTI</td>
            <td>20-223</td>
            <td>IZMIR - ODESSA</td>
            <td>2020-04-02</td>
          </tr>
          <tr>
            <td>13</td>
            <td>PACOBA</td>
            <td>2020-03-25</td>
            <td>2020-04-02</td>
            <td>MARTI</td>
            <td>20-304</td>
            <td>IZMIR - ODESSA</td>
            <td>2020-03-26</td>
          </tr>
          <tr>
            <td>12</td>
            <td>PAAVA </td>
            <td>2020-03-20</td>
            <td>2020-03-24</td>
            <td>MARTI</td>
            <td> 20/123</td>
            <td>IZMIR - ODESSA</td>
            <td>2020-03-18</td>
          </tr>
          <tr>
            <td>11</td>
            <td>PAOLA</td>
            <td>2020-03-12</td>
            <td>2020-03-15</td>
            <td>MARTI</td>
            <td>20-222</td>
            <td>IZMIR - ODESSA</td>
            <td>2020-03-11</td>
          </tr>
          <tr>
            <td>9</td>
            <td>PAAVA </td>
            <td>2020-02-27</td>
            <td>2020-03-03</td>
            <td>MARTI</td>
            <td>20-122</td>
            <td>IZMIR - ODESSA</td>
            <td>2020-02-26</td>
          </tr>
          <tr>
            <td>7</td>
            <td>MEHMET KAHVECI A</td>
            <td>2020-02-09</td>
            <td>2020-02-11</td>
            <td>ARKAS</td>
            <td>A LTS05S20</td>
            <td>IZMIR - ODESSA</td>
            <td>2020-02-06</td>
          </tr>
          <tr>
            <td>7</td>
            <td>PACOBA</td>
            <td>2020-02-13</td>
            <td>2020-02-16</td>
            <td>MARTI</td>
            <td> 20/302 </td>
            <td>IZMIR - ODESSA</td>
            <td>2020-02-12</td>
          </tr>
          <tr>
            <td>6</td>
            <td>PAAVA </td>
            <td>2020-02-06</td>
            <td>2020-02-09</td>
            <td>MARTI</td>
            <td>20/121</td>
            <td>IZMIR - ODESSA</td>
            <td>2020-02-05</td>
          </tr>
        </tbody>
      </table>
    ),
  },
  {
    id: 10,
    itemText: "IZMIR - PIVDENNYI",
    itemValue: (
      <table class="vessel-table">
        <thead>
          <tr>
            <th>Week</th>
            <th>Ship</th>
            <th>ETD</th>
            <th>ETA</th>
            <th>Line</th>
            <th>Voyage</th>
            <th>VesselCategories</th>
            <th>CutOff</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>8</td>
            <td>NEXOE MAERSK</td>
            <td>2022-03-05</td>
            <td>2022-03-21</td>
            <td>Sealand</td>
            <td>209S</td>
            <td>IZMIR - PIVDENNYI</td>
            <td>2022-03-02</td>
          </tr>
          <tr>
            <td>8</td>
            <td>ALEXANDER MAERSK </td>
            <td>2021-03-03</td>
            <td>2021-03-21</td>
            <td>Sealand</td>
            <td>109S</td>
            <td>IZMIR - PIVDENNYI</td>
            <td>2021-03-01</td>
          </tr>
          <tr>
            <td>8</td>
            <td>ASIATIC ORCHID </td>
            <td>2020-02-24</td>
            <td>2020-02-28</td>
            <td>Sealand</td>
            <td>009W</td>
            <td>IZMIR - PIVDENNYI</td>
            <td>2020-02-20</td>
          </tr>
          <tr>
            <td>7</td>
            <td>CAPE MALE</td>
            <td>2022-02-26</td>
            <td>2022-03-14</td>
            <td>Sealand</td>
            <td>208S</td>
            <td>IZMIR - PIVDENNYI</td>
            <td>2022-02-23</td>
          </tr>
          <tr>
            <td>7</td>
            <td>ANTWERP</td>
            <td>2021-02-24</td>
            <td>2021-03-14</td>
            <td>Sealand</td>
            <td>108S</td>
            <td>IZMIR - PIVDENNYI</td>
            <td>2021-02-22</td>
          </tr>
          <tr>
            <td>7</td>
            <td>PACUL </td>
            <td>2020-02-17</td>
            <td>2020-02-20</td>
            <td>Sealand</td>
            <td>008W</td>
            <td>IZMIR - PIVDENNYI</td>
            <td>2020-02-13</td>
          </tr>
          <tr>
            <td>6</td>
            <td>CAPE MALE</td>
            <td>2022-02-19</td>
            <td>2022-03-07</td>
            <td>Sealand</td>
            <td>207S</td>
            <td>IZMIR - PIVDENNYI</td>
            <td>2022-02-16</td>
          </tr>
          <tr>
            <td>6</td>
            <td>MAERSK ARIZONA</td>
            <td>2021-02-22</td>
            <td>2021-03-13</td>
            <td>Sealand</td>
            <td>108S</td>
            <td>IZMIR - PIVDENNYI</td>
            <td>2021-02-17</td>
          </tr>
          <tr>
            <td>6</td>
            <td>ASIATIC ORCHID </td>
            <td>2020-02-10</td>
            <td>2020-02-14</td>
            <td>Sealand</td>
            <td>007W</td>
            <td>IZMIR - PIVDENNYI</td>
            <td>2020-02-06</td>
          </tr>
          <tr>
            <td>5</td>
            <td>CAPE MALE</td>
            <td>2022-02-04</td>
            <td>2022-02-20</td>
            <td>Sealand</td>
            <td>205S</td>
            <td>IZMIR - PIVDENNYI</td>
            <td>2022-02-02</td>
          </tr>
          <tr>
            <td>5</td>
            <td>LAURA MAERSK</td>
            <td>2021-02-10</td>
            <td>2021-02-23</td>
            <td>Sealand</td>
            <td>102E</td>
            <td>IZMIR - PIVDENNYI</td>
            <td>2021-02-08</td>
          </tr>
          <tr>
            <td>5</td>
            <td>PACUL </td>
            <td>2020-02-03</td>
            <td>2020-02-07</td>
            <td>Sealand</td>
            <td>006W</td>
            <td>IZMIR - PIVDENNYI</td>
            <td>2020-01-30</td>
          </tr>
          <tr>
            <td>4</td>
            <td>CAPE MALE</td>
            <td>2022-01-28</td>
            <td>2022-02-13</td>
            <td>Sealand</td>
            <td>205S</td>
            <td>IZMIR - PIVDENNYI</td>
            <td>2022-01-26</td>
          </tr>
          <tr>
            <td>4</td>
            <td>ANTWERP</td>
            <td>2021-02-04</td>
            <td>2021-02-23</td>
            <td>Sealand</td>
            <td>105S</td>
            <td>IZMIR - PIVDENNYI</td>
            <td>2021-02-01</td>
          </tr>
          <tr>
            <td>51</td>
            <td>CAPE MALE</td>
            <td>2021-12-31</td>
            <td>2022-01-17</td>
            <td>Sealand</td>
            <td>152S</td>
            <td>IZMIR - PIVDENNYI</td>
            <td>2021-12-28</td>
          </tr>
          <tr>
            <td>50</td>
            <td>CAPE MALE</td>
            <td>2021-12-25</td>
            <td>2022-01-12</td>
            <td>Sealand</td>
            <td>151S</td>
            <td>IZMIR - PIVDENNYI</td>
            <td>2021-12-22</td>
          </tr>
          <tr>
            <td>49</td>
            <td>AS CAMELLIA</td>
            <td>2021-12-18</td>
            <td>2022-01-05</td>
            <td>Sealand</td>
            <td>150N</td>
            <td>IZMIR - PIVDENNYI</td>
            <td>2021-12-15</td>
          </tr>
          <tr>
            <td>48</td>
            <td>AS CAMELLIA</td>
            <td>2021-12-11</td>
            <td>2021-12-29</td>
            <td>Sealand</td>
            <td>149S</td>
            <td>IZMIR - PIVDENNYI</td>
            <td>2021-12-08</td>
          </tr>
          <tr>
            <td>47</td>
            <td>AS CAMELLIA</td>
            <td>2021-12-04</td>
            <td>2021-12-22</td>
            <td>Sealand</td>
            <td>148S</td>
            <td>IZMIR - PIVDENNYI</td>
            <td>2021-12-01</td>
          </tr>
          <tr>
            <td>46</td>
            <td>AS CAMELLIA</td>
            <td>2021-11-27</td>
            <td>2021-12-15</td>
            <td>Sealand</td>
            <td>147S</td>
            <td>IZMIR - PIVDENNYI</td>
            <td>2021-11-24</td>
          </tr>
          <tr>
            <td>45</td>
            <td>AS CAMELLIA</td>
            <td>2021-11-19</td>
            <td>2021-12-07</td>
            <td>Sealand</td>
            <td>146S</td>
            <td>IZMIR - PIVDENNYI</td>
            <td>2021-11-17</td>
          </tr>
          <tr>
            <td>44</td>
            <td>AS CAMELLIA</td>
            <td>2021-11-12</td>
            <td>2021-11-30</td>
            <td>Sealand</td>
            <td>145S</td>
            <td>IZMIR - PIVDENNYI</td>
            <td>2021-11-10</td>
          </tr>
          <tr>
            <td>43</td>
            <td>AS CAMELLIA</td>
            <td>2021-11-06</td>
            <td>2021-11-23</td>
            <td>Sealand</td>
            <td>144S</td>
            <td>IZMIR - PIVDENNYI</td>
            <td>2021-11-03</td>
          </tr>
          <tr>
            <td>42</td>
            <td>AS CAMELLIA</td>
            <td>2021-10-29</td>
            <td>2021-11-17</td>
            <td>Sealand</td>
            <td>143S</td>
            <td>IZMIR - PIVDENNYI</td>
            <td>2021-10-26</td>
          </tr>
          <tr>
            <td>41</td>
            <td>AS CAMELLIA</td>
            <td>2021-10-22</td>
            <td>2021-11-07</td>
            <td>Sealand</td>
            <td>142S</td>
            <td>IZMIR - PIVDENNYI</td>
            <td>2021-10-20</td>
          </tr>
          <tr>
            <td>40</td>
            <td>AS CAMELLIA</td>
            <td>2021-10-16</td>
            <td>2021-10-18</td>
            <td>Sealand</td>
            <td>141S</td>
            <td>IZMIR - PIVDENNYI</td>
            <td>2021-10-13</td>
          </tr>
          <tr>
            <td>39</td>
            <td>AS CAMELLIA</td>
            <td>2021-10-08</td>
            <td>2021-10-24</td>
            <td>Sealand</td>
            <td>140S</td>
            <td>IZMIR - PIVDENNYI</td>
            <td>2021-10-06</td>
          </tr>
          <tr>
            <td>39</td>
            <td>MAERSK ARIZONA</td>
            <td>2020-09-23</td>
            <td>2020-10-08</td>
            <td>Sealand</td>
            <td>038S</td>
            <td>IZMIR - PIVDENNYI</td>
            <td>2020-09-21</td>
          </tr>
          <tr>
            <td>38</td>
            <td>AS CAMELLIA</td>
            <td>2021-10-01</td>
            <td>2021-09-18</td>
            <td>Sealand</td>
            <td>139S</td>
            <td>IZMIR - PIVDENNYI</td>
            <td>2021-09-29</td>
          </tr>
          <tr>
            <td>38</td>
            <td>ANTWERP</td>
            <td>2020-09-16</td>
            <td>2020-10-03</td>
            <td>Sealand</td>
            <td>038S</td>
            <td>IZMIR - PIVDENNYI</td>
            <td>2020-09-14</td>
          </tr>
          <tr>
            <td>37</td>
            <td>AS CAMELLIA</td>
            <td>2021-09-24</td>
            <td>2021-10-12</td>
            <td>Sealand</td>
            <td>138S</td>
            <td>IZMIR - PIVDENNYI</td>
            <td>2021-09-22</td>
          </tr>
          <tr>
            <td>37</td>
            <td>MAERSK ARIZONA</td>
            <td>2020-09-09</td>
            <td>2020-09-28</td>
            <td>Sealand</td>
            <td>037S</td>
            <td>IZMIR - PIVDENNYI</td>
            <td>2020-09-07</td>
          </tr>
          <tr>
            <td>36</td>
            <td>AS CAMELLIA</td>
            <td>2021-09-17</td>
            <td>2021-10-05</td>
            <td>Sealand</td>
            <td>137S</td>
            <td>IZMIR - PIVDENNYI</td>
            <td>2021-09-15</td>
          </tr>
          <tr>
            <td>36</td>
            <td>ANTWERP</td>
            <td>2020-09-02</td>
            <td>2020-09-24</td>
            <td>Sealand</td>
            <td>036S</td>
            <td>IZMIR - PIVDENNYI</td>
            <td>2020-08-31</td>
          </tr>
          <tr>
            <td>35</td>
            <td>SAFMARINE NAKURU</td>
            <td>2021-09-12</td>
            <td>2021-09-30</td>
            <td>Sealand</td>
            <td>137S</td>
            <td>IZMIR - PIVDENNYI</td>
            <td>2021-09-09</td>
          </tr>
          <tr>
            <td>35</td>
            <td>MAERSK ARIZONA</td>
            <td>2020-08-27</td>
            <td>2020-09-14</td>
            <td>Sealand</td>
            <td>035S</td>
            <td>IZMIR - PIVDENNYI</td>
            <td>2020-08-24</td>
          </tr>
          <tr>
            <td>33</td>
            <td>ALEXANDER MAERSK </td>
            <td>2021-08-26</td>
            <td>2021-09-13</td>
            <td>Sealand</td>
            <td>134S</td>
            <td>IZMIR - PIVDENNYI</td>
            <td>2021-08-23</td>
          </tr>
          <tr>
            <td>33</td>
            <td>MAERSK ARIZONA</td>
            <td>2020-08-13</td>
            <td>2020-08-17</td>
            <td>Sealand</td>
            <td>033S</td>
            <td>IZMIR - PIVDENNYI</td>
            <td>2020-08-10</td>
          </tr>
          <tr>
            <td>32</td>
            <td>ANTWERP</td>
            <td>2021-08-18</td>
            <td>2021-08-24</td>
            <td>Sealand</td>
            <td>133S</td>
            <td>IZMIR - PIVDENNYI</td>
            <td>2021-08-16</td>
          </tr>
          <tr>
            <td>31</td>
            <td>ALEXANDER MAERSK </td>
            <td>2021-08-12</td>
            <td>2021-08-31</td>
            <td>Sealand</td>
            <td>132S</td>
            <td>IZMIR - PIVDENNYI</td>
            <td>2021-08-09</td>
          </tr>
          <tr>
            <td>31</td>
            <td>MAERSK ARIZONA</td>
            <td>2020-07-30</td>
            <td>2020-08-14</td>
            <td>Sealand</td>
            <td>031S</td>
            <td>IZMIR - PIVDENNYI</td>
            <td>2020-07-27</td>
          </tr>
          <tr>
            <td>30</td>
            <td>ANTWERP</td>
            <td>2021-08-04</td>
            <td>2021-08-22</td>
            <td>Sealand</td>
            <td>131S</td>
            <td>IZMIR - PIVDENNYI</td>
            <td>2021-08-02</td>
          </tr>
          <tr>
            <td>30</td>
            <td>ANTWERP</td>
            <td>2020-07-23</td>
            <td>2020-08-10</td>
            <td>Sealand</td>
            <td>030S</td>
            <td>IZMIR - PIVDENNYI</td>
            <td>2020-07-20</td>
          </tr>
          <tr>
            <td>28</td>
            <td>ALEXANDER MAERSK </td>
            <td>2021-07-28</td>
            <td>2021-08-15</td>
            <td>Sealand</td>
            <td>130S</td>
            <td>IZMIR - PIVDENNYI</td>
            <td>2021-07-13</td>
          </tr>
          <tr>
            <td>28</td>
            <td>ANTWERP</td>
            <td>2020-07-09</td>
            <td>2020-07-30</td>
            <td>Sealand</td>
            <td>028S</td>
            <td>IZMIR - PIVDENNYI</td>
            <td>2020-07-06</td>
          </tr>
          <tr>
            <td>27</td>
            <td>ANTWERP</td>
            <td>2021-07-21</td>
            <td>2021-08-08</td>
            <td>Sealand</td>
            <td>129S</td>
            <td>IZMIR - PIVDENNYI</td>
            <td>2021-07-12</td>
          </tr>
          <tr>
            <td>27</td>
            <td>MAERSK ARIZONA</td>
            <td>2020-07-07</td>
            <td>2020-07-25</td>
            <td>Sealand</td>
            <td>027S</td>
            <td>IZMIR - PIVDENNYI</td>
            <td>2020-07-02</td>
          </tr>
          <tr>
            <td>26</td>
            <td>ALEXANDER MAERSK </td>
            <td>2021-07-13</td>
            <td>2021-07-31</td>
            <td>Sealand</td>
            <td>128S</td>
            <td>IZMIR - PIVDENNYI</td>
            <td>2021-07-09</td>
          </tr>
          <tr>
            <td>26</td>
            <td>ANTWERP</td>
            <td>2020-06-25</td>
            <td>2020-07-12</td>
            <td>Sealand</td>
            <td>026S</td>
            <td>IZMIR - PIVDENNYI</td>
            <td>2020-06-22</td>
          </tr>
          <tr>
            <td>25</td>
            <td>ALEXANDER MAERSK </td>
            <td>2021-07-06</td>
            <td>2021-07-24</td>
            <td>Sealand</td>
            <td>127S</td>
            <td>IZMIR - PIVDENNYI</td>
            <td>2021-07-02</td>
          </tr>
          <tr>
            <td>25</td>
            <td>MAERSK ARIZONA</td>
            <td>2020-06-18</td>
            <td>2020-07-07</td>
            <td>Sealand</td>
            <td>025S</td>
            <td>IZMIR - PIVDENNYI</td>
            <td>2020-06-15</td>
          </tr>
          <tr>
            <td>24</td>
            <td>ALEXANDER MAERSK </td>
            <td>2021-06-29</td>
            <td>2021-07-17</td>
            <td>Sealand</td>
            <td>126S</td>
            <td>IZMIR - PIVDENNYI</td>
            <td>2021-06-25</td>
          </tr>
          <tr>
            <td>24</td>
            <td>ANTWERP</td>
            <td>2020-06-11</td>
            <td>2020-07-07</td>
            <td>Sealand</td>
            <td>024S</td>
            <td>IZMIR - PIVDENNYI</td>
            <td>2020-06-08</td>
          </tr>
          <tr>
            <td>23</td>
            <td>ALEXANDER MAERSK </td>
            <td>2021-06-22</td>
            <td>2021-07-11</td>
            <td>Sealand</td>
            <td>125S</td>
            <td>IZMIR - PIVDENNYI</td>
            <td>2021-06-18</td>
          </tr>
          <tr>
            <td>23</td>
            <td>PACUL </td>
            <td>2020-06-09</td>
            <td>2020-06-13</td>
            <td>Sealand</td>
            <td>024W</td>
            <td>IZMIR - PIVDENNYI</td>
            <td>2020-06-04</td>
          </tr>
          <tr>
            <td>22</td>
            <td>ALEXANDER MAERSK </td>
            <td>2021-06-15</td>
            <td>2021-07-03</td>
            <td>Sealand</td>
            <td>123S</td>
            <td>IZMIR - PIVDENNYI</td>
            <td>2021-06-11</td>
          </tr>
          <tr>
            <td>22</td>
            <td>ASIATIC ORCHID </td>
            <td>2020-06-01</td>
            <td>2020-06-19</td>
            <td>Sealand</td>
            <td>023W</td>
            <td>IZMIR - PIVDENNYI</td>
            <td>2020-05-28</td>
          </tr>
          <tr>
            <td>21</td>
            <td>ALEXANDER MAERSK </td>
            <td>2021-06-08</td>
            <td>2021-06-25</td>
            <td>Sealand</td>
            <td>123S</td>
            <td>IZMIR - PIVDENNYI</td>
            <td>2021-06-04</td>
          </tr>
          <tr>
            <td>21</td>
            <td>PACUL </td>
            <td>2020-05-25</td>
            <td>2020-06-12</td>
            <td>Sealand</td>
            <td>022W</td>
            <td>IZMIR - PIVDENNYI</td>
            <td>2020-05-21</td>
          </tr>
          <tr>
            <td>20</td>
            <td>ALEXANDER MAERSK </td>
            <td>2021-06-01</td>
            <td>2021-06-20</td>
            <td>Sealand</td>
            <td>122S</td>
            <td>IZMIR - PIVDENNYI</td>
            <td>2021-06-28</td>
          </tr>
          <tr>
            <td>20</td>
            <td>ASIATIC ORCHID </td>
            <td>2020-05-18</td>
            <td>2020-06-05</td>
            <td>Sealand</td>
            <td>021W</td>
            <td>IZMIR - PIVDENNYI</td>
            <td>2020-05-14</td>
          </tr>
          <tr>
            <td>19</td>
            <td>ALEXANDER MAERSK </td>
            <td>2021-05-18</td>
            <td>2021-05-25</td>
            <td>Sealand</td>
            <td>120S</td>
            <td>IZMIR - PIVDENNYI</td>
            <td>2021-05-10</td>
          </tr>
          <tr>
            <td>19</td>
            <td>PACUL </td>
            <td>2020-05-10</td>
            <td>2020-05-28</td>
            <td>Sealand</td>
            <td>020W</td>
            <td>IZMIR - PIVDENNYI</td>
            <td>2020-05-07</td>
          </tr>
          <tr>
            <td>18</td>
            <td>ALEXANDER MAERSK </td>
            <td>2021-05-18</td>
            <td>2021-06-06</td>
            <td>Sealand</td>
            <td>120S</td>
            <td>IZMIR - PIVDENNYI</td>
            <td>2021-05-10</td>
          </tr>
          <tr>
            <td>18</td>
            <td>ASIATIC ORCHID </td>
            <td>2020-05-01</td>
            <td>2020-05-19</td>
            <td>Sealand</td>
            <td>019W</td>
            <td>IZMIR - PIVDENNYI</td>
            <td>2020-04-30</td>
          </tr>
          <tr>
            <td>17</td>
            <td>ALEXANDER MAERSK </td>
            <td>2021-05-11</td>
            <td>2021-05-30</td>
            <td>Sealand</td>
            <td>119S</td>
            <td>IZMIR - PIVDENNYI</td>
            <td>2021-05-07</td>
          </tr>
          <tr>
            <td>17</td>
            <td>PACUL </td>
            <td>2020-04-28</td>
            <td>2020-05-16</td>
            <td>Sealand</td>
            <td>018W</td>
            <td>IZMIR - PIVDENNYI</td>
            <td>2020-04-23</td>
          </tr>
          <tr>
            <td>16</td>
            <td>ALEXANDRA A</td>
            <td>2021-05-04</td>
            <td>2021-04-23</td>
            <td>Sealand</td>
            <td>118S</td>
            <td>IZMIR - PIVDENNYI</td>
            <td>2021-04-30</td>
          </tr>
          <tr>
            <td>16</td>
            <td>ASIATIC ORCHID </td>
            <td>2020-04-20</td>
            <td>2020-05-05</td>
            <td>Sealand</td>
            <td>017W</td>
            <td>IZMIR - PIVDENNYI</td>
            <td>2020-04-16</td>
          </tr>
          <tr>
            <td>15</td>
            <td>ALEXANDER MAERSK </td>
            <td>2021-04-27</td>
            <td>2021-05-16</td>
            <td>Sealand</td>
            <td>117S</td>
            <td>IZMIR - PIVDENNYI</td>
            <td>2021-04-22</td>
          </tr>
          <tr>
            <td>15</td>
            <td>PACUL </td>
            <td>2020-04-14</td>
            <td>2020-04-27</td>
            <td>Sealand</td>
            <td>016W</td>
            <td>IZMIR - PIVDENNYI</td>
            <td>2020-04-09</td>
          </tr>
          <tr>
            <td>14</td>
            <td>ALEXANDER MAERSK </td>
            <td>2021-04-15</td>
            <td>2021-05-04</td>
            <td>Sealand</td>
            <td>115S</td>
            <td>IZMIR - PIVDENNYI</td>
            <td>2021-04-12</td>
          </tr>
          <tr>
            <td>14</td>
            <td>ASIATIC ORCHID </td>
            <td>2020-04-07</td>
            <td>2020-04-27</td>
            <td>Sealand</td>
            <td>015W</td>
            <td>IZMIR - PIVDENNYI</td>
            <td>2020-04-02</td>
          </tr>
          <tr>
            <td>13</td>
            <td>ALEXANDER MAERSK </td>
            <td>2021-03-31</td>
            <td>2021-04-19</td>
            <td>Sealand</td>
            <td>113E</td>
            <td>IZMIR - PIVDENNYI</td>
            <td>2021-03-29</td>
          </tr>
          <tr>
            <td>13</td>
            <td>PACUL </td>
            <td>2020-04-01</td>
            <td>2020-03-12</td>
            <td>Sealand</td>
            <td>014E</td>
            <td>IZMIR - PIVDENNYI</td>
            <td>2020-03-26</td>
          </tr>
          <tr>
            <td>12</td>
            <td>ANTWERP</td>
            <td>2021-03-24</td>
            <td>2021-04-11</td>
            <td>Sealand</td>
            <td>111S</td>
            <td>IZMIR - PIVDENNYI</td>
            <td>2021-03-22</td>
          </tr>
          <tr>
            <td>12</td>
            <td>ASIATIC ORCHID </td>
            <td>2020-03-23</td>
            <td>2020-04-13</td>
            <td>Sealand</td>
            <td>013W</td>
            <td>IZMIR - PIVDENNYI</td>
            <td>2020-03-19</td>
          </tr>
          <tr>
            <td>11</td>
            <td>ANTWERP</td>
            <td>2021-03-24</td>
            <td>2021-04-12</td>
            <td>Sealand</td>
            <td>111S</td>
            <td>IZMIR - PIVDENNYI</td>
            <td>2021-03-22</td>
          </tr>
          <tr>
            <td>11</td>
            <td>PACUL </td>
            <td>2020-03-17</td>
            <td>2020-03-20</td>
            <td>Sealand</td>
            <td>12W</td>
            <td>IZMIR - PIVDENNYI</td>
            <td>2020-03-12</td>
          </tr>
          <tr>
            <td>10</td>
            <td>ALEXANDER MAERSK </td>
            <td>2021-03-17</td>
            <td>2021-04-05</td>
            <td>Sealand</td>
            <td>111N</td>
            <td>IZMIR - PIVDENNYI</td>
            <td>2021-03-15</td>
          </tr>
          <tr>
            <td>10</td>
            <td>PACUL </td>
            <td>2020-03-04</td>
            <td>2020-03-23</td>
            <td>Sealand</td>
            <td>010W</td>
            <td>IZMIR - PIVDENNYI</td>
            <td>2020-02-27</td>
          </tr>
          <tr>
            <td>9</td>
            <td>ANTWERP</td>
            <td>2021-03-11</td>
            <td>2021-03-30</td>
            <td>Sealand</td>
            <td>110S</td>
            <td>IZMIR - PIVDENNYI</td>
            <td>2021-03-08</td>
          </tr>
          <tr>
            <td>9</td>
            <td>PACUL </td>
            <td>2020-03-02</td>
            <td>2020-03-05</td>
            <td>Sealand</td>
            <td>010W</td>
            <td>IZMIR - PIVDENNYI</td>
            <td>2020-02-27</td>
          </tr>
          <tr>
            <td>3</td>
            <td>MAERSK ARIZONA</td>
            <td>2021-01-28</td>
            <td>2021-02-11</td>
            <td>Sealand</td>
            <td>104S</td>
            <td>IZMIR - PIVDENNYI</td>
            <td>2021-01-25</td>
          </tr>
          <tr>
            <td>2</td>
            <td>ANTWERP</td>
            <td>2021-01-20</td>
            <td>2020-12-04</td>
            <td>Sealand</td>
            <td>103S</td>
            <td>IZMIR - PIVDENNYI</td>
            <td>2021-01-18</td>
          </tr>
          <tr>
            <td>1</td>
            <td>MAERSK ARIZONA</td>
            <td>2021-01-13</td>
            <td>2021-01-31</td>
            <td>Sealand</td>
            <td>102S</td>
            <td>IZMIR - PIVDENNYI</td>
            <td>2021-01-11</td>
          </tr>
          <tr>
            <td>1</td>
            <td>MAERSK ARIZONA</td>
            <td>2021-01-06</td>
            <td>2021-01-23</td>
            <td>Sealand</td>
            <td>101S</td>
            <td>IZMIR - PIVDENNYI</td>
            <td>2021-01-04</td>
          </tr>
          <tr>
            <td>52</td>
            <td>ANTWERP</td>
            <td>2020-12-24</td>
            <td>2021-01-12</td>
            <td>Sealand</td>
            <td>052S</td>
            <td>IZMIR - PIVDENNYI</td>
            <td>2020-12-21</td>
          </tr>
          <tr>
            <td>34</td>
            <td>ANTWERP</td>
            <td>2020-08-20</td>
            <td>2020-09-08</td>
            <td>Sealand</td>
            <td>034S</td>
            <td>IZMIR - PIVDENNYI</td>
            <td>2020-08-17</td>
          </tr>
          <tr>
            <td>29</td>
            <td>MAERSK ARIZONA</td>
            <td>2020-07-15</td>
            <td>2020-08-03</td>
            <td>Sealand</td>
            <td>029S</td>
            <td>IZMIR - PIVDENNYI</td>
            <td>2020-07-13</td>
          </tr>
        </tbody>
      </table>
    ),
  },
  {
    id: 11,
    itemText: "IZMIT KORFEZI - PIVDENNYI",
    itemValue: (
      <table class="vessel-table">
        <thead>
          <tr>
            <th>Week</th>
            <th>Ship</th>
            <th>ETD</th>
            <th>ETA</th>
            <th>Line</th>
            <th>Voyage</th>
            <th>VesselCategories</th>
            <th>CutOff</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>8</td>
            <td> POLAR BRASIL</td>
            <td>2022-03-03</td>
            <td>2022-03-07</td>
            <td>Sealand</td>
            <td>205E</td>
            <td>IZMIT KORFEZI - PIVDENNYI</td>
            <td>2022-02-28</td>
          </tr>
          <tr>
            <td>8</td>
            <td>SERENADA</td>
            <td>2022-03-07</td>
            <td>2022-03-09</td>
            <td>AKKON</td>
            <td>SER0822</td>
            <td>IZMIT KORFEZI - PIVDENNYI</td>
            <td>2022-03-04</td>
          </tr>
          <tr>
            <td>8</td>
            <td>SAFMARINE NOKWANDA</td>
            <td>2021-03-04</td>
            <td>2021-03-07</td>
            <td>Sealand</td>
            <td>105E</td>
            <td>IZMIT KORFEZI - PIVDENNYI</td>
            <td>2021-03-01</td>
          </tr>
          <tr>
            <td>8</td>
            <td> LARS MAERSK</td>
            <td>2020-02-19</td>
            <td>2020-02-23</td>
            <td>Sealand</td>
            <td>004E</td>
            <td>IZMIT KORFEZI - PIVDENNYI</td>
            <td>2020-02-17</td>
          </tr>
          <tr>
            <td>7</td>
            <td>SAFMARINE NOMAZWE</td>
            <td>2022-02-24</td>
            <td>2022-03-01</td>
            <td>Sealand</td>
            <td>204E</td>
            <td>IZMIT KORFEZI - PIVDENNYI</td>
            <td>2022-02-21</td>
          </tr>
          <tr>
            <td>7</td>
            <td>SERENADA</td>
            <td>2022-02-26</td>
            <td>2022-02-28</td>
            <td>AKKON</td>
            <td>SER0722</td>
            <td>IZMIT KORFEZI - PIVDENNYI</td>
            <td>2022-02-25</td>
          </tr>
          <tr>
            <td>7</td>
            <td>LEXA MAERSK</td>
            <td>2021-02-25</td>
            <td>2021-03-01</td>
            <td>Sealand</td>
            <td>104E</td>
            <td>IZMIT KORFEZI - PIVDENNYI</td>
            <td>2021-02-22</td>
          </tr>
          <tr>
            <td>7</td>
            <td>LUNA MAERSK</td>
            <td>2020-02-12</td>
            <td>2020-02-15</td>
            <td>Sealand</td>
            <td>003E</td>
            <td>IZMIT KORFEZI - PIVDENNYI</td>
            <td>2020-02-10</td>
          </tr>
          <tr>
            <td>6</td>
            <td>SAFMARINE NOKWANDA</td>
            <td>2022-02-17</td>
            <td>2022-02-22</td>
            <td>Sealand</td>
            <td>203E</td>
            <td>IZMIT KORFEZI - PIVDENNYI</td>
            <td>2022-02-14</td>
          </tr>
          <tr>
            <td>6</td>
            <td>SERENADA</td>
            <td>2022-02-09</td>
            <td>2022-02-13</td>
            <td>AKKON</td>
            <td>SER0522</td>
            <td>IZMIT KORFEZI - PIVDENNYI</td>
            <td>2022-02-08</td>
          </tr>
          <tr>
            <td>6</td>
            <td>LEXA MAERSK</td>
            <td>2021-02-25</td>
            <td>2021-03-02</td>
            <td>Sealand</td>
            <td>104E</td>
            <td>IZMIT KORFEZI - PIVDENNYI</td>
            <td>2021-02-22</td>
          </tr>
          <tr>
            <td>6</td>
            <td>LAUST MAERSK</td>
            <td>2020-02-05</td>
            <td>2020-02-09</td>
            <td>Sealand</td>
            <td>002E</td>
            <td>IZMIT KORFEZI - PIVDENNYI</td>
            <td>2020-02-03</td>
          </tr>
          <tr>
            <td>5</td>
            <td>LEDA MAERSK</td>
            <td>2022-02-10</td>
            <td>2022-02-14</td>
            <td>Sealand</td>
            <td>202E</td>
            <td>IZMIT KORFEZI - PIVDENNYI</td>
            <td>2022-02-07</td>
          </tr>
          <tr>
            <td>5</td>
            <td>LAURA MAERSK</td>
            <td>2021-02-11</td>
            <td>2021-02-16</td>
            <td>Sealand</td>
            <td>102E</td>
            <td>IZMIT KORFEZI - PIVDENNYI</td>
            <td>2021-02-08</td>
          </tr>
          <tr>
            <td>5</td>
            <td>MAERSK BINTAN </td>
            <td>2020-01-30</td>
            <td>2020-02-03</td>
            <td>Sealand</td>
            <td>001E</td>
            <td>IZMIT KORFEZI - PIVDENNYI</td>
            <td>2020-01-27</td>
          </tr>
          <tr>
            <td>4</td>
            <td>LAURA MAERSK</td>
            <td>2022-02-03</td>
            <td>2022-02-07</td>
            <td>Sealand</td>
            <td>201E</td>
            <td>IZMIT KORFEZI - PIVDENNYI</td>
            <td>2022-01-31</td>
          </tr>
          <tr>
            <td>4</td>
            <td>SERENADA</td>
            <td>2022-02-08</td>
            <td>2022-02-10</td>
            <td>AKKON</td>
            <td>SER0522</td>
            <td>IZMIT KORFEZI - PIVDENNYI</td>
            <td>2022-02-04</td>
          </tr>
          <tr>
            <td>4</td>
            <td> LARS MAERSK</td>
            <td>2021-02-04</td>
            <td>2021-02-09</td>
            <td>Sealand</td>
            <td>101E</td>
            <td>IZMIT KORFEZI - PIVDENNYI</td>
            <td>2021-02-01</td>
          </tr>
          <tr>
            <td>51</td>
            <td>ADILIA I</td>
            <td>2021-12-26</td>
            <td>2021-12-31</td>
            <td>AKKON</td>
            <td>0721</td>
            <td>IZMIT KORFEZI - PIVDENNYI</td>
            <td>2021-12-24</td>
          </tr>
          <tr>
            <td>51</td>
            <td>CSAV TRAIGUEN</td>
            <td>2021-12-30</td>
            <td>2022-01-02</td>
            <td>Sealand</td>
            <td>148E</td>
            <td>IZMIT KORFEZI - PIVDENNYI</td>
            <td>2021-12-27</td>
          </tr>
          <tr>
            <td>50</td>
            <td>ADILIA I</td>
            <td>2021-12-23</td>
            <td>2021-12-28</td>
            <td>AKKON</td>
            <td>0721</td>
            <td>IZMIT KORFEZI - PIVDENNYI</td>
            <td>2021-12-22</td>
          </tr>
          <tr>
            <td>50</td>
            <td>MAERSK LAUNCESTON</td>
            <td>2021-12-23</td>
            <td>2021-12-24</td>
            <td>Sealand</td>
            <td>147E</td>
            <td>IZMIT KORFEZI - PIVDENNYI</td>
            <td>2021-12-20</td>
          </tr>
          <tr>
            <td>49</td>
            <td>ADILIA I</td>
            <td>2021-12-11</td>
            <td>2021-12-17</td>
            <td>AKKON</td>
            <td>0621</td>
            <td>IZMIT KORFEZI - PIVDENNYI</td>
            <td>2021-12-10</td>
          </tr>
          <tr>
            <td>49</td>
            <td>SAFMARINE NOMAZWE</td>
            <td>2021-12-16</td>
            <td>2021-12-17</td>
            <td>Sealand</td>
            <td>146E</td>
            <td>IZMIT KORFEZI - PIVDENNYI</td>
            <td>2021-12-13</td>
          </tr>
          <tr>
            <td>49</td>
            <td>ADILIA I</td>
            <td>2021-12-14</td>
            <td>2021-12-18</td>
            <td>AKKON</td>
            <td>0721</td>
            <td>IZMIT KORFEZI - PIVDENNYI</td>
            <td>2021-12-13</td>
          </tr>
          <tr>
            <td>48</td>
            <td>ADILIA I</td>
            <td>2021-12-05</td>
            <td>2021-12-09</td>
            <td>AKKON</td>
            <td>0621</td>
            <td>IZMIT KORFEZI - PIVDENNYI</td>
            <td>2021-12-03</td>
          </tr>
          <tr>
            <td>48</td>
            <td>SAFMARINE NOKWANDA</td>
            <td>2021-12-09</td>
            <td>2021-12-10</td>
            <td>Sealand</td>
            <td>145E</td>
            <td>IZMIT KORFEZI - PIVDENNYI</td>
            <td>2021-12-06</td>
          </tr>
          <tr>
            <td>47</td>
            <td>ADILIA I</td>
            <td>2021-12-04</td>
            <td>2021-12-08</td>
            <td>AKKON</td>
            <td>0621</td>
            <td>IZMIT KORFEZI - PIVDENNYI</td>
            <td>2021-12-03</td>
          </tr>
          <tr>
            <td>47</td>
            <td>CSAV TRAIGUEN</td>
            <td>2021-12-02</td>
            <td>2021-12-03</td>
            <td>Sealand</td>
            <td>144E</td>
            <td>IZMIT KORFEZI - PIVDENNYI</td>
            <td>2021-11-29</td>
          </tr>
          <tr>
            <td>46</td>
            <td>LAURA MAERSK</td>
            <td>2021-11-25</td>
            <td>2021-11-26</td>
            <td>Sealand</td>
            <td>143E</td>
            <td>IZMIT KORFEZI - PIVDENNYI</td>
            <td>2021-11-22</td>
          </tr>
          <tr>
            <td>46</td>
            <td>ADILIA I</td>
            <td>2021-11-26</td>
            <td>2021-11-30</td>
            <td>AKKON</td>
            <td>0521</td>
            <td>IZMIT KORFEZI - PIVDENNYI</td>
            <td>2021-11-26</td>
          </tr>
          <tr>
            <td>45</td>
            <td>LICA MAERSK</td>
            <td>2021-11-18</td>
            <td>2021-11-20</td>
            <td>Sealand</td>
            <td>142E</td>
            <td>IZMIT KORFEZI - PIVDENNYI</td>
            <td>2021-11-15</td>
          </tr>
          <tr>
            <td>44</td>
            <td> LARS MAERSK</td>
            <td>2021-11-09</td>
            <td>2021-11-12</td>
            <td>Sealand</td>
            <td>141E</td>
            <td>IZMIT KORFEZI - PIVDENNYI</td>
            <td>2021-11-08</td>
          </tr>
          <tr>
            <td>42</td>
            <td>LAUST MAERSK</td>
            <td>2021-10-28</td>
            <td>2021-10-30</td>
            <td>Sealand</td>
            <td>139E</td>
            <td>IZMIT KORFEZI - PIVDENNYI</td>
            <td>2021-10-25</td>
          </tr>
          <tr>
            <td>42</td>
            <td>LAUST MAERSK</td>
            <td>2021-10-28</td>
            <td>2021-10-31</td>
            <td>Sealand</td>
            <td>139E</td>
            <td>IZMIT KORFEZI - PIVDENNYI</td>
            <td>2021-10-25</td>
          </tr>
          <tr>
            <td>41</td>
            <td>LEDA MAERSK</td>
            <td>2021-10-21</td>
            <td>2021-10-23</td>
            <td>Sealand</td>
            <td>138E</td>
            <td>IZMIT KORFEZI - PIVDENNYI</td>
            <td>2021-10-18</td>
          </tr>
          <tr>
            <td>40</td>
            <td>MAERSK LAUNCESTON</td>
            <td>2021-10-14</td>
            <td>2021-10-15</td>
            <td>Sealand</td>
            <td>137E</td>
            <td>IZMIT KORFEZI - PIVDENNYI</td>
            <td>2021-10-11</td>
          </tr>
          <tr>
            <td>39</td>
            <td>SAFMARINE NOMAZWE</td>
            <td>2021-10-07</td>
            <td>2021-10-11</td>
            <td>Sealand</td>
            <td>136E</td>
            <td>IZMIT KORFEZI - PIVDENNYI</td>
            <td>2021-10-04</td>
          </tr>
          <tr>
            <td>39</td>
            <td>LAURA MAERSK</td>
            <td>2020-09-24</td>
            <td>2020-09-28</td>
            <td>Sealand</td>
            <td>035E</td>
            <td>IZMIT KORFEZI - PIVDENNYI</td>
            <td>2020-09-21</td>
          </tr>
          <tr>
            <td>38</td>
            <td>SAFMARINE NOKWANDA</td>
            <td>2021-09-30</td>
            <td>2021-10-02</td>
            <td>Sealand</td>
            <td>135E</td>
            <td>IZMIT KORFEZI - PIVDENNYI</td>
            <td>2021-09-27</td>
          </tr>
          <tr>
            <td>38</td>
            <td> LARS MAERSK</td>
            <td>2020-09-18</td>
            <td>2020-09-22</td>
            <td>Sealand</td>
            <td>034E</td>
            <td>IZMIT KORFEZI - PIVDENNYI</td>
            <td>2020-09-14</td>
          </tr>
          <tr>
            <td>37</td>
            <td>LEXA MAERSK</td>
            <td>2021-09-23</td>
            <td>2021-09-27</td>
            <td>Sealand</td>
            <td>134E</td>
            <td>IZMIT KORFEZI - PIVDENNYI</td>
            <td>2021-09-20</td>
          </tr>
          <tr>
            <td>37</td>
            <td>LUNA MAERSK</td>
            <td>2020-09-10</td>
            <td>2020-09-14</td>
            <td>Sealand</td>
            <td>033E</td>
            <td>IZMIT KORFEZI - PIVDENNYI</td>
            <td>2020-09-07</td>
          </tr>
          <tr>
            <td>36</td>
            <td>LAURA MAERSK</td>
            <td>2021-09-16</td>
            <td>2021-09-21</td>
            <td>Sealand</td>
            <td>133E</td>
            <td>IZMIT KORFEZI - PIVDENNYI</td>
            <td>2021-09-13</td>
          </tr>
          <tr>
            <td>36</td>
            <td>LAUST MAERSK</td>
            <td>2020-09-03</td>
            <td>2020-09-07</td>
            <td>Sealand</td>
            <td>034E</td>
            <td>IZMIT KORFEZI - PIVDENNYI</td>
            <td>2020-08-31</td>
          </tr>
          <tr>
            <td>35</td>
            <td>LAURA MAERSK</td>
            <td>2021-09-16</td>
            <td>2021-09-21</td>
            <td>Sealand</td>
            <td>135E</td>
            <td>IZMIT KORFEZI - PIVDENNYI</td>
            <td>2021-09-13</td>
          </tr>
          <tr>
            <td>35</td>
            <td>LEDA MAERSK</td>
            <td>2020-08-27</td>
            <td>2020-08-31</td>
            <td>Sealand</td>
            <td>031E</td>
            <td>IZMIT KORFEZI - PIVDENNYI</td>
            <td>2020-08-24</td>
          </tr>
          <tr>
            <td>34</td>
            <td>LICA MAERSK</td>
            <td>2021-09-09</td>
            <td>2021-09-14</td>
            <td>Sealand</td>
            <td>132E</td>
            <td>IZMIT KORFEZI - PIVDENNYI</td>
            <td>2021-09-06</td>
          </tr>
          <tr>
            <td>34</td>
            <td>MAERSK LAUNCESTON</td>
            <td>2020-08-21</td>
            <td>2020-08-25</td>
            <td>Sealand</td>
            <td>032E</td>
            <td>IZMIT KORFEZI - PIVDENNYI</td>
            <td>2020-08-17</td>
          </tr>
          <tr>
            <td>33</td>
            <td> LARS MAERSK</td>
            <td>2021-09-01</td>
            <td>2021-08-06</td>
            <td>Sealand</td>
            <td>131E</td>
            <td>IZMIT KORFEZI - PIVDENNYI</td>
            <td>2021-08-26</td>
          </tr>
          <tr>
            <td>33</td>
            <td>SAFMARINE NOMAZWE</td>
            <td>2020-08-13</td>
            <td>2020-08-17</td>
            <td>Sealand</td>
            <td>029E</td>
            <td>IZMIT KORFEZI - PIVDENNYI</td>
            <td>2020-08-10</td>
          </tr>
          <tr>
            <td>32</td>
            <td>LAUST MAERSK</td>
            <td>2021-08-19</td>
            <td>2021-08-24</td>
            <td>Sealand</td>
            <td>129E</td>
            <td>IZMIT KORFEZI - PIVDENNYI</td>
            <td>2021-08-16</td>
          </tr>
          <tr>
            <td>31</td>
            <td>LEDA MAERSK</td>
            <td>2021-08-12</td>
            <td>2021-08-17</td>
            <td>Sealand</td>
            <td>128E</td>
            <td>IZMIT KORFEZI - PIVDENNYI</td>
            <td>2021-08-09</td>
          </tr>
          <tr>
            <td>31</td>
            <td>SAFMARINE NOKWANDA</td>
            <td>2020-07-11</td>
            <td>2020-07-15</td>
            <td>Sealand</td>
            <td>028E</td>
            <td>IZMIT KORFEZI - PIVDENNYI</td>
            <td>2020-07-29</td>
          </tr>
          <tr>
            <td>30</td>
            <td>MAERSK LAUNCESTON</td>
            <td>2021-08-05</td>
            <td>2021-08-10</td>
            <td>Sealand</td>
            <td>127E</td>
            <td>IZMIT KORFEZI - PIVDENNYI</td>
            <td>2021-08-02</td>
          </tr>
          <tr>
            <td>30</td>
            <td>LICA MAERSK</td>
            <td>2020-07-24</td>
            <td>2020-07-27</td>
            <td>Sealand</td>
            <td>026E</td>
            <td>IZMIT KORFEZI - PIVDENNYI</td>
            <td>2020-07-20</td>
          </tr>
          <tr>
            <td>29</td>
            <td>SAFMARINE NOMAZWE</td>
            <td>2021-07-29</td>
            <td>2021-08-03</td>
            <td>Sealand</td>
            <td>126E</td>
            <td>IZMIT KORFEZI - PIVDENNYI</td>
            <td>2021-07-26</td>
          </tr>
          <tr>
            <td>29</td>
            <td>MONTE ACONCAGUA </td>
            <td>2020-07-17</td>
            <td>2020-07-21</td>
            <td>Sealand</td>
            <td>026E</td>
            <td>IZMIT KORFEZI - PIVDENNYI</td>
            <td>2020-07-13</td>
          </tr>
          <tr>
            <td>28</td>
            <td>SEAGO ISTANBUL</td>
            <td>2021-07-22</td>
            <td>2021-07-27</td>
            <td>Sealand</td>
            <td>125</td>
            <td>IZMIT KORFEZI - PIVDENNYI</td>
            <td>2021-07-12</td>
          </tr>
          <tr>
            <td>28</td>
            <td>MONTE ACONCAGUA </td>
            <td>2020-07-17</td>
            <td>2020-07-22</td>
            <td>Sealand</td>
            <td>026E</td>
            <td>IZMIT KORFEZI - PIVDENNYI</td>
            <td>2020-07-13</td>
          </tr>
          <tr>
            <td>27</td>
            <td>LEXA MAERSK</td>
            <td>2021-07-15</td>
            <td>2021-07-20</td>
            <td>Sealand</td>
            <td>124E</td>
            <td>IZMIT KORFEZI - PIVDENNYI</td>
            <td>2021-07-12</td>
          </tr>
          <tr>
            <td>27</td>
            <td>LUNA MAERSK</td>
            <td>2020-07-03</td>
            <td>2020-07-07</td>
            <td>Sealand</td>
            <td>023E</td>
            <td>IZMIT KORFEZI - PIVDENNYI</td>
            <td>2020-06-29</td>
          </tr>
          <tr>
            <td>26</td>
            <td>SEAGO ISTANBUL</td>
            <td>2021-07-08</td>
            <td>2021-07-13</td>
            <td>Sealand</td>
            <td>123E</td>
            <td>IZMIT KORFEZI - PIVDENNYI</td>
            <td>2021-07-05</td>
          </tr>
          <tr>
            <td>26</td>
            <td>LAUST MAERSK</td>
            <td>2020-06-25</td>
            <td>2020-06-29</td>
            <td>Sealand</td>
            <td>022E</td>
            <td>IZMIT KORFEZI - PIVDENNYI</td>
            <td>2020-06-22</td>
          </tr>
          <tr>
            <td>25</td>
            <td>LAURA MAERSK</td>
            <td>2021-07-01</td>
            <td>2021-07-06</td>
            <td>Sealand</td>
            <td>122E</td>
            <td>IZMIT KORFEZI - PIVDENNYI</td>
            <td>2021-06-28</td>
          </tr>
          <tr>
            <td>25</td>
            <td>LEDA MAERSK</td>
            <td>2020-06-18</td>
            <td>2020-06-23</td>
            <td>Sealand</td>
            <td>021E</td>
            <td>IZMIT KORFEZI - PIVDENNYI</td>
            <td>2020-06-15</td>
          </tr>
          <tr>
            <td>24</td>
            <td> LARS MAERSK</td>
            <td>2021-06-24</td>
            <td>2021-06-29</td>
            <td>Sealand</td>
            <td>121E</td>
            <td>IZMIT KORFEZI - PIVDENNYI</td>
            <td>2021-06-21</td>
          </tr>
          <tr>
            <td>24</td>
            <td>MAERSK LAUNCESTON</td>
            <td>2020-06-11</td>
            <td>2020-06-15</td>
            <td>Sealand</td>
            <td>020E</td>
            <td>IZMIT KORFEZI - PIVDENNYI</td>
            <td>2020-06-08</td>
          </tr>
          <tr>
            <td>23</td>
            <td>LUNA MAERSK</td>
            <td>2021-06-24</td>
            <td>2021-06-29</td>
            <td>Sealand</td>
            <td>120E</td>
            <td>IZMIT KORFEZI - PIVDENNYI</td>
            <td>2021-06-21</td>
          </tr>
          <tr>
            <td>23</td>
            <td>SAFMARINE NOMAZWE</td>
            <td>2020-06-04</td>
            <td>2020-06-08</td>
            <td>Sealand</td>
            <td>019E</td>
            <td>IZMIT KORFEZI - PIVDENNYI</td>
            <td>2020-06-01</td>
          </tr>
          <tr>
            <td>22</td>
            <td>SEAGO ISTANBUL</td>
            <td>2021-06-10</td>
            <td>2021-06-15</td>
            <td>Sealand</td>
            <td>119E</td>
            <td>IZMIT KORFEZI - PIVDENNYI</td>
            <td>2021-06-07</td>
          </tr>
          <tr>
            <td>22</td>
            <td>SAFMARINE NOMAZWE</td>
            <td>2020-06-04</td>
            <td>2020-06-08</td>
            <td>Sealand</td>
            <td>019E</td>
            <td>IZMIT KORFEZI - PIVDENNYI</td>
            <td>2020-05-27</td>
          </tr>
          <tr>
            <td>21</td>
            <td>LEDA MAERSK</td>
            <td>2021-06-03</td>
            <td>2021-06-08</td>
            <td>Sealand</td>
            <td>118E</td>
            <td>IZMIT KORFEZI - PIVDENNYI</td>
            <td>2021-05-31</td>
          </tr>
          <tr>
            <td>21</td>
            <td>LEXA MAERSK</td>
            <td>2020-05-29</td>
            <td>2020-06-02</td>
            <td>Sealand</td>
            <td>017E</td>
            <td>IZMIT KORFEZI - PIVDENNYI</td>
            <td>2020-06-20</td>
          </tr>
          <tr>
            <td>20</td>
            <td>MAERSK LAUNCESTON</td>
            <td>2021-05-27</td>
            <td>2021-06-01</td>
            <td>Sealand</td>
            <td>117E</td>
            <td>IZMIT KORFEZI - PIVDENNYI</td>
            <td>2021-05-24</td>
          </tr>
          <tr>
            <td>20</td>
            <td>LICA MAERSK</td>
            <td>2020-05-15</td>
            <td>2020-05-19</td>
            <td>Sealand</td>
            <td>016E</td>
            <td>IZMIT KORFEZI - PIVDENNYI</td>
            <td>2020-05-11</td>
          </tr>
          <tr>
            <td>19</td>
            <td>SAFMARINE NOMAZWE</td>
            <td>2021-05-20</td>
            <td>2021-05-25</td>
            <td>Sealand</td>
            <td>116E</td>
            <td>IZMIT KORFEZI - PIVDENNYI</td>
            <td>2021-05-17</td>
          </tr>
          <tr>
            <td>19</td>
            <td>LAURA MAERSK</td>
            <td>2020-05-07</td>
            <td>2020-05-11</td>
            <td>Sealand</td>
            <td>015E</td>
            <td>IZMIT KORFEZI - PIVDENNYI</td>
            <td>2020-05-04</td>
          </tr>
          <tr>
            <td>18</td>
            <td>SAFMARINE NOKWANDA</td>
            <td>2021-05-13</td>
            <td>2021-05-18</td>
            <td>Sealand</td>
            <td>115E</td>
            <td>IZMIT KORFEZI - PIVDENNYI</td>
            <td>2021-05-10</td>
          </tr>
          <tr>
            <td>18</td>
            <td> LARS MAERSK</td>
            <td>2020-05-01</td>
            <td>2020-05-05</td>
            <td>Sealand</td>
            <td>014E</td>
            <td>IZMIT KORFEZI - PIVDENNYI</td>
            <td>2020-04-27</td>
          </tr>
          <tr>
            <td>17</td>
            <td>LEXA MAERSK</td>
            <td>2021-05-06</td>
            <td>2021-05-11</td>
            <td>Sealand</td>
            <td>114E</td>
            <td>IZMIT KORFEZI - PIVDENNYI</td>
            <td>2021-05-03</td>
          </tr>
          <tr>
            <td>17</td>
            <td>LUNA MAERSK</td>
            <td>2020-04-24</td>
            <td>2020-04-28</td>
            <td>Sealand</td>
            <td>013E</td>
            <td>IZMIT KORFEZI - PIVDENNYI</td>
            <td>2020-04-20</td>
          </tr>
          <tr>
            <td>16</td>
            <td>SEAGO ISTANBUL</td>
            <td>2021-04-29</td>
            <td>2021-05-04</td>
            <td>Sealand</td>
            <td>113E</td>
            <td>IZMIT KORFEZI - PIVDENNYI</td>
            <td>2021-04-26</td>
          </tr>
          <tr>
            <td>16</td>
            <td>LAUST MAERSK</td>
            <td>2020-04-17</td>
            <td>2020-04-21</td>
            <td>Sealand</td>
            <td>012E</td>
            <td>IZMIT KORFEZI - PIVDENNYI</td>
            <td>2020-04-13</td>
          </tr>
          <tr>
            <td>15</td>
            <td>LAURA MAERSK</td>
            <td>2021-04-22</td>
            <td>2021-04-27</td>
            <td>Sealand</td>
            <td>112E</td>
            <td>IZMIT KORFEZI - PIVDENNYI</td>
            <td>2021-04-19</td>
          </tr>
          <tr>
            <td>15</td>
            <td>LEDA MAERSK</td>
            <td>2020-04-10</td>
            <td>2020-04-14</td>
            <td>Sealand</td>
            <td>011E</td>
            <td>IZMIT KORFEZI - PIVDENNYI</td>
            <td>2020-04-06</td>
          </tr>
          <tr>
            <td>14</td>
            <td> LARS MAERSK</td>
            <td>2021-04-15</td>
            <td>2021-04-21</td>
            <td>Sealand</td>
            <td>111E</td>
            <td>IZMIT KORFEZI - PIVDENNYI</td>
            <td>2021-04-12</td>
          </tr>
          <tr>
            <td>14</td>
            <td>MAERSK LAUNCESTON</td>
            <td>2020-04-03</td>
            <td>2020-04-07</td>
            <td>Sealand</td>
            <td>010E</td>
            <td>IZMIT KORFEZI - PIVDENNYI</td>
            <td>2020-03-30</td>
          </tr>
          <tr>
            <td>13</td>
            <td>SEAGO ISTANBUL</td>
            <td>2021-04-01</td>
            <td>2021-04-06</td>
            <td>Sealand</td>
            <td>112E</td>
            <td>IZMIT KORFEZI - PIVDENNYI</td>
            <td>2021-03-29</td>
          </tr>
          <tr>
            <td>13</td>
            <td>SAFMARINE NOMAZWE</td>
            <td>2020-03-27</td>
            <td>2020-04-01</td>
            <td>Sealand</td>
            <td>009E</td>
            <td>IZMIT KORFEZI - PIVDENNYI</td>
            <td>2020-03-23</td>
          </tr>
          <tr>
            <td>12</td>
            <td>SEAGO ISTANBUL</td>
            <td>2021-04-01</td>
            <td>2021-04-05</td>
            <td>Sealand</td>
            <td>109E</td>
            <td>IZMIT KORFEZI - PIVDENNYI</td>
            <td>2021-03-29</td>
          </tr>
          <tr>
            <td>12</td>
            <td>SAFMARINE NOKWANDA</td>
            <td>2020-03-20</td>
            <td>2020-03-24</td>
            <td>Sealand</td>
            <td>008E</td>
            <td>IZMIT KORFEZI - PIVDENNYI</td>
            <td>2020-03-16</td>
          </tr>
          <tr>
            <td>11</td>
            <td>LEDA MAERSK</td>
            <td>2021-03-25</td>
            <td>2021-03-30</td>
            <td>Sealand</td>
            <td>108E</td>
            <td>IZMIT KORFEZI - PIVDENNYI</td>
            <td>2021-03-22</td>
          </tr>
          <tr>
            <td>11</td>
            <td>LEXA MAERSK</td>
            <td>2020-03-12</td>
            <td>2020-03-15</td>
            <td>Sealand</td>
            <td>011N</td>
            <td>IZMIT KORFEZI - PIVDENNYI</td>
            <td>2020-03-09</td>
          </tr>
          <tr>
            <td>10</td>
            <td>MAERSK LAUNCESTON</td>
            <td>2021-03-18</td>
            <td>2021-03-22</td>
            <td>Sealand</td>
            <td>107E</td>
            <td>IZMIT KORFEZI - PIVDENNYI</td>
            <td>2021-03-15</td>
          </tr>
          <tr>
            <td>10</td>
            <td>LICA MAERSK</td>
            <td>2020-03-06</td>
            <td>2020-03-09</td>
            <td>Sealand</td>
            <td>006E</td>
            <td>IZMIT KORFEZI - PIVDENNYI</td>
            <td>2020-03-02</td>
          </tr>
          <tr>
            <td>9</td>
            <td>SAFMARINE NOMAZWE</td>
            <td>2021-03-11</td>
            <td>2021-03-15</td>
            <td>Sealand</td>
            <td>106E</td>
            <td>IZMIT KORFEZI - PIVDENNYI</td>
            <td>2021-03-08</td>
          </tr>
          <tr>
            <td>9</td>
            <td>LAURA MAERSK</td>
            <td>2020-02-27</td>
            <td>2020-03-02</td>
            <td>Sealand</td>
            <td>005E</td>
            <td>IZMIT KORFEZI - PIVDENNYI</td>
            <td>2020-02-24</td>
          </tr>
          <tr>
            <td>3</td>
            <td>LUNA MAERSK</td>
            <td>2021-01-29</td>
            <td>2021-02-01</td>
            <td>Sealand</td>
            <td>053E</td>
            <td>IZMIT KORFEZI - PIVDENNYI</td>
            <td>2021-01-25</td>
          </tr>
          <tr>
            <td>2</td>
            <td>LAUST MAERSK</td>
            <td>2021-01-22</td>
            <td>2021-01-26</td>
            <td>Sealand</td>
            <td>052E</td>
            <td>IZMIT KORFEZI - PIVDENNYI</td>
            <td>2021-01-18</td>
          </tr>
          <tr>
            <td>1</td>
            <td>LEDA MAERSK</td>
            <td>2021-01-13</td>
            <td>2021-01-17</td>
            <td>Sealand</td>
            <td>051E</td>
            <td>IZMIT KORFEZI - PIVDENNYI</td>
            <td>2021-01-11</td>
          </tr>
          <tr>
            <td>1</td>
            <td>MAERSK LAUNCESTON</td>
            <td>2021-01-08</td>
            <td>2021-01-11</td>
            <td>Sealand</td>
            <td>050E</td>
            <td>IZMIT KORFEZI - PIVDENNYI</td>
            <td>2021-01-04</td>
          </tr>
          <tr>
            <td>52</td>
            <td>SAFMARINE NOKWANDA</td>
            <td>2020-12-24</td>
            <td>2020-12-28</td>
            <td>Sealand</td>
            <td>048E</td>
            <td>IZMIT KORFEZI - PIVDENNYI</td>
            <td>2020-12-21</td>
          </tr>
        </tbody>
      </table>
    ),
  },
  {
    id: 12,
    itemText: "IZMIT KORFEZI - CHORNOMORSK",
    itemValue: (
      <table class="vessel-table">
        <thead>
          <tr>
            <th>Week</th>
            <th>Ship</th>
            <th>ETD</th>
            <th>ETA</th>
            <th>Line</th>
            <th>Voyage</th>
            <th>VesselCategories</th>
            <th>CutOff</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>30</td>
            <td>MAERSK KYRENIA</td>
            <td>2021-08-10</td>
            <td>2021-08-17</td>
            <td>Sealand</td>
            <td>128W</td>
            <td>IZMIT KORFEZI - CHORNOMORSK </td>
            <td>2021-08-06</td>
          </tr>
          <tr>
            <td>30</td>
            <td>MAERSK KALMAR</td>
            <td>2020-07-27</td>
            <td>2020-08-13</td>
            <td>Sealand</td>
            <td>028W</td>
            <td>IZMIT KORFEZI - CHORNOMORSK </td>
            <td>2020-07-24</td>
          </tr>
          <tr>
            <td>29</td>
            <td>MAERSK KIEL</td>
            <td>2021-08-02</td>
            <td>2021-08-09</td>
            <td>Sealand</td>
            <td>127W</td>
            <td>IZMIT KORFEZI - CHORNOMORSK </td>
            <td>2021-07-29</td>
          </tr>
          <tr>
            <td>29</td>
            <td>MAERSK KLAIPEDA</td>
            <td>2020-07-21</td>
            <td>2020-08-07</td>
            <td>Sealand</td>
            <td>027W</td>
            <td>IZMIT KORFEZI - CHORNOMORSK </td>
            <td>2020-07-17</td>
          </tr>
          <tr>
            <td>28</td>
            <td>MAERSK KAMPALA</td>
            <td>2021-07-30</td>
            <td>2021-08-06</td>
            <td>Sealand</td>
            <td>126W</td>
            <td>IZMIT KORFEZI - CHORNOMORSK </td>
            <td>2021-07-26</td>
          </tr>
          <tr>
            <td>28</td>
            <td> MAERSK KITHIRA </td>
            <td>2020-07-14</td>
            <td>2020-07-28</td>
            <td>Sealand</td>
            <td>026W</td>
            <td>IZMIT KORFEZI - CHORNOMORSK </td>
            <td>2020-07-10</td>
          </tr>
          <tr>
            <td>27</td>
            <td>SANTA CRUZ </td>
            <td>2021-07-20</td>
            <td>2021-07-27</td>
            <td>Sealand</td>
            <td>125W</td>
            <td>IZMIT KORFEZI - CHORNOMORSK </td>
            <td>2021-07-12</td>
          </tr>
          <tr>
            <td>27</td>
            <td>MAERSK KIEL</td>
            <td>2020-07-07</td>
            <td>2020-07-19</td>
            <td>Sealand</td>
            <td>025W</td>
            <td>IZMIT KORFEZI - CHORNOMORSK </td>
            <td>2020-07-02</td>
          </tr>
          <tr>
            <td>26</td>
            <td>MAERSK KIMI</td>
            <td>2021-07-13</td>
            <td>2021-07-20</td>
            <td>Sealand</td>
            <td>124W</td>
            <td>IZMIT KORFEZI - CHORNOMORSK </td>
            <td>2021-07-09</td>
          </tr>
          <tr>
            <td>26</td>
            <td>MAERSK KAMPALA</td>
            <td>2020-06-30</td>
            <td>2020-07-02</td>
            <td>Sealand</td>
            <td>024W</td>
            <td>IZMIT KORFEZI - CHORNOMORSK </td>
            <td>2020-06-25</td>
          </tr>
          <tr>
            <td>25</td>
            <td>MAERSK KLAIPEDA</td>
            <td>2021-07-06</td>
            <td>2021-07-13</td>
            <td>Sealand</td>
            <td>123W</td>
            <td>IZMIT KORFEZI - CHORNOMORSK </td>
            <td>2021-07-02</td>
          </tr>
          <tr>
            <td>24</td>
            <td>MAERSK KALMAR</td>
            <td>2021-07-29</td>
            <td>2021-07-06</td>
            <td>Sealand</td>
            <td>121W</td>
            <td>IZMIT KORFEZI - CHORNOMORSK </td>
            <td>2021-06-25</td>
          </tr>
          <tr>
            <td>24</td>
            <td>MAERSK KIMI</td>
            <td>2020-06-15</td>
            <td>2020-06-19</td>
            <td>Sealand</td>
            <td>022W</td>
            <td>IZMIT KORFEZI - CHORNOMORSK </td>
            <td>2020-06-12</td>
          </tr>
          <tr>
            <td>23</td>
            <td>MAERSK KLAIPEDA</td>
            <td>2021-06-22</td>
            <td>2021-06-29</td>
            <td>Sealand</td>
            <td>120W</td>
            <td>IZMIT KORFEZI - CHORNOMORSK </td>
            <td>2021-06-18</td>
          </tr>
          <tr>
            <td>23</td>
            <td>MAERSK KYRENIA</td>
            <td>2020-06-08</td>
            <td>2020-06-12</td>
            <td>Sealand</td>
            <td>022W</td>
            <td>IZMIT KORFEZI - CHORNOMORSK </td>
            <td>2020-06-05</td>
          </tr>
          <tr>
            <td>22</td>
            <td> MAERSK KITHIRA </td>
            <td>2021-06-17</td>
            <td>2021-06-24</td>
            <td>Sealand</td>
            <td>120W</td>
            <td>IZMIT KORFEZI - CHORNOMORSK </td>
            <td>2021-06-11</td>
          </tr>
          <tr>
            <td>22</td>
            <td>MAERSK KALMAR</td>
            <td>2020-06-01</td>
            <td>2020-06-05</td>
            <td>Sealand</td>
            <td>020W</td>
            <td>IZMIT KORFEZI - CHORNOMORSK </td>
            <td>2020-05-29</td>
          </tr>
          <tr>
            <td>21</td>
            <td>MAERSK KIEL</td>
            <td>2021-06-08</td>
            <td>2021-06-15</td>
            <td>Sealand</td>
            <td>118W</td>
            <td>IZMIT KORFEZI - CHORNOMORSK </td>
            <td>2021-06-04</td>
          </tr>
          <tr>
            <td>20</td>
            <td>MAERSK KAMPALA</td>
            <td>2021-06-01</td>
            <td>2021-06-08</td>
            <td>Sealand</td>
            <td>117W</td>
            <td>IZMIT KORFEZI - CHORNOMORSK </td>
            <td>2021-05-25</td>
          </tr>
          <tr>
            <td>20</td>
            <td> MAERSK KITHIRA </td>
            <td>2020-05-18</td>
            <td>2020-05-22</td>
            <td>Sealand</td>
            <td>018W</td>
            <td>IZMIT KORFEZI - CHORNOMORSK </td>
            <td>2020-05-15</td>
          </tr>
          <tr>
            <td>19</td>
            <td>SANTA CRUZ </td>
            <td>2021-05-25</td>
            <td>2021-06-01</td>
            <td>Sealand</td>
            <td>117W</td>
            <td>IZMIT KORFEZI - CHORNOMORSK </td>
            <td>2021-05-21</td>
          </tr>
          <tr>
            <td>19</td>
            <td>MAERSK KIEL</td>
            <td>2020-05-12</td>
            <td>2020-05-16</td>
            <td>Sealand</td>
            <td>017W</td>
            <td>IZMIT KORFEZI - CHORNOMORSK </td>
            <td>2020-05-08</td>
          </tr>
          <tr>
            <td>18</td>
            <td>MAERSK KIMI</td>
            <td>2021-05-18</td>
            <td>2021-05-25</td>
            <td>Sealand</td>
            <td>116W</td>
            <td>IZMIT KORFEZI - CHORNOMORSK </td>
            <td>2021-05-12</td>
          </tr>
          <tr>
            <td>18</td>
            <td>MAERSK KAMPALA</td>
            <td>2020-05-04</td>
            <td>2020-05-09</td>
            <td>Sealand</td>
            <td>016W</td>
            <td>IZMIT KORFEZI - CHORNOMORSK </td>
            <td>2020-05-01</td>
          </tr>
          <tr>
            <td>17</td>
            <td>MAERSK KYRENIA</td>
            <td>2021-05-11</td>
            <td>2021-05-18</td>
            <td>Sealand</td>
            <td>115W</td>
            <td>IZMIT KORFEZI - CHORNOMORSK </td>
            <td>2021-05-07</td>
          </tr>
          <tr>
            <td>17</td>
            <td>SANTA CRUZ </td>
            <td>2020-04-28</td>
            <td>2020-05-02</td>
            <td>Sealand</td>
            <td>015W</td>
            <td>IZMIT KORFEZI - CHORNOMORSK </td>
            <td>2020-04-24</td>
          </tr>
          <tr>
            <td>16</td>
            <td>MAERSK KALMAR</td>
            <td>2021-05-04</td>
            <td>2021-05-11</td>
            <td>Sealand</td>
            <td>114W</td>
            <td>IZMIT KORFEZI - CHORNOMORSK </td>
            <td>2021-04-30</td>
          </tr>
          <tr>
            <td>16</td>
            <td>MAERSK KIMI</td>
            <td>2020-04-21</td>
            <td>2020-04-25</td>
            <td>Sealand</td>
            <td>014W</td>
            <td>IZMIT KORFEZI - CHORNOMORSK </td>
            <td>2020-04-17</td>
          </tr>
          <tr>
            <td>15</td>
            <td>MAERSK KLAIPEDA</td>
            <td>2021-04-27</td>
            <td>2021-05-04</td>
            <td>Sealand</td>
            <td>113W</td>
            <td>IZMIT KORFEZI - CHORNOMORSK </td>
            <td>2021-04-22</td>
          </tr>
          <tr>
            <td>15</td>
            <td>MAERSK KYRENIA</td>
            <td>2020-04-14</td>
            <td>2020-04-18</td>
            <td>Sealand</td>
            <td>013W</td>
            <td>IZMIT KORFEZI - CHORNOMORSK </td>
            <td>2020-04-10</td>
          </tr>
          <tr>
            <td>14</td>
            <td> MAERSK KITHIRA </td>
            <td>2021-04-20</td>
            <td>2021-04-29</td>
            <td>Sealand</td>
            <td>112W</td>
            <td>IZMIT KORFEZI - CHORNOMORSK </td>
            <td>2021-04-16</td>
          </tr>
          <tr>
            <td>14</td>
            <td>MAERSK KALMAR</td>
            <td>2020-04-06</td>
            <td>2020-04-10</td>
            <td>Sealand</td>
            <td>012W</td>
            <td>IZMIT KORFEZI - CHORNOMORSK </td>
            <td>2020-04-03</td>
          </tr>
          <tr>
            <td>13</td>
            <td>MAERSK KAMPALA</td>
            <td>2021-04-06</td>
            <td>2021-04-13</td>
            <td>Sealand</td>
            <td>110W</td>
            <td>IZMIT KORFEZI - CHORNOMORSK </td>
            <td>2021-04-02</td>
          </tr>
          <tr>
            <td>13</td>
            <td>MAERSK KLAIPEDA</td>
            <td>2020-03-31</td>
            <td>2020-04-05</td>
            <td>Sealand</td>
            <td>011W</td>
            <td>IZMIT KORFEZI - CHORNOMORSK </td>
            <td>2020-03-27</td>
          </tr>
          <tr>
            <td>12</td>
            <td>MAERSK KAMPALA</td>
            <td>2021-04-06</td>
            <td>2021-04-13</td>
            <td>Sealand</td>
            <td>110W</td>
            <td>IZMIT KORFEZI - CHORNOMORSK </td>
            <td>2021-04-02</td>
          </tr>
          <tr>
            <td>12</td>
            <td> MAERSK KITHIRA </td>
            <td>2020-03-24</td>
            <td>2020-03-28</td>
            <td>Sealand</td>
            <td>010W</td>
            <td>IZMIT KORFEZI - CHORNOMORSK </td>
            <td>2020-03-20</td>
          </tr>
          <tr>
            <td>11</td>
            <td>SANTA CRUZ </td>
            <td>2021-03-30</td>
            <td>2021-04-06</td>
            <td>Sealand</td>
            <td>109W</td>
            <td>IZMIT KORFEZI - CHORNOMORSK </td>
            <td>2021-03-26</td>
          </tr>
          <tr>
            <td>11</td>
            <td>MAERSK KIEL</td>
            <td>2020-03-17</td>
            <td>2020-03-20</td>
            <td>Sealand</td>
            <td>009W</td>
            <td>IZMIT KORFEZI - CHORNOMORSK </td>
            <td>2020-03-13</td>
          </tr>
          <tr>
            <td>10</td>
            <td>MAERSK KIMI</td>
            <td>2021-03-23</td>
            <td>2021-03-30</td>
            <td>Sealand</td>
            <td>108W</td>
            <td>IZMIT KORFEZI - CHORNOMORSK </td>
            <td>2021-03-19</td>
          </tr>
          <tr>
            <td>10</td>
            <td>LICA MAERSK</td>
            <td>2020-03-06</td>
            <td>2020-03-09</td>
            <td>Sealand</td>
            <td>006E</td>
            <td>IZMIT KORFEZI - CHORNOMORSK </td>
            <td>2020-03-02</td>
          </tr>
          <tr>
            <td>9</td>
            <td>MAERSK KYRENIA</td>
            <td>2021-03-16</td>
            <td>2021-03-23</td>
            <td>Sealand</td>
            <td>107W</td>
            <td>IZMIT KORFEZI - CHORNOMORSK </td>
            <td>2021-03-12</td>
          </tr>
          <tr>
            <td>9</td>
            <td>LAURA MAERSK</td>
            <td>2020-02-27</td>
            <td>2020-03-03</td>
            <td>Sealand</td>
            <td>005E</td>
            <td>IZMIT KORFEZI - CHORNOMORSK </td>
            <td>2020-02-24</td>
          </tr>
          <tr>
            <td>8</td>
            <td>MAERSK KALMAR</td>
            <td>2021-03-09</td>
            <td>2021-03-16</td>
            <td>Sealand</td>
            <td>106W</td>
            <td>IZMIT KORFEZI - CHORNOMORSK </td>
            <td>2021-03-05</td>
          </tr>
          <tr>
            <td>8</td>
            <td> LARS MAERSK</td>
            <td>2020-02-19</td>
            <td>2020-02-23</td>
            <td>Sealand</td>
            <td>004E</td>
            <td>IZMIT KORFEZI - CHORNOMORSK </td>
            <td>2020-02-17</td>
          </tr>
          <tr>
            <td>7</td>
            <td>MAERSK KLAIPEDA</td>
            <td>2021-03-02</td>
            <td>2021-03-12</td>
            <td>Sealand</td>
            <td>105W</td>
            <td>IZMIT KORFEZI - CHORNOMORSK </td>
            <td>2021-02-26</td>
          </tr>
          <tr>
            <td>7</td>
            <td>LUNA MAERSK</td>
            <td>2020-02-12</td>
            <td>2020-02-15</td>
            <td>Sealand</td>
            <td>003E</td>
            <td>IZMIT KORFEZI - CHORNOMORSK </td>
            <td>2020-02-10</td>
          </tr>
          <tr>
            <td>6</td>
            <td> MAERSK KITHIRA </td>
            <td>2021-02-23</td>
            <td>2021-03-02</td>
            <td>Sealand</td>
            <td>104W</td>
            <td>IZMIT KORFEZI - CHORNOMORSK </td>
            <td>2021-02-19</td>
          </tr>
          <tr>
            <td>6</td>
            <td>LAUST MAERSK</td>
            <td>2020-02-05</td>
            <td>2020-02-09</td>
            <td>Sealand</td>
            <td>002E</td>
            <td>IZMIT KORFEZI - CHORNOMORSK </td>
            <td>2020-02-03</td>
          </tr>
          <tr>
            <td>4</td>
            <td>MAERSK KAMPALA</td>
            <td>2021-02-09</td>
            <td>2021-02-20</td>
            <td>Sealand</td>
            <td>102W</td>
            <td>IZMIT KORFEZI - CHORNOMORSK </td>
            <td>2021-02-05</td>
          </tr>
          <tr>
            <td>3</td>
            <td>SANTA CRUZ </td>
            <td>2021-02-01</td>
            <td>2021-02-15</td>
            <td>Sealand</td>
            <td>101W</td>
            <td>IZMIT KORFEZI - CHORNOMORSK </td>
            <td>2021-01-29</td>
          </tr>
          <tr>
            <td>2</td>
            <td>MAERSK KIMI</td>
            <td>2021-01-25</td>
            <td>2021-02-01</td>
            <td>Sealand</td>
            <td>053E</td>
            <td>IZMIT KORFEZI - CHORNOMORSK </td>
            <td>2021-01-22</td>
          </tr>
          <tr>
            <td>1</td>
            <td>MAERSK KYRENIA</td>
            <td>2021-01-19</td>
            <td>2021-01-25</td>
            <td>Sealand</td>
            <td>052E</td>
            <td>IZMIT KORFEZI - CHORNOMORSK </td>
            <td>2021-01-15</td>
          </tr>
          <tr>
            <td>1</td>
            <td>MAERSK KALMAR</td>
            <td>2021-01-12</td>
            <td>2021-01-20</td>
            <td>Sealand</td>
            <td>051W</td>
            <td>IZMIT KORFEZI - CHORNOMORSK </td>
            <td>2021-01-08</td>
          </tr>
          <tr>
            <td>52</td>
            <td> MAERSK KITHIRA </td>
            <td>2020-12-29</td>
            <td>2021-01-05</td>
            <td>Sealand</td>
            <td>049W</td>
            <td>IZMIT KORFEZI - CHORNOMORSK </td>
            <td>2020-12-25</td>
          </tr>
          <tr>
            <td>39</td>
            <td>MAERSK KYRENIA</td>
            <td>2020-09-28</td>
            <td>2020-10-03</td>
            <td>Sealand</td>
            <td>037W</td>
            <td>IZMIT KORFEZI - CHORNOMORSK </td>
            <td>2020-09-25</td>
          </tr>
          <tr>
            <td>38</td>
            <td>MAERSK KALMAR</td>
            <td>2020-09-22</td>
            <td>2020-09-29</td>
            <td>Sealand</td>
            <td>036W</td>
            <td>IZMIT KORFEZI - CHORNOMORSK </td>
            <td>2020-09-18</td>
          </tr>
          <tr>
            <td>37</td>
            <td>MAERSK KLAIPEDA</td>
            <td>2020-09-14</td>
            <td>2020-09-28</td>
            <td>Sealand</td>
            <td>035W</td>
            <td>IZMIT KORFEZI - CHORNOMORSK </td>
            <td>2020-09-11</td>
          </tr>
          <tr>
            <td>36</td>
            <td> MAERSK KITHIRA </td>
            <td>2020-09-07</td>
            <td>2020-09-28</td>
            <td>Sealand</td>
            <td>035W</td>
            <td>IZMIT KORFEZI - CHORNOMORSK </td>
            <td>2020-09-03</td>
          </tr>
          <tr>
            <td>35</td>
            <td>MAERSK KIEL</td>
            <td>2020-09-01</td>
            <td>2020-09-20</td>
            <td>Sealand</td>
            <td>033W</td>
            <td>IZMIT KORFEZI - CHORNOMORSK </td>
            <td>2020-08-28</td>
          </tr>
          <tr>
            <td>34</td>
            <td>MAERSK KAMPALA</td>
            <td>2020-08-25</td>
            <td>2020-09-16</td>
            <td>Sealand</td>
            <td>032W</td>
            <td>IZMIT KORFEZI - CHORNOMORSK </td>
            <td>2020-08-21</td>
          </tr>
          <tr>
            <td>33</td>
            <td>SANTA CRUZ </td>
            <td>2020-08-18</td>
            <td>2020-09-14</td>
            <td>Sealand</td>
            <td>031W</td>
            <td>IZMIT KORFEZI - CHORNOMORSK </td>
            <td>2020-08-14</td>
          </tr>
          <tr>
            <td>32</td>
            <td>MAERSK KIMI</td>
            <td>2020-08-11</td>
            <td>2020-09-07</td>
            <td>Sealand</td>
            <td>030W</td>
            <td>IZMIT KORFEZI - CHORNOMORSK </td>
            <td>2020-08-07</td>
          </tr>
          <tr>
            <td>31</td>
            <td>MAERSK KYRENIA</td>
            <td>2020-08-03</td>
            <td>2020-08-18</td>
            <td>Sealand</td>
            <td>029W</td>
            <td>IZMIT KORFEZI - CHORNOMORSK </td>
            <td>2020-07-28</td>
          </tr>
          <tr>
            <td>5</td>
            <td>MAERSK BINTAN </td>
            <td>2020-01-30</td>
            <td>2020-02-03</td>
            <td>Sealand</td>
            <td>001E</td>
            <td>IZMIT KORFEZI - CHORNOMORSK </td>
            <td>2020-01-27</td>
          </tr>
        </tbody>
      </table>
    ),
  },
  {
    id: 13,
    itemText: "IZMIT KORFEZI - Novorossiysk",
    itemValue: (
      <table class="vessel-table">
        <thead>
          <tr>
            <th>Week</th>
            <th>Ship</th>
            <th>ETD</th>
            <th>ETA</th>
            <th>Line</th>
            <th>Voyage</th>
            <th>VesselCategories</th>
            <th>CutOff</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>8</td>
            <td> POLAR BRASIL</td>
            <td>2022-03-03</td>
            <td>2022-02-07</td>
            <td>Sealand</td>
            <td>205E</td>
            <td>IZMIT KORFEZI - Novorossiysk</td>
            <td>2022-02-28</td>
          </tr>
          <tr>
            <td>8</td>
            <td>SAFMARINE NOKWANDA</td>
            <td>2021-03-04</td>
            <td>2021-03-08</td>
            <td>Sealand</td>
            <td>105E</td>
            <td>IZMIT KORFEZI - Novorossiysk</td>
            <td>2021-03-01</td>
          </tr>
          <tr>
            <td>8</td>
            <td> LARS MAERSK</td>
            <td>2020-02-19</td>
            <td>2020-02-23</td>
            <td>Sealand</td>
            <td>004E</td>
            <td>IZMIT KORFEZI - Novorossiysk</td>
            <td>2020-02-17</td>
          </tr>
          <tr>
            <td>7</td>
            <td>SAFMARINE NOMAZWE</td>
            <td>2022-02-24</td>
            <td>2022-03-01</td>
            <td>Sealand</td>
            <td>204E</td>
            <td>IZMIT KORFEZI - Novorossiysk</td>
            <td>2022-02-21</td>
          </tr>
          <tr>
            <td>7</td>
            <td>LEXA MAERSK</td>
            <td>2021-02-25</td>
            <td>2021-03-01</td>
            <td>Sealand</td>
            <td>104E</td>
            <td>IZMIT KORFEZI - Novorossiysk</td>
            <td>2021-02-22</td>
          </tr>
          <tr>
            <td>7</td>
            <td>LUNA MAERSK</td>
            <td>2020-02-12</td>
            <td>2020-02-16</td>
            <td>Sealand</td>
            <td>003E</td>
            <td>IZMIT KORFEZI - Novorossiysk</td>
            <td>2020-02-10</td>
          </tr>
          <tr>
            <td>6</td>
            <td>SAFMARINE NOKWANDA</td>
            <td>2022-02-17</td>
            <td>2022-02-22</td>
            <td>Sealand</td>
            <td>203E</td>
            <td>IZMIT KORFEZI - Novorossiysk</td>
            <td>2022-02-14</td>
          </tr>
          <tr>
            <td>6</td>
            <td>LEXA MAERSK</td>
            <td>2021-02-25</td>
            <td>2021-03-02</td>
            <td>Sealand</td>
            <td>104E</td>
            <td>IZMIT KORFEZI - Novorossiysk</td>
            <td>2021-02-22</td>
          </tr>
          <tr>
            <td>6</td>
            <td>LAUST MAERSK</td>
            <td>2020-02-05</td>
            <td>2020-02-09</td>
            <td>Sealand</td>
            <td>002E</td>
            <td>IZMIT KORFEZI - Novorossiysk</td>
            <td>2020-02-03</td>
          </tr>
          <tr>
            <td>5</td>
            <td>LEDA MAERSK</td>
            <td>2022-02-10</td>
            <td>2022-02-14</td>
            <td>Sealand</td>
            <td>202E</td>
            <td>IZMIT KORFEZI - Novorossiysk</td>
            <td>2022-02-07</td>
          </tr>
          <tr>
            <td>5</td>
            <td>LAURA MAERSK</td>
            <td>2021-02-11</td>
            <td>2021-02-16</td>
            <td>Sealand</td>
            <td>102E</td>
            <td>IZMIT KORFEZI - Novorossiysk</td>
            <td>2021-02-08</td>
          </tr>
          <tr>
            <td>5</td>
            <td>LAURA MAERSK</td>
            <td>2021-02-11</td>
            <td>2021-02-16</td>
            <td>Sealand</td>
            <td>102E</td>
            <td>IZMIT KORFEZI - Novorossiysk</td>
            <td>2021-02-08</td>
          </tr>
          <tr>
            <td>5</td>
            <td>MAERSK BINTAN </td>
            <td>2020-01-30</td>
            <td>2020-02-04</td>
            <td>Sealand</td>
            <td>001E</td>
            <td>IZMIT KORFEZI - Novorossiysk</td>
            <td>2020-01-27</td>
          </tr>
          <tr>
            <td>4</td>
            <td>LAURA MAERSK</td>
            <td>2022-02-03</td>
            <td>2022-02-07</td>
            <td>Sealand</td>
            <td>201E</td>
            <td>IZMIT KORFEZI - Novorossiysk</td>
            <td>2022-01-31</td>
          </tr>
          <tr>
            <td>4</td>
            <td> LARS MAERSK</td>
            <td>2021-02-04</td>
            <td>2021-02-09</td>
            <td>Sealand</td>
            <td>101E</td>
            <td>IZMIT KORFEZI - Novorossiysk</td>
            <td>2021-02-01</td>
          </tr>
          <tr>
            <td>51</td>
            <td>ADILIA I</td>
            <td>2021-12-26</td>
            <td>2021-12-29</td>
            <td>AKKON</td>
            <td>0721</td>
            <td>IZMIT KORFEZI - Novorossiysk</td>
            <td>2021-12-24</td>
          </tr>
          <tr>
            <td>51</td>
            <td>CSAV TRAIGUEN</td>
            <td>2021-12-30</td>
            <td>2022-01-03</td>
            <td>Sealand</td>
            <td>148E</td>
            <td>IZMIT KORFEZI - Novorossiysk</td>
            <td>2021-12-27</td>
          </tr>
          <tr>
            <td>50</td>
            <td>ADILIA I</td>
            <td>2021-12-23</td>
            <td>2021-12-26</td>
            <td>AKKON</td>
            <td>0721</td>
            <td>IZMIT KORFEZI - Novorossiysk</td>
            <td>2021-12-22</td>
          </tr>
          <tr>
            <td>50</td>
            <td>MAERSK LAUNCESTON</td>
            <td>2021-12-23</td>
            <td>2021-12-25</td>
            <td>Sealand</td>
            <td>147E</td>
            <td>IZMIT KORFEZI - Novorossiysk</td>
            <td>2021-12-20</td>
          </tr>
          <tr>
            <td>49</td>
            <td>ADILIA I</td>
            <td>2021-12-11</td>
            <td>2021-12-14</td>
            <td>AKKON</td>
            <td>0621</td>
            <td>IZMIT KORFEZI - Novorossiysk</td>
            <td>2021-12-10</td>
          </tr>
          <tr>
            <td>49</td>
            <td>SAFMARINE NOMAZWE</td>
            <td>2021-12-16</td>
            <td>2021-12-18</td>
            <td>Sealand</td>
            <td>146E</td>
            <td>IZMIT KORFEZI - Novorossiysk</td>
            <td>2021-12-13</td>
          </tr>
          <tr>
            <td>49</td>
            <td>ADILIA I</td>
            <td>2021-12-14</td>
            <td>2021-12-16</td>
            <td>AKKON</td>
            <td>0721</td>
            <td>IZMIT KORFEZI - Novorossiysk</td>
            <td>2021-12-13</td>
          </tr>
          <tr>
            <td>48</td>
            <td>ADILIA I</td>
            <td>2021-12-05</td>
            <td>2021-12-07</td>
            <td>AKKON</td>
            <td>0621</td>
            <td>IZMIT KORFEZI - Novorossiysk</td>
            <td>2021-12-03</td>
          </tr>
          <tr>
            <td>48</td>
            <td>SAFMARINE NOKWANDA</td>
            <td>2021-12-09</td>
            <td>2021-12-11</td>
            <td>Sealand</td>
            <td>145E</td>
            <td>IZMIT KORFEZI - Novorossiysk</td>
            <td>2021-12-06</td>
          </tr>
          <tr>
            <td>47</td>
            <td>CSAV TRAIGUEN</td>
            <td>2021-12-02</td>
            <td>2021-12-04</td>
            <td>Sealand</td>
            <td>144E</td>
            <td>IZMIT KORFEZI - Novorossiysk</td>
            <td>2021-11-29</td>
          </tr>
          <tr>
            <td>46</td>
            <td>LAURA MAERSK</td>
            <td>2021-11-25</td>
            <td>2021-11-27</td>
            <td>Sealand</td>
            <td>143E</td>
            <td>IZMIT KORFEZI - Novorossiysk</td>
            <td>2021-11-22</td>
          </tr>
          <tr>
            <td>45</td>
            <td>LICA MAERSK</td>
            <td>2021-11-18</td>
            <td>2021-11-19</td>
            <td>Sealand</td>
            <td>142E</td>
            <td>IZMIT KORFEZI - Novorossiysk</td>
            <td>2021-11-15</td>
          </tr>
          <tr>
            <td>44</td>
            <td> LARS MAERSK</td>
            <td>2021-11-09</td>
            <td>2021-11-11</td>
            <td>Sealand</td>
            <td>141E</td>
            <td>IZMIT KORFEZI - Novorossiysk</td>
            <td>2021-11-08</td>
          </tr>
          <tr>
            <td>42</td>
            <td>LAUST MAERSK</td>
            <td>2021-10-28</td>
            <td>2021-10-31</td>
            <td>Sealand</td>
            <td>139E</td>
            <td>IZMIT KORFEZI - Novorossiysk</td>
            <td>2021-10-25</td>
          </tr>
          <tr>
            <td>42</td>
            <td>LAUST MAERSK</td>
            <td>2021-10-28</td>
            <td>2021-10-30</td>
            <td>Sealand</td>
            <td>139E</td>
            <td>IZMIT KORFEZI - Novorossiysk</td>
            <td>2021-10-25</td>
          </tr>
          <tr>
            <td>41</td>
            <td>LEDA MAERSK</td>
            <td>2021-10-21</td>
            <td>2021-10-22</td>
            <td>Sealand</td>
            <td>138E</td>
            <td>IZMIT KORFEZI - Novorossiysk</td>
            <td>2021-10-18</td>
          </tr>
          <tr>
            <td>40</td>
            <td>MAERSK LAUNCESTON</td>
            <td>2021-10-14</td>
            <td>2021-10-16</td>
            <td>Sealand</td>
            <td>137E</td>
            <td>IZMIT KORFEZI - Novorossiysk</td>
            <td>2021-10-11</td>
          </tr>
          <tr>
            <td>39</td>
            <td>SAFMARINE NOMAZWE</td>
            <td>2021-10-07</td>
            <td>2021-10-11</td>
            <td>Sealand</td>
            <td>136E</td>
            <td>IZMIT KORFEZI - Novorossiysk</td>
            <td>2021-10-04</td>
          </tr>
          <tr>
            <td>39</td>
            <td>LAURA MAERSK</td>
            <td>2020-09-24</td>
            <td>2020-10-28</td>
            <td>Sealand</td>
            <td>035E</td>
            <td>IZMIT KORFEZI - Novorossiysk</td>
            <td>2020-09-21</td>
          </tr>
          <tr>
            <td>38</td>
            <td>SAFMARINE NOKWANDA</td>
            <td>2021-09-30</td>
            <td>2021-10-01</td>
            <td>Sealand</td>
            <td>135E</td>
            <td>IZMIT KORFEZI - Novorossiysk</td>
            <td>2021-09-27</td>
          </tr>
          <tr>
            <td>38</td>
            <td> LARS MAERSK</td>
            <td>2020-09-18</td>
            <td>2020-09-22</td>
            <td>Sealand</td>
            <td>034E</td>
            <td>IZMIT KORFEZI - Novorossiysk</td>
            <td>2020-09-14</td>
          </tr>
          <tr>
            <td>37</td>
            <td>LEXA MAERSK</td>
            <td>2021-09-23</td>
            <td>2021-09-27</td>
            <td>Sealand</td>
            <td>134E</td>
            <td>IZMIT KORFEZI - Novorossiysk</td>
            <td>2021-09-20</td>
          </tr>
          <tr>
            <td>37</td>
            <td>LUNA MAERSK</td>
            <td>2020-09-10</td>
            <td>2020-09-14</td>
            <td>Sealand</td>
            <td>033E</td>
            <td>IZMIT KORFEZI - Novorossiysk</td>
            <td>2020-09-07</td>
          </tr>
          <tr>
            <td>36</td>
            <td>LAURA MAERSK</td>
            <td>2021-09-16</td>
            <td>2021-09-21</td>
            <td>Sealand</td>
            <td>133E</td>
            <td>IZMIT KORFEZI - Novorossiysk</td>
            <td>2021-09-13</td>
          </tr>
          <tr>
            <td>36</td>
            <td>LAUST MAERSK</td>
            <td>2020-09-03</td>
            <td>2020-09-07</td>
            <td>Sealand</td>
            <td>032E</td>
            <td>IZMIT KORFEZI - Novorossiysk</td>
            <td>2020-08-31</td>
          </tr>
          <tr>
            <td>35</td>
            <td>LAURA MAERSK</td>
            <td>2021-09-16</td>
            <td>2021-09-21</td>
            <td>Sealand</td>
            <td>133E</td>
            <td>IZMIT KORFEZI - Novorossiysk</td>
            <td>2021-09-13</td>
          </tr>
          <tr>
            <td>35</td>
            <td>LEDA MAERSK</td>
            <td>2020-08-27</td>
            <td>2020-08-31</td>
            <td>Sealand</td>
            <td>031E</td>
            <td>IZMIT KORFEZI - Novorossiysk</td>
            <td>2020-08-24</td>
          </tr>
          <tr>
            <td>34</td>
            <td>LICA MAERSK</td>
            <td>2021-09-09</td>
            <td>2021-09-14</td>
            <td>Sealand</td>
            <td>132E</td>
            <td>IZMIT KORFEZI - Novorossiysk</td>
            <td>2021-09-06</td>
          </tr>
          <tr>
            <td>34</td>
            <td>MAERSK LAUNCESTON</td>
            <td>2020-08-21</td>
            <td>2020-08-25</td>
            <td>Sealand</td>
            <td>032E</td>
            <td>IZMIT KORFEZI - Novorossiysk</td>
            <td>2020-08-17</td>
          </tr>
          <tr>
            <td>33</td>
            <td> LARS MAERSK</td>
            <td>2021-08-27</td>
            <td>2021-08-31</td>
            <td>Sealand</td>
            <td>131E</td>
            <td>IZMIT KORFEZI - Novorossiysk</td>
            <td>2021-08-26</td>
          </tr>
          <tr>
            <td>32</td>
            <td>LAUST MAERSK</td>
            <td>2021-08-19</td>
            <td>2021-08-24</td>
            <td>Sealand</td>
            <td>129E</td>
            <td>IZMIT KORFEZI - Novorossiysk</td>
            <td>2021-08-16</td>
          </tr>
          <tr>
            <td>31</td>
            <td>LEDA MAERSK</td>
            <td>2021-08-12</td>
            <td>2021-08-17</td>
            <td>Sealand</td>
            <td>128E</td>
            <td>IZMIT KORFEZI - Novorossiysk</td>
            <td>2021-08-09</td>
          </tr>
          <tr>
            <td>31</td>
            <td>SAFMARINE NOKWANDA</td>
            <td>2020-08-03</td>
            <td>2020-08-18</td>
            <td>Sealand</td>
            <td>028E</td>
            <td>IZMIT KORFEZI - Novorossiysk</td>
            <td>2020-07-29</td>
          </tr>
          <tr>
            <td>31</td>
            <td>SAFMARINE NOKWANDA</td>
            <td>2020-08-03</td>
            <td>2020-08-18</td>
            <td>Sealand</td>
            <td>028E</td>
            <td>IZMIT KORFEZI - Novorossiysk</td>
            <td>2020-07-29</td>
          </tr>
          <tr>
            <td>30</td>
            <td>MAERSK LAUNCESTON</td>
            <td>2021-08-05</td>
            <td>2021-08-10</td>
            <td>Sealand</td>
            <td>127E</td>
            <td>IZMIT KORFEZI - Novorossiysk</td>
            <td>2021-08-02</td>
          </tr>
          <tr>
            <td>30</td>
            <td>LICA MAERSK</td>
            <td>2020-07-24</td>
            <td>2020-07-27</td>
            <td>Sealand</td>
            <td>026E</td>
            <td>IZMIT KORFEZI - Novorossiysk</td>
            <td>2020-07-20</td>
          </tr>
          <tr>
            <td>29</td>
            <td>SAFMARINE NOMAZWE</td>
            <td>2021-07-29</td>
            <td>2021-08-03</td>
            <td>Sealand</td>
            <td>126E</td>
            <td>IZMIT KORFEZI - Novorossiysk</td>
            <td>2021-07-27</td>
          </tr>
          <tr>
            <td>29</td>
            <td>MONTE ACONCAGUA </td>
            <td>2020-07-17</td>
            <td>2020-07-21</td>
            <td>Sealand</td>
            <td>026E</td>
            <td>IZMIT KORFEZI - Novorossiysk</td>
            <td>2020-07-13</td>
          </tr>
          <tr>
            <td>28</td>
            <td>SEAGO ISTANBUL</td>
            <td>2021-07-22</td>
            <td>2021-07-27</td>
            <td>Sealand</td>
            <td>125E</td>
            <td>IZMIT KORFEZI - Novorossiysk</td>
            <td>2021-07-12</td>
          </tr>
          <tr>
            <td>28</td>
            <td>MONTE ACONCAGUA </td>
            <td>2020-07-17</td>
            <td>2020-07-22</td>
            <td>Sealand</td>
            <td>026E</td>
            <td>IZMIT KORFEZI - Novorossiysk</td>
            <td>2020-07-13</td>
          </tr>
          <tr>
            <td>27</td>
            <td>LEXA MAERSK</td>
            <td>2021-07-15</td>
            <td>2021-07-20</td>
            <td>Sealand</td>
            <td>124E</td>
            <td>IZMIT KORFEZI - Novorossiysk</td>
            <td>2021-07-12</td>
          </tr>
          <tr>
            <td>27</td>
            <td>LUNA MAERSK</td>
            <td>2020-07-03</td>
            <td>2020-07-07</td>
            <td>Sealand</td>
            <td>023E</td>
            <td>IZMIT KORFEZI - Novorossiysk</td>
            <td>2020-06-29</td>
          </tr>
          <tr>
            <td>26</td>
            <td>SEAGO ISTANBUL</td>
            <td>2021-07-08</td>
            <td>2021-07-13</td>
            <td>Sealand</td>
            <td>123E</td>
            <td>IZMIT KORFEZI - Novorossiysk</td>
            <td>2021-07-05</td>
          </tr>
          <tr>
            <td>26</td>
            <td>LAUST MAERSK</td>
            <td>2020-06-25</td>
            <td>2020-06-29</td>
            <td>Sealand</td>
            <td>022E</td>
            <td>IZMIT KORFEZI - Novorossiysk</td>
            <td>2020-06-22</td>
          </tr>
          <tr>
            <td>25</td>
            <td>LAURA MAERSK</td>
            <td>2021-07-01</td>
            <td>2021-07-06</td>
            <td>Sealand</td>
            <td>122E</td>
            <td>IZMIT KORFEZI - Novorossiysk</td>
            <td>2021-06-28</td>
          </tr>
          <tr>
            <td>25</td>
            <td>LEDA MAERSK</td>
            <td>2020-06-18</td>
            <td>2020-06-23</td>
            <td>Sealand</td>
            <td>021E</td>
            <td>IZMIT KORFEZI - Novorossiysk</td>
            <td>2020-06-15</td>
          </tr>
          <tr>
            <td>24</td>
            <td> LARS MAERSK</td>
            <td>2021-06-24</td>
            <td>2021-06-29</td>
            <td>Sealand</td>
            <td>121E</td>
            <td>IZMIT KORFEZI - Novorossiysk</td>
            <td>2021-06-21</td>
          </tr>
          <tr>
            <td>24</td>
            <td>MAERSK LAUNCESTON</td>
            <td>2020-06-11</td>
            <td>2020-06-15</td>
            <td>Sealand</td>
            <td>020E</td>
            <td>IZMIT KORFEZI - Novorossiysk</td>
            <td>2020-06-08</td>
          </tr>
          <tr>
            <td>23</td>
            <td>LUNA MAERSK</td>
            <td>2021-06-24</td>
            <td>2021-06-29</td>
            <td>Sealand</td>
            <td>120E</td>
            <td>IZMIT KORFEZI - Novorossiysk</td>
            <td>2021-06-21</td>
          </tr>
          <tr>
            <td>23</td>
            <td>SAFMARINE NOMAZWE</td>
            <td>2020-06-04</td>
            <td>2020-06-08</td>
            <td>Sealand</td>
            <td>019E</td>
            <td>IZMIT KORFEZI - Novorossiysk</td>
            <td>2020-06-01</td>
          </tr>
          <tr>
            <td>22</td>
            <td>SEAGO ISTANBUL</td>
            <td>2021-06-10</td>
            <td>2021-06-15</td>
            <td>Sealand</td>
            <td>119E</td>
            <td>IZMIT KORFEZI - Novorossiysk</td>
            <td>2021-06-07</td>
          </tr>
          <tr>
            <td>22</td>
            <td>SAFMARINE NOMAZWE</td>
            <td>2020-06-04</td>
            <td>2020-06-08</td>
            <td>Sealand</td>
            <td>019E</td>
            <td>IZMIT KORFEZI - Novorossiysk</td>
            <td>2020-05-27</td>
          </tr>
          <tr>
            <td>21</td>
            <td>LEDA MAERSK</td>
            <td>2021-06-03</td>
            <td>2021-06-08</td>
            <td>Sealand</td>
            <td>118E</td>
            <td>IZMIT KORFEZI - Novorossiysk</td>
            <td>2021-05-31</td>
          </tr>
          <tr>
            <td>21</td>
            <td>LEXA MAERSK</td>
            <td>2020-05-29</td>
            <td>2020-06-02</td>
            <td>Sealand</td>
            <td>017E</td>
            <td>IZMIT KORFEZI - Novorossiysk</td>
            <td>2020-05-20</td>
          </tr>
          <tr>
            <td>20</td>
            <td>MAERSK LAUNCESTON</td>
            <td>2021-05-27</td>
            <td>2021-06-01</td>
            <td>Sealand</td>
            <td>117E</td>
            <td>IZMIT KORFEZI - Novorossiysk</td>
            <td>2021-05-24</td>
          </tr>
          <tr>
            <td>20</td>
            <td>GRETA</td>
            <td>2021-05-22</td>
            <td>2021-05-26</td>
            <td>MARTI</td>
            <td>21-425</td>
            <td>IZMIT KORFEZI - Novorossiysk</td>
            <td>2021-05-20</td>
          </tr>
          <tr>
            <td>20</td>
            <td>LICA MAERSK</td>
            <td>2020-05-15</td>
            <td>2020-05-19</td>
            <td>Sealand</td>
            <td>016E</td>
            <td>IZMIT KORFEZI - Novorossiysk</td>
            <td>2020-05-11</td>
          </tr>
          <tr>
            <td>19</td>
            <td>SAFMARINE NOMAZWE</td>
            <td>2021-05-20</td>
            <td>2021-05-25</td>
            <td>Sealand</td>
            <td>116E</td>
            <td>IZMIT KORFEZI - Novorossiysk</td>
            <td>2021-05-17</td>
          </tr>
          <tr>
            <td>19</td>
            <td>LAURA MAERSK</td>
            <td>2020-05-07</td>
            <td>2020-05-11</td>
            <td>Sealand</td>
            <td>015E</td>
            <td>IZMIT KORFEZI - Novorossiysk</td>
            <td>2020-05-04</td>
          </tr>
          <tr>
            <td>18</td>
            <td>SAFMARINE NOKWANDA</td>
            <td>2021-05-13</td>
            <td>2021-05-18</td>
            <td>Sealand</td>
            <td>115E</td>
            <td>IZMIT KORFEZI - Novorossiysk</td>
            <td>2021-05-10</td>
          </tr>
          <tr>
            <td>18</td>
            <td>GRETA</td>
            <td>2021-05-08</td>
            <td>2021-05-13</td>
            <td>MARTI</td>
            <td>21-423</td>
            <td>IZMIT KORFEZI - Novorossiysk</td>
            <td>2021-05-06</td>
          </tr>
          <tr>
            <td>18</td>
            <td> LARS MAERSK</td>
            <td>2020-05-01</td>
            <td>2020-05-05</td>
            <td>Sealand</td>
            <td>014E</td>
            <td>IZMIT KORFEZI - Novorossiysk</td>
            <td>2020-04-27</td>
          </tr>
          <tr>
            <td>17</td>
            <td>LEXA MAERSK</td>
            <td>2021-05-06</td>
            <td>2021-05-11</td>
            <td>Sealand</td>
            <td>114E</td>
            <td>IZMIT KORFEZI - Novorossiysk</td>
            <td>2021-05-03</td>
          </tr>
          <tr>
            <td>17</td>
            <td>LUNA MAERSK</td>
            <td>2020-04-24</td>
            <td>2020-04-28</td>
            <td>Sealand</td>
            <td>013E</td>
            <td>IZMIT KORFEZI - Novorossiysk</td>
            <td>2020-04-20</td>
          </tr>
          <tr>
            <td>16</td>
            <td>SEAGO ISTANBUL</td>
            <td>2021-04-29</td>
            <td>2021-05-04</td>
            <td>Sealand</td>
            <td>113E</td>
            <td>IZMIT KORFEZI - Novorossiysk</td>
            <td>2021-04-26</td>
          </tr>
          <tr>
            <td>16</td>
            <td>LAUST MAERSK</td>
            <td>2020-04-17</td>
            <td>2020-04-21</td>
            <td>Sealand</td>
            <td>012E</td>
            <td>IZMIT KORFEZI - Novorossiysk</td>
            <td>2020-04-13</td>
          </tr>
          <tr>
            <td>15</td>
            <td>LAURA MAERSK</td>
            <td>2021-04-22</td>
            <td>2021-04-27</td>
            <td>Sealand</td>
            <td>112E</td>
            <td>IZMIT KORFEZI - Novorossiysk</td>
            <td>2021-04-19</td>
          </tr>
          <tr>
            <td>15</td>
            <td>LEDA MAERSK</td>
            <td>2020-04-10</td>
            <td>2020-04-14</td>
            <td>Sealand</td>
            <td>011E</td>
            <td>IZMIT KORFEZI - Novorossiysk</td>
            <td>2020-04-06</td>
          </tr>
          <tr>
            <td>14</td>
            <td> LARS MAERSK</td>
            <td>2021-04-15</td>
            <td>2021-04-21</td>
            <td>Sealand</td>
            <td>111E</td>
            <td>IZMIT KORFEZI - Novorossiysk</td>
            <td>2021-04-12</td>
          </tr>
          <tr>
            <td>14</td>
            <td>MAERSK LAUNCESTON</td>
            <td>2020-04-03</td>
            <td>2020-04-07</td>
            <td>Sealand</td>
            <td>010E</td>
            <td>IZMIT KORFEZI - Novorossiysk</td>
            <td>2020-03-30</td>
          </tr>
          <tr>
            <td>13</td>
            <td>SEAGO ISTANBUL</td>
            <td>2021-04-01</td>
            <td>2021-04-06</td>
            <td>Sealand</td>
            <td>112E</td>
            <td>IZMIT KORFEZI - Novorossiysk</td>
            <td>2021-03-29</td>
          </tr>
          <tr>
            <td>13</td>
            <td>SAFMARINE NOMAZWE</td>
            <td>2020-04-27</td>
            <td>2020-04-01</td>
            <td>Sealand</td>
            <td>009E</td>
            <td>IZMIT KORFEZI - Novorossiysk</td>
            <td>2020-03-23</td>
          </tr>
          <tr>
            <td>12</td>
            <td>SEAGO ISTANBUL</td>
            <td>2021-04-01</td>
            <td>2021-04-06</td>
            <td>Sealand</td>
            <td>109E</td>
            <td>IZMIT KORFEZI - Novorossiysk</td>
            <td>2021-03-29</td>
          </tr>
          <tr>
            <td>12</td>
            <td>SAFMARINE NOKWANDA</td>
            <td>2020-03-20</td>
            <td>2020-03-24</td>
            <td>Sealand</td>
            <td>008E</td>
            <td>IZMIT KORFEZI - Novorossiysk</td>
            <td>2020-03-16</td>
          </tr>
          <tr>
            <td>11</td>
            <td>LEDA MAERSK</td>
            <td>2021-03-25</td>
            <td>2021-03-30</td>
            <td>Sealand</td>
            <td>108E</td>
            <td>IZMIT KORFEZI - Novorossiysk</td>
            <td>2021-03-22</td>
          </tr>
          <tr>
            <td>11</td>
            <td>LEXA MAERSK</td>
            <td>2020-03-12</td>
            <td>2020-03-16</td>
            <td>Sealand</td>
            <td>011N</td>
            <td>IZMIT KORFEZI - Novorossiysk</td>
            <td>2020-03-09</td>
          </tr>
          <tr>
            <td>10</td>
            <td>MAERSK LAUNCESTON</td>
            <td>2021-03-18</td>
            <td>2021-03-22</td>
            <td>Sealand</td>
            <td>107E</td>
            <td>IZMIT KORFEZI - Novorossiysk</td>
            <td>2021-03-15</td>
          </tr>
          <tr>
            <td>10</td>
            <td>LICA MAERSK</td>
            <td>2020-03-06</td>
            <td>2020-03-10</td>
            <td>Sealand</td>
            <td>006E</td>
            <td>IZMIT KORFEZI - Novorossiysk</td>
            <td>2020-03-02</td>
          </tr>
          <tr>
            <td>9</td>
            <td>SAFMARINE NOMAZWE</td>
            <td>2021-03-11</td>
            <td>2021-03-15</td>
            <td>Sealand</td>
            <td>106E</td>
            <td>IZMIT KORFEZI - Novorossiysk</td>
            <td>2021-03-08</td>
          </tr>
          <tr>
            <td>9</td>
            <td>LAURA MAERSK</td>
            <td>2020-02-27</td>
            <td>2020-03-03</td>
            <td>Sealand</td>
            <td>005E</td>
            <td>IZMIT KORFEZI - Novorossiysk</td>
            <td>2020-02-24</td>
          </tr>
          <tr>
            <td>3</td>
            <td>LUNA MAERSK</td>
            <td>2021-01-29</td>
            <td>2021-02-02</td>
            <td>Sealand</td>
            <td>053E</td>
            <td>IZMIT KORFEZI - Novorossiysk</td>
            <td>2021-01-25</td>
          </tr>
          <tr>
            <td>2</td>
            <td>LAUST MAERSK</td>
            <td>2021-01-22</td>
            <td>2021-01-26</td>
            <td>Sealand</td>
            <td>052E</td>
            <td>IZMIT KORFEZI - Novorossiysk</td>
            <td>2021-01-18</td>
          </tr>
          <tr>
            <td>1</td>
            <td>LEDA MAERSK</td>
            <td>2021-01-13</td>
            <td>2021-01-17</td>
            <td>Sealand</td>
            <td>O51E</td>
            <td>IZMIT KORFEZI - Novorossiysk</td>
            <td>2021-01-11</td>
          </tr>
          <tr>
            <td>1</td>
            <td>MAERSK LAUNCESTON</td>
            <td>2021-01-08</td>
            <td>2021-01-11</td>
            <td>Sealand</td>
            <td>050E</td>
            <td>IZMIT KORFEZI - Novorossiysk</td>
            <td>2021-01-04</td>
          </tr>
          <tr>
            <td>52</td>
            <td>SAFMARINE NOKWANDA</td>
            <td>2020-12-24</td>
            <td>2020-12-29</td>
            <td>Sealand</td>
            <td>048E</td>
            <td>IZMIT KORFEZI - Novorossiysk</td>
            <td>2020-12-21</td>
          </tr>
        </tbody>
      </table>
    ),
  },
];
