import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
const RSMobileMenu = ({ setMenuOpen, menuOpen, activeMenu }) => {

	const { t } = useTranslation();
	const [home, setHome] = useState(false)
	const [homeMultipage, setHomeMultipage] = useState(false)
	const [homeOnepage, setHomeOnepage] = useState(false)
	const [about, setAbout] = useState(false)
	const [services, setServices] = useState(false)
	const [blog, setBlog] = useState(false)
	const [pages, setPages] = useState(false)
	const [servicePages, setServicePages] = useState(false)
	const [caseStudies, setCaseStudies] = useState(false)
	const [shopPages, setShopPages] = useState(false)

	const openMobileMenu = menu => {

		if (menu === 'home') {
			setHome(!home)
			setHomeMultipage(false)
			setHomeOnepage(false)
			setAbout(false)
			setServices(false)
			setBlog(false)
			setPages(false)
			setServicePages(false)
			setCaseStudies(false)
			setShopPages(false)
		}
		else if (menu === 'homeMultipage') {
			setHome(true)
			setHomeMultipage(!homeMultipage)
			setHomeOnepage(false)
			setAbout(false)
			setServices(false)
			setBlog(false)
			setPages(false)
			setServicePages(false)
			setCaseStudies(false)
			setShopPages(false)
		}
		else if (menu === 'homeOnepage') {
			setHome(true)
			setHomeMultipage(false)
			setHomeOnepage(!homeOnepage)
			setAbout(false)
			setServices(false)
			setBlog(false)
			setPages(false)
			setServicePages(false)
			setCaseStudies(false)
			setShopPages(false)
		}
		else if (menu === 'about') {
			setHome(false)
			setHomeMultipage(false)
			setHomeOnepage(false)
			setAbout(!about)
			setServices(false)
			setBlog(false)
			setPages(false)
			setServicePages(false)
			setCaseStudies(false)
			setShopPages(false)
		}
		else if (menu === 'services') {
			setHome(false)
			setHomeMultipage(false)
			setHomeOnepage(false)
			setAbout(false)
			setServices(!services)
			setBlog(false)
			setPages(false)
			setServicePages(false)
			setCaseStudies(false)
			setShopPages(false)
		}
		else if (menu === 'blog') {
			setHome(false)
			setHomeMultipage(false)
			setHomeOnepage(false)
			setAbout(false)
			setServices(false)
			setBlog(!blog)
			setPages(false)
			setServicePages(false)
			setCaseStudies(false)
			setShopPages(false)
		}
		else if (menu === 'pages') {
			setHome(false)
			setHomeMultipage(false)
			setHomeOnepage(false)
			setAbout(false)
			setServices(false)
			setBlog(false)
			setPages(!pages)
			setServicePages(false)
			setCaseStudies(false)
			setShopPages(false)
		}
		else if (menu === 'servicePages') {
			setHome(false)
			setHomeMultipage(false)
			setHomeOnepage(false)
			setAbout(false)
			setServices(false)
			setBlog(false)
			setPages(true)
			setServicePages(!servicePages)
			setCaseStudies(false)
			setShopPages(false)
		}
		else if (menu === 'caseStudies') {
			setHome(false)
			setHomeMultipage(false)
			setHomeOnepage(false)
			setAbout(false)
			setServices(false)
			setBlog(false)
			setPages(true)
			setServicePages(false)
			setCaseStudies(!caseStudies)
			setShopPages(false)
		}
		else if (menu === 'shopPages') {
			setHome(false)
			setHomeMultipage(false)
			setHomeOnepage(false)
			setAbout(false)
			setServices(false)
			setBlog(false)
			setPages(true)
			setServicePages(false)
			setCaseStudies(false)
			setShopPages(!shopPages)
		}
	};

	return (
		<div className="container relative">
			<div className={menuOpen ? "mobile-menu-part open" : "mobile-menu-part"}>
				<div className="mobile-menu">
					<ul className="nav-menu">
						<li>
							<Link to="/" className={activeMenu === "/" ? "active-menu" : ""}>{t("menu.home")}</Link>
						</li>
						<li>
							<Link to="/about" className={activeMenu === "/about" ? "active-menu" : ""}>{t("menu.about")}</Link>
						</li>
						<li >
                <Link to="/scholarship" className={activeMenu === "/scholarship" ? "active-menu" : ""}>{t("menu.scholarShip")}</Link>
            </li>
						<li className={services ? "menu-item-has-children current-menu-item" : "menu-item-has-children"}>
						<Link to="#" onClick={() => { openMobileMenu('services'); }}>{t("menu.service")}</Link>
							<ul className={services ? "sub-menu current-menu" : "sub-menu"}>            <li>
                        <Link to="/service/airline-transport" className={activeMenu === "/airline-transport" ? "active-menu" : ""}>{t("menu.service1")}</Link>
                    </li>
                    <li>
                        <Link to="/service/railway-transport" className={activeMenu === "/railway-transport" ? "active-menu" : ""}>{t("menu.service2")}</Link>
                    </li>
                    <li>
                        <Link to="/service/road-transport" className={activeMenu === "/road-transport" ? "active-menu" : ""}>{t("menu.service3")}</Link>
                    </li>
                    <li>
                        <Link to="/service/sea-transport" className={activeMenu === "/sea-transport" ? "active-menu" : ""}>{t("menu.service4")}</Link>
                    </li>
                    <li>
                        <Link to="/service/multimodal-transport" className={activeMenu === "/multimodal-transport" ? "active-menu" : ""}>{t("menu.service5")}</Link>
                    </li>
                    <li>
                        <Link to="/service/blacksea-transport" lassName={activeMenu === "/blacksea-transport" ? "active-menu" : ""}>{t("menu.service6")}</Link>
                    </li>
                </ul>
						</li>
						
                    <li className={''}>
                <a href="https://www.baisgloballogistics.com" className={activeMenu === "/contact" ? "active-menu" : ""}>{t("menu.eBais")}</a>
            </li>
            <li >
                <Link to="/contact" className={activeMenu === "/contact" ? "active-menu" : ""}>{t("menu.contact")}</Link>
            </li>
					</ul>
				</div>
			</div>
		</div>
	)
}

export default RSMobileMenu;