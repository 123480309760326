import SectionTitle from '../SectionTitle';

import techImg1 from '../../../assets/img/logo/eczacı.png';
import techImg2 from '../../../assets/img/logo/ciner.png';
import techImg3 from '../../../assets/img/logo/Sisecam_yatay_logo.png';
import techImg4 from '../../../assets/img/logo/daikin.jpg';
import techImg5 from '../../../assets/img/logo/BorusanHolding.png';
import techImg6 from '../../../assets/img/logo/arcelik.png';
import techImg7 from '../../../assets/img/logo/vestel.png';
import techImg8 from '../../../assets/img/logo/petlas-lastik-logo.png';
import techImg9 from '../../../assets/img/logo/yurtbay-seramik.png';
import techImg10 from '../../../assets/img/logo/seramiksan.png';
import techImg11 from '../../../assets/img/logo/kutahya-seramik-log.png';
import techImg12 from '../../../assets/img/logo/logos_yildizentegre.png';
import { useTranslation } from 'react-i18next';

const Brand = (props) => {
    const { t } = useTranslation();
    return (
        <div className="rs-technology gray-bg pt-120 pb-130 md-pt-70 md-pb-80 rs-rain-animate">
            <div className="container">
                <SectionTitle
                    sectionClass="sec-title2 text-center mb-44"
                    titleClass="title title2"
                    title={t("references.content")}
                />
                <div className="row">
                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="technology-item">
                            <a href="#">
                                <div className="logo-img">
                                    <img src={techImg1} alt="" />
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="technology-item">
                            <a href="#">
                                <div className="logo-img">
                                    <img src={techImg2} alt="" />
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="technology-item">
                            <a href="#">
                                <div className="logo-img">
                                    <img src={techImg3} alt="" />
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="technology-item">
                            <a href="#">
                                <div className="logo-img">
                                    <img src={techImg4} alt="" />
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="technology-item">
                            <a href="#">
                                <div className="logo-img">
                                    <img src={techImg5} alt="" />
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="technology-item">
                            <a href="#">
                                <div className="logo-img">
                                    <img src={techImg6} alt="" />
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="technology-item">
                            <a href="#">
                                <div className="logo-img">
                                    <img src={techImg7} alt="" />
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="technology-item">
                            <a href="#">
                                <div className="logo-img">
                                    <img src={techImg8} alt="" />
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="technology-item">
                            <a href="#">
                                <div className="logo-img">
                                    <img src={techImg9} alt="" />
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="technology-item">
                            <a href="#">
                                <div className="logo-img">
                                    <img src={techImg10} alt="" />
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="technology-item">
                            <a href="#">
                                <div className="logo-img">
                                    <img src={techImg11} alt="" />
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="technology-item">
                            <a href="#">
                                <div className="logo-img">
                                    <img src={techImg12} alt="" />
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="line-inner">
                    <div className="line"></div>
                    <div className="line" style={{display: "none"}}></div>
                    <div className="line"></div>
                </div>
            </div>
        </div>
    )
}

export default Brand;